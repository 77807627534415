import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { AddonsService } from 'src/app/shared/services/addons.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BoletasService } from 'src/app/shared/services/boletas.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { DataService } from  'src/app/shared/services/data.service';
import { FileService } from 'src/app/shared/services/file.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolpeService } from 'src/app/shared/services/solpe.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { ViewExcelButtonsProps } from 'src/app/shared/view-excel-button/view-excel-buttons.component';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { PDF } from 'src/app/shared/functions/pdf';
import { formatCurrency } from 'src/app/shared/functions/common';

@Component({
  selector: 'app-solpe-detalle',
  templateUrl: './solpe-detalle.component.html',
  styleUrls: ['./solpe-detalle.component.css']
})
export class SolpeDetalleComponent implements OnInit {

  solpeVisible: boolean;

  /** Acciones permitidas para el usuario segun su rol */
  accionesPermitidas: string[] = [];
  accionesPermitidasCorr: string[] = [];
  userId: number;
  rolId: number;
  porcentajeModifacionInfo: number = 0;
  porcentajeSumaModifacionesInfo: number = 0;

  corrSolpe: number = 0;
  corrEstadoSolpe: number
  tipoSolpeCorr: number
  formaSolpeCorr: number
  codigoSolpe: string
  estadoSolpe: string = null
  solpe: any
  tipos: any[] = []
  formas: any[] = []
  monedas: any[] = []
  contractCurrenci
  solpeCurrenci

  formSolpe: FormGroup
  fechaTerminoSolpe: string | Date
  lblFechaTerminoSolpe = 'Fecha Término Solpe'
  mnjTipoCompras = false
  documentosCargados = false
  empresasPropuestas: any[] = []
  contCorrelativo = 0
  contrato = {
    CODIGO_CONTRATO: '',
    FECHA_INICIO_CONTRATO: '',
    FECHA_TERMINO_CONTRATO: '',
    PROVEEDOR: '',
    TIPO_MONEDA: '',
    RUT_PROVEEDOR: '',
    MONTO_ORIGINAL: 0,
    NOMBRE_CONTRATO: '',
    MONTO_ACTUALIZADO: 0,
    ADMINISTRADOR: '',
    MONTO_REAJUSTE: 0,
    SALDO_CONTRATO: 0,
    REQUIERE_BG: 0,
    ADMIN_PROVEEDOR: '',
    TIMO_CORRELATIVO: 9,
    MONTO_EP: 0
  }

  /** Archivos */
  documentosSolpe: any[]
  formAgregarArchivo: FormGroup
  archivos: any[]
  archivos64 :any
  fileName :string
  type :string
  submittedDocumento = false
  ordenCompraSubida = false

  /** Comentarios */
  comentariosSolpe: any[] = []
  formAgregarComentario: FormGroup
  submittedComentario = false

  /** Distribucion */
  formAgregarDistribucion: FormGroup
  formModificarPorcentaje: FormGroup
  formAgregarEmpresaPropuesta: FormGroup
  submittedDistribucion = false
  submittedEmpresaPropuesta = false
  submittedModificarPorcentaje = false
  articulos:any
  zonas:any
  blancos:any
  proyectos: any
  grafos: any
  distribucion: any = null
  selection = new SelectionModel<any>(false, [])
  totalPorcentaje: number = 0
  grafosAprobados = 0;
  grafosRechazados = 0;
  corr_distribucion = 0;
  maxPorcentajeDisponible = 0;

  /** Historial */
  historial: any[]

  /** Encuesta */
  formAgregarEncuesta: FormGroup
  submittedEncuesta = false
  encuesta: any[] = []

  /** Proveedor */
  formProveedor: FormGroup
  submittedProveedor = false
  proveedores: any[]
  nuevoProveedor = false
  proveedorRelacionado = false
  relacionProveedor: any[] = []

  /** Form Asignacion Directa */
  formAsignacion: FormGroup
  submittedAsignacion = false
  justificacion: any[] = [];
  justificacionData: any[] = [];
  formTablaAsignacion: FormGroup;

  /** Servicios Profesionales */
  formServicios: FormGroup
  submittedServicios = false
  fechaSolpe = null;
  usuarioSolicitante = null
  dataServiciosPro: any[] = []

  /** Modificacion de Contrato */
  formAgregarModificacion: FormGroup
  submittedModificacion = false
  modificacionesCto: any[] = []

  /** Getionador */
  gestionadores: any[];
  formGCC: FormGroup;
  gcc_correlativo: number;
  isGestionada = false;

  formRechazar: FormGroup;
  submittedRechazar = false;

  formCambioGestor: FormGroup;
  submittedCambioGestor = false;

  /* Orden de compra */
  formAgregarOrdenCompra: FormGroup;
  submittedOrdenCompra: boolean;

  dataSource:any;
  dataSourceHeader: string[] = ['Articulo', 'Zona', 'Blanco', 'Proyecto', 'Porcentaje', 'Grafo', 'Monto', 'Responsable', 'Acciones', 'Eliminar']
  dataHistorial:any
  /* dataHeaderHistorial: string[] = ['Fecha', 'Usuario', 'Tipo', 'Estado', 'Vigente', 'Grafo', 'Resp', 'Observacion'] */
  dataHeaderHistorial: string[] = ['Fecha', 'Usuario', 'Tipo', 'Estado', 'Vigente', 'Grafo', 'Resp']
  selectionHistorial = new SelectionModel<any>(false, []);

  montoActualizadoContrato: number = 0;
  montoActualizadoContratoLbl: number = 0;
  montoModificadoContrato: number = 0;
  montoModificadoContratotoLbl: number = 0;
  enviarAPyC: boolean = false;
  estrategiaLiberacion: any[];
  tipoModificacion = '';
  nombreProveedor: string;
  tipoContrato: boolean = false;
  submitted: boolean = false;
  formaPagosMis = false;
  fechaEstimadaIncioServicio: string | null = null;
  duracionEstimadaServicio: number | null = null;
  /* actulizar solpe*/

  formUpdateSolpe : FormGroup;
  empresas_propuestas;

  excelButtonHistorial: ViewExcelButtonsProps = {
    title: 'Historial Solpe',
    filename: 'historial_solpe',
    headers: [
      {
        title: 'Fecha',
        value: 'FECHA_CREACION',
      },
      {
        title: 'Usuario',
        value: 'NOMBRE_USUARIO',
      },
      {
        title: 'Tipo',
        value: 'TIPO',
      },
      {
        title: 'Estado',
        value: 'ESTADO',
      },
      {
        title: 'Vigente',
        value: 'VIGENTE',
      },
      {
        title: 'Grafo',
        value: 'GRAFO',
      },
      {
        title: 'Resp',
        value: 'RESP',
      }
    ],
    data: []
  };

  encuentaEnviada: boolean;

  @ViewChild('closeModalAgregarArchivo') closeModalAgregarArchivo: ElementRef;
  @ViewChild('closeModalAgregarComentario') closeModalAgregarComentario: ElementRef;
  @ViewChild('closeModalAgregarDistribucion') closeModalAgregarDistribucion: ElementRef;
  @ViewChild('closeModalEncuesta') closeModalEncuesta: ElementRef;
  @ViewChild('closeModalAgregarProveedor') closeModalAgregarProveedor: ElementRef;
  @ViewChild('closeModalAsignacion') closeModalAsignacion: ElementRef;
  @ViewChild('closeModalServicios') closeModalServicios: ElementRef;
  @ViewChild('closeModalModificarPorcentaje') closeModalModificarPorcentaje: ElementRef;
  @ViewChild('closeModalRechazar') closeModalRechazar: ElementRef;
  @ViewChild('closeModalOC') closeModalOC: ElementRef;
  @ViewChild('closeModalCambioGestor') closeModalCambioGestor: ElementRef;
  @ViewChild('closeModalUpdateData') closeModalUpdateData: ElementRef;
  @ViewChild('closeModalEliminarArchivo') closeModalEliminarArchivo: ElementRef;

  nombreArchivoSolpe: string;
  correlativoArchivoSolpe: number;
  aceptarDistribucionCorrelativo: number;
  eliminarDistribucionCorrelativo: number;
  rechazarDistribucionCorrelativo: number;

  constructor(
    private boletasService: BoletasService,
    private dataService: DataService,
    private solpeService: SolpeService,
    private notifyService: NotificationService,
    private addonsService: AddonsService,
    private fileService: FileService,
    private contratoService: ContratoService,
    private authService: AuthService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public GloblaDataService: GlobalDataService,
  ) {

    this.solpeVisible = false;
    this.corrSolpe = this.dataService.corrSolpe;
    this.corrEstadoSolpe = this.dataService.corrEstadoSolpe;
    this.aceptarDistribucionCorrelativo = 0;
    this.eliminarDistribucionCorrelativo = 0;
    this.rechazarDistribucionCorrelativo = 0;
    this.submittedOrdenCompra = false;

    this.activatedRoute.params.subscribe(params => {
      this.corrSolpe =  params['solpe_correlativo'];
    });

    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
    let corrMenu = 1;
    this.authService.getAccionesPorUsuario(corrMenu).subscribe(resp => {
      let acciones: any[] = JSON.parse(resp);
      if(acciones.length > 0) {
        this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
        this.accionesPermitidasCorr = acciones[0].ACCIONES_CORRELATIVO.split(' - ');
      }
    },
    error => {console.log(error)});


    this.formAgregarEmpresaPropuesta = new FormGroup({
      empresa: new FormControl(null, Validators.required)
    });

    this.enviarAPyC = true;
    this.encuentaEnviada = false;
    this.estrategiaLiberacion = [];
    this.nombreProveedor = '';

    this.formUpdateSolpe = new FormGroup({
      'tipo_solicitud' : new FormControl(null, Validators.required),
      'forma' : new FormControl(null),
      'fecha_termino' : new FormControl(null),
      'nombre' : new FormControl(null, Validators.required),
      'descripcion' : new FormControl(null, Validators.required),
      'monto' : new FormControl(null),
      'moneda' : new FormControl(null),
      'agregar_empresa': new FormControl(null),
      'rut_empresa' : new FormControl(null),
      'razon_social' : new FormControl(null),
      'contrato': new FormControl(null, Validators.required)
    });

    if(this.GloblaDataService.CurrenciesList?.length == 0 || this.GloblaDataService.CurrenciesList == undefined)
    {
      this.GloblaDataService.GetCurrencie();
    }
    
    
    this.solpeService.getRelacionSolpeProveedor(this.corrSolpe).subscribe(resp => {
      this.relacionProveedor = JSON.parse(resp);
      //this.relacionProveedor.length
    });

    this.validateExisteJustificacionAsginacion();

    this.nombreArchivoSolpe = '';
    this.correlativoArchivoSolpe = 0;
  }
  existeAccion(accion: string) {
    return (this.accionesPermitidasCorr.includes(accion));
  }
  ngOnInit(): void {
    this.getSolpe();
    this.getTipos()
    this.getFormas()
    this.getMonedas()
    this.getArchivos()
    this.getDocumentosSolpe()
    this.getGestionador()

    this.getArticulos()
    this.getZonas()
    this.getBlancos()
    this.getProyectos()
    this.getGrafos()
    this.getHistorial()
    this.getDistribucion()
    this.getProveedores()
    this.getDocumentosSolpe()
    this.getComentariosSolpe(this.corrSolpe)
    this.definirEstrategiaLiberacion();


    this.resetFormSolpe()
    this.resetFormArchivo()
    this.resetFormComentario()
    this.resetFormDistribucion()
    this.resetFormEncuesta()
    this.resetFormProveedor()
    this.resetFormAsignacion()
    this.resetFormServiciosPro()
    this.resetFormModificacion()
    this.resetFormModificarPorcentaje();
    this.resetFormGCC();
    this.resetFormRechazar();
    this.resetFormOrdenCompra();
    this.resetFormCambioGestor();
  }

  validateExisteJustificacionAsginacion(){
    this.solpeService.getJustificacion(this.corrSolpe).subscribe(resp => {
      this.justificacionData = JSON.parse(resp);
    });
  }

  definirEstrategiaLiberacion(){
    let pasosBasicos: any[] = [];

    this.solpeService.postCargosSolpeEstrategia({in_solpe_correlativo: this.corrSolpe}).subscribe(resp => {
      let cargosSolpeEstrategia: any[] = JSON.parse(resp);
      let pasosBasicos: any[] = [];
      let vaAJac: any[] = cargosSolpeEstrategia.filter(item => item.cargo_correlativo === 25);
      //console.log(cargosSolpeEstrategia);

      if (this.tipoSolpeCorr === 82) {
        if (this.enviarAPyC) {

          if (vaAJac.length > 0) {
            pasosBasicos = [
              'Solicitante',
              'Enviar a JAC',
              'Enviar a PyC',
              'Enviar a Responsable(s) Grafos',
              'Enviar a Director(a) Administración y Finanzas'
            ];
          } else {
            pasosBasicos = [
              'Solicitante',
              'Enviar a JAC',
              'Enviar a PyC',
              'Enviar a Responsable(s) Grafos',
              'Enviar a Director(a) Administración y Finanzas',
            ];
          }

          for (let index = 0; index < cargosSolpeEstrategia.length; index++) {
            const element = cargosSolpeEstrategia[index];

            if (element.cargo_correlativo === 25) {
              pasosBasicos.push('Enviar a Directorio (MAF)');
            }

            if (element.cargo_correlativo === 11) {
              pasosBasicos.push('Enviar a Gerente General (MAF)');
            }

            if (element.cargo_correlativo === 8) {
              pasosBasicos.push('Enviar a Director(a) Administración y Finanzas (MAF)');
            }

            if (element.cargo_correlativo === 27) {
              pasosBasicos.push('Enviar a Director de Abastecimiento (MAF)');
            }

            if (element.cargo_correlativo === 15) {
              pasosBasicos.push('Enviar a Jefe de Abastecimiento y Contratos (MAF)');
            }

          }

          pasosBasicos.push(
            'Enviar a Jefe de Abastecimiento y Contratos',
            'Designación a Gestionador',
            'Gestión',
            'Cierre'
          );

          this.estrategiaLiberacion = pasosBasicos;
        } else {

          if (vaAJac.length > 0) {
            pasosBasicos = [
              'Solicitante',
              'Enviar a Jefe de Abastecimiento y Contratos',
              'Enviar a Director(a) Administración y Finanzas',
            ];
          } else {
            pasosBasicos = [
              'Solicitante',
              'Enviar a Jefe de Abastecimiento y Contratos',
              'Enviar a Director(a) Administración y Finanzas',
            ];
          }

          for (let index = 0; index < cargosSolpeEstrategia.length; index++) {
            const element = cargosSolpeEstrategia[index];

            if (element.cargo_correlativo === 25) {
              pasosBasicos.push('Enviar a Directorio (MAF)');
            }

            if (element.cargo_correlativo === 11) {
              pasosBasicos.push('Enviar a Gerente General (MAF)');
            }

            if (element.cargo_correlativo === 8) {
              pasosBasicos.push('Enviar a Director(a) Administración y Finanzas (MAF)');
            }

            if (element.cargo_correlativo === 27) {
              pasosBasicos.push('Enviar a Director de Abastecimiento (MAF)');
            }

            if (element.cargo_correlativo === 15) {
              pasosBasicos.push('Enviar a Jefe de Abastecimiento y Contratos (MAF)');
            }
          }

          pasosBasicos.push(
            'Enviar a Jefe de Abastecimiento y Contratos',
            'Designación a Gestionador',
            'Gestión',
            'Cierre'
          );

          this.estrategiaLiberacion = pasosBasicos;
        }
      } else {

        if (this.formaSolpeCorr == 66) {
          pasosBasicos = [
            'Solicitante',
            'Enviar a JAC',
            'Enviar a PyC',
            'Enviar a Responsable(s) Grafos',
            'Enviar a Director(a) Administración y Finanzas',
            'Enviar a Jefe de Abastecimiento y Contratos'
          ];

          for (let index = 0; index < cargosSolpeEstrategia.length; index++) {
            const element = cargosSolpeEstrategia[index];

            if (element.cargo_correlativo === 25) {
              pasosBasicos.push('Enviar a Directorio (MAF)');
            }

            if (element.cargo_correlativo === 11) {
              pasosBasicos.push('Enviar a Gerente General (MAF)');
            }

            if (element.cargo_correlativo === 8) {
              pasosBasicos.push('Enviar a Director(a) Administración y Finanzas (MAF)');
            }

            if (element.cargo_correlativo === 27) {
              pasosBasicos.push('Enviar a Director de Abastecimiento (MAF)');
            }

            if (element.cargo_correlativo === 15) {
              pasosBasicos.push('Enviar a Jefe de Abastecimiento y Contratos (MAF)');
            }
          }

          pasosBasicos.push(
            'Enviar a Jefe de Abastecimiento y Contratos',
            'Designación a Gestionador',
            'Gestión',
            'Cierre'
          );

          this.estrategiaLiberacion = pasosBasicos;
        }else{
          if (vaAJac.length > 0) {
            pasosBasicos = [
              'Solicitante',
              'Enviar a JAC',
              'Enviar a PyC',
              'Enviar a Responsable(s) Grafos',
              'Enviar a Director(a) Administración y Finanzas'
            ];
          } else {
            pasosBasicos = [
              'Solicitante',
              'Enviar a JAC',
              'Enviar a PyC',
              'Enviar a Responsable(s) Grafos',
              'Enviar a Director(a) Administración y Finanzas',
              'Enviar a Jefe de Abastecimiento y Contratos'
            ];
          }

          for (let index = 0; index < cargosSolpeEstrategia.length; index++) {
            const element = cargosSolpeEstrategia[index];

            if (element.cargo_correlativo === 25) {
              pasosBasicos.push('Enviar a Directorio (MAF)');
            }

            if (element.cargo_correlativo === 11) {
              pasosBasicos.push('Enviar a Gerente General (MAF)');
            }

            if (element.cargo_correlativo === 8) {
              pasosBasicos.push('Enviar a Director(a) Administración y Finanzas (MAF)');
            }

            if (element.cargo_correlativo === 27) {
              pasosBasicos.push('Enviar a Director de Abastecimiento (MAF)');
            }

            if (element.cargo_correlativo === 15) {
              pasosBasicos.push('Enviar a Jefe de Abastecimiento y Contratos (MAF)');
            }
          }

          pasosBasicos.push(
            'Enviar a Jefe de Abastecimiento y Contratos',
            'Designación a Gestionador',
            'Gestión',
            'Cierre'
          );

          this.estrategiaLiberacion = pasosBasicos;
        }
      }
    });
  }

  calcularPorcentajeMonto(){
    setTimeout(() => {
      if(this.formAgregarModificacion.value.monto_modificacion != null){
        this.porcentajeModifacionInfo = ((this.formAgregarModificacion.value.monto_modificacion / this.contrato.MONTO_ORIGINAL) * 100);
        let porcentajeContrato: number = ((this.contrato.MONTO_ACTUALIZADO - this.contrato.MONTO_ORIGINAL)/this.contrato.MONTO_ORIGINAL)*100;
        this.porcentajeSumaModifacionesInfo = porcentajeContrato + this.porcentajeModifacionInfo;
      }
    }, 500);
  }

  back(): void {
    this.location.back();
  }

  private getSolpe() {
    this.solpeService.getSolpe(this.corrSolpe).subscribe(resp => {
      this.solpe = JSON.parse(resp)[0]

      if (this.solpe.TIMO_CORRELATIVO !== null) {
        if(this.GloblaDataService.CurrenciesList?.length > 0){
          this.solpeCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.solpe.TIMO_CORRELATIVO)[0]
        }
        else{
          this.GloblaDataService.GetCurrencie();
          this.solpeCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.solpe.TIMO_CORRELATIVO)[0]
        } 
      }

      this.contCorrelativo = this.solpe.CONT_CORRELATIVO
      this.corrEstadoSolpe = this.solpe.ESTADO_CORRELATIVO
      this.estadoSolpe = this.solpe.ESTADO_SOLPE
      this.tipoSolpeCorr = this.solpe.TIPO_SOLPE_CORRELATIVO
      this.formaSolpeCorr = this.solpe.FORMA_SOLPE_CORRELATIVO
      this.codigoSolpe = this.solpe.SOLPE_CODIGO
      this.gcc_correlativo = (this.solpe.GCC_CORRELATIVO == null) ? 0 : this.solpe.GCC_CORRELATIVO;
      this.solpeVisible = this.solpe.VISIBLE === 1 ? true : false;

      if(this.solpe['FECHA_TERMINO_SOLPE']) {
        this.fechaTerminoSolpe = this.solpe['FECHA_TERMINO_SOLPE']
        this.mnjTipoCompras = false
        this.lblFechaTerminoSolpe = 'Fecha Término Solpe'
      } else {
        this.fechaTerminoSolpe = this.solpe['FECHA_ENTREGA_OC']
        this.mnjTipoCompras = true
        this.lblFechaTerminoSolpe = 'Fecha Requerida Producto'
      }

      if(this.tipoSolpeCorr != 82 && this.solpe['EMPR_PROPUESTA']) {
        let empresasPropuestas = this.solpe['EMPR_PROPUESTA']
        this.empresasPropuestas = empresasPropuestas.split(',')
        this.empresas_propuestas = this.empresasPropuestas
      }
      //this.documentosCargados = this.solpe['TODOS_DOC_ADJ'] == 'S'

      this.fechaSolpe = this.solpe.FECHA_INGRESO_SOLPE;
      this.fechaEstimadaIncioServicio = this.solpe.FECHA_ESTIMADA_INICIO_SERVICIO;
      this.duracionEstimadaServicio = this.solpe.DURACION_ESTIMADA_SERVICIO;
      this.usuarioSolicitante = this.solpe.USUA_SOLICITANTE;

      let rut_empresa = this.solpe.RUT_EMPRESA + "-" + this.solpe.DIG_RUT_EMPRESA;
      this.formSolpe = new FormGroup({
        solicitante: new FormControl(this.usuarioSolicitante),
        empresa: new FormControl({value: this.solpe.RAZON_SOCIAL, disabled: true}),
        rut_empresa: new FormControl({value: rut_empresa, disabled: true}),
        fecha_ingreso: new FormControl(moment(this.fechaSolpe).format('DD-MM-YYYY')),
        nombre: new FormControl(this.solpe.NOMBRE_SOLPE),
        monto: new FormControl(this.solpe.MONTO_SOLPE),
        descripcion: new FormControl(this.solpe.DESC_SOLPE),
        tipo_solicitud: new FormControl(this.solpe.TIPO_SOLPE_CORRELATIVO),
        forma: new FormControl(this.solpe.FORMA_SOLPE_CORRELATIVO),
        moneda: new FormControl(this.solpe.TIMO_CORRELATIVO)
      });
      this.onChangeTipo();
      this.resetFormAsignacion();
    },
    error => { console.log(error); },
    () => {
      this.formGCC.controls.gestionador.setValue(this.gcc_correlativo);
      if(this.gcc_correlativo > 0) {
        //this.formGCC.controls.gestionador.disable();
        //this.formGCC.controls.gestionador.updateValueAndValidity();
        //this.isGestionada = true;
        if(!((this.corrEstadoSolpe == 80 || this.corrEstadoSolpe == 132 || this.corrEstadoSolpe == 133) && this.rolId == 15))
        {
          this.formGCC.controls.gestionador.disable();
          this.formGCC.controls.gestionador.updateValueAndValidity();
        }
      }

      if(this.tipoSolpeCorr == 82) {
        this.contratoService.getContratoId(this.contCorrelativo).subscribe(resp => {
          this.contrato = JSON.parse(resp)[0];
          this.contractCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.contrato.TIMO_CORRELATIVO);
        });
        
        setTimeout(() => {
          this.getModificacionCto();
        }, 500);
      }
      this.getHistorial()
    });
  }
  private getTipos() {
    this.boletasService.getTipoDominios('TIPOSOLPE').subscribe(resp => {
      this.tipos = JSON.parse(resp)
    });
  }
  private getMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.monedas = JSON.parse(resp);
    });
  }
  private getFormas() {
    this.boletasService.getTipoDominios('FORSOLPE').subscribe(resp => {
      this.formas = JSON.parse(resp);
    });
  }
  private resetFormSolpe() {
    this.formSolpe = new FormGroup({
      solicitante: new FormControl({value: null, disabled: true}),
      empresa: new FormControl({value: null, disabled: true}),
      rut_empresa: new FormControl({value: null, disabled: true}),
      fecha_ingreso: new FormControl({value: null, disabled: true}),
      nombre: new FormControl({value: null, disabled: true}),
      monto: new FormControl({value: null, disabled: true}),
      descripcion: new FormControl({value: null, disabled: true}),
      tipo_solicitud: new FormControl({value: 0, disabled: true}),
      forma: new FormControl({value: 0, disabled: true}),
        moneda: new FormControl({value: 0, disabled: true})
    })
  }
  private resetFormArchivo(){
    this.formAgregarArchivo = new FormGroup({
      grupo : new FormControl(null, Validators.required),
      adjunto : new FormControl(null),
      corr_solpe: new FormControl(this.corrSolpe)
    });
    this.submittedDocumento = false;
    this.fileName = null;
  }
  private resetFormComentario(){
    this.formAgregarComentario = new FormGroup({
      comentario : new FormControl(null, Validators.required),
      corr_solpe: new FormControl(this.corrSolpe)
    });
    this.submittedComentario = false;
  }
  private resetFormModificacion() {
    this.formAgregarModificacion = new FormGroup({
      monto_modificacion : new FormControl({value: null, disabled: true}),
      monto_actualizado : new FormControl({value: null, disabled: true}),
      fecha_inicio : new FormControl({value: null, disabled: true}),
      fecha_termino : new FormControl({value: null, disabled: true}),
      fecha_inicio_2 : new FormControl({value: null, disabled: true}),
      fecha_termino_2 : new FormControl({value: null, disabled: true}),
      alcance : new FormControl({value: null, disabled: true}),
      chk_monto: new FormControl(false),
      chk_fecha: new FormControl(false),
      chk_alcance: new FormControl(false)
    });

    this.submittedModificacion = false;
  }
  /** Modificacion de contrato */
  getModificacionCto() {
    this.solpeService.getModificacionCto(this.corrSolpe).subscribe(resp => {
      this.modificacionesCto = JSON.parse(resp);

      let modificacionPorMonto: any[] = this.modificacionesCto.filter(item => item.TIMOD_CORRELATIVO === 83);
      if (modificacionPorMonto.length > 0) {
        this.enviarAPyC = true;
      } else {
        this.enviarAPyC = true;
      }

      if(this.modificacionesCto.length > 0) {
        this.formAgregarModificacion.controls.chk_monto.disable();
        this.formAgregarModificacion.controls.chk_fecha.disable();
        this.formAgregarModificacion.controls.chk_alcance.disable();

        for(let item of this.modificacionesCto) {
          if(item.TIMOD_CORRELATIVO == 83) {
            this.formAgregarModificacion.controls.chk_monto.setValue(1);
            this.formAgregarModificacion.controls.monto_modificacion.setValue(item.MONTO_MODIFICACION);
            this.formAgregarModificacion.controls.monto_actualizado.setValue(item.MONTO_ACTUALIZADO);
            this.montoActualizadoContrato = item.MONTO_ACTUALIZADO;
            this.montoModificadoContrato = item.MONTO_MODIFICACION;
            this.montoModificadoContratotoLbl = 0;
            this.calcularPorcentajeMonto();
            this.formAgregarModificacion.controls.monto_modificacion.disable();
            this.tipoModificacion = 'Monto';
          }
          if(item.TIMOD_CORRELATIVO == 84) {
            this.formAgregarModificacion.controls.chk_fecha.setValue(1);
            this.formAgregarModificacion.controls.fecha_inicio_2.setValue(item.FECHA_INICIO);
            this.formAgregarModificacion.controls.fecha_termino_2.setValue(item.FECHA_TERMINO);
            this.tipoModificacion = 'Fecha';
          }
          if(item.TIMOD_CORRELATIVO == 85) {
            this.formAgregarModificacion.controls.chk_alcance.setValue(1);
            this.formAgregarModificacion.controls.alcance.setValue(item.ALCANCE);
            this.formAgregarModificacion.controls.alcance.disable();
            this.tipoModificacion = 'Alcance';
          }
        }
        this.formAgregarModificacion.updateValueAndValidity();
      }
    });
  }

  aceptarSolpeEnviarAdaf(){
    if(this.formaSolpeCorr == 66) {
      this.aceptarSolpe(89);
    } else {
      this.aceptarSolpe(97);
    }
  }

  onChangeMontoMod(event: any) {
    if(event.currentTarget.checked) {
      this.formAgregarModificacion.controls.monto_modificacion.enable();
      //this.formAgregarModificacion.controls.monto_modificacion.setValidators([Validators.required]);
      //this.formAgregarModificacion.updateValueAndValidity();
    } else {
      this.formAgregarModificacion.controls.monto_modificacion.disable();
    }
  }
  onChangeFechaMod(event: any) {
    if(event.currentTarget.checked) {
      this.formAgregarModificacion.controls.fecha_inicio.enable();
      this.formAgregarModificacion.controls.fecha_termino.enable();
    } else {
      this.formAgregarModificacion.controls.fecha_inicio.disable();
      this.formAgregarModificacion.controls.fecha_termino.disable();
    }
  }
  onChangeAlcanceMod(event: any) {
    if(event.currentTarget.checked) {
      this.formAgregarModificacion.controls.alcance.enable();
    } else {
      this.formAgregarModificacion.controls.alcance.disable();
    }
  }
  onChangeMontoModificacion(monto: number) {
    let montoModificacion = (this.contrato.MONTO_ACTUALIZADO - this.contrato.MONTO_ORIGINAL);
    this.formAgregarModificacion.controls.monto_actualizado.setValue(Number(monto) + this.contrato.MONTO_ACTUALIZADO);
    //this.formAgregarModificacion.controls.monto_actualizado.setValue(Number(monto) + montoModificacion);
    //this.porcentajeModifacionInfo = (((this.contrato.MONTO_ACTUALIZADO + +monto) - this.contrato.MONTO_ORIGINAL) / this.contrato.MONTO_ORIGINAL)*100;
    setTimeout(() => {
      this.montoActualizadoContratoLbl = Number(monto) + this.contrato.MONTO_ACTUALIZADO;
      this.montoModificadoContratotoLbl = Number(monto);
      this.porcentajeModifacionInfo = ((+monto / this.contrato.MONTO_ORIGINAL) * 100);
      let porcentajeContrato: number = ((this.contrato.MONTO_ACTUALIZADO - this.contrato.MONTO_ORIGINAL)/this.contrato.MONTO_ORIGINAL)*100;
      this.porcentajeSumaModifacionesInfo = porcentajeContrato + this.porcentajeModifacionInfo;
    }, 500);
  }
  onCrearModificacion() {
    let diezPorcienContrato = (this.contrato.MONTO_ORIGINAL * 10) / 100;
    let montoModificacion = (this.contrato.MONTO_ACTUALIZADO - this.contrato.MONTO_ORIGINAL);
    let montoCompleto = (montoModificacion + this.formAgregarModificacion.value.monto_modificacion);

    if(montoCompleto > diezPorcienContrato) {
      this.notifyService.showWarning('Monto de modificación exede el 10% del monto original del contrato', 'Crear Modificacion');
    }

    let fecha_modificacion = moment().format('YYYY-MM-DD');
    let postData : {
      in_solpe_correlativo: number,
      in_domi_timod_correlativo: number,
      in_monto_modificacion: number,
      in_monto_actualizado: number,
      iv_fecha_modificacion: string,
      iv_fecha_inicio: string,
      iv_fecha_termino: string,
      iv_alcance: string
    };

    if (this.formAgregarModificacion.value.chk_monto) {
       postData = {
        in_solpe_correlativo: this.corrSolpe,
        in_domi_timod_correlativo: 83,
        in_monto_modificacion: this.formAgregarModificacion.value.monto_modificacion,
        in_monto_actualizado: this.formAgregarModificacion.value.monto_modificacion + this.contrato.MONTO_ACTUALIZADO,
        iv_fecha_modificacion: fecha_modificacion,
        iv_fecha_inicio: null,
        iv_fecha_termino: null,
        iv_alcance: null
      };

      this.solpeService.postModificacionCto(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Modificación de contrato creada con exito.', 'Crear Modificacion');
      }, err => {
        this.notifyService.showError('Error al crear modificación de contrato', 'Crear Modificacion');
        console.log(err);
      }, () => {
        setTimeout(() => {
          this.getModificacionCto();
        }, 500);
      });
    }
    if (this.formAgregarModificacion.value.chk_fecha) {
      postData = {
        in_solpe_correlativo: this.corrSolpe,
        in_domi_timod_correlativo: 84,
        in_monto_modificacion: 0,
        in_monto_actualizado: 0,
        iv_fecha_modificacion: fecha_modificacion,
        iv_fecha_inicio: moment(this.contrato.FECHA_INICIO_CONTRATO).format('YYYY-MM-DD'),
        iv_fecha_termino: moment(this.formAgregarModificacion.value.fecha_termino).format('YYYY-MM-DD'),
        iv_alcance: null
      };

      this.solpeService.postModificacionCto(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Modificación de contrato creada con exito.', 'Crear Modificacion');
      }, err => {
        this.notifyService.showError('Error al crear modificación de contrato', 'Crear Modificacion');
        console.log(err);
      }, () => {
        setTimeout(() => {
          this.getModificacionCto();
        }, 500);
      });
    }
    if (this.formAgregarModificacion.value.chk_alcance) {
      postData = {
        in_solpe_correlativo: this.corrSolpe,
        in_domi_timod_correlativo: 85,
        in_monto_modificacion: 0,
        in_monto_actualizado: 0,
        iv_fecha_modificacion: fecha_modificacion,
        iv_fecha_inicio: null,
        iv_fecha_termino: null,
        iv_alcance: this.formAgregarModificacion.value.alcance
      };

      this.solpeService.postModificacionCto(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Modificación de contrato creada con exito.', 'Crear Modificacion');
      }, err => {
        this.notifyService.showError('Error al crear modificación de contrato', 'Crear Modificacion');
        console.log(err);
      }, () => {
        setTimeout(() => {
          this.getModificacionCto();
        }, 500);
      });
    }
  }
  /** Archivos */
  private getArchivos() {
    this.boletasService.getTipoDominios('DOCSOLPE').subscribe(resp => {
      this.archivos = JSON.parse(resp);
    });
  }
  private getDocumentosSolpe() {
    this.solpeService.getDocumentosSolpe(this.corrSolpe, this.rolId).subscribe(resp => {
      this.documentosSolpe = JSON.parse(resp);

      for(let i in this.documentosSolpe) {
        /** Documento de Recepcion de Compra Subido (se activa la encuesta de compra) */
        if(this.tipoSolpeCorr == 61 && this.documentosSolpe[i]['correlativo'] == 79 && this.documentosSolpe[i]['nombre_archivo']) {
          this.ordenCompraSubida = true;
          return;
        }
      }
    }, err => { console.log(err) },
    () => {
      this.documentosCargados = true;
      for(let i in this.documentosSolpe) {
        if(this.documentosSolpe[i]['OBLIGATORIO'] == 'S' && !this.documentosSolpe[i]['nombre_archivo']) {
          this.documentosCargados = false;
        }
      }
    });
  }
  cargarModalArchivo(correlativoSolpe: number) {
    this.formAgregarArchivo.controls.corr_solpe.setValue(correlativoSolpe);
    this.getDocumentosSolpe();
  }
  detectFile(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64 = bs64
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }
  onAgregarArchivo() {
    this.submittedDocumento = true;
    if (this.formAgregarArchivo.invalid) {
      return;
    }

    if(this.fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64}
      this.addonsService.postFileSolpe(postDataFile).subscribe(resp =>{
        let descripciones: any = JSON.parse(resp);
        let nombreArchivo: string = descripciones.nombreArchivo;
        let descripcionArchivo: string = descripciones.descripcionArchivo;
        this.postDocumento(nombreArchivo, descripcionArchivo);
      });
    }
    return;
  }
  private postDocumento(nombreArchivo: string, descripcionArchivo: string) {
    const corr_solpe = this.formAgregarArchivo.value.corr_solpe
    const postData: {
      in_solpe_correlativo: number,
      in_domi_correlativo_docsolpe: number,
      iv_nombre_archivo: string,
      iv_descripcion_archivo: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo:  this.corrSolpe,
      in_domi_correlativo_docsolpe: this.formAgregarArchivo.value.grupo,
      iv_nombre_archivo: nombreArchivo,
      iv_descripcion_archivo: descripcionArchivo,
      in_usua_correlativo_creacion: 0
    };

    this.solpeService.postDocumentoSolpe(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
    }, err => {
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
      console.log(err);
    }, () => {
      this.getDocumentosSolpe();
      this.resetFormArchivo();
    });
  }
  onCloseArchivo() {
    this.resetFormArchivo();
  }
  onDescargar(fileName: string) {
    this.fileService.downloadDocumentoSolpe(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }

  // Eliminar archivode solpe
  onEliminarArchivoSolpe() {

    // this.solpeService.deleteArchivoSolpe({pin_correlativo_archivo: correlativo_archivo}).subscribe(resp=>{
    //   this.notifyService.showSuccess('Archivo eliminado con exito', 'Crear Comentario');
    // }, err =>{
    //   this.notifyService.showError('Error al eliminar el archivo', 'Crear Comentario');
    //   console.log(err);
    // }, () => {
    //   // cargar lista de archivos otra vez
    //   this.getDocumentosSolpe();
    // });

    this.fileService.deleteFile({in_file_name: this.nombreArchivoSolpe, in_file_type: "solpe", in_correlativo: this.correlativoArchivoSolpe}).subscribe(resp => {
      this.notifyService.showSuccess('Eliminado ...', 'Documento eliminado');
      this.getDocumentosSolpe();
      this.closeModalEliminarArchivo.nativeElement.click();
    }, err =>{
      this.getDocumentosSolpe();
      this.notifyService.showError(err.error.message, 'Erro al eliminar archivo');
      this.closeModalEliminarArchivo.nativeElement.click();
    });
  }
  /**Comentarios */
  private getComentariosSolpe(in_solpe_correlativo: number) {
    this.solpeService.getComentariosSolpe(in_solpe_correlativo).subscribe(resp => {
      this.comentariosSolpe = JSON.parse(resp);
    });
  }
  cargarModalComentarios(correlativoSolpe: number) {
    this.formAgregarComentario.controls.corr_solpe.setValue(correlativoSolpe);
    this.getComentariosSolpe(correlativoSolpe);
  }
  onAgregarComentario() {
    this.submittedComentario = true;

    if (this.formAgregarComentario.invalid) {
      this.notifyService.showWarning('Ingrese un comentario', 'Crear Comentario');
      return;
    }

    const postData : {
      in_solpe_correlativo: number,
      iv_comentario: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo: this.formAgregarComentario.value.corr_solpe,
      iv_comentario: this.formAgregarComentario.value.comentario,
      in_usua_correlativo_creacion: 0
    };

    this.postAgregarComentario(postData) 
  }

  postAgregarComentario(postData: {in_solpe_correlativo: number,iv_comentario: string,in_usua_correlativo_creacion: number}) {
    this.solpeService.postComentarioSolpe(postData).subscribe(resp=>{
      this.submittedComentario = false;
      this.notifyService.showSuccess('Comentario creado con exito.', 'Crear Comentario');
    }, err =>{
      this.submittedComentario = false;
      this.notifyService.showError('Error al crear comentario', 'Crear Comentario');
      console.log(err);
    }, () => {
      this.getComentariosSolpe(this.formAgregarComentario.value.corr_solpe);
      this.resetFormComentario();
    });
  }
  onCloseComentario() {
    this.resetFormComentario();
  }
  /** Historial */
  private getHistorial() {
    this.solpeService.getEstadosSolpe(this.corrSolpe).subscribe(resp => {
      this.historial = JSON.parse(resp);
      this.dataHistorial = new MatTableDataSource(JSON.parse(resp));
      this.excelButtonHistorial.data = this.historial;
    })
  }
  /** Distribucion */
  private resetFormDistribucion() {
    this.formAgregarDistribucion = new FormGroup({
      articulo: new FormControl(null, Validators.required),
      zona: new FormControl(null, Validators.required),
      blanco: new FormControl(null, Validators.required),
      proyecto: new FormControl(null, Validators.required),
      grafo: new FormControl(null, Validators.required),
      porcentaje: new FormControl(null),
      corr_solpe: new FormControl(null)
    })
    this.submittedDistribucion = false;
  }

  private resetFormOrdenCompra()
  {
    this.formAgregarOrdenCompra = new FormGroup({
      orden_compra: new FormControl(null, Validators.required),
      fecha_entrega: new FormControl(null)
    });
  }
  private getArticulos() {
    this.solpeService.getArticulos().subscribe(resp => {
      this.articulos = JSON.parse(resp)
    })
  }
  private getZonas() {
    this.solpeService.getZonas().subscribe(resp => {
      this.zonas = JSON.parse(resp)
    })
  }
  private getBlancos() {
    this.solpeService.getBlancos().subscribe(resp => {
      this.blancos = JSON.parse(resp)
    })
  }
  private getProyectos() {
    this.solpeService.getProyectos().subscribe(resp => {
      this.proyectos = JSON.parse(resp)
    })
  }
  private getGrafos() {
    this.solpeService.getGrafos().subscribe(resp => {
      this.grafos = JSON.parse(resp)
    })
  }
  private getDistribucion() {
    this.solpeService.getDistribucion(this.corrSolpe, this.rolId).subscribe(resp => {
      this.distribucion = JSON.parse(resp)
      this.dataSource = new MatTableDataSource(JSON.parse(resp));

      this.totalPorcentaje = 0;
      this.grafosRechazados = 0;
      this.grafosAprobados = 0;
      
      for(let i in this.distribucion) {
        // if(this.distribucion[i]['SODI_VIGENTE'] !== 'N') {
        //   this.totalPorcentaje += this.distribucion[i]['PORCENTAJE'];
        // }
        this.totalPorcentaje += this.distribucion[i]['PORCENTAJE'];
      }

      this.grafosAprobados = 0;

      for(let i in this.distribucion) {
        if(this.corrEstadoSolpe == 88) {
          if(this.distribucion[i]['SODI_VIGENTE'] === 'S') {
            this.grafosAprobados += this.distribucion[i]['PORCENTAJE']
          }
          if(this.distribucion[i]['SODI_VIGENTE'] === 'N') {
            this.grafosRechazados += this.distribucion[i]['PORCENTAJE']
          }
        }
      }

      let max = 100 - this.totalPorcentaje
      this.formAgregarDistribucion.controls['porcentaje'].setValidators([Validators.required, Validators.min(1), Validators.max(max)])
      this.formAgregarDistribucion.controls['porcentaje'].updateValueAndValidity();

      this.resetFormDistribucion();
    })
  }
  calcularMontoPorcentaje(monto: number, porcentaje: number) {
    return (monto * porcentaje) / 100
  }
  cargarModalDistribucion(correlativoSolpe: number) {
    this.formAgregarDistribucion.controls.corr_solpe.setValue(correlativoSolpe);
  }
  onAgregarDistribucion() {
    this.submittedDistribucion = true;
    if (this.formAgregarDistribucion.invalid) {
      this.submittedDistribucion = false;
      return;
    }

    const postData : {
      in_solpe_correlativo: number,
      in_articulo_correlativo: number,
      in_zona_correlativo: number,
      in_blanco_correlativo: number,
      in_proyecto_correlativo: number,
      in_grafos_correlativo: number,
      in_porcentaje_asignado: number,
      iv_vigente: string
    } = {
      in_solpe_correlativo: this.formAgregarDistribucion.value.corr_solpe,
      in_articulo_correlativo: this.formAgregarDistribucion.value.articulo,
      in_zona_correlativo: this.formAgregarDistribucion.value.zona,
      in_blanco_correlativo: this.formAgregarDistribucion.value.blanco,
      in_proyecto_correlativo: this.formAgregarDistribucion.value.proyecto,
      in_grafos_correlativo: this.formAgregarDistribucion.value.grafo,
      in_porcentaje_asignado: this.formAgregarDistribucion.value.porcentaje,
      iv_vigente: 'P'
    };

    this.solpeService.postDistribucion(postData).subscribe(resp=>{
      let respuesta = JSON.parse(resp)[0];

      if(respuesta.DISTRIB_CORRELATIVO == -1) {
        this.notifyService.showWarning('Distribucion ya existe.', 'Crear Distribucion');
      } else {
        this.notifyService.showSuccess('Distribucion creado con exito.', 'Crear Distribucion');
      }

    }, err =>{
      this.submittedDistribucion = false;
      this.notifyService.showError('Error al crear Distribucion', 'Crear Distribucion');
      console.log(err);
    }, () => {
      this.getDistribucion();
      this.closeModalAgregarDistribucion.nativeElement.click();
    });
  }
  onAgregarEmpresaSeleccionada() {
    this.submittedEmpresaPropuesta = true;
    if (this.formAgregarEmpresaPropuesta.invalid) {
      return;
    }

    const postData : {
      iv_empresa: string
    } = {
      iv_empresa: this.formAgregarEmpresaPropuesta.value.empresa
    };
    //alert(this.formAgregarEmpresaPropuesta.value.empresa)
    /* this.solpeService.postDistribucion(postData).subscribe(resp=>{
      let respuesta = JSON.parse(resp)[0];

      if(respuesta.DISTRIB_CORRELATIVO == -1) {
        this.notifyService.showWarning('Distribucion ya existe.', 'Crear Distribucion');
      } else {
        this.notifyService.showSuccess('Distribucion creado con exito.', 'Crear Distribucion');
      }
    }, err =>{
      this.notifyService.showError('Error al crear Distribucion', 'Crear Distribucion');
      console.log(err);
    }, () => {
      this.resetFormDistribucion()
      this.getDistribucion()
      this.closeModalAgregarDistribucion.nativeElement.click();
    }); */
  }
  onCloseDistribucion() {
    this.resetFormDistribucion()
  }

  rechazarDistribucion() {
    this.solpeService.updateEstadoDistribucion(this.rechazarDistribucionCorrelativo, 'N').subscribe(resp => {
      this.notifyService.showSuccess('Distribucion rechazada con exito.', 'Rechazar Distribucion')
    }, err =>{
      this.notifyService.showError('Error al rechazada distribucion', 'Rechazar Distribucion')
      console.log(err)
    }, () => {
      this.getDistribucion()
    })
  }

  onRechazarDistribucion(corr_distribucion: number) {
    this.rechazarDistribucionCorrelativo = corr_distribucion;
  }

  aceptarDistribucion(corr_distribucion: number) {
    let grafosCompletados: boolean = false;

    this.solpeService.updateEstadoDistribucion(corr_distribucion, 'S').subscribe(resp => {
      this.notifyService.showSuccess('Distribucion aceptada con exito.', 'Aceptar Distribucion')
    }, err =>{
      this.notifyService.showError('Error al aceptar distribucion', 'Aceptar Distribucion')
      console.log(err)
    }, () => {
        this.solpeService.getDistribucion(this.corrSolpe, 17).subscribe(
        resp => {
          this.distribucion = JSON.parse(resp)
          this.grafosAprobados = 0;

          for(let i in this.distribucion) {
            if(this.corrEstadoSolpe == 88) {
              if(this.distribucion[i]['SODI_VIGENTE'] === 'S') {
                grafosCompletados = true;
                this.grafosAprobados += this.distribucion[i]['PORCENTAJE'];
              }else{
                grafosCompletados = false;
                break;
              }
            }
          }

          if (grafosCompletados) {
            if(this.grafosAprobados == 100) {
              if(this.formaSolpeCorr == 66) {
                this.aceptarSolpe(89);
              } else {
                this.aceptarSolpe(97);
              }
            } else if(this.grafosAprobados > 100) {
              this.notifyService.showWarning('Distribucion supera el 100% de los grafos, por favor verifique nuevamente los porcentajes asignados.', 'Distribucion')
            }
          }

          this.getDistribucion();
          this.getHistorial();
        }, 
        err => console.log(err)
      );
    });
  }
  eliminarDistribucion() {
    this.solpeService.updateEstadoDistribucion(this.eliminarDistribucionCorrelativo, 'E').subscribe(resp => {
      this.notifyService.showSuccess('Distribucion eliminada con exito.', 'Eliminar Distribucion');
      this.getDistribucion();
      this.getHistorial();
    }, err =>{
      this.notifyService.showError('Error al eliminar distribucion', 'Eliminar Distribucion')
      console.log(err)
    }, () => {
      //
    })
  }
  
  onEliminarDistribucion(corr_distribucion: number) {
    this.eliminarDistribucionCorrelativo = corr_distribucion;
  }

  private resetFormModificarPorcentaje(){
    this.formModificarPorcentaje = new FormGroup({
      porcentaje : new FormControl(null),
      corr_distribucion: new FormControl(null)
    });
    this.submittedModificarPorcentaje = false;
  }
  cargarModalPorDistribucion(corrDistribucion: number, porcentaje: number, estado: string) {
    this.maxPorcentajeDisponible = (estado == 'N') ? (100 - this.totalPorcentaje) : (100 - this.totalPorcentaje) + porcentaje;
    this.formModificarPorcentaje.controls.corr_distribucion.setValue(corrDistribucion);
    this.formModificarPorcentaje.controls['porcentaje'].setValidators([Validators.required, Validators.min(1), Validators.max(this.maxPorcentajeDisponible)])
    this.formModificarPorcentaje.controls['porcentaje'].updateValueAndValidity()
  }
  onModificarPorcentaje() {
    this.submittedModificarPorcentaje = true;
    if (this.formModificarPorcentaje.invalid) {
      return;
    }

    const putData: {
      in_distribucion_correlativo: number,
      in_porcentaje: number
    } = {
      in_distribucion_correlativo: this.formModificarPorcentaje.value.corr_distribucion,
      in_porcentaje: this.formModificarPorcentaje.value.porcentaje
    };

    this.solpeService.updatePorcentajeDistribucion(putData).subscribe(resp => {
      this.notifyService.showSuccess('Porcentaje de distribución modificado', 'Modificar Porcentaje');
      this.resetFormModificarPorcentaje();
      this.closeModalModificarPorcentaje.nativeElement.click();
    }, err =>{
      this.notifyService.showError('Error al modificar porcentaje de distribución', 'Modificar Porcentaje');
      console.log(err);
    }, () => {
      this.getDistribucion();
    });
  }
  onCloseModificarPorcentaje() {

  }
  /** Encuesta */
  private resetFormEncuesta() {
    this.formAgregarEncuesta = new FormGroup({
      pregunta_1: new FormControl(null, [Validators.required]),
      pregunta_2: new FormControl(null, [Validators.required]),
      pregunta_3: new FormControl(null, [Validators.required]),
      descripcion: new FormControl(null)
    })

    this.submittedEncuesta = false
  }
  openModalEncuesta() {
    this.solpeService.getEncuesta(this.corrSolpe).subscribe(resp => {
      this.encuesta = JSON.parse(resp)
      if(this.encuesta.length > 0) {
        this.formAgregarEncuesta.controls.pregunta_1.setValue(this.encuesta[0].RESPUESTA_ENCUESTA);
        this.formAgregarEncuesta.controls.pregunta_2.setValue(this.encuesta[1].RESPUESTA_ENCUESTA);
        this.formAgregarEncuesta.controls.pregunta_3.setValue(this.encuesta[2].RESPUESTA_ENCUESTA);
        this.formAgregarEncuesta.controls.descripcion.setValue(this.encuesta[3].RESPUESTA_ENCUESTA);
        this.formAgregarEncuesta.updateValueAndValidity();
        this.formAgregarEncuesta.controls.pregunta_1.disable();
        this.formAgregarEncuesta.controls.pregunta_2.disable();
        this.formAgregarEncuesta.controls.pregunta_3.disable();
        this.formAgregarEncuesta.controls.descripcion.disable();
        this.encuentaEnviada = true;
      }
    })
  }
  openModalEmpresas()
  {

  }
  onAgregarEncuesta() {
    this.submittedEncuesta = true;
    if (this.formAgregarEncuesta.invalid) {
      return;
    }

    const postData : {
      in_solpe_correlativo: number,
      in_domi_correlativo_encuesta1: number,
      iv_respuesta1: string,
      in_domi_correlativo_encuesta2: number,
      iv_respuesta2: string,
      in_domi_correlativo_encuesta3: number,
      iv_respuesta3: string,
      in_domi_correlativo_encuesta4: number,
      iv_respuesta4: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo: this.corrSolpe,
      in_domi_correlativo_encuesta1: 90,
      iv_respuesta1: this.formAgregarEncuesta.value.pregunta_1,
      in_domi_correlativo_encuesta2: 91,
      iv_respuesta2: this.formAgregarEncuesta.value.pregunta_2,
      in_domi_correlativo_encuesta3: 92,
      iv_respuesta3: this.formAgregarEncuesta.value.pregunta_3,
      in_domi_correlativo_encuesta4: 93,
      iv_respuesta4: this.formAgregarEncuesta.value.descripcion,
      in_usua_correlativo_creacion: 0
    }

    this.solpeService.postEncuesta(postData).subscribe(resp => {
      this.notifyService.showSuccess('Encuesta creada con exito.', 'Crear Encuesta');
    }, err =>{
      this.notifyService.showError('Error al crear Encuesta', 'Crear Encuesta');
      console.log(err);
    }, () => {
      this.resetFormEncuesta()
      this.closeModalEncuesta.nativeElement.click();
    });
  }
  
  onCloseEncuesta() {
    this.resetFormEncuesta()
  }
  /** Proveedor */
  private resetFormProveedor() {
    this.formProveedor = new FormGroup({
      proveedor: new FormControl(0),
      proveedor_input: new FormControl(null),
      rut: new FormControl({value: null, disabled: true,}, [Validators.pattern('^[0-9]+-[0-9kK]{1}$')]),
      telefono: new FormControl({value: null, disabled: true}),
      contacto: new FormControl({value: null, disabled: true}),
      email: new FormControl({value: null, disabled: true})
    });

    if(this.rolId != 23 && this.corrEstadoSolpe != 86) {
      this.formProveedor.controls.proveedor.disable();
      this.formProveedor.controls.proveedor_input.disable();
      this.formProveedor.updateValueAndValidity();
    }
    this.submittedProveedor = false;
    this.nuevoProveedor = false;
  }
  openModalProveedor() {
    this.solpeService.getRelacionSolpeProveedor(this.corrSolpe).subscribe(resp => {
      this.relacionProveedor = JSON.parse(resp);

      if(this.relacionProveedor.length > 0) {
        this.formProveedor.controls.proveedor.setValue(this.relacionProveedor[0].CORRELATIVO_PROVEEDOR);
        this.formProveedor.controls.rut.setValue(this.relacionProveedor[0].RUT_PROVEEDOR + '-' + this.relacionProveedor[0].DIG_RUT_PROVEEDOR);
        this.formProveedor.controls.email.setValue(this.relacionProveedor[0].EMAIL_PROVEEDOR);
        this.formProveedor.controls.telefono.setValue(this.relacionProveedor[0].TELEFONO_PROVEEDOR);
        this.formProveedor.controls.contacto.setValue(this.relacionProveedor[0].CONTACTO_PROVEEDOR);
        if (this.relacionProveedor[0].NOMBRE_PROVEEDOR) {
          this.nombreProveedor = this.relacionProveedor[0].NOMBRE_PROVEEDOR;
        }
        this.formProveedor.updateValueAndValidity();
      }
    });
  }
  private getProveedores() {
    this.solpeService.getProveedores().subscribe(resp => {
      this.proveedores = JSON.parse(resp);
      //console.log(this.proveedores);
    })
  }
  onChangeProveedor(value: any) {
    // const id_proveedor = this.formProveedor.value.proveedor;
    const id_proveedor = value;
    
    this.solpeService.getProveedor(id_proveedor).subscribe(resp => {

      const proveedor: any[] = JSON.parse(resp);

      if (proveedor.length > 0) {
        this.formProveedor.controls.rut.setValue(JSON.parse(resp)[0].rut + '-' + JSON.parse(resp)[0].dig_rut);
        this.formProveedor.controls.email.setValue(JSON.parse(resp)[0].email);
        this.formProveedor.controls.telefono.setValue(JSON.parse(resp)[0].telefono);
        this.formProveedor.controls.contacto.setValue(JSON.parse(resp)[0].contacto);

        // this.proveedorSeter = proveedor;
      }
    });

    this.formProveedor.updateValueAndValidity();
  }
  onNuevoProveedor() {
    this.formProveedor.controls.rut.enable();
    this.formProveedor.controls.telefono.enable();
    this.formProveedor.controls.contacto.enable();
    this.formProveedor.controls.email.enable();

    this.nuevoProveedor = true;
    this.formProveedor = new FormGroup({
      proveedor: new FormControl(0),
      proveedor_input: new FormControl(null, [Validators.required]),
      rut: new FormControl(null, [Validators.pattern('^[0-9]+-[0-9kK]{1}$'),Validators.required]),
      telefono: new FormControl(null, [Validators.required]),
      contacto: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required])
    })
    this.submittedProveedor = false;
  }
  onAgregarProveedor() {
    if(this.nuevoProveedor) {
      this.submittedProveedor = true;
      if (this.formProveedor.invalid) {
        return;
      }

      // validacion para saber si el proveedor o contratista ya se existe
      let proveedorExiste: any[] = this.proveedores.filter(item => item.rut === this.formProveedor.value.rut);

      if (proveedorExiste.length > 0) {
        this.notifyService.showWarning('El Proveedor existe, verifique la informacion que ingreso', 'Relacionar Proveedor');
      }else{
        let rut: number = null;
        let digito: string = null;
        let rut_empresa = this.formProveedor.value.rut;

        if(rut_empresa !== null && rut_empresa.includes('-')) 
        {
          rut_empresa = rut_empresa.split('-');
          rut = rut_empresa[0];
          digito = rut_empresa[1];
        }

        const postData : {
          in_rut: number,
          iv_digrut: string,
          iv_nombre: string,
          iv_email: string,
          iv_telefono: string,
          iv_contacto: string,
          in_usua_correlativo_creacion: number
        } = {
          in_rut: rut,
          iv_digrut: digito,
          iv_nombre: this.formProveedor.value.proveedor_input,
          iv_email: this.formProveedor.value.email,
          iv_telefono: this.formProveedor.value.telefono,
          iv_contacto: this.formProveedor.value.contacto,
          in_usua_correlativo_creacion: 0
        }

        this.solpeService.postProveedor(postData).subscribe(resp => {
          let corr_proveedor = JSON.parse(resp)[0].CORRELATIVO_PROVEEDOR
          this.solpeService.relacionaSolpeProveedor(this.corrSolpe, corr_proveedor).subscribe(resp => {
            this.getProveedores();
            this.openModalProveedor();
            this.notifyService.showSuccess('Proveedor relacionado con exito.', 'Relacionar Proveedor');
            // cerrar el modal en caso de que vaya todo bien
            this.proveedorRelacionado = true;
            this.onCloseProveedor();
            this.closeModalAgregarProveedor.nativeElement.click();
          }, err =>{
            this.notifyService.showError('Error al relacionar Proveedor', 'Relacionar Proveedor');
            console.log(err);
          })
        }, err =>{
          this.notifyService.showError('Error al crear Proveedor', 'Crear Proveedor');
          console.log(err);
        });
      }
    } else {
      let corr_proveedor = this.formProveedor.value.proveedor
        this.solpeService.relacionaSolpeProveedor(this.corrSolpe, corr_proveedor).subscribe(resp => {
          this.getProveedores();
          this.openModalProveedor();
          this.notifyService.showSuccess('Proveedor relacionado con exito.', 'Relacionar Proveedor');
          // cerrar el modal en caso de que vaya todo bien
          this.proveedorRelacionado = true;
          this.onCloseProveedor();
          this.closeModalAgregarProveedor.nativeElement.click();
        }, err =>{
          this.notifyService.showError('Error al relacionar Proveedor', 'Relacionar Proveedor');
          console.log(err);
        });
    }
  }
  onCloseProveedor() {
    this.formProveedor.controls.rut.disable()
    this.formProveedor.controls.telefono.disable()
    this.formProveedor.controls.contacto.disable()
    this.formProveedor.controls.email.disable()
    this.resetFormProveedor()
  }
  /** Form Asignacion Directa */
  private resetFormAsignacion() {
    this.formAsignacion = new FormGroup({
      justificacion: new FormControl(null, [Validators.required]),
      razones: new FormControl(null, [Validators.required]),
      explicacion: new FormControl(null, [Validators.required]),
      compras_1: new FormControl(false),
      servicios_1: new FormControl(false),
      arriendos_1: new FormControl(false),
      sprofesionales_1: new FormControl(false),
      compras_2: new FormControl(false),
      servicios_2: new FormControl(false),
      arriendos_2: new FormControl(false),
      sprofesionales_2: new FormControl(false),
      compras_3: new FormControl(false),
      servicios_3: new FormControl(false),
      arriendos_3: new FormControl(false),
      sprofesionales_3: new FormControl(false),
      compras_4: new FormControl(false),
      servicios_4: new FormControl(false),
      arriendos_4: new FormControl(false),
      sprofesionales_4: new FormControl(false),
      compras_5: new FormControl(false),
      servicios_5: new FormControl(false),
      arriendos_5: new FormControl(false),
      sprofesionales_5: new FormControl(false),
      compras_6: new FormControl(false),
      servicios_6: new FormControl(false),
      arriendos_6: new FormControl(false),
      sprofesionales_6: new FormControl(false)
    })
    if(this.rolId != 23 || this.corrEstadoSolpe != 86) {
      this.formAsignacion.controls.justificacion.disable();
      this.formAsignacion.controls.razones.disable();
      this.formAsignacion.controls.explicacion.disable();
      this.formAsignacion.controls.compras_1.disable();
      this.formAsignacion.controls.servicios_1.disable();
      this.formAsignacion.controls.arriendos_1.disable();
      this.formAsignacion.controls.sprofesionales_1.disable();
      this.formAsignacion.controls.compras_2.disable();
      this.formAsignacion.controls.servicios_2.disable();
      this.formAsignacion.controls.arriendos_2.disable();
      this.formAsignacion.controls.sprofesionales_2.disable();
      this.formAsignacion.controls.compras_3.disable();
      this.formAsignacion.controls.servicios_3.disable();
      this.formAsignacion.controls.arriendos_3.disable();
      this.formAsignacion.controls.sprofesionales_3.disable();
      this.formAsignacion.controls.compras_4.disable();
      this.formAsignacion.controls.servicios_4.disable();
      this.formAsignacion.controls.arriendos_4.disable();
      this.formAsignacion.controls.sprofesionales_4.disable();
      this.formAsignacion.controls.compras_5.disable();
      this.formAsignacion.controls.servicios_5.disable();
      this.formAsignacion.controls.arriendos_5.disable();
      this.formAsignacion.controls.sprofesionales_5.disable();
      this.formAsignacion.controls.compras_6.disable();
      this.formAsignacion.controls.servicios_6.disable();
      this.formAsignacion.controls.arriendos_6.disable();
      this.formAsignacion.controls.sprofesionales_6.disable();
      this.formAsignacion.updateValueAndValidity();
    }

    this.submittedAsignacion = false
  }
  openModalAsignacion() {
    this.solpeService.getJustificacion(this.corrSolpe).subscribe(resp => {
      this.justificacion = JSON.parse(resp)

      if(this.justificacion.length > 0) {
        this.formAsignacion.controls.justificacion.setValue(this.justificacion[0].JUSTIFICACION1);
        this.formAsignacion.controls.razones.setValue(this.justificacion[0].JUSTIFICACION2);
        this.formAsignacion.controls.explicacion.setValue(this.justificacion[0].JUSTIFICACION3);

        if(this.tipoSolpeCorr == 61) {
          this.formAsignacion.controls.compras_1.setValue((this.justificacion[0].COMPRA == "1") ? 1 : 0);
          this.formAsignacion.controls.compras_2.setValue((this.justificacion[1].COMPRA == "1") ? 1 : 0);
          this.formAsignacion.controls.compras_3.setValue((this.justificacion[2].COMPRA == "1") ? 1 : 0);
          this.formAsignacion.controls.compras_4.setValue((this.justificacion[3].COMPRA == "1") ? 1 : 0);
          this.formAsignacion.controls.compras_5.setValue((this.justificacion[4].COMPRA == "1") ? 1 : 0);
          this.formAsignacion.controls.compras_6.setValue((this.justificacion[5].COMPRA == "1") ? 1 : 0);
        }
        if(this.tipoSolpeCorr == 62) {
          this.formAsignacion.controls.servicios_1.setValue((this.justificacion[0].SERVICIO == "1") ? 1 : 0);
          this.formAsignacion.controls.servicios_2.setValue((this.justificacion[1].SERVICIO == "1") ? 1 : 0);
          this.formAsignacion.controls.servicios_3.setValue((this.justificacion[2].SERVICIO == "1") ? 1 : 0);
          this.formAsignacion.controls.servicios_4.setValue((this.justificacion[3].SERVICIO == "1") ? 1 : 0);
          this.formAsignacion.controls.servicios_5.setValue((this.justificacion[4].SERVICIO == "1") ? 1 : 0);
          this.formAsignacion.controls.servicios_6.setValue((this.justificacion[5].SERVICIO == "1") ? 1 : 0);
        }
        if(this.tipoSolpeCorr == 60) {
          this.formAsignacion.controls.arriendos_1.setValue((this.justificacion[0].ARRIENDO == "1") ? 1 : 0);
          this.formAsignacion.controls.arriendos_2.setValue((this.justificacion[1].ARRIENDO == "1") ? 1 : 0);
          this.formAsignacion.controls.arriendos_3.setValue((this.justificacion[2].ARRIENDO == "1") ? 1 : 0);
          this.formAsignacion.controls.arriendos_4.setValue((this.justificacion[3].ARRIENDO == "1") ? 1 : 0);
          this.formAsignacion.controls.arriendos_5.setValue((this.justificacion[4].ARRIENDO == "1") ? 1 : 0);
          this.formAsignacion.controls.arriendos_6.setValue((this.justificacion[5].ARRIENDO == "1") ? 1 : 0);
        }
        if(this.tipoSolpeCorr == 63) {
          this.formAsignacion.controls.sprofesionales_1.setValue((this.justificacion[0].SERV_PROFESIONALES == "1") ? 1 : 0);
          this.formAsignacion.controls.sprofesionales_2.setValue((this.justificacion[1].SERV_PROFESIONALES == "1") ? 1 : 0);
          this.formAsignacion.controls.sprofesionales_3.setValue((this.justificacion[2].SERV_PROFESIONALES == "1") ? 1 : 0);
          this.formAsignacion.controls.sprofesionales_4.setValue((this.justificacion[3].SERV_PROFESIONALES == "1") ? 1 : 0);
          this.formAsignacion.controls.sprofesionales_5.setValue((this.justificacion[4].SERV_PROFESIONALES == "1") ? 1 : 0);
          this.formAsignacion.controls.sprofesionales_6.setValue((this.justificacion[5].SERV_PROFESIONALES == "1") ? 1 : 0);
        }

        this.formAsignacion.updateValueAndValidity();
      }
    });
  }
  onAgregarAsignacion() {
    this.submittedAsignacion = true;
    if (this.formAsignacion.invalid) {
      return;
    }

    let postDataJust: {
      in_solpe_correlativo: number,
      in_nro_justificacion: number,
      iv_compra: string,
      iv_servicio: string,
      iv_arriendo: string,
      iv_servicios_profesionales: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo: this.corrSolpe,
      in_nro_justificacion: 0,
      iv_compra: null,
      iv_servicio: null,
      iv_arriendo: null,
      iv_servicios_profesionales: null,
      in_usua_correlativo_creacion: 0
    };

    postDataJust.in_nro_justificacion = 1;
    if(this.tipoSolpeCorr == 61) postDataJust.iv_compra = this.formAsignacion.value.compras_1
    else if(this.tipoSolpeCorr == 62) postDataJust.iv_servicio = this.formAsignacion.value.servicios_1
    else if(this.tipoSolpeCorr == 60) postDataJust.iv_arriendo = this.formAsignacion.value.arriendos_1
    else if(this.tipoSolpeCorr == 63) postDataJust.iv_servicios_profesionales = this.formAsignacion.value.sprofesionales_1
    this.registraTablaJustificacion(postDataJust);

    postDataJust.in_nro_justificacion = 2;
    if(this.tipoSolpeCorr == 61) postDataJust.iv_compra = this.formAsignacion.value.compras_2
    else if(this.tipoSolpeCorr == 62) postDataJust.iv_servicio = this.formAsignacion.value.servicios_2
    else if(this.tipoSolpeCorr == 60) postDataJust.iv_arriendo = this.formAsignacion.value.arriendos_2
    else if(this.tipoSolpeCorr == 63) postDataJust.iv_servicios_profesionales = this.formAsignacion.value.sprofesionales_2
    this.registraTablaJustificacion(postDataJust);

    postDataJust.in_nro_justificacion = 3;
    if(this.tipoSolpeCorr == 61) postDataJust.iv_compra = this.formAsignacion.value.compras_3
    else if(this.tipoSolpeCorr == 62) postDataJust.iv_servicio = this.formAsignacion.value.servicios_3
    else if(this.tipoSolpeCorr == 60) postDataJust.iv_arriendo = this.formAsignacion.value.arriendos_3
    else if(this.tipoSolpeCorr == 63) postDataJust.iv_servicios_profesionales = this.formAsignacion.value.sprofesionales_3
    this.registraTablaJustificacion(postDataJust);

    postDataJust.in_nro_justificacion = 4;
    if(this.tipoSolpeCorr == 61) postDataJust.iv_compra = this.formAsignacion.value.compras_4
    else if(this.tipoSolpeCorr == 62) postDataJust.iv_servicio = this.formAsignacion.value.servicios_4
    else if(this.tipoSolpeCorr == 60) postDataJust.iv_arriendo = this.formAsignacion.value.arriendos_4
    else if(this.tipoSolpeCorr == 63) postDataJust.iv_servicios_profesionales = this.formAsignacion.value.sprofesionales_4
    this.registraTablaJustificacion(postDataJust);

    postDataJust.in_nro_justificacion = 5;
    if(this.tipoSolpeCorr == 61) postDataJust.iv_compra = this.formAsignacion.value.compras_5
    else if(this.tipoSolpeCorr == 62) postDataJust.iv_servicio = this.formAsignacion.value.servicios_5
    else if(this.tipoSolpeCorr == 60) postDataJust.iv_arriendo = this.formAsignacion.value.arriendos_5
    else if(this.tipoSolpeCorr == 63) postDataJust.iv_servicios_profesionales = this.formAsignacion.value.sprofesionales_5
    this.registraTablaJustificacion(postDataJust);

    postDataJust.in_nro_justificacion = 6;
    if(this.tipoSolpeCorr == 61) postDataJust.iv_compra = this.formAsignacion.value.compras_6
    else if(this.tipoSolpeCorr == 62) postDataJust.iv_servicio = this.formAsignacion.value.servicios_6
    else if(this.tipoSolpeCorr == 60) postDataJust.iv_arriendo = this.formAsignacion.value.arriendos_6
    else if(this.tipoSolpeCorr == 63) postDataJust.iv_servicios_profesionales = this.formAsignacion.value.sprofesionales_6
    this.registraTablaJustificacion(postDataJust);

    const postData : {
      in_solpe_correlativo: number,
      iv_justificacion1: string,
      iv_justificacion2: string,
      iv_justificacion3: string,
      in_usua_correlativo: number
    } = {
      in_solpe_correlativo: this.corrSolpe,
      iv_justificacion1: this.formAsignacion.value.justificacion,
      iv_justificacion2: this.formAsignacion.value.razones,
      iv_justificacion3: this.formAsignacion.value.explicacion,
      in_usua_correlativo: 0
    }

    this.solpeService.postJustificacion(postData).subscribe(resp => {
      this.validateExisteJustificacionAsginacion();
      this.notifyService.showSuccess('Justificación creada con exito.', 'Justificación Asignación Directa');
      this.resetFormAsignacion();
      this.closeModalAsignacion.nativeElement.click();
    }, err =>{
      this.notifyService.showError('Error al crear justificación', 'Justificación Asignación Directa');
      console.log(err);
    });
  }
  onCloseAsignacion() {
    this.resetFormAsignacion()
  }
  registraTablaJustificacion(postData : {
    in_solpe_correlativo: number,
    in_nro_justificacion: number,
    iv_compra: string,
    iv_servicio: string,
    iv_arriendo: string,
    iv_servicios_profesionales: string,
    in_usua_correlativo_creacion: number
  }) {
    this.solpeService.postTablaJustificacion(postData).subscribe(resp => {
    }, err =>{
      this.notifyService.showError('Error al crear Formulario de justificación directa', 'Justificación Directa');
      console.log(err);
    })
  }
  /** Servicios Profesionales */
  private resetFormServiciosPro() {
    this.formServicios = new FormGroup({
      fecha_solpe: new FormControl({ value: moment(this.fechaSolpe).format('DD-MM-YYYY'), disabled: true}),
      nombre_solicitante: new FormControl({ value: this.usuarioSolicitante, disabled: true}),
      cargo_solicitante: new FormControl({value: null, disabled: true}),
      proyecto_usuario: new FormControl(null),
      admin_contrato: new FormControl(null),
      centro_costo: new FormControl(null),
      financiamiento: new FormControl(null),
      nombre_consultor: new FormControl(0),
      rut_consultor: new FormControl({value: null, disabled: true}),
      profesion_consultor: new FormControl({value: null, disabled: true}),
      telefono_consultor: new FormControl({value: null, disabled: true}),
      email_consultor: new FormControl({value: null, disabled: true}),
      negocio_er_consultor: new FormControl({value: null, disabled: true}),
      fecha_inicio: new FormControl(null),
      fecha_termino: new FormControl(null),
      plazo_total: new FormControl({ value: "0", disabled: true}),
      tarifa: new FormControl(null),
      moneda: new FormControl(0),
      unidad: new FormControl(null),
      cantidad: new FormControl(null),
      otros_gastos: new FormControl(null),
      costo_total: new FormControl({ value: "0", disabled: true}),
      justificacion_otros_gastos: new FormControl(null),
      justificacion_necesidad: new FormControl(null),
      actividades: new FormControl(null)
    })

    this.submittedServicios = false
  }
  openModalServicios() {
    this.resetFormServiciosPro()

    this.solpeService.getServiciosProfesionales(this.corrSolpe).subscribe(resp => {
      this.dataServiciosPro = JSON.parse(resp)

      this.formServicios.controls.proyecto_usuario.setValue(this.dataServiciosPro[0].AREA_PROYECTO)
      this.formServicios.controls.admin_contrato.setValue(this.dataServiciosPro[0].ADMIN_CONTRATO)
      this.formServicios.controls.centro_costo.setValue(this.dataServiciosPro[0].GRAFO)
      this.formServicios.controls.financiamiento.setValue(this.dataServiciosPro[0].TIENE_FINANCIAMIENTO)
      this.formServicios.controls.nombre_consultor.setValue(this.dataServiciosPro[0].PROV_CORRELATIVO)
      this.formServicios.controls.fecha_inicio.setValue(this.dataServiciosPro[0].PER_FECHA_INICIO)
      this.formServicios.controls.fecha_termino.setValue(this.dataServiciosPro[0].PER_FECHA_TERMINO)
      this.formServicios.controls.tarifa.setValue(this.dataServiciosPro[0].TARIFA)
      this.formServicios.controls.moneda.setValue(this.dataServiciosPro[0].TIMO_CORRELATIVO)
      this.formServicios.controls.unidad.setValue(this.dataServiciosPro[0].UNIDAD)
      this.formServicios.controls.cantidad.setValue(this.dataServiciosPro[0].CANTIDAD)
      this.formServicios.controls.otros_gastos.setValue(this.dataServiciosPro[0].OTROS_GASTOS)
      this.formServicios.controls.justificacion_otros_gastos.setValue(this.dataServiciosPro[0].JUST_OTROS_GASTOS)
      this.formServicios.controls.justificacion_necesidad.setValue(this.dataServiciosPro[0].JUST_NECESIDAD)
      this.formServicios.controls.actividades.setValue(this.dataServiciosPro[0].ACT_EJEC_CONSULTOR)
      this.formServicios.updateValueAndValidity()

      // this.onChangeProveedorServicios()
      this.onChangeFecha()
      this.onChangeTarifa()
    })
  }
  onChangeProveedorServicios(value: any) {
    // let id_proveedor = this.formServicios.value.nombre_consultor
    const id_proveedor = value;
    this.solpeService.getProveedor(id_proveedor).subscribe(resp => {

      const proveedorServicios: any[] = JSON.parse(resp);

      if (proveedorServicios.length > 0) {
        this.formServicios.controls.rut_consultor.setValue(JSON.parse(resp)[0].rut + '-' + JSON.parse(resp)[0].dig_rut);
        this.formServicios.controls.email_consultor.setValue(JSON.parse(resp)[0].email);
        this.formServicios.updateValueAndValidity();
      }
    });
  }
  onChangeFecha() {
    let fecha_inicio = (this.formServicios.value.fecha_inicio) ? moment(this.formServicios.value.fecha_inicio).format('DD-MM-YYYY') : null;
    let fecha_termino = (this.formServicios.value.fecha_termino) ? moment(this.formServicios.value.fecha_termino).format('DD-MM-YYYY') : null;

    if(fecha_inicio && fecha_termino) {
      let fecha_i = moment(this.formServicios.value.fecha_inicio)
      let fecha_t = moment(this.formServicios.value.fecha_termino)
      let meses = fecha_t.diff(fecha_i, 'month')

      this.formServicios.controls.plazo_total.setValue(meses)
    } else {
      this.formServicios.controls.plazo_total.setValue("0")
    }
    this.formServicios.updateValueAndValidity()
  }
  onChangeTarifa() {
    if(this.formServicios.value.tarifa && this.formServicios.value.cantidad) {
      let total = 0
      total = (parseInt(this.formServicios.value.tarifa) * parseInt(this.formServicios.value.cantidad))
      if(this.formServicios.value.otros_gastos) {
        total += parseInt(this.formServicios.value.otros_gastos)
      }
      this.formServicios.controls.costo_total.setValue(total)
      this.formServicios.updateValueAndValidity()
    }
  }
  onAgregarServicios() {
    this.submittedServicios = true;
    if (this.formServicios.invalid) {
      return;
    }

    let fecha_inicio = moment(this.fechaSolpe).format('YYYY-MM-DD'); //(this.formServicios.value.fecha_inicio) ? moment(this.formServicios.value.fecha_inicio).format('DD-MM-YYYY') : null;
    let fecha_termino = moment(this.fechaTerminoSolpe).format('YYYY-MM-DD'); //(this.formServicios.value.fecha_termino) ? moment(this.formServicios.value.fecha_termino).format('DD-MM-YYYY') : null;

    this.formServicios.controls.plazo_total.enable()
    this.formServicios.controls.costo_total.enable()
    const postData : {
      in_solpe_correlativo: number,
      iv_area_proyecto: string,
      iv_admin_contrato: string,
      iv_grafo: string,
      iv_tiene_financiemiento: string,
      in_prov_correlativo: number,
      iv_per_cont_fecha_inicio: string,
      iv_per_cont_fecha_termino: string,
      in_plazo_total_meses: number,
      in_tarifa: number,
      in_timo_correlativo: number,
      iv_unidad: string,
      in_cantidad: number,
      in_otros_gastos: number,
      in_costo_total_estimado: number,
      iv_just_otros_gastos: string,
      iv_just_necesidad: string,
      iv_act_ejec_consultor: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo: this.corrSolpe,
      iv_area_proyecto: this.formServicios.value.proyecto_usuario,
      iv_admin_contrato: this.formServicios.value.admin_contrato,
      iv_grafo: this.formServicios.value.centro_costo,
      iv_tiene_financiemiento: this.formServicios.value.financiamiento,
      in_prov_correlativo: this.formServicios.value.nombre_consultor,
      iv_per_cont_fecha_inicio: fecha_inicio,
      iv_per_cont_fecha_termino: fecha_termino,
      in_plazo_total_meses: this.formServicios.value.plazo_total,
      in_tarifa: this.formServicios.value.tarifa,
      in_timo_correlativo: this.formServicios.value.moneda,
      iv_unidad: this.formServicios.value.unidad,
      in_cantidad: this.formServicios.value.cantidad,
      in_otros_gastos: this.formServicios.value.otros_gastos,
      in_costo_total_estimado: this.formServicios.value.costo_total,
      iv_just_otros_gastos: this.formServicios.value.justificacion_otros_gastos,
      iv_just_necesidad: this.formServicios.value.justificacion_necesidad,
      iv_act_ejec_consultor: this.formServicios.value.actividades,
      in_usua_correlativo_creacion: 0
    }

    this.formServicios.controls.plazo_total.disable()
    this.formServicios.controls.costo_total.disable()

    this.solpeService.postServiciosProfesionales(postData).subscribe(resp => {
      this.notifyService.showSuccess('Formulario de Servicios Profesionales creado con exito.', 'Servicios Profesionales');
    }, err =>{
      this.notifyService.showError('Error al crear Formulario de Servicios Profesionales', 'Servicios Profesionales');
      console.log(err);
    })

    this.resetFormServiciosPro()
    this.closeModalServicios.nativeElement.click()
  }
  onCloseServicios() {
    this.resetFormServiciosPro()
  }
  /** Aceptar o rechazar solpe */
  cerrarSolpe() {
    this.solpeService.updateEstadoSolpe(this.corrSolpe, 110, this.rolId).subscribe(resp => {
      this.notifyService.showSuccess('Solpe Cerrada con exito.', 'Cerrar Solpe')
      this.getSolpe()
    }, err =>{
      this.notifyService.showError('Error al Cerrar solpe', 'Cerrar Solpe')
      console.log(err)
    })
  }
  rechazarSolpe() {
    this.submittedRechazar = true;
    if (this.formRechazar.invalid) {
      return;
    }

    this.solpeService.updateEstadoSolpe(this.corrSolpe, 81, this.rolId, this.formRechazar.value.observacion).subscribe(resp => {
      this.notifyService.showSuccess('Solpe Rechazada con exito.', 'Rechazar Solpe')
    }, err =>{
      this.notifyService.showError('Error al Rechazar solpe', 'Rechazar Solpe')
      console.log(err)
    }, () => {
      this.getSolpe()
    })
    this.resetFormRechazar();
    this.getHistorial();
    this.closeModalRechazar.nativeElement.click();
  }
  enviarPyC() {
    if(!this.documentosCargados) {
      this.notifyService.showInfo('Debe adjuntar todos los documentos obligatorios (rojos), antes de avanzar de etapa', 'Domumentos Adjuntos')
      return;
    }

    this.solpeService.updateEstadoSolpe(this.corrSolpe, 87, this.rolId).subscribe(resp => {
      this.notifyService.showSuccess('Solpe enviada a Lider P&C.', 'Enviar Solpe a P&C')
      this.getSolpe()
      this.resetFormAsignacion();
    }, err =>{
      this.notifyService.showError('Error al enviar solpe a P&C', 'Enviar Solpe a P&C')
      console.log(err)
    })
  }
  enviarRespGrafos() {
    if(!this.documentosCargados) {
      this.notifyService.showInfo('Debe adjuntar todos los documentos obligatorios (rojos), antes de avanzar de etapa', 'Domumentos Adjuntos')
      return;
    }

    this.solpeService.updateEstadoSolpe(this.corrSolpe, 88, this.rolId).subscribe(resp => {
      this.notifyService.showSuccess('Solpe enviada a Resp. Grafos', 'Enviar Solpe a Resp. Grafos')
      this.getSolpe()
    }, err =>{
      this.notifyService.showError('Error al enviar solpe a Resp. Grafos', 'Enviar Solpe a Resp. Grafos')
      console.log(err)
    })
  }
  aceptarSolpe(corrEstado: number) {
    if(!this.documentosCargados) {
      this.notifyService.showInfo('Debe adjuntar todos los documentos obligatorios (rojos), antes de avanzar de etapa', 'Domumentos Adjuntos')
      return;
    }
    if(this.formaSolpeCorr == 64 && this.relacionProveedor?.length == 0 && this.corrEstadoSolpe == 86)
    {
      this.notifyService.showWarning('Para poder enviar la solpe debe asignar el proveedor', 'Enviar solpe');
      return;
    }

    if (this.formaSolpeCorr == 64) {
      if (this.justificacionData.length <= 0) {
        this.notifyService.showWarning('Para poder enviar la solpe debe completar la justificación de asignación', 'Enviar solpe');
        return;
      }
    }

    this.solpeService.updateEstadoSolpe(this.corrSolpe, corrEstado, this.rolId).subscribe(resp => {
      let mensaje = (corrEstado == 89) ? 'Avanzar Etapa': 'Solpe Aceptada';
      this.notifyService.showSuccess(mensaje, 'Etapa Solpe')
      this.getSolpe()
    }, err =>{
      this.notifyService.showError('Error al Aceptar Solpe', 'Aceptar Solpe')
      console.log(err)
    })
  }
  /** Gestionador */
  private getGestionador() {
    this.contratoService.getCargosContrato('GESCONTCOMP').subscribe(resp => {
      this.gestionadores = JSON.parse(resp);
    });
  }
  private resetFormGCC() {
    this.formGCC = new FormGroup({
      gestionador: new FormControl(0)
    });
  }
  relacionaGestionador() {
    this.solpeService.relacionaSolpeGCC(this.corrSolpe, this.formGCC.value.gestionador).subscribe(resp => {
      this.notifyService.showSuccess('Gestionador de Contratos y Compras designado exitosamente', 'Etapa Solpe');
    }, err =>{
      this.notifyService.showError('Error al relacionar gestionador', 'Relacionar Gestionador')
      console.error(err)
    }, () => {
      this.getHistorial();
      this.getSolpe();
    });
  }
  /* Rechazar */
  private resetFormRechazar() {
    this.formRechazar = new FormGroup({
      observacion: new FormControl(null, Validators.required)
    });

    this.submittedRechazar = false;
  }

  postOrdenCompra(event: any){

    event.preventDefault();

    this.submittedOrdenCompra = true;

    if (this.formAgregarOrdenCompra.invalid) {
      this.notifyService.showInfo('Debe de completar los campos necesario para esta etapa.', 'Número de documento')
      return;
    }

    if(!this.documentosCargados) {
      this.notifyService.showInfo('Debe adjuntar todos los documentos obligatorios (rojos), antes de avanzar de etapa', 'Domumentos Adjuntos')
      return;
    }

    let rut = null
    let digito = null

    if (this.solpe.RUT_EMPRESA !== null && this.solpe.RUT_EMPRESA.toString().includes('-')) {
      const rutEmpresa = this.solpe.RUT_EMPRESA.split('-');
      rut = rutEmpresa[0];
      digito = rutEmpresa[1];
    }
    let fecha_termino = null
    if(this.solpe?.FECHA_TERMINO_SOLPE !== null) fecha_termino = moment(this.solpe?.FECHA_TERMINO_SOLPE).format('YYYY-MM-DD')
    let empresas = null
    if(this.solpe?.EMPR_PROPUESTA !== null) empresas = this.solpe?.EMPR_PROPUESTA.toString()
    
    const postData = {
      nombre_solpe : this.solpe?.NOMBRE_SOLPE,
      descripcion: this.solpe?.DESC_SOLPE,
      monto: this.solpe?.MONTO_SOLPE,
      fecha_entrega_oc: this.formAgregarOrdenCompra.controls['fecha_entrega'].value,
      codigo_solpe: this.solpe?.SOLPE_CODIGO,
      cont_correlativo: this.solpe?.CONT_CORRELATIVO,
      orden_de_compra: this.formAgregarOrdenCompra.controls['orden_compra'].value,
      correlativo: this.solpe?.SOLPE_CORRELATIVO,
      rut: rut, 
      dig_rut: digito, 
      razon_social: this.solpe?.RAZON_SOCIAL, 
      fecha_termino: fecha_termino,
      domi_correlativo_timo: this.solpe?.TIMO_CORRELATIVO,
      empresas: empresas
    }

    if(postData.orden_de_compra == '' || postData.orden_de_compra == null)
    {
      this.notifyService.showError('El numero de orden de compra es obligatoria', 'Orden de compra')
      return
    }

    this.solpeService.updateSolpe(postData).subscribe(
      resp => {
        this.closeModalOC.nativeElement.click();
        this.aceptarSolpe(133);
        this.submittedOrdenCompra = false;
      },
      err => {
        console.log(err);
        this.notifyService.showError('Error al registrar OC', 'Registrar OC');
        this.submittedOrdenCompra = false;
      }
    );
  }
  onCloseOC() {
    this.resetFormOrdenCompra();
    this.submittedOrdenCompra = false;
  }

  onDescargarPDF() {

    // 'Comentarios',
    const doc = new PDF(
      { format: "A4", unit: "mm" },
      [
        '',
        'Historial',
        'Archivos',
        'Estrategia de liberación'
      ]
    );

    doc.addLogo();
    doc.addTitle(`Solpe (${this.codigoSolpe}) ${this.solpe?.NOMBRE_SOLPE}`);

    const iLengthTitleFirst = 80.975;
    const iLengthValueFirst = 100;

    doc.identText(
      "Código",
      this.codigoSolpe,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha Creación",
      this.solpe?.FECHA_INGRESO_SOLPE ? moment(this.solpe?.FECHA_INGRESO_SOLPE).format('DD/MM/YYYY') : '--',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Tipo de Solpe",
      this.solpe?.TIPO_SOLPE,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Forma",
      this.solpe?.FORMA_SOLPE,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Solicitante",
      this.usuarioSolicitante,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Estado",
      this.solpe?.ESTADO_SOLPE,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha estimada inicio servicio",
      this.solpe?.FECHA_ESTIMADA_INICIO_SERVICIO ? moment(this.solpe?.FECHA_ESTIMADA_INICIO_SERVICIO).format('DD/MM/YYYY') : '--',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Duración estimada servicio", 
      this.solpe?.DURACION_ESTIMADA_SERVICIO ? `${this.solpe?.DURACION_ESTIMADA_SERVICIO} Meses` : '--',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Nombre",
      this.solpe?.NOMBRE_SOLPE,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Descripción",
      this.solpe?.DESC_SOLPE,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Monto",
      formatCurrency(this.solpe?.MONTO_SOLPE),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Moneda",
      this.contrato?.TIPO_MONEDA || this.solpe?.TIMO_DESCRIPCION,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "N° Documento",
      this.solpe?.ORDEN_COMPRA || '',
      iLengthTitleFirst,
      iLengthValueFirst
    );

    if(this.tipoSolpeCorr == 82) {
      doc.increasePageY(5);

      doc.identText(
        "Modificación De Contrato",
        '',
        iLengthTitleFirst,
        iLengthValueFirst
      );
      doc.increasePageY(2);

      doc.identText(
        "Modificación",
        this.tipoModificacion,
        iLengthTitleFirst,
        iLengthValueFirst
      );

      doc.identText(
        "Monto Modificación",
        (this.montoModificadoContrato || '').toString(),
        iLengthTitleFirst,
        iLengthValueFirst
      );

      doc.identText(
        "Nuevo Monto Actualizado",
        (this.montoActualizadoContrato || '').toString(),
        iLengthTitleFirst,
        iLengthValueFirst
      );

      doc.identText(
        "Fecha Término",
        this.formAgregarModificacion.controls.fecha_termino_2.value || '',
        iLengthTitleFirst,
        iLengthValueFirst
      );

      doc.identText(
        "Alcance",
        this.formAgregarModificacion.controls.alcance.value || '',
        iLengthTitleFirst,
        iLengthValueFirst
      );
    }

    doc.increasePageY(5);
    doc.addTitle('Distribución');

    if(Array.isArray(this.distribucion) && this.distribucion.length > 0) {
      const distribucion = this.distribucion.map(grafo => {
        return [
          grafo?.ARTICULO_CODIGO,
          grafo?.ZONA_CODIGO,
          grafo?.BLANCO_CODIGO,
          grafo?.PROYECTO_CODIGO,
          grafo?.PORCENTAJE,
          grafo?.GRAFO_CODIGO,
          formatCurrency((grafo?.PORCENTAJE / 100) * this.solpe?.MONTO_SOLPE) || '0'
        ]
      });

      doc.addTable(
        [['ARTÍCULO', 'ZONA', 'BLANCO', 'PROYECTO', '%', 'GRAFO', 'MONTO']],
        distribucion,
        { 6: { halign: 'right'} },
      );
      doc.increasePageY((this.distribucion.length || 1) * 10);
    } else {
      doc.text('Sin distribución', doc.iMargin, doc.nPageYAxi);
    }

    doc.increasePageY(10);
    doc.addTitle('Empresas Propuestas');

    if(this.empresasPropuestas && this.empresasPropuestas.length > 0) {
      this.empresasPropuestas.forEach(empresa => {
        doc.text(empresa, doc.iMargin, doc.nPageYAxi);
        doc.increasePageY(5);
      })
    } else {
      doc.text('Sin empresas propuestas', doc.iMargin, doc.nPageYAxi);
      doc.increasePageY(5);
    }

    //SEGUNDA PAGINA

    // doc.iSection = 2;
    // doc.addNewPage();

    // const iLengthTitleSecond = 80.975;
    // const iLengthValueSecond = 100;

    // this.comentariosSolpe.forEach((comentario) => {
    //   doc.identText(
    //     [
    //       comentario?.NOMBRE_USUARIO,
    //       comentario?.FECHA_COMENTARIO,
    //     ],
    //     comentario.COMENTARIO,
    //     iLengthTitleSecond,
    //     iLengthValueSecond
    //   );
    //   doc.increasePageY(5);
    // });

    //TERCERA PAGINA

    doc.iSection = 2;
    doc.addNewPage();

    const historial = Array.isArray(this.historial) && this.historial.length > 0 ? this.historial.map(historial => {
      return [
        moment(historial?.FECHA_CREACION).format('DD/MM/YYYY HH:mm:ss'),
        historial?.NOMBRE_USUARIO,
        historial?.TIPO,
        historial?.ESTADO,
        historial?.GRAFO,
        historial?.RESP
      ]
    }) : [];

    doc.addTable(
      [['Fecha', 'Usuario', 'Tipo', 'Estado', 'Grafo', 'Responsable']],
      historial
    );

    //CUARTA PAGINA

    doc.iSection = 3;
    doc.addNewPage();

    if(this.documentosSolpe) {
      this.documentosSolpe.forEach(documento => {
        doc.text(documento?.descripcion || '', doc.iMargin, doc.nPageYAxi);
        doc.text(documento?.descripcion_archivo || '', doc.iMargin + 80, doc.nPageYAxi);
        doc.increasePageY(10);
      })
    }

    doc.iSection = 4;
    doc.addNewPage();

    if(this.estrategiaLiberacion) {
      this.estrategiaLiberacion.forEach((estrategia, i) => {
        doc.text(`${i + 1} - ${estrategia}`, doc.iMargin, doc.nPageYAxi);
        doc.increasePageY(10);
      });
    }

    doc.addFooter();
    doc.save(`solpe_${this.codigoSolpe}.pdf`);

  }

  private resetFormCambioGestor()
  {
    this.formCambioGestor = new FormGroup({
      observacion: new FormControl(null, Validators.required)
    });

    this.submittedCambioGestor = false;
  }
  getGestionadorName()
  {
    const gestinadorFind = this.gestionadores?.filter(g => g.CORRELATIVO == this.gcc_correlativo)

    if(gestinadorFind?.length > 0)
    {
      return gestinadorFind[0].NOMBRE
    }
    else
    {
      return "Gestionador por definir"
    }
  }

  cambioGestor()
  {
    this.submittedCambioGestor = true;
    if (this.formCambioGestor.invalid) {
      return;
    }

    const postData : {
      in_solpe_correlativo: number,
      iv_comentario: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo: this.corrSolpe,
      iv_comentario: "Motivo del cambio de gestionador: " + this.formCambioGestor.value.observacion,
      in_usua_correlativo_creacion: 0
    };
    this.postAgregarComentario(postData) 

    this.relacionaGestionador()

    this.resetFormRechazar();
    this.getHistorial();
    this.closeModalCambioGestor.nativeElement.click();
  }

  private setFormUpdate()
  {
      this.formUpdateSolpe = new FormGroup({
        'solicitante' : new FormControl({value: "", disabled: true}),
        'tipo_solicitud' : new FormControl(null, Validators.required),
        'forma' : new FormControl(null),
        'fecha_ingreso' : new FormControl({value: moment().format('YYYY-MM-DD'), disabled: true}, Validators.required),
        'fecha_termino' : new FormControl(null),
        'nombre' : new FormControl(null, Validators.required),
        'descripcion' : new FormControl(null, Validators.required),
        'monto' : new FormControl(null),
        'moneda' : new FormControl(null),
        'agregar_empresa': new FormControl(null),
        'rut_empresa' : new FormControl(null),
        'razon_social' : new FormControl(null),
        'contrato': new FormControl(null)
      });
  
      //this.submitted = false;
  }

  onChangeTipo() {
    if(this.solpe.TIPO_SOLPE_CORRELATIVO == 61) {
      this.lblFechaTerminoSolpe = 'Fecha Requerida Producto';
      this.mnjTipoCompras = true;
      this.tipoContrato = false;
    }
    else if(this.solpe.TIPO_SOLPE_CORRELATIVO == 82) {
      this.tipoContrato = true;
    }
    else {
      this.lblFechaTerminoSolpe = 'Fecha Término Solpe';
      this.mnjTipoCompras = false;
      this.tipoContrato = false;
    }

    /* solicitante: new FormControl(this.usuarioSolicitante),
        empresa: new FormControl({value: this.solpe.RAZON_SOCIAL, disabled: true}),
        rut_empresa: new FormControl({value: rut_empresa, disabled: true}),
        fecha_ingreso: new FormControl(moment(this.fechaSolpe).format('DD-MM-YYYY')),
        nombre: new FormControl(this.solpe.NOMBRE_SOLPE),
        monto: new FormControl(this.solpe.MONTO_SOLPE),
        descripcion: new FormControl(this.solpe.DESC_SOLPE),
        tipo_solicitud: new FormControl(this.solpe.TIPO_SOLPE_CORRELATIVO),
        forma: new FormControl(this.solpe.FORMA_SOLPE_CORRELATIVO),
        moneda: new FormControl(this.solpe.TIMO_CORRELATIVO) */

    if(this.solpe.TIPO_SOLPE_CORRELATIVO == 82) {
      this.formUpdateSolpe = new FormGroup({
        'tipo_solicitud' : new FormControl(this.solpe.TIPO_SOLPE_CORRELATIVO, Validators.required),
        'forma' : new FormControl(null),
        'fecha_termino' : new FormControl(moment(this.fechaTerminoSolpe).format('YYYY-MM-DD')),
        'nombre' : new FormControl(this.solpe.NOMBRE_SOLPE, Validators.required),
        'descripcion' : new FormControl(this.solpe.DESC_SOLPE, Validators.required),
        'monto' : new FormControl(this.solpe.MONTO_SOLPE),
        'moneda' : new FormControl(this.solpe.TIMO_CORRELATIVO),
        'agregar_empresa': new FormControl(null),
        'rut_empresa' : new FormControl(null),
        'razon_social' : new FormControl(this.solpe.RAZON_SOCIAL),
        'contrato': new FormControl(null, Validators.required)
      });
      /* this.formAgregarSolpe.controls.contrato.setValidators();
      this.formAgregarSolpe.controls.contrato.updateValueAndValidity(); */
    }
    if(this.solpe.TIPO_SOLPE_CORRELATIVO != 82) {


      this.formUpdateSolpe = new FormGroup({
        'tipo_solicitud' : new FormControl(this.solpe.TIPO_SOLPE_CORRELATIVO, Validators.required),
        'forma' : new FormControl(null, Validators.required),
        'fecha_termino' : new FormControl(moment(this.fechaTerminoSolpe).format('YYYY-MM-DD'), Validators.required),
        'nombre' : new FormControl(this.solpe.NOMBRE_SOLPE, Validators.required),
        'descripcion' : new FormControl(this.solpe.DESC_SOLPE, Validators.required),
        'monto' : new FormControl(this.solpe.MONTO_SOLPE),
        'moneda' : new FormControl(this.solpe.TIMO_CORRELATIVO),
        'agregar_empresa': new FormControl(null),
        'rut_empresa' : new FormControl(null),
        'razon_social' : new FormControl(this.solpe.RAZON_SOCIAL),
        'contrato': new FormControl(null)
      });
    }

    this.formUpdateSolpe.controls.tipo_solicitud.disable();
    this.formUpdateSolpe.get('tipo_solicitud').disable();
  }

  addEmpresaPropuesta() {
    if (this.formUpdateSolpe.controls.agregar_empresa.value === '' || this.formUpdateSolpe.controls.agregar_empresa.value === null) {
      this.notifyService.showInfo('Debe ingresar una Empresa.', 'Agregar empresa');
    } else {
      if(!this.empresas_propuestas) this.empresas_propuestas = []
      this.empresas_propuestas.push(this.formUpdateSolpe.controls.agregar_empresa.value);
      this.formUpdateSolpe.controls.agregar_empresa.setValue(null);
    }
  }
  deleteEmpresaSugerida(index: number){
    //console.log(index);
    if(index > -1){
      this.empresas_propuestas.splice(index, 1);
    }
  }
  onUpdate()
  {
    //let postData = {in_solpe_correlativo: Number, in_domi_correlativo_estadosolpe: Number, in_cargo_correlativo: Number, iv_observacion :String }
    let rut = null
    let digito = null
    if (this.solpe.FORMA_SOLPE_CORRELATIVO == 66) {
      //validar monto es mayor 20000
      let valor = this.formUpdateSolpe.value.monto
      let moneda = this.formUpdateSolpe.value.moneda
      const solpeUpdateCurrenci = this.GloblaDataService.CurrenciesList.find(currencie => currencie.TimoCode == moneda)[0]

      if(moneda != 9) {
        valor = valor * solpeUpdateCurrenci.ExchageRate;
      }
      let valorUsdSolpe = valor / this.GloblaDataService.USD.ExchageRate; 
      if (valorUsdSolpe > 20000) {
        this.notifyService.showWarning('La SOLPE excede el monto permitido por el MAF para Pagos Misceláneo según MAF', 'Crear Solpe');
        return;
      }


      let rut: string = null;
      let digito: string = null;
      let rut_empresa: string = this.solpe.RUT_EMPRESA;

      if(rut_empresa !== null && this.solpe.RUT_EMPRESA?.toString().includes("-")) {
        let rut_empresa_arr = rut_empresa.split('-');
        rut = rut_empresa[0];
        digito = rut_empresa[1];
      }
    }

   /*  this.formUpdateSolpe.controls.fecha_ingreso.enable();
    let fecha_ingreso = (this.formUpdateSolpe.value.fecha_ingreso) ? moment(this.formUpdateSolpe.value.fecha_ingreso).format('YYYY-MM-DD') : null;
    let fecha_termino, fecha_entrega_oc, fecha_fin: string;
    if(this.solpe.TIPO_SOLPE_CORRELATIVO == 61) {
      fecha_termino = null;
      fecha_entrega_oc = (this.formUpdateSolpe.value.fecha_termino) ? moment(this.formUpdateSolpe.value.fecha_termino).format('YYYY-MM-DD') : null;
      fecha_fin = fecha_entrega_oc;

    } else {
      fecha_termino = (this.formUpdateSolpe.value.fecha_termino) ? moment(this.formUpdateSolpe.value.fecha_termino).format('YYYY-MM-DD') : null;
      fecha_entrega_oc = null;
      fecha_fin = fecha_termino;
    }
    let f_inicio = moment(fecha_ingreso, "YYYY-MM-DD").format();
    let f_termino = moment(fecha_fin, "YYYY-MM-DD").format();

    if(f_termino < f_inicio) {
      this.notifyService.showInfo('Fecha de término no puede ser menor a fecha de inicio', 'Crear Solpe');
      return
    } */

    let fecha_termino = null
    if(this.solpe?.FECHA_TERMINO_SOLPE !== null) fecha_termino = moment(this.solpe?.FECHA_TERMINO_SOLPE).format('YYYY-MM-DD')
    let empresas = null
    if(this.empresas_propuestas !== null) empresas = this.empresas_propuestas.toString()

    const postData : {
      nombre_solpe: string, 
      descripcion: string, 
      monto:number, 
      fecha_entrega_oc: Date, 
      codigo_solpe: string, 
      cont_correlativo: number, 
      orden_de_compra: string, 
      correlativo: number,
      rut: number, 
      dig_rut: string, 
      razon_social: string, 
      fecha_termino: string,
      domi_correlativo_timo: number,
      empresas: string
      } = {
        nombre_solpe: this.formUpdateSolpe.value.nombre,
        descripcion: this.formUpdateSolpe.value.descripcion,
        monto: this.formUpdateSolpe.value.monto,
        fecha_entrega_oc: this.solpe.FECHA_ENTREGA_OC,
        cont_correlativo: this.solpe.CONT_CORRELATIVO,
        orden_de_compra: this.solpe.ORDEN_COMPRA,
        correlativo: this.corrSolpe,
        codigo_solpe: this.solpe.SOLPE_CODIGO,
        rut: rut,
        dig_rut: digito,
        razon_social: this.solpe.RAZON_SOCIAL,
        fecha_termino: fecha_termino,
        domi_correlativo_timo: this.formUpdateSolpe.value.moneda,
        empresas: empresas
    };


    this.solpeService.updateSolpe(postData).subscribe(resp => {
      this.notifyService.showSuccess('Solpe creada con exito.', 'Crear Solpe');

      this.closeModalUpdateData.nativeElement.click()
      this.getSolpe()
      //this.dataService.corrSolpe = JSON.parse(resp)[0].SOLPE_CORRELATIVO;
      //this.dataService.corrEstadoSolpe = JSON.parse(resp)[0].ESTADO_CORRELATIVO;
      //this.router.navigate(['/detalle-solpe/' + JSON.parse(resp)[0].SOLPE_CORRELATIVO]);
    }, err =>{
      this.notifyService.showError('Error al crear Solpe', 'Crear Sple');
      //console.log(err);
    });

  }
  onClose()
  {

  }

  dateDiff(startDate, endDate)
  {
    //return startDate + ', ' + endDate
    if(!endDate)
    {
      return ''
    }

    const mStartDate = moment(startDate, 'YYYY-MM-DD');
    const mEndDate = moment(endDate, 'YYYY-MM-DD');

    const months = mEndDate.diff(mStartDate, 'months', true);
    const days = mEndDate.diff(mStartDate, 'days');

    return months.toFixed(1) + ` Meses (`+days+` dias)`
  }

  get fd() { return this.formAgregarArchivo.controls; }
  get fc() { return this.formAgregarComentario.controls; }
  get fdist() { return this.formAgregarDistribucion.controls; }
  get ford() { return this.formAgregarOrdenCompra.controls; }

  get fEmp() { return this.formAgregarEmpresaPropuesta.controls; }
  get fModPor() { return this.formModificarPorcentaje.controls; }
  get fe() { return this.formAgregarEncuesta.controls; }
  get fp() { return this.formProveedor.controls; }
  get fa() { return this.formAsignacion.controls; }
  get fs() { return this.formServicios.controls; }
  get fr() { return this.formRechazar.controls; }
  get fg() { return this.formCambioGestor.controls; }

  get fu() { return this.formUpdateSolpe.controls; }

  // set proveedorSeter(proveedor: any[]) {
  //   this.formProveedor.controls.rut.setValue(proveedor[0].rut + '-' + proveedor[0].dig_rut);
  //   this.formProveedor.controls.email.setValue(proveedor[0].email);
  //   this.formProveedor.controls.telefono.setValue(proveedor[0].telefono);
  //   this.formProveedor.controls.contacto.setValue(proveedor[0].contacto);
  // }

  eliminarArchivoSolpe(correlativoArchivo: number, nombreArchivo: string) {
    this.nombreArchivoSolpe = nombreArchivo;
    this.correlativoArchivoSolpe = correlativoArchivo;
  }

  onAceptarDistribucion(distribucionCorrelativo: number) {
    this.aceptarDistribucionCorrelativo = distribucionCorrelativo;
  }

  isInfiniteNumber(number:  number): boolean {
    return Number.isFinite(number);
  }

  onRefresh() {
    window.location.reload();
  }
}
