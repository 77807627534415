<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12 ui-sortable">

            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <!-- <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">Boletas en Garantía</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div> -->

                <div class="bg-white rounded p3-rem">
                    <div class="row mb-4">
                        <!-- <div class="col-md-3">
                            <div class="widget widget-stats bg-primary">
                                <div class="stats-icon"><i class="fa fa-money-bill-alt"></i></div>
                                <div class="stats-info">
                                    <h4>Total de boletas</h4>
                                    <p>{{ totalBoletas }}</p>
                                </div>
                                <div class="stats-link">
                                    <a [routerLink]="" (click)="getAllBoletas()" style="cursor: pointer;">Ver detalle <i class="fa fa-arrow-alt-circle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="widget widget-stats bg-danger">
                                <div class="stats-icon"><i class="fa fa-calendar-times"></i></div>
                                <div class="stats-info">
                                    <h4>Vencen en 30 d&iacute;as</h4>
                                    <p>{{ boletas30Dias }}</p>
                                </div>
                                <div class="stats-link">
                                    <a [routerLink]="" (click)="getBoletasPorDias(30)" style="cursor: pointer;">Ver detalle <i class="fa fa-arrow-alt-circle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="widget widget-stats bg-warning">
                                <div class="stats-icon"><i class="fa fa-calendar-minus"></i></div>
                                <div class="stats-info">
                                    <h4>Vencen en 60 d&iacute;as</h4>
                                    <p>{{ boletas60Dias }}</p>
                                </div>
                                <div class="stats-link">
                                    <a [routerLink]="" (click)="getBoletasPorDias(60)" style="cursor: pointer;">Ver detalle <i class="fa fa-arrow-alt-circle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="widget widget-stats bg-info">
                                <div class="stats-icon"><i class="fa fa-calendar-check"></i></div>
                                <div class="stats-info">
                                    <h4>Vencen en 90 d&iacute;as</h4>
                                    <p>{{ boletas90Dias }}</p>
                                </div>
                                <div class="stats-link">
                                    <a [routerLink]="" (click)="getBoletasPorDias(90)" style="cursor: pointer;">Ver detalle <i class="fa fa-arrow-alt-circle-right"></i></a>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-12 col-md-6">
                            <h4 class="normalText">
                                <!-- {{ msg_boletas }} ({{cantidadBoletasMsj}}) -->
                                Documentos de garantía
                            </h4>
                        </div>
                        <div class="col-12 col-md-6 text-right">
                            <!-- <button *ngIf="existeAccion('Exportar')" class="btn tag text-primary" (click)="exportAsXLSX()"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-file-excel"></i>
                                Exportar
                            </button>&nbsp;&nbsp; -->
                            <app-view-excel-buttons *ngIf="existeAccion('Exportar')" [props]="excelButtonDocGarantia">
                            </app-view-excel-buttons>
                            <a *ngIf="existeAccion('Agregar')" class="btn btn-primary ml-2"
                            (click)="resetForm()"
                            href="#modal-agregar" data-toggle="modal">
                                Agregar
                            </a>

                        </div>
                    </div>
                    <!-- <div class="row">

                    </div> -->
                    <br>
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <!-- <button [ngClass]="{'text-primary text-bolder': selectedFilter === 1}" class="btn tag mb-1" style="margin-right: 5px;" (click)="getAllBoletas()">Todos <span [ngClass]="{'bg-light-orange': selectedFilter === 1}" class="counter">{{totalBoletas}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 2}" class="btn tag mb-1" type="button" (click)="getBoletasPorDias(30, 2)" style="margin-right: 5px;">Vencen en 30 d&iacute;as <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 2}">{{boletas30Dias}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 3}" class="btn tag mb-1" type="button" (click)="getBoletasPorDias(60, 3)" style="margin-right: 5px;">Vencen en 60 d&iacute;as <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 3}">{{boletas60Dias}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 4}" class="btn tag mb-1" type="button"  (click)="getBoletasPorDias(90, 4)" style="margin-right: 5px;">Vencen en 90 d&iacute;as <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 4}">{{boletas90Dias}}</span></button> -->
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 1}" class="btn tag mb-1" style="margin-right: 5px;" (click)="getAllBoletas()">Todos <span [ngClass]="{'bg-light-orange': selectedFilter === 1}" class="counter">{{totalBoletas}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 6}" class="btn tag mb-1" type="button"  (click)="filtrarBoletas(0)" style="margin-right: 5px;">Vencidas <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 6}">{{boletas0Dias}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 7}" class="btn tag mb-1" type="button"  (click)="filtrarBoletas(1)" style="margin-right: 5px;">Vigentes <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 7}">{{boletasVigente}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 8}" class="btn tag mb-1" type="button"  (click)="filtrarBoletas(2)" style="margin-right: 5px;">Devueltas <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 8}">{{boletasDevueltas}}</span></button>
                            <br>
                            <br>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 2}" class="btn tag mb-1" type="button" (click)="filtrarBoletas(30)" style="margin-right: 5px;">Vencen en 30 d&iacute;as <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 2}">{{boletas30Dias}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 3}" class="btn tag mb-1" type="button" (click)="filtrarBoletas(45)" style="margin-right: 5px;">Vencen en 45 d&iacute;as <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 3}">{{boletas45Dias}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 4}" class="btn tag mb-1" type="button" (click)="filtrarBoletas(60)" style="margin-right: 5px;">Vencen en 60 d&iacute;as <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 4}">{{boletas60Dias}}</span></button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 5}" class="btn tag mb-1" type="button"  (click)="filtrarBoletas(90)" style="margin-right: 5px;">Vencen en 90 d&iacute;as <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 5}">{{boletas90Dias}}</span></button>
                        </div>
                        <div class="col-12 col-md-4">
                            <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterBoletasGrl($event)" placeholder="Buscar ... " #input>
                            <i matSuffix class="fas fa-search" style="position: absolute;top: 0.7rem;right: 1.5rem;"></i>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-md-12">
                            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                            class="table border table-striped table-td-valign-middle table-th-valign-middle table-responsive">
                                <ng-container matColumnDef="IDboleta">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="IDboleta">#</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.BG_CORRELATIVO }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Contrato">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Contrato">Contrato</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.CODIGO_CONTRATO }}</td>
                                </ng-container>
                                <ng-container matColumnDef="NroBoleta">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="NroBoleta">
                                        N° Documento
                                    </th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                        <p class="mb-0">
                                            {{element.TIPO_DOCUMENTO_DESCRIPCION}}
                                        </p>
                                        <span>
                                            {{ element.NRO_BOLETA }}
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NombreServicio">
                                    <th class=" normalText" style="padding:
                                      10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="NombreServicio">Servicio</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                        <p class="mb-0">
                                            {{ element.DESCRIPCION_BG}}
                                        </p>
                                        <span class="text-muted">
                                            {{element.PROVEEDORNAME}}
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Observaciones">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Observaciones">Observaciones</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.OBSERVACION_BG }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Administrador">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Administrador">Administrador EMSA</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.ADMINISTRADORNAME }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Monto">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Monto">Monto</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                        <p class="mb-0 text-muted">
                                            {{ element.BANCO }}
                                        </p>
                                        {{ element.MONTO | currency: element.ISOCode: element.Simbol: element.decimalPipe : 'es-CL' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DESCRIPCION_ADJ">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Moneda">Tipo</th>
                                    <!-- <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.TIPO_MONEDA }}</td> -->
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.DESCRIPCION_ADJ }}</td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="Banco">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Banco">Banco</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.BANCO }}</td>
                                </ng-container> -->
                                <ng-container matColumnDef="EstadoBoleta">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="EstadoBoleta">Estado</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                        <div *ngIf="element.FECHA_DEVOLUCION !== null" class="tab bg-success text-white">Devuelta</div>
                                        <div *ngIf="element.DIAS_POR_VENCER > 0 && element.FECHA_DEVOLUCION === null" class="tab tab-green">Vigente</div>
                                        <div *ngIf="element.DIAS_POR_VENCER <= 0 && element.FECHA_DEVOLUCION === null" class="tab tab-yellow">Vencida</div>
                                    </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="FechaEmision">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaEmision">Emisión</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_EMISION | date:'dd/MM/yyyy'}}</td>
                                </ng-container> -->
                                <!-- <ng-container matColumnDef="FechaIngreso">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaIngreso">Fecha Ingreso</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_INGRESO | date:'dd/MM/yyyy'}}</td>
                                </ng-container> -->
                                <!-- <ng-container matColumnDef="FechaVencimiento">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaVencimiento">Vencimiento</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_VENCIMIENTO }}</td>
                                </ng-container> -->
                                <ng-container matColumnDef="DiasVencimiento">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="DiasVencimiento">Vencimiento</th>
                                    <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                        <p *ngIf="element.FECHA_PRORROGA === null" class="mb-0">{{ element.FECHA_VENCIMIENTO | date:'dd-MM-yyyy' }}</p>
                                        <p *ngIf="element.FECHA_PRORROGA !== null" class="mb-0">{{ element.FECHA_PRORROGA | date:'dd-MM-yyyy' }}</p>
                                        <span *ngIf="element.DIAS_POR_VENCER <= 0 && element.FECHA_DEVOLUCION == null">
                                            <span *ngIf="element.DIAS_POR_VENCER <= 30" style="padding-top: 15px !important;"><i style="color: #FF5B57;" class="fa fa-exclamation-triangle led"></i></span>
                                            <span *ngIf="element.DIAS_POR_VENCER > 30 && element.DIAS_POR_VENCER <= 60" style="padding-top: 15px !important;"><i style="color: #F59C1A;" class="fa fa-exclamation-triangle"></i></span>
                                            <span *ngIf="element.DIAS_POR_VENCER > 60 && element.DIAS_POR_VENCER <= 90" style="padding-top: 15px !important;"><i style="color: #2C6D80;" class="fa fa-exclamation-triangle"></i></span>
                                            {{ element.DIAS_POR_VENCER }} días
                                        </span>
                                        <span *ngIf="element.DIAS_POR_VENCER > 0 && element.FECHA_DEVOLUCION == null">
                                            <span *ngIf="element.DIAS_POR_VENCER <= 30 && element.DIAS_POR_VENCER" style="padding-top: 15px !important;"><i style="color: #FF5B57;" class="fa fa-exclamation-triangle led"></i></span>
                                            <span *ngIf="element.DIAS_POR_VENCER > 30 && element.DIAS_POR_VENCER <= 60" style="padding-top: 15px !important;"><i style="color: #F59C1A;" class="fa fa-exclamation-triangle"></i></span>
                                            <span *ngIf="element.DIAS_POR_VENCER > 60 && element.DIAS_POR_VENCER <= 90" style="padding-top: 15px !important;"><i style="color: #2C6D80;" class="fa fa-exclamation-triangle"></i></span>
                                            {{ element.DIAS_POR_VENCER }} días
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaDevolucion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaDevolucion">Devolución</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_DEVOLUCION | date:'dd-MM-yyyy' }}</td>
                                </ng-container>
                                <ng-container matColumnDef="docDevolucion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Doc. Devolución
                                    </th>
                                    <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                        <ng-container *ngIf="element.ADJUNTO_DEVOLUCION != null && element.ADJUNTO_DEVOLUCION !== ''">
                                            <view-file-button
                                                [nombreArchivo]="element.ADJUNTO_DEVOLUCION"
                                                [tipoArchivo]="'boletas'"
                                            ></view-file-button>
                                            <a (click)="onDescargar(element.ADJUNTO_DEVOLUCION)" class="btn rounded-icon mr-2"  title="descargar">
                                                <i class="fas fa-file-download"></i>
                                            </a>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaVctoCto">
                                    <th class=" normalText" style="padding-top: 10px !important; padding-bottom: 10px !important; padding-left: 30px !important; padding-right: 30px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaVctoCto">Fecha Vcto Cto</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_TERMINO_CONTRATO | date:'dd-MM-yyyy' }}</td>
                                </ng-container>
                                <ng-container matColumnDef="FechaProyectadaVencimientoContrato">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaDevolucion">Alerta de vencimiento</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                        <span *ngIf="element.FECHA_DEVOLUCION === null">{{ alertaVencimientoBoleta(element.FECHA_TERMINO_CONTRATO, element.FECHA_VENCIMIENTO, element.FECHA_PRORROGA, element.DIAS_POR_VENCER) }}</span>
                                    </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="Observaciones">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Observaciones">Observaciones</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.OBSERVACION_BG }}</td>
                                </ng-container> -->

                                <ng-container matColumnDef="Acciones">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Acciones
                                    </th>
                                    <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                        <!-- <a (click)="onVer(element.ARCHIVO_ADJUNTO, 'boleta')" class="btn rounded-icon mr-2"  title="descargar">
                                            <i class="fa fa-cloud-download-alt"></i>
                                        </a> -->
                                        <!-- <view-file-button
                                            [nombreArchivo]="element.ARCHIVO_ADJUNTO"
                                            [tipoArchivo]="'boleta'"
                                        >
                                        </view-file-button> -->
                                        <view-file-button
                                            [nombreArchivo]="element.ARCHIVO_ADJUNTO"
                                            [tipoArchivo]="'boletas'"
                                        ></view-file-button>
                                        <a (click)="onDescargar(element.ARCHIVO_ADJUNTO)" class="btn rounded-icon mr-2"  title="descargar">
                                            <i class="fas fa-file-download"></i>
                                        </a>
                                        <a *ngIf="existeAccion('Editar')" href="#modal-editar" data-toggle="modal"
                                        (click)="resetForm(); editar(element.BG_CORRELATIVO)" class="btn rounded-icon mr-2"  title="Editar">
                                            <i class="fa fa-edit"></i>
                                        </a>

                                        <!-- <div class="btn-group">
                                            <a [routerLink]="" (click)="onDescargar(element.ARCHIVO_ADJUNTO)" class="btn btn-default btn-sm" style="color: #666;"><i class="fa fa-cloud-download-alt"></i></a>
                                            <a *ngIf="existeAccion('Editar')" href="#modal-editar" data-toggle="modal" (click)="editar(element.BG_CORRELATIVO)" class="btn btn-default btn-sm" style="color: #666;"><i class="fa fa-edit"></i></a>
                                        </div> -->
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="dataSourceHeader"></tr>
                                <tr mat-row *matRowDef="let row; columns: dataSourceHeader;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                                <tr class="mat-row " *matNoDataRow>
                                    <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                </tr>
                            </table>

                            <mat-paginator #paginatorFuncionalidadGrl [pageSize]="20" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Modal de crear boleta garantia -->
    <div class="modal fade" id="modal-agregar">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Agregar documento de garantía</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="boletaFormAgregar" (ngSubmit)="onCrear()" novalidate>
                        <div class="row form-group">
                            <label for="contrato" class="col-md-2 col-form-label">Contrato (Código Interno)</label>
                            <div class="col-md-10">
                                <ng-select2 [data]="contratos" [(ngModel)]="contratoSelected" #contrato formControlName="contrato" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submitted && f.contrato.errors}" (valueChanged)="onChange(contratoSelected)">
                                </ng-select2>
                                <!-- <select class="form-control" formControlName="contrato" #contrato [ngClass]="{ 'is-invalid': submitted && f.contrato.errors }" (change)="onChange($event.target.value)">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of contratos" [value]="item.CONTRATO_CORRELATIVO">{{item.CODIGO_CONTRATO}} - {{item.NOMBRE_CONTRATO}}</option>
                                </select> -->
                                <div *ngIf="submitted && f.contrato.errors" class="invalid-feedback">
                                    <div *ngIf="f.contrato.errors.required">Selecciona un elemento de la lista</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="banco" class="col-md-2 col-form-label">Banco</label>
                            <div class="col-md-10">
                                <select class="form-control" formControlName="banco" #banco [ngClass]="{ 'is-invalid': submitted && f.banco.errors }">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of bancos" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="tipoDocumento" class="col-md-2 col-form-label">Tipo de documento</label>
                            <div class="col-md-10">
                                <select class="form-control" formControlName="tipoDocumento" #tipoDocumento [ngClass]="{ 'is-invalid': submitted && f.tipoDocumento.errors }">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of tipoDoc" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-md-2 col-form-label">Número de documento</label>
                            <div class="col-md-10">
                                <input type="text" formControlName="nro_boleta" #nro_boleta class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nro_boleta.errors }" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="monto" class="col-md-2 col-form-label">Monto</label>
                            <div class="col-md-4 input-group">
                                <input type="number" formControlName="monto" #monto class="form-control" [ngClass]="{ 'is-invalid': submitted && f.monto.errors }" />
                            </div>
                            <label for="moneda" class="col-md-2 col-form-label">Moneda</label>
                            <div class="col-md-4">
                                <select class="form-control" formControlName="moneda" #moneda [ngClass]="{ 'is-invalid': submitted && f.moneda.errors }">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of monedas" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <div style="background-color: #f5f5f5; border-radius: 5px; padding: 1rem">
                            <div class="row form-group">
                                <label for="fecha_ingreso" class="col-md-2 col-form-label">Fecha de Ingreso</label>
                                <div class="col-md-4">
                                    <input type="text" class="form-control m-b-5" placeholder="Fecha de Ingreso" formControlName="fecha_ingreso" #fecha_ingreso readonly>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="fecha_emision" class="col-md-2 col-form-label">Fecha de Emisión</label>
                                <div class="col-md-4 input-group">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Emisión" formControlName="fecha_emision" #fecha_emision [ngClass]="{ 'is-invalid': submitted && f.fecha_emision.errors }">
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="fecha_vencimiento" class="col-md-2 col-form-label">Fecha de Vencimiento</label>
                                <div class="col-md-4 input-group">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Vencimiento" formControlName="fecha_vencimiento" #fecha_vencimiento [ngClass]="{ 'is-invalid': submitted && f.fecha_vencimiento.errors }">
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="fecha_prorroga" class="col-md-2 col-form-label">Fecha de Prórroga</label>
                                <div class="col-md-4">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Prorroga" formControlName="fecha_prorroga" #fecha_prorroga>
                                </div>
                            </div>
                            <hr>
                            <div class="row form-group">
                                <label for="fecha_devolucion" class="col-md-2 col-form-label">Fecha de Devolución</label>
                                <div class="col-md-4 input-group">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Devolución" formControlName="fecha_devolucion" #fecha_devolucion>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="adjunto" class="col-md-2 col-form-label">Adjunto Devolucion</label>
                                <div class="col-md-10">
                                    <div class="custom-file">
                                        <input type="file" #fileAdjuntoDevolucion class="custom-file-input" name="adjuntoDevolucion"
                                        id="adjuntoDevolucion" aria-describedby="inputGroupFileAddon01"
                                        [ngClass]="{ 'is-invalid': submitted && !fileValid }"
                                        (change)="detectFilesDevolucion($event)">
                                        <label class="custom-file-label" for="adjuntoDevolucion"
                                        >
                                            {{fileNameDevolucion}}
                                        </label>
                                    </div>
                                    <span class="text-muted">formatos permitidos: pdf, doc, docx</span>
                                </div>
                            </div>
                        </div>
                        <br>
                        <!-- <div style="display: none;" class="row form-group">
                            <label for="descripcion" class="col-md-2
                                        col-form-label">Descripcion del Servicio</label>
                            <div class="col-md-10">
                                <input class="form-control" formControlName="descripcion" #descripcion [ngClass]="{ 'is-invalid': submitted &&
                                f.descripcion.errors }" [value]="filterContrato(contratoSelected)" />
                            </div>
                        </div> -->
                        <div class="row form-group">
                            <label for="observaciones" class="col-md-2 col-form-label">Observaciones</label>
                            <div class="col-md-10">
                                <textarea class="form-control" rows="3" formControlName="observacion" #observacion [ngClass]="{ 'is-invalid': submitted && f.observacion.errors }"></textarea>
                            </div>
                        </div>
                        <div class="row form-group" *ngIf="existeAccion('Adjuntar Archivo')">
                            <label for="adjunto" class="col-md-2 col-form-label">Adjunto</label>
                            <div class="col-md-10">
                                <div class="custom-file">
                                    <input type="file" #fileAdjunto class="custom-file-input" name="adjunto"
                                    id="adjunto" aria-describedby="inputGroupFileAddon01"
                                    [ngClass]="{ 'is-invalid': submitted && !fileValid }"
                                    (change)="detectFiles($event)">
                                    <label class="custom-file-label" for="adjunto"
                                    >
                                        {{fileName}}
                                    </label>
                                </div>
                                <span class="text-muted">formatos permitidos: pdf, doc, docx</span>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="tipo" class="col-md-2 col-form-label">Tipo</label>
                            <div class="col-md-10">
                                <select class="form-control" formControlName="tipo" #tipo [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of tipos" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <input type="hidden" name="bg_correlativo" id="bg_correlativo" formControlName="bg_correlativo" #bg_correlativo value="" />
                        <div class="row mt-5">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-primary mr-3" type="submit" [disabled]="this.boletaFormAgregar.invalid || submitted">Agregar</button>
                                <a href="javascript:;" (click)="onClose()" #closeModalAgregar class="btn btn-white " data-dismiss="modal">Cerrar</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal de editar boleta grantia-->
    <div class="modal fade" id="modal-editar">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Editar documento de garantía</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="boletaFormEditar" (ngSubmit)="onEdit()" novalidate>
                        <div class="row form-group">
                            <!-- <label for="contrato" class="col-md-2 col-form-label">Contrato (Código Interno)</label> -->
                            <div class="col-2">
                                <p>Contrato (Código Interno)</p>
                            </div>
                            <div class="col-10">
                                <!-- <ng-select2 [data]="contratos" #contrato formControlName="contrato" class="select2-selection select2-selection--single" (valueChanged)="onChange(contratoSelectedEditar)" [(ngModel)]="contratoSelectedEditar">
                                </ng-select2> -->
                                <h4>{{descripcionContratoEditar}}</h4>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="banco" class="col-md-2 col-form-label">Banco</label>
                            <div class="col-md-10">
                                <select class="form-control" formControlName="banco" #banco [ngClass]="{ 'is-invalid': submitted && ef.banco.errors }">
                                  <option value="0">Seleccione ...</option>
                                  <option *ngFor="let item of bancos" [value]="item.correlativo">{{item.descripcion}}</option>
                              </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="tipoDocumento" class="col-md-2 col-form-label">Tipo de documento</label>
                            <div class="col-md-10">
                                <select class="form-control" formControlName="tipoDocumento" #tipoDocumento [ngClass]="{ 'is-invalid': submitted && ef.tipoDocumento.errors }">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of tipoDoc" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-md-2 col-form-label">Número de Documento</label>
                            <div class="col-md-10">
                                <input type="text" formControlName="nro_boleta" #nro_boleta class="form-control" [ngClass]="{ 'is-invalid': submitted && ef.nro_boleta.errors }" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="monto" class="col-md-2 col-form-label">Monto</label>
                            <div class="col-md-4 input-group">
                                <input type="number" formControlName="monto" #monto class="form-control" [ngClass]="{ 'is-invalid': submitted && ef.monto.errors }" />
                            </div>
                            <label for="moneda" class="col-md-2 col-form-label">Moneda</label>
                            <div class="col-md-4">
                                <select class="form-control" formControlName="moneda" #moneda [ngClass]="{ 'is-invalid': submitted && ef.moneda.errors }">
                                  <option value="0">Seleccione ...</option>
                                  <option *ngFor="let item of monedas" [value]="item.correlativo">{{item.descripcion}}</option>
                              </select>
                            </div>
                        </div>
                        <div style="background-color: #f5f5f5; border-radius: 5px; padding: 1rem">
                            <div class="row form-group">
                                <label for="fecha_ingreso" class="col-md-2 col-form-label">Fecha de Ingreso</label>
                                <div class="col-md-4">
                                    <input type="text" class="form-control m-b-5" placeholder="Fecha de Ingreso" formControlName="fecha_ingreso" #fecha_ingreso readonly>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="fecha_emision" class="col-md-2 col-form-label">Fecha de Emisión</label>
                                <div class="col-md-4 input-group">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Emisión" formControlName="fecha_emision" #fecha_emision [ngClass]="{ 'is-invalid': submitted && ef.fecha_emision.errors }">
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="fecha_vencimiento" class="col-md-2 col-form-label">Fecha de Vencimiento</label>
                                <div class="col-md-4 input-group">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Vencimiento" formControlName="fecha_vencimiento" #fecha_vencimiento [ngClass]="{ 'is-invalid': submitted && ef.fecha_vencimiento.errors }">
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="fecha_prorroga" class="col-md-2 col-form-label">Fecha de Prórroga</label>
                                <div class="col-md-4">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Prorroga" formControlName="fecha_prorroga" #fecha_prorroga>
                                </div>
                            </div>
                            <hr>
                            <div class="row form-group mb-0">
                                <label for="fecha_devolucion" class="col-md-2 col-form-label">Fecha de Devolución</label>
                                <div class="col-md-4 input-group">
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha de Devolución" formControlName="fecha_devolucion" #fecha_devolucion>
                                </div>
                            </div>
                            <div *ngIf="editarBoleta == false" class="row form-group">
                                <label for="adjunto" class="col-md-2 col-form-label">Adjunto Devolucion</label>
                                <div class="col-md-10">
                                    <div class="custom-file">
                                        <input type="file" #fileAdjuntoDevolucion class="custom-file-input" name="adjuntoDevolucion"
                                        id="adjuntoDevolucion" aria-describedby="inputGroupFileAddon01"
                                        (change)="detectFilesDevolucion($event)">
                                        <label class="custom-file-label" for="adjuntoDevolucion"
                                        >
                                            {{fileNameDevolucion}}
                                        </label>
                                    </div>
                                    <span class="text-muted">formatos permitidos: pdf, doc, docx</span>
                                </div>
                            </div>
                        </div>
                        <br>
                        <!-- <div class="row form-group">
                            <label for="descripcion" class="col-md-2 col-form-label">Nombre del Servicio</label>
                            <div class="col-md-10">
                                <input [disabled]="true" class="form-control" formControlName="descripcion" #descripcion />
                            </div>
                        </div> -->
                        <div class="row form-group">
                            <label for="observaciones" class="col-md-2 col-form-label">Observaciones</label>
                            <div class="col-md-10">
                                <textarea class="form-control" rows="3" formControlName="observacion" #observacion></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="tipo" class="col-md-2 col-form-label">Tipo</label>
                            <div class="col-md-10">
                                <select class="form-control" formControlName="tipo" #tipo>
                                  <option value="0">Seleccione ...</option>
                                  <option *ngFor="let item of tipos" [value]="item.correlativo">{{item.descripcion}}</option>
                              </select>
                            </div>
                        </div>
                        <input type="hidden" name="bg_correlativo" id="bg_correlativo" formControlName="bg_correlativo" #bg_correlativo value="" />
                        <div class="row">
                            <!-- <div class="col-md-3"></div>
                            <div class="col-md-3"></div> -->
                            <div class="col-md-12 text-right">
                                <a href="javascript:;" (click)="onClose()" #closeModalEditar class="btn btn-white mr-2" data-dismiss="modal">Cerrar</a>
                                <button *ngIf="editarBoleta == false" class="btn btn-primary" type="submit" [disabled]="this.boletaFormEditar.invalid || submitted">Guardar</button>
                                <!-- <div *ngIf="editarBoleta == false" class="col-md-3 text-right">
                                    
                                </div> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>