import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContratoService } from '../shared/services/contrato.service';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';

import { FileService } from '../shared/services/file.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BoletasService } from '../shared/services/boletas.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { NotificationService } from '../shared/services/notification.service';
import { DataService } from '../shared/services/data.service';
import { EstadoPagoService } from '../shared/services/estado-pago.service';
import { SolpeService } from '../shared/services/solpe.service';
import { SelectionModel } from '@angular/cdk/collections';
import { GlobalDataService, ICurrencies } from '../shared/services/global-data.service';
import { ModalDocumentsComponent } from '../shared/modal-documents/modal-documents.component';
import { MatDialog } from '@angular/material/dialog';
import { AddonsService } from 'src/app/shared/services/addons.service';
import { AuthService } from '../shared/services/auth.service';
import { formatCurrency } from './../shared/functions/common';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.css']
})
export class ContratosComponent implements OnInit {
  tipo_ep_normal = "NORMAL";
  tipo_ep_incurrido = "INCURRIDO";
  tipo_ep_provision = "PROVISION";

  //EP Normal
  ep_nornal: any;
  dsEPNormal:any;
  dsHeaderEPNormal: string[] = ['EP_CORRELATIVO','DatosEp', 'EP_PERIODO_DESDE','FechaEp','Monto','Opciones'];
  @ViewChild('paginatorEPNormal', {static: false}) paginatorEPNormal: MatPaginator;
  @ViewChild('closebutton') closebutton;

  //EP incurrido
  ep_incurrido : any;
  dsEPIncurrido:any;
  dsHeaderEPIncurrido: string[] = ['EP_CORRELATIVO','DatosEp', 'FechaEp','Monto', 'Opciones'];
  @ViewChild('paginatorEPIncurrido', {static: false}) paginatorEPIncurrido: MatPaginator;

  //EP Provisional
  ep_provisional: any;
  dsEPProvisional:any;
  dsHeaderEPProvisional: string[] = ['EP_CORRELATIVO','DatosEp', 'FechaEp','Monto', 'Opciones'];
  @ViewChild('paginatorEPProvisional', {static: false}) paginatorEPProvisional: MatPaginator;

  //
  showUpdateEmsa: boolean = false;
  adecEmsaUpdateId: number;
  //Adec
  public selected: string;
  selection = new SelectionModel<any>(false, []);
  dsAdecContrato:any;
  dsHeaderAdecContrato: string[] = ['AdminContratista', 'FechaInicio', 'FechaTermino','Vigente','UsuarioActualizacion','FechaActualizacion'];
  @ViewChild('paginatorAdec', {static: false}) paginatorAdec: MatPaginator;

  //Adec Emsa
  dsAdecEmsaContrato:any;
  dsHeaderAdecEmsaContrato: string[] = ['AdminEmsa', 'FechaInicio', 'FechaTermino','Vigente','UsuarioActualizacion','FechaActualizacion'];

  //Seleccionar Solpe
  selectionSolpe = new SelectionModel<any>(false, []);
  dataSourceSolpe:any;
  dataSourceHeaderSolpe: string[] = ['Correlativo', 'Nombre', 'Forma', 'Tipo', 'FechaAprobacion', 'Acciones'];
  @ViewChild('paginatorSolpe', {static: false}) paginatorSolpe: MatPaginator;
  @ViewChild('modalSeleccionarSolpe') modalSeleccionarSolpe: ElementRef
  solpesCerradas: any[];

  @ViewChild('paginatorAdecEmsa', {static: false}) paginatorAdecEmsa: MatPaginator;

  adecEmsaContrato: any[];
  adecContrato: any[];
  proveedores: any[];
  admcPorProveedor: any[] ;
  contratos:any;
  baseContra:any;
  boletas: any;
  dcContratos: string[] = ['Codigo', 'Contrato','Proveedor','Adm','FechaIni', 'Ver'];
  dcBoletas: string[] = ['NroBoleta', 'DescripcionBoleta', 'Banco', 'TipoMoneda', 'EstadoBoleta', 'FechaVencimiento', 'DiasVencimiento', 'Opciones'];
  itemizados:any;
  Headitemizados:any;
  Footitemizados:any;
  dcItemizados: string[] = ['Descripcion','Precio' ];
  archivos:any;
  archivosContrato: any[];
  archivosRegistrados:any;
  dcArchivos: string[] = ['Descripcion','Link'];

  dataContrato:any;
  idContrato: number;
  nameCode: string;

  avanceTiempo: any;
  avanceFinanciero: any;
  chartDonutDataTiempo:any[];
  chartDonutDataFinc:any[];


  filtro:[];
  dashboard:any;
  dataForExcel: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) paginatorBoletas: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  chartBarOptions: any;
  chartBarData:any[];

  chartDonutData: any[];
  chartDonutOptions: any;

  selectedRowIndex = -1;

  sortedData: any;
  contratosInit: any;
  rolId: number;

  /** Agregar Contrato */
  submitted = false;
  formAgregarContrato : FormGroup;
  formExportarContratosPorFecha: FormGroup;
  tipoContrato: any[];
  tipoMoneda: any[];
  tipoGerencia: any[];
  tipoArea: any[];
  tipoOrigen: any[];
  tipoEstadoProceso: any[];
  tipoEstadoContrato: any[];
  administradores: any[];
  ademsa: any[];
  gestionadores: any[];
  modificacionesContrato: any[];
  @ViewChild('closeModalAgregar') closeModalAgregar: ElementRef;
  @ViewChild('closeModalVer') closeModalVer: ElementRef;

  itemizado: any[] = [];

  idRol: number = 0;

  isCheckedCInterno: boolean;
  public contractCurrenci: ICurrencies;
  selectedFilter: number = 1;
  nTotal: number = 0;
  nVigentes: number = 0;
  nExpirados: number = 0;
  nFiniquitos: number = 0;
  nCancelados: number = 0;

  /* archivos */
  formAgregarArchivo: FormGroup;
  /* archivos: any[]; */
  archivos64 :any;
  fileName :string;
  type :string;
  submittedDocumento = false;

  ultimaBoleta: any[] = [];

  agregarArchivoVisible: boolean = false;
  tipoArchivoContrato: string = 'contratos';
  tipoArchivoBoleta: string = 'boleta';
  montoSolpeParaContrato: number = 0;

  solpeContrato: any[] = [];
  montoContratoInput: number = 0;
  @ViewChild('closeModalContratos') closeModalContratos: ElementRef;

  estadosDePago: any[] = [];
  montosCalculados = false;
  isExporting = false;

  /** Acciones permitidas para el usuario segun su rol */
  accionesPermitidas: string[] = [];

  constructor(private router: Router,
    private contratoService: ContratoService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private boletasService: BoletasService,
    private notifyService: NotificationService,
    public fb: FormBuilder,
    private dataService: DataService,
    private estadoPagoService: EstadoPagoService,
    private solpeService: SolpeService,
    public GloblaDataService: GlobalDataService,
    public objDialog: MatDialog,
    private addonsService: AddonsService,
    private authService: AuthService
  ) {
    if(this.GloblaDataService.CurrenciesList.length == 0)
    {
      this.GloblaDataService.GetCurrencie();
    }
    this.isCheckedCInterno = false;
    //this.contractCurrenci = this.GloblaDataService.CurrenciesList.find(currencie => currencie.TimoCode == this.dataContrato.TIMO_CORRELATIVO)[0]
    //this.GloblaDataService.USD = this.GloblaDataService.CurrenciesList.find(currencie => currencie.TimoCode == 12);
    this.formAgregarArchivo = new FormGroup({
      grupo : new FormControl(null, Validators.required),
      adjunto : new FormControl(null),
      corr_cont: new FormControl(null),
      descripcion: new FormControl(null),
      isPublic: new FormControl(null)
    });

    this.formExportarContratosPorFecha = new FormGroup({
      fechaIni: new FormControl(null, [Validators.required]),
      fechaFin: new FormControl(null, [Validators.required])
    });

    let corrMenu = 2;

    this.authService.getAccionesPorUsuario(corrMenu).subscribe(resp => {
      let acciones: any[] = JSON.parse(resp);
      if(acciones.length > 0) {
        this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
      }
    },
    error => {console.log(error)});
  }

  ngOnInit(): void {

    this.idRol = JSON.parse(localStorage.getItem('userData')).rolId;
    /* this.chartBarOptions = {
      resize: true,
      xkey: 'y',
      axes: false,
      ykeys: ['a','b'],
      labels: ['Total', 'Fecha termino', 'Monto'],
      barColors: ["#2d38cd", "#bb2688" , "#cd5c2b"]
    };
    this.chartDonutOptions = {
      resize: true
    }; */

    /* this.contratoService.getDashboard().subscribe(resp =>{
      if (JSON.parse(resp)[0]){
        this.dashboard = JSON.parse(resp)[0];
        if (this.dashboard){
          this.chartDonutData = [
              {label: "Vigentes", value: this.dashboard.VIGENTES, color: '#49B6D6'},
              {label: "Expirados", value: this.dashboard.EXPIRADOS , color: '#FF5B57'},
              {label: "Finiquitados", value: this.dashboard.FINIQUITADOS , color: '#F59C1A'},
              {label: "Cancelados", value: this.dashboard.CANCELADOS , color: 'black'}
            ];
        }
      }
    }); */
    this.contratoService.getDominios('TIBU').subscribe(resp =>{
      this.filtro =JSON.parse(resp);
    });
    let contratoInterno = this.isCheckedCInterno == true ? 'S': 'N';
    this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
    this.getContratos(1, contratoInterno, this.rolId);
    this.getProveedores();
    /**Agregar contrato */
    this.resetForm();
    this.getTipoContrato();
    this.getTipoMonedas();
    this.getTipoGerencia();
    this.getTipoArea();
    this.getTipoOrigen();
    this.getTipoEstadoProceso();
    this.getTipoEstadoContrato();
    this.getCargos();
    this.getAdemsa();
    this.getSolpesCerradas();
    //this.validacion();
  }

  existeAccion(accion: string) {
    return (this.accionesPermitidas.includes(accion));
  }

  get fd() { return this.formAgregarArchivo.controls; }
  detectFile(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64 = bs64
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }

  onGetSolpeContrato(solpeCorrelativo: number){
    this.contratoService.getSolpeContrato(solpeCorrelativo).subscribe(resp =>{
      this.solpeContrato = JSON.parse(resp);
    });
  }

  revisarSolpe(corr_solpe: number, corr_estado_solpe: number) {
    this.closeModalContratos.nativeElement.click();
    // this.dataService.corrSolpe = corr_solpe;
    // this.dataService.corrEstadoSolpe = corr_estado_solpe;
  }

  private resetFormArchivo(){
    this.formAgregarArchivo = new FormGroup({
      grupo : new FormControl(null, Validators.required),
      adjunto : new FormControl(null),
      corr_cont: new FormControl(null),
      descripcion: new FormControl(null),
      isPublic: new FormControl(null)
    });
    this.submittedDocumento = false;
    this.fileName = null;
  }
  onAgregarArchivo() {
    this.submittedDocumento = true;
    if (this.formAgregarArchivo.invalid) {
      return;
    }

    if(this.fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64}
      this.addonsService.postFileContrato(postDataFile).subscribe(resp =>{
        this.postDocumento(JSON.parse(resp));
        this.getDocumentos(this.idContrato);
        this.closebutton.nativeElement.click();
      });
    }
    return;
  }
  private postDocumento(nombreArchivo: string) {
    const postData : {
      pin_cont_correlativo: number,
      pin_doc_correlativo: number,
      piv_nom_archivo: string,
      pin_usua_correlativo: number,
      piv_descripcion: string,
      piv_esPublico: string
    } = {
      pin_cont_correlativo: this.idContrato,
      pin_doc_correlativo: +this.formAgregarArchivo.value.grupo,
      piv_nom_archivo: nombreArchivo,
      pin_usua_correlativo: 0,
      piv_descripcion: this.formAgregarArchivo.value.descripcion,
      piv_esPublico: this.formAgregarArchivo.value.isPublic ? 'S' : 'N'
    };
    this.contratoService.postcreaArchivoContrato(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
    }, err =>{
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
      console.log(err);
    });
    this.getDocumentos(this.idContrato);
    this.resetFormArchivo();
  }
  onCloseArchivo() {
    this.resetFormArchivo();
  }
  cargarModalArchivo(correlativoCont: number) {
    this.formAgregarArchivo.controls.corr_cont.setValue(correlativoCont);
    this.agregarArchivoVisible = !this.agregarArchivoVisible;
    //this.getDocumentosSolpe(correlativoSolpe);
  }
  onFiltro(filtro: any){
    let contratoInterno = this.isCheckedCInterno == true ? 'S': 'N'
    if(filtro.target.value){
      this.getContratos(filtro.target.value, contratoInterno, this.rolId);
    }
  }
  onFiltroDashboard(filtro: any){
    let contratos = [];
    switch(filtro)
    {
      case 1:
        this.selectedFilter = 1;
        contratos = this.baseContra;
      break;
      case 30:
        this.selectedFilter = 2;
        contratos = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Vigente');
      break;
      case 31:
        this.selectedFilter = 3;
        contratos = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Expirado');
      break;
      case 32:
        this.selectedFilter = 4;
        contratos = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Finiquitado');
      break;
      case 33:
        this.selectedFilter = 5;
        contratos = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Cancelado');
      break;
      case 34:
        this.selectedFilter = 6;
        contratos = this.baseContra.filter(t=>t.CONTRATO_INITERNO === 'S');
      break;
    }
    this.setContratos(contratos);
    if (contratos){
      this.createPaginator(this.paginator, this.contratos);
    }
  }

  onChangeCInterno (contrato_interno: any) {
    this.isCheckedCInterno = contrato_interno;
  }
  onVerBoleta(id:number){

  }
  async onExport(){
    this.isExporting = true;
    this.dataForExcel = [];
    const dataXLS = this.contratos.data.map((contrato: any) => ({
      // Correlativo: contrato['CONTRATO_CORRELATIVO'],
      'Año': contrato['ANIO_CONTRATO'],
      'Código': contrato['CODIGO_CONTRATO'],
      Nombre: contrato['NOMBRE_CONTRATO'],
      Estado: contrato['ESTADO_CONTRATO'],
      Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['CODIGO_SOLPE'] : '',
      Documento: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['DOCUMENTO_SOLPE'] : '',
      Tipo_Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['TIPO_SOLPE'] : '',
      Forma_Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['FORMA_SOLPE'] : '',
      Estado_Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['ESTADO_SOLPE'] : '',
      // Tipo: contrato['TIPO_CONTRATO'],
      Proveedor: contrato['PROVEEDOR'],
      'Rut Proveedor': contrato['RUT_PROVEEDOR'],
      'Fecha Creacion': contrato['FECHA_CREACION'],
      'Fecha Contrato': contrato['FECHA_CONTRATO'],
      'Fecha Inicio': contrato['FECHA_INICIO_CONTRATO'],
      'Fecha Termino': contrato['FECHA_TERMINO_CONTRATO'],
      Gerencia: contrato['GERENCIAQ'],
      'Dirección': contrato['AREA'],
      'Tipo Contrato': contrato['TIPO_CONTRATO'],
      'Admin. EMSA': contrato['ADMINISTRADOR'],
      'Email Admin. EMSA': contrato['EMAIL_ADMINISTRADOR'],
      'Admin. EECC': contrato['ADMINISTRADOR_PROVEEDOR'],
      'Email Admin. EECC': contrato['EMAIL_ADMINISTRADOR_PROVEEDOR'],
      Gestionador: contrato['GESTIONADOR'],
      // Origen: contrato['ORIGEN'],
      // 'Requiere BG': contrato['REQUIERE_BG'] == 1 ? 'SI' : 'NO',
      // 'Dias restantes': contrato['DIAS_RESTANTES'],
      // 'Contrato Interno': contrato['CONTRATO_INITERNO'] == 'S' ? 'SI' : 'NO',
      Moneda: contrato['Simbol'],
      'Monto Original': contrato['MONTO_ORIGINAL'],
      'Monto Modificaciones': contrato['MONTO_MODIF'],
      'Monto Actualizado': contrato['MONTO_ACTUALIZADO'],
      'Monto Reajuste': contrato['MONTO_REAJUSTE'],
      // '% Reajuste': contrato['PORCENTAJE_REAJUSTE'],
      // '% Modificaciones': contrato['PORCENTAJE_MODIF'],
      'Monto EP': contrato['MONTO_EP'],
      'Monto EP S/R': contrato['MONTO_EP_SR'],
      'Saldo S/R': contrato['MONTO_SALDO'],
      'USD Monto original': contrato['MONTO_USD'],
      'USD Monto Modificaciones': contrato['MONTO_MODIF_USD'],
      'USD Monto Actualizado ': contrato['MONTO_ACTUALIZADO_USD'],
      'USD Reajuste': contrato['REAJUSTE_USD'],
      'USD Monto EP': contrato['MONTO_EP_USD'],
      'USD Saldo S/R': contrato['MONTO_SALDO_USD'],
      // '% EP': contrato['PORCENTAJE_EP'],
      // '% Saldo': contrato['PORCENTAJE_SALDO'],
      'Requiere Documento de garantía': contrato['REQUIERE_BG'] ? +contrato['REQUIERE_BG'] === 1 ? 'SI' : 'NO' : 'NO',
      'Tiene Documento de garantía': contrato['NRO_BOLETA'] ? 'SI' : 'NO',
      'Tipo de documento de garantía': contrato['TIPO_DOCUMENTO_BG'],
      'Estado documento de garantía': this.validarParametrosDocGarfantia(+contrato['REQUIERE_BG'], contrato['NRO_BOLETA'], +contrato['DIAS_POR_VENCER'], contrato['FECHA_DEVOLUCION_BG']),
      'Dias restantes': contrato['DIAS_POR_VENCER'],
      'Nro. Documento': contrato['NRO_BOLETA'],
      'Moneda Documento': contrato['TIPO_MONEDA_BOLETA'],
      'Monto Documento': contrato['MONTO_BOLETA'] ? formatCurrency(contrato['MONTO_BOLETA']) : '',
      // '% Boleta': contrato['PORCENTAJE_BOLETA'],
      'Fecha Creacion Documento': contrato['FECHA_CREACION_BG'],
      'Fecha Emisión Documento': contrato['FECHA_EMISION_BOLETA'],
      'Fecha Vencimiento Documento': contrato['FECHA_VENCIMIENTO_BOLETA'],
      // 'Alerta Boleta': this.createMensajeAlertaBoleta(contrato),
      // 'Documentos Vigentes': contrato['REQUIERE_BG'] == 1 ? contrato['BOLETAS_VIGENTES'] : '',
    }));
    dataXLS.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })
    const reportData = {
      title: 'Contratos',
      data: this.dataForExcel,
      headers: Object.keys(dataXLS[0]),
      filename: 'contratos'
    }
    this.excelService.exportExcel(reportData);
    this.isExporting = false;
  }

  onExportarContratosPorFecha(){
    this.submitted = true;
    if (this.formExportarContratosPorFecha.invalid) {
      return;
    }

    this.isExporting = true;
    this.dataForExcel = [];

    // cambiado por consulta a servicio que retorna contratos por fecha
    let contratoInterno = this.isCheckedCInterno == true ? 'S': 'N';
    this.contratoService.getContratosExportarPorfecha(contratoInterno, this.idRol, this.formExportarContratosPorFecha.value.fechaIni, this.formExportarContratosPorFecha.value.fechaFin).subscribe(resp=>{
      const contratosResponse: any[] = JSON.parse(resp);
      let contratos: any[] = [];
      

      if (contratosResponse.length > 0) {
        contratosResponse.forEach((contrato: any) => {
          contratos.push({
            ...contrato,
            ...this.getMontosContrato(contrato)
          })
        });

        const dataXLS = contratos.map((contrato: any) => ({
          // Correlativo: contrato['CONTRATO_CORRELATIVO'],
          'Año': contrato['ANIO_CONTRATO'],
          'Código': contrato['CODIGO_CONTRATO'],
          Nombre: contrato['NOMBRE_CONTRATO'],
          Estado: contrato['ESTADO_CONTRATO'],
          // Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['CODIGO_SOLPE'] : '',
          // Documento: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['DOCUMENTO_SOLPE'] : '',
          // Tipo_Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['TIPO_SOLPE'] : '',
          // Forma_Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['FORMA_SOLPE'] : '',
          // Estado_Solpe: contrato['ESTADO_CONTRATO'] == 'Vigente' ? contrato['ESTADO_SOLPE'] : '',
          // Tipo: contrato['TIPO_CONTRATO'],
          Proveedor: contrato['PROVEEDOR'],
          'Rut Proveedor': contrato['RUT_PROVEEDOR'],
          // 'Fecha Creacion': contrato['FECHA_CREACION'],
          'Fecha Contrato': contrato['FECHA_CONTRATO'],
          'Fecha Inicio': contrato['FECHA_INICIO_CONTRATO'],
          'Fecha Termino': contrato['FECHA_TERMINO_CONTRATO'],
          Gerencia: contrato['GERENCIAQ'],
          'Dirección': contrato['AREA'],
          'Tipo Contrato': contrato['TIPO_CONTRATO'],
          'Admin. EMSA': contrato['ADMINISTRADOR'],
          'Email Admin. EMSA': contrato['EMAIL_ADMINISTRADOR'],
          Gestionador: contrato['GESTIONADOR'],
          // Origen: contrato['ORIGEN'],
          // 'Requiere BG': contrato['REQUIERE_BG'] == 1 ? 'SI' : 'NO',
          // 'Dias restantes': contrato['DIAS_RESTANTES'],
          // 'Admin. EECC': contrato['ADMINISTRADOR_PROVEEDOR'],
          // 'Email Admin. EECC': contrato['EMAIL_ADMINISTRADOR_PROVEEDOR'],
          // 'Contrato Interno': contrato['CONTRATO_INITERNO'] == 'S' ? 'SI' : 'NO',
          Moneda: contrato['Simbol'],
          'Monto Original': contrato['MONTO_ORIGINAL'],
          'Monto Modificaciones': contrato['MONTO_MODIF'],
          'Monto Actualizado': contrato['MONTO_ACTUALIZADO'],
          'Monto Reajuste': contrato['MONTO_REAJUSTE'],
          // '% Reajuste': contrato['PORCENTAJE_REAJUSTE'],
          // '% Modificaciones': contrato['PORCENTAJE_MODIF'],
          'Monto EP': contrato['MONTO_EP'],
          'Monto EP S/R': contrato['MONTO_EP_SR'],
          'Saldo S/R': contrato['MONTO_SALDO'],
          'USD Monto original': contrato['MONTO_USD'],
          'USD Monto Modificaciones': contrato['MONTO_MODIF_USD'],
          'USD Monto Actualizado ': contrato['MONTO_ACTUALIZADO_USD'],
          'USD Reajuste': contrato['REAJUSTE_USD'],
          'USD Monto EP': contrato['MONTO_EP_USD'],
          'USD Saldo S/R': contrato['MONTO_SALDO_USD'],
          // '% EP': contrato['PORCENTAJE_EP'],
          // '% Saldo': contrato['PORCENTAJE_SALDO'],
          'Requiere Documento de garantía': contrato['REQUIERE_BG'] ? +contrato['REQUIERE_BG'] === 1 ? 'SI' : 'NO' : 'NO',
          'Tiene Documento de garantía': contrato['NRO_BOLETA'] ? 'SI' : 'NO',
          'Tipo de documento de garantía': contrato['TIPO_DOCUMENTO_BG'],
          'Estado documento de garantía': this.validarParametrosDocGarfantia(+contrato['REQUIERE_BG'], contrato['NRO_BOLETA'], +contrato['DIAS_POR_VENCER'], contrato['FECHA_DEVOLUCION_BG']),
          'Dias restantes': contrato['DIAS_POR_VENCER'],
          'Nro. Documento': contrato['NRO_BOLETA'],
          'Moneda Documento': contrato['TIPO_MONEDA_BOLETA'],
          'Monto Documento': contrato['MONTO_BOLETA'] ? formatCurrency(contrato['MONTO_BOLETA']) : '',
          // '% Boleta': contrato['PORCENTAJE_BOLETA'],
          'Fecha Creacion Documento': contrato['FECHA_CREACION_BG'],
          'Fecha Emisión Documento': contrato['FECHA_EMISION_BOLETA'],
          'Fecha Vencimiento Documento': contrato['FECHA_VENCIMIENTO_BOLETA'],
          // 'Alerta Boleta': this.createMensajeAlertaBoleta(contrato),
          // 'Documentos Vigentes': contrato['REQUIERE_BG'] == 1 ? contrato['BOLETAS_VIGENTES'] : '',
        }));
  
        dataXLS.forEach((row: any) => {
          this.dataForExcel.push(Object.values(row))
        });
  
        const reportData = {
          title: 'Contratos',
          data: this.dataForExcel,
          headers: Object.keys(dataXLS[0]),
          filename: 'contratos'
        }
  
        this.excelService.exportExcel(reportData);
        this.isExporting = false;
        this.submitted = false;
      }else{
        this.isExporting = false;
        this.submitted = false;
        this.notifyService.showWarning('Exportar', 'No se encontran contratos para exportar en el rango de fechas seleccionado.');
      }
    });
  }
  applyFilterContrato(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.contratos.filter = filterValue.trim().toLowerCase();
  }
  applyFilterBoletas(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.boletas.filter = filterValue.trim().toLowerCase();
  }
  applyFilterItem(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.itemizados.filter = filterValue.trim().toLowerCase();
  }
  applyFilterEPIncurrido(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEPIncurrido.filter = filterValue.trim().toLowerCase();
  }
  applyFilterEPNormal(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEPNormal.filter = filterValue.trim().toLowerCase();
  }
  applyFilterEPProvisional(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEPProvisional.filter = filterValue.trim().toLowerCase();
  }
  applyFilterSolpes(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceSolpe.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag : any, table: any ){
    //if(table?.paginator !== undefined) return;
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  sortData(sort: Sort) {
    const data = this.contratosInit.slice();
    if (!sort.active || sort.direction === '') {
      this.setContratos(data)
      this.createPaginator(this.paginator, this.contratos);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Codigo': return compare(a.Codigo, b.Codigo, isAsc);
        case 'Proveedor': return compare(a.Proveedor, b.Proveedor, isAsc);
        case 'FechaIni': return compare(a.FechaIni, b.FechaIni, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.setContratos(this.sortedData)
      this.createPaginator(this.paginator, this.contratos);
    }
  }
  private getContratos(filtro: number, contratoInterno: string, idRol: number){
    this.contratoService.getContratos(filtro, contratoInterno, idRol).subscribe(resp=>{
      const contratos = JSON.parse(resp);
      this.setContratos(contratos);
      this.baseContra = contratos;

      this.nTotal = this.baseContra.length;
      this.nVigentes = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Vigente').length;
      this.nFiniquitos = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Finiquitado').length;
      this.nExpirados = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Expirado').length;
      this.nCancelados = this.baseContra.filter(t=>t.ESTADO_CONTRATO ==='Cancelado').length;


      //this.contratos.sort = this.sort;
      if (this.contratos){
        this.createPaginator(this.paginator, this.contratos);
        this.setMontosContratos();
      }
      this.contratosInit = contratos;
    });
  }
  private getSolpesCerradas(){
    this.contratoService.getSolpesCerradas().subscribe(resp => {

      this.solpesCerradas = JSON.parse(resp);
      this.dataSourceSolpe = new MatTableDataSource(this.solpesCerradas);

      //this.createPaginator(this.paginator, this.contratos);
    });
  }
  private getBoletasContratoId(filtro: number){
    this.contratoService.getBoletasContratoId(filtro).subscribe(resp=>{
      this.boletas = new MatTableDataSource(JSON.parse(resp));
      if (this.boletas){
        this.paginatorBoletas._intl.itemsPerPageLabel = 'Registros por página';
        this.paginatorBoletas._intl.firstPageLabel = 'Primera pagina';
        this.paginatorBoletas._intl.lastPageLabel = 'Última pagina';
        this.paginatorBoletas._intl.nextPageLabel = 'Siguiente';
        this.paginatorBoletas._intl.previousPageLabel = 'Anterior';
        this.boletas.paginatorBoletas = this.paginatorBoletas;
        this.ultimaBoleta = JSON.parse(resp);
      }
    });
  }
  private getContratoId(id: number){
    this.dataContrato = {};
    this.contratoService.getContratoId(id).subscribe(resp=>{

      if (JSON.parse(resp)[0]){
        this.dataContrato = JSON.parse(resp)[0];
        this.contractCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.dataContrato.TIMO_CORRELATIVO)

      }
    });
  }
  private getModificacionesContrato(id: number){
    this.dataContrato = {};
    this.solpeService.getSolpeModificacionContrato(1,this.rolId,id).subscribe(resp=>{

      if (JSON.parse(resp)){
        this.modificacionesContrato = JSON.parse(resp);
      }
    });
  }
  public aplicarModificacion(idSolpe: number){
    let postData = {
      in_solpe_correlativo: idSolpe,
      in_cargo_correlativo: this.rolId,
      in_usua_correlativo: idSolpe
    };
    this.idContrato
    //alert(idSolpe);

    this.solpeService.postAplicaModificacionContrato(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Modificación de contrato aplicada con exito.', 'Crear Modificacion');
      // notificacion de modificacion de contrato a admin. EMSA
      this.solpeService.postEnviarCorreoInfoEmsaAplicacionModificacionContrato({in_cont_correlativo: this.idContrato}).subscribe(resp=>{
        this.notifyService.showSuccess('Notificacion de Modificación de contrato enviada.', 'Notificacion de Modificacion');
      }, err => {
        this.notifyService.showError('Error al enviar la notificacion de modificación de contrato', 'Notificacion de modificacion');
      });

    }, err => {
      this.notifyService.showError('Error al crear modificación de contrato', 'Crear Modificacion');
    }, () => {
      this.getContratoId(this.idContrato);
      this.getAvanceTiempo(this.idContrato);
      this.getAvanceFinanciero(this.idContrato);
      this.getAdecContrato(this.idContrato);
      this.getAdecEmsaContrato(this.idContrato);
      this.getModificacionesContrato(this.idContrato);
    });
  }

  private getDocumentos(id: number){
    this.contratoService.getContratoDocumentos(id).subscribe(resp=>{
      if (JSON.parse(resp)[0]){
        this.archivos = JSON.parse(resp);
        this.archivosRegistrados = this.archivos.filter(t=>t.EXISTE ==='S');
      }
    });
    this.contratoService.getDocumentosContrato(this.idContrato).subscribe(resp=>{
      this.archivosContrato = JSON.parse(resp);
    });
  }
  private getAvanceTiempo (id: number){
    this.contratoService.getAvanceTiempo(id).subscribe(resp=>{
      if (JSON.parse(resp)[0]){
        this.avanceTiempo = JSON.parse(resp)[0];
        if (this.avanceTiempo){
          this.chartDonutDataTiempo = [
            {label: "Meses restantes", value: this.avanceTiempo.MESES_RESTANTES > 0 ? this.avanceTiempo.MESES_RESTANTES : 0 , color: '#FF5B57'},
            {label: "Meses de avance", value: this.avanceTiempo.MESES_AVANCE > 0 ? this.avanceTiempo.MESES_AVANCE : 0 , color: '#49B6D6'}
          ];
        }
      }
    });
  }
  private getAvanceFinanciero (id: number){
    this.contratoService.getAvanceFinanciero(id).subscribe(resp=>{
      if (JSON.parse(resp)[0]){
        this.avanceFinanciero = JSON.parse(resp)[0];
        if (this.avanceFinanciero){
          this.chartDonutDataFinc = [
            {label: "Monto EP", value:  this.avanceFinanciero.MONTO_EP > 0 ? this.avanceFinanciero.MONTO_EP: 0 , color: '#49B6D6'},
            {label: "Saldo contrato", value: (this.avanceFinanciero.SALDO_CONTRATO - this.avanceFinanciero.MONTO_EP) >0 ? (this.avanceFinanciero.SALDO_CONTRATO - this.avanceFinanciero.MONTO_EP): 0, color: '#FF5B57'}
          ];
        }
      }
    });
  }
  /*private getItemizado(id : number){
    this.contratoService.getHeadItemizado(id).subscribe(resp =>{
      this.Headitemizados =JSON.parse(resp);

      if (this.Headitemizados) {
        this.contratoService.getFootItemizado(id).subscribe(resp=>{
          this.Footitemizados = JSON.parse(resp);

        });
        this.contratoService.getItemizado(id).subscribe(resp =>{
          this.itemizados =JSON.parse(resp);

        });
      }
    });
  }*/
  onVer(id: number, code: string, row:any, solpeCorrelativo: number){
    this.selectedRowIndex = row.id;
    this.idContrato =id;
    this.nameCode =code;
    if (this.idContrato){
      this.getContratoId(this.idContrato);
      this.getBoletasContratoId(this.idContrato);
      this.getDocumentos(this.idContrato);
      this.getAvanceTiempo(this.idContrato);
      this.getAvanceFinanciero(this.idContrato);
      this.getItemizado(this.idContrato);
      this.getAdecContrato(this.idContrato);
      this.getAdecEmsaContrato(this.idContrato);
      this.getEPContrato(this.tipo_ep_normal,this.idContrato);
      this.getEPContrato(this.tipo_ep_provision,this.idContrato);
      this.getEPContrato(this.tipo_ep_incurrido,this.idContrato);
      this.getModificacionesContrato(this.idContrato);
      this.onGetSolpeContrato(solpeCorrelativo);
    }

  }
  onDescargarBoleta(fileName: string){
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }
  onDescargar(fileName: string){
    this.fileService.downloadDocumentoContrato(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }
  onDelete(fileName: string, correlativo: number){
    this.fileService.deleteFile({in_file_name: fileName, in_file_type: this.tipoArchivoContrato, in_correlativo: correlativo}).subscribe(resp => {
      this.notifyService.showSuccess('Eliminado ...', 'Documento eliminado');
      this.getDocumentos(this.idContrato);
    }, err =>{
      this.getDocumentos(this.idContrato);
      this.notifyService.showError(err.error.message, 'Erro al eliminar archivo');
    });
  }
  onEditar(id: number){
    this.router.navigate(['/edit-contrato/'+ id]);
  }

  /** Agregar Contrato */
  resetForm(){
    this.formAgregarContrato = new FormGroup({
      'solpe': new FormControl({value: null, disabled: true}),
      'ano_contrato' : new FormControl(null, [Validators.required]),
      'codigo_contrato' : new FormControl(null, [Validators.required]),
      'nombre_contrato' : new FormControl(null, [Validators.required]),
      'tipo_contrato' : new FormControl(null, [Validators.required]),
      'proveedor' : new FormControl(null, [Validators.required]),
      'admin_contrato' : new FormControl(null, [Validators.required]),
      'monto' : new FormControl(null, [Validators.required]),
      'tipo_moneda' : new FormControl(null, [Validators.required]),
      'fecha_contrato' : new FormControl(null, [Validators.required]),
      'fecha_inicio' : new FormControl(null, [Validators.required]),
      'fecha_termino' : new FormControl(null, [Validators.required]),
      'gerencia' : new FormControl(null, [Validators.required]),
      'area' : new FormControl(null, [Validators.required]),
      'administrador' : new FormControl(null, [Validators.required]),
      'gestionador': new FormControl(null, [Validators.required]),
      'origen': new FormControl(null, [Validators.required]),
      'cct_critico': new FormControl(null, [Validators.required]),
      'bg': new FormControl(null),
      'estado_proceso': new FormControl(null, [Validators.required]),
      'estado_contrato': new FormControl(null, [Validators.required]),
      'contrato_interno': new FormControl(null)
    });

    this.submitted = false;
  }
  private getTipoContrato() {
    this.boletasService.getTipoDominios('TICO').subscribe(resp => {
      this.tipoContrato = JSON.parse(resp);
    });
  }
  private getTipoMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.tipoMoneda = JSON.parse(resp);
    });
  }
  private getTipoGerencia() {
    this.boletasService.getTipoDominios('TIGE').subscribe(resp => {
      this.tipoGerencia = JSON.parse(resp);
    });
  }
  private getTipoArea() {
    this.boletasService.getTipoDominios('TIAR').subscribe(resp => {
      this.tipoArea = JSON.parse(resp);
    });
  }
  private getTipoOrigen() {
    this.boletasService.getTipoDominios('TIOR').subscribe(resp => {
      this.tipoOrigen = JSON.parse(resp);
    });
  }
  private getTipoEstadoProceso() {
    this.boletasService.getTipoDominios('TIESPROC').subscribe(resp => {
      this.tipoEstadoProceso = JSON.parse(resp);
    });
  }
  private getTipoEstadoContrato() {
    this.boletasService.getTipoDominios('ESDOCONT').subscribe(resp => {
      this.tipoEstadoContrato = JSON.parse(resp);
    });
  }
  private getCargos() {
    this.contratoService.getCargosContrato('ACEMSA').subscribe(resp => {
      this.administradores = JSON.parse(resp);
    });
    this.contratoService.getCargosContrato('GESCONTCOMP').subscribe(resp => {
      this.gestionadores = JSON.parse(resp);
    });
  }
  private getAdemsa() {
    this.contratoService.getAdemsa().subscribe(resp => {
      this.ademsa = JSON.parse(resp);
    });
  }

  private getProveedores() {
    this.solpeService.getProveedores().subscribe(resp => {
      this.proveedores = JSON.parse(resp);
    }, error => console.log(error));
  }

  onGetAdminContrato(idProveedor: any){
    if (idProveedor > 0 ){
      this.getAdmcProveedor(idProveedor);
    }
  }

  getAdmcProveedor(corr_proveedor: number) {
    if(corr_proveedor){
      this.contratoService.getAdmcsProveedorSelect2(corr_proveedor).subscribe(resp => {
        this.admcPorProveedor = JSON.parse(resp);
      });
    }
  }

  private getEPContrato(tipo: string, cont_correlativo: number){
    this.contratoService.getEPContrato(tipo, cont_correlativo).subscribe(resp =>{
      switch(tipo){
        case this.tipo_ep_incurrido:
          this.ep_incurrido = JSON.parse(resp);
          this.dsEPIncurrido = new MatTableDataSource(this.ep_incurrido);
          if (this.ep_incurrido.length > 0){
            this.createPaginator(this.paginatorEPIncurrido, this.dsEPIncurrido);
          }
          break;
          case this.tipo_ep_provision:
          this.ep_provisional = JSON.parse(resp);
          this.dsEPProvisional = new MatTableDataSource(this.ep_provisional);
          if (this.ep_provisional.length > 0){
            this.createPaginator(this.paginatorEPProvisional, this.dsEPProvisional);
          }
          break;
          case this.tipo_ep_normal:
          this.ep_nornal = JSON.parse(resp);
          this.estadosDePago = this.ep_nornal;
          this.dsEPNormal = new MatTableDataSource(this.ep_nornal);
          if(this.ep_nornal.length > 0){
            this.createPaginator(this.paginatorEPNormal, this.dsEPNormal);
          }
          break;
          default:
          this.ep_nornal = JSON.parse(resp);
          this.estadosDePago = this.ep_nornal;
          this.dsEPNormal = new MatTableDataSource(this.ep_nornal);
          if(this.ep_nornal.length > 0){
            this.createPaginator(this.paginatorEPNormal, this.dsEPNormal);
          }
      }
    });
  }

  private getAdecContrato(idContrato: number) {
    this.contratoService.getAdecContrato(idContrato).subscribe(resp => {
      this.adecContrato = JSON.parse(resp);
      this.dsAdecContrato = new MatTableDataSource(this.adecContrato);
      this.createPaginator(this.paginatorAdec, this.dsAdecContrato);
    });
  }
  private getAdecEmsaContrato(idContrato: number) {
    this.contratoService.getAdecEmsaContrato(idContrato).subscribe(resp => {
      this.adecEmsaContrato = JSON.parse(resp);
      this.dsAdecEmsaContrato = new MatTableDataSource(this.adecEmsaContrato);
      this.createPaginator(this.paginatorAdecEmsa, this.dsAdecEmsaContrato);
    });
  }

  onGetAdemsaUpdateId(ademsaId: number){
    this.adecEmsaUpdateId = ademsaId;
  }
  onGuardarAdminEmsa(){
    if (this.adecEmsaUpdateId > 0 ){
      const postData : {
        in_cont_correlativo :  number,
        in_admin_emsa :  number,
        in_usua_correlativo_creacion :  number
      } = {
        in_cont_correlativo :  this.idContrato,
        in_admin_emsa :  this.adecEmsaUpdateId,
        in_usua_correlativo_creacion : 0 // se cambia por el id en el servicio
      }
      this.contratoService.postAdecEmsaContrato(postData).subscribe(resp=>{
        if (JSON.parse(resp)[0].CORRELATIVO){
          this.notifyService.showSuccess('Administrador EMSA actualizado con exito.', 'Actualizar Contrato');
          this.getAdecEmsaContrato(this.idContrato);
          this.showUpdateEmsa = !this.showUpdateEmsa;
        }
      });
    }
  }

  get f() { return this.formAgregarContrato.controls; }
  /*private validacion() {
    this.formAgregarContrato = this.fb.group({
      ano_contrato : ['', [Validators.required]],
      codigo_contrato : ['', [Validators.required]],
      nombre_contrato : ['', [Validators.required]],
      tipo_contrato : ['', [Validators.required]],
      proveedor : ['', [Validators.required]],
      monto : ['', [Validators.required]],
      tipo_moneda : ['', [Validators.required]],
      fecha_contrato : ['', [Validators.required]],
      fecha_inicio : ['', [Validators.required]],
      fecha_termino : ['', [Validators.required]],
      bg : ['', [Validators.required]],
      gerencia : ['', [Validators.required]],
      area : ['', [Validators.required]],
      administrador :  ['', [Validators.required]],
      gestionador : ['', [Validators.required]],
      origen :  ['', [Validators.required]],
      estado_proceso : ['', [Validators.required]],
      estado_contrato : ['', [Validators.required]],
    });
  }*/
  onCrearContrato() {
    this.submitted = true;
    if (this.formAgregarContrato.invalid) {
      return;
    }

    this.contratoService.postValidateCodigoContrato(
      {
        codigoContrato: this.formAgregarContrato.value.codigo_contrato
      }
    ).subscribe(
      (resp) => {
        const result = JSON.parse(resp);

        console.log(result.isValid);

        if (result.isValid) {
          let fecha_contrato = (this.formAgregarContrato.value.fecha_contrato) ? moment(this.formAgregarContrato.value.fecha_contrato).format('YYYY-MM-DD') : null;
          let fecha_inicio = (this.formAgregarContrato.value.fecha_inicio) ? moment(this.formAgregarContrato.value.fecha_inicio).format('YYYY-MM-DD') : null;
          let fecha_termino = (this.formAgregarContrato.value.fecha_termino) ? moment(this.formAgregarContrato.value.fecha_termino).format('YYYY-MM-DD') : null;
          this.formAgregarContrato.controls.solpe.enable();

          const postData : {
            iv_anio_contrato : string,
            iv_nombre_contrato : string,
            iv_codigo_contrato : string,
            in_tico_correlativo : number,
            in_proveedor : number,
            in_monto : number,
            in_timo_correlativo : number,
            iv_fecha_contrato : string,
            iv_fecha_inicio_contrato : string,
            iv_fecha_termino_contrato : string,
            iv_requiere_bg : string,
            in_tige_correlativo : number,
            in_tiar_correlativo : number,
            in_admin_correlativo : number,
            in_gest_correlativo : number,
            in_tior_correlativo : number,
            in_tiesproc_correlativo : number,
            in_esdo_correlativo : number,
            in_admin_ec : number,
            iv_es_cont_interno: string
            in_usua_correlativo_creacion: number,
            in_solpe_correlativo: number,
            in_cct_critico: number
            } = {
              iv_anio_contrato : this.formAgregarContrato.value.ano_contrato,
              iv_nombre_contrato : this.formAgregarContrato.value.nombre_contrato,
              iv_codigo_contrato : this.formAgregarContrato.value.codigo_contrato,
              in_tico_correlativo : this.formAgregarContrato.value.tipo_contrato,
              in_proveedor : this.formAgregarContrato.value.proveedor,
              in_monto : this.formAgregarContrato.value.monto,
              in_timo_correlativo : this.formAgregarContrato.value.tipo_moneda,
              iv_fecha_contrato : fecha_contrato,
              iv_fecha_inicio_contrato : fecha_inicio,
              iv_fecha_termino_contrato : fecha_termino,
              iv_requiere_bg :(this.formAgregarContrato.value.bg == 1) ? '1' : '0',
              in_tige_correlativo : this.formAgregarContrato.value.gerencia,
              in_tiar_correlativo : this.formAgregarContrato.value.area,
              in_admin_correlativo : this.formAgregarContrato.value.administrador,
              in_gest_correlativo :this.formAgregarContrato.value.gestionador,
              in_tior_correlativo :this.formAgregarContrato.value.origen,
              in_tiesproc_correlativo :this.formAgregarContrato.value.estado_proceso,
              in_esdo_correlativo :this.formAgregarContrato.value.estado_contrato,
              in_admin_ec : this.formAgregarContrato.value.admin_contrato,
              in_usua_correlativo_creacion: 0,
              iv_es_cont_interno: (this.formAgregarContrato.value.contrato_interno == 1) ? 'S' : 'N' ,
              in_solpe_correlativo: this.formAgregarContrato.value.solpe,
              in_cct_critico: this.formAgregarContrato.value.cct_critico
          };
          this.formAgregarContrato.controls.solpe.disable();

          this.contratoService.crearContrato(postData).subscribe(resp=>{
            this.notifyService.showSuccess('Contrato creado con exito.', 'Crear Contrato');
          }, err =>{
            this.notifyService.showError('Error al crear contrato', 'Crear Contrato');
            console.log(err);
          });
          let contratoInterno = this.isCheckedCInterno == true ? 'S': 'N';
          this.getContratos(1, contratoInterno, this.rolId);
          this.closeModalAgregar.nativeElement.click(); 
        } else {
          this.notifyService.showWarning('El codigo de contrato ya existe', 'Crear Contrato');
          return;
        }        
      }, 
      (error) => {
        console.log(error);
        this.notifyService.showError('Error al validar codigo de contrato', 'Crear Contrato');
      }
    );

    
  }
  onClose() {
    this.resetForm();
  }
  modifContrato(idContrato: number) {
    this.dataService.idContrato = idContrato;
  }
  estadosPago(idContrato: number) {
    this.dataService.idContrato = idContrato;
  }

  onIrEp(corrEstadoPago: number){
    this.closeModalVer.nativeElement.click();
    this.router.navigate(['/estado-pago-checklist', corrEstadoPago]);
  }

  /*openModalDetalle(corrContrato: number) {
    this.getItemizado(corrContrato);
  }*/
  getSumTotal() : number {
    let sum = 0;
    for(let i = 0; i < this.itemizado.length; i++) {
      sum += (this.itemizado[i].Cantidad * this.itemizado[i].PrecioUnitario);
    }
    return sum;
  }
  getSumAvance() : number {
    let sum = 0;
    for(let i = 0; i < this.itemizado.length; i++) {
      sum += this.itemizado[i].Consumo;
    }
    return sum;
  }
  getSaldo() : number {
    let sum = 0;
    for(let i = 0; i < this.itemizado.length; i++) {
      sum += ((this.itemizado[i].Cantidad * this.itemizado[i].PrecioUnitario) - this.itemizado[i].Consumo);
    }
    return sum;
  }
  private getItemizado(corrContrato: number) {
    /* this.contratoService.getFootItemizado(corrContrato).subscribe(resp=>{
      this.Footitemizados = JSON.parse(resp);
    }); */
    this.estadoPagoService.getItemizadoDetalle(corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);

      let idx = 0;
      let cabecera = null;
      this.itemizado = [];
      // creo estructura del itemizado para mostrar de forma ordenada
      for(let i in response) {
        // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
        if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
          // cabecera
          this.itemizado[idx] = {
            Cabecera: response[i]['ITEM_CABECERA'],
            Subpos: null,
            Descripcion: null,
            Unidad: null,
            Cantidad: null,
            PrecioUnitario: null,
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: null,
            Scpq: null,
            Consumo: null
          };
          // primer subitem
          idx++;
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ'],
            ConsumoQ: response[i]['AVANCE_Q'],
            Consumo: response[i]['AVANCE']
          };
          idx++;
        // de lo contrario sigo registrando subitems hasta que cambie el item principal
        } else {
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ'],
            ConsumoQ: response[i]['AVANCE_Q'],
            Consumo: response[i]['AVANCE']
          };
          idx++;
        }
        cabecera = response[i]['ITEM_CABECERA'];
      }
    });
  }
  seleccionarSolpe(
    solpe_correlativo: number,
    montoSolpe: number,
    monedaSolpe: number,
    nombreSolpeContrato: string,
    gestionadorSolpe: number
  ) {
    this.modalSeleccionarSolpe.nativeElement.click();
    this.formAgregarContrato.controls.solpe.setValue(solpe_correlativo);
    this.formAgregarContrato.controls.monto.setValue(montoSolpe);
    this.formAgregarContrato.controls.tipo_moneda.setValue(monedaSolpe);
    this.formAgregarContrato.controls.nombre_contrato.setValue(nombreSolpeContrato);
    this.formAgregarContrato.controls.gestionador.setValue(gestionadorSolpe);
    this.montoSolpeParaContrato = montoSolpe;
    this.formAgregarContrato.updateValueAndValidity();
  }

  seleccionarContratoInterno() {
    this.modalSeleccionarSolpe.nativeElement.click();
    this.montoSolpeParaContrato = 0;
    this.montoContratoInput = 0;
    this.resetForm();
    this.formAgregarContrato.controls.solpe.setValue(0);
    this.formAgregarContrato.controls.contrato_interno.setValue(1);
    this.formAgregarContrato.updateValueAndValidity();
  }

  CalcularDiferencia(dateCompare){
    let currentDate = new Date();
    let aDateCompare = dateCompare.split('-');
    dateCompare = new Date(aDateCompare[2],(aDateCompare[1] - 1),aDateCompare[0]);
    let toReturn = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateCompare.getFullYear(), dateCompare.getMonth(), dateCompare.getDate()) ) /(1000 * 60 * 60 * 24));
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateCompare.getFullYear(), dateCompare.getMonth(), dateCompare.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  public showModalPreview(fileName)
  {
    this.fileService.downloadDocumentoContrato(fileName).subscribe(resp => {
      //FileSaver.saveAs(resp, fileName);
      const url = URL.createObjectURL(resp);
      const DIALOGREF = this.objDialog.open(ModalDocumentsComponent, {
        data: {fileUrl: url}
      });
      //this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });

  }

  changeMontoContratovista(newValue){
    this.montoContratoInput = newValue;
  }

  setContratos(data: any) {
    this.contratos = new MatTableDataSource(data);
  }

  getFechaVencimientoData(contrato: any) {
    const fechaVencimiento = moment(contrato['FECHA_VENCIMIENTO_BOLETA'], 'DD-MM-YYYY');
    const fechaVencimientoCalc = moment(contrato['FECHA_TERMINO_CONTRATO'], 'DD-MM-YYYY').add(100, 'days');
    const fechaVencimientoDiff = fechaVencimiento.diff(fechaVencimientoCalc, 'days');

    return { fechaVencimientoCalc, fechaVencimientoDiff };
  }

  createMensajeAlertaBoleta(contrato: any) {
    const EstadoContrato = contrato['ESTADO_CONTRATO'];
    if(contrato['REQUIERE_BG'] != 1 || !['Vigente', 'Expirado'].includes(EstadoContrato)) return '';

    let alertaBoleta = `** Contrato ${EstadoContrato.toLowerCase()} - `;
    if(contrato['NRO_BOLETA']){
      alertaBoleta += 'boleta vigente';
    } else {
      alertaBoleta += 'requiere boleta';
    }
    if(contrato['FECHA_VENCIMIENTO_BOLETA']) {
      const { fechaVencimientoDiff } = this.getFechaVencimientoData(contrato);
      if(fechaVencimientoDiff < 0) alertaBoleta += ' - contrato sin cobertura 100 días'
    }

    alertaBoleta += ' **';
    return alertaBoleta;
  }

  getMontosContrato(dataContrato: any) {
    const VALOR_MONEDA_CONTRATO = dataContrato.VALOR_MONEDA_CONTRATO;
    let MONTO_ORIGINAL = dataContrato.MONTO_ORIGINAL;
    let MONTO_REAJUSTE = dataContrato.MONTO_REAJUSTE;
    let MONTO_MODIF = dataContrato.MONTO_ACTUALIZADO - dataContrato.MONTO_ORIGINAL;
    let MONTO_ACTUALIZADO = dataContrato.MONTO_ACTUALIZADO;
    let MONTO_EP = dataContrato.MONTO_EP;
    let MONTO_SALDO = dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP;
    let MONTO_EP_SR = dataContrato.MONTO_EP - dataContrato.MONTO_REAJUSTE;

    if(dataContrato.CORRELATIVO_MONEDA_CONTRATO != 9) {
      MONTO_ORIGINAL = MONTO_ORIGINAL * VALOR_MONEDA_CONTRATO;
      MONTO_REAJUSTE = MONTO_REAJUSTE * VALOR_MONEDA_CONTRATO;
      MONTO_MODIF = MONTO_MODIF * VALOR_MONEDA_CONTRATO;
      MONTO_ACTUALIZADO = MONTO_ACTUALIZADO * VALOR_MONEDA_CONTRATO;
      MONTO_EP = MONTO_EP * VALOR_MONEDA_CONTRATO;
      MONTO_SALDO = MONTO_SALDO * VALOR_MONEDA_CONTRATO;
    }
    
    const MONTO_USD = (MONTO_ORIGINAL / dataContrato.VALOR_USD);
    const REAJUSTE_USD = (MONTO_REAJUSTE / dataContrato.VALOR_USD);
    const MONTO_MODIF_USD = (MONTO_MODIF / dataContrato.VALOR_USD);
    const MONTO_ACTUALIZADO_USD = (MONTO_ACTUALIZADO / dataContrato.VALOR_USD);
    const MONTO_EP_USD = (MONTO_EP / dataContrato.VALOR_USD);
    const MONTO_SALDO_USD = (MONTO_SALDO / dataContrato.VALOR_USD);

    MONTO_MODIF = dataContrato.MONTO_ACTUALIZADO - dataContrato.MONTO_ORIGINAL;
    MONTO_SALDO = dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP;

    const PORCENTAJE_REAJUSTE = this.getPorcentaje(dataContrato.MONTO_REAJUSTE, dataContrato.SALDO_CONTRATO);
    const PORCENTAJE_MODIF = this.getPorcentaje(MONTO_MODIF, dataContrato.SALDO_CONTRATO);
    const PORCENTAJE_EP = this.getPorcentaje(dataContrato.MONTO_EP, dataContrato.SALDO_CONTRATO);
    const PORCENTAJE_SALDO = this.getPorcentaje(MONTO_SALDO, dataContrato.SALDO_CONTRATO);

    let PORCENTAJE_BOLETA: string | number = '';
    if(dataContrato.NRO_BOLETA) {
      let MONTO_BOLETA = dataContrato.MONTO_BOLETA;
      if(dataContrato.CORRELATIVO_MONEDA_BOLETA != 9) MONTO_BOLETA = MONTO_BOLETA * dataContrato.VALOR_MONEDA_BOLETA;
      PORCENTAJE_BOLETA = this.getPorcentaje(MONTO_BOLETA, MONTO_ACTUALIZADO);
    }

    const montos = {
      MONTO_ORIGINAL: formatCurrency(dataContrato.MONTO_ORIGINAL,dataContrato.Decimal),
      MONTO_USD: formatCurrency(MONTO_USD,2),
      MONTO_REAJUSTE: formatCurrency(dataContrato.MONTO_REAJUSTE,dataContrato.Decimal),
      REAJUSTE_USD: formatCurrency(REAJUSTE_USD,2),
      PORCENTAJE_REAJUSTE,
      MONTO_MODIF: formatCurrency(MONTO_MODIF,dataContrato.Decimal),
      MONTO_MODIF_USD: formatCurrency(MONTO_MODIF_USD,2),
      PORCENTAJE_MODIF,
      MONTO_ACTUALIZADO: formatCurrency(dataContrato.MONTO_ACTUALIZADO,dataContrato.Decimal),
      MONTO_ACTUALIZADO_USD: formatCurrency(MONTO_ACTUALIZADO_USD,2),
      MONTO_EP: formatCurrency(dataContrato.MONTO_EP,dataContrato.Decimal),
      MONTO_EP_USD: formatCurrency(MONTO_EP_USD,2),
      PORCENTAJE_EP,
      MONTO_SALDO: formatCurrency((MONTO_SALDO + dataContrato.MONTO_REAJUSTE),dataContrato.Decimal),
      MONTO_SALDO_USD: formatCurrency(MONTO_SALDO_USD + REAJUSTE_USD,2),
      PORCENTAJE_SALDO,
      PORCENTAJE_BOLETA,
      MONTO_EP_SR: formatCurrency(MONTO_EP_SR,dataContrato.Decimal),
    }

    return montos;
  }

  setMontosContratos() {
    const contratos = [];
    this.contratos.data.forEach((contrato: any) => {
      contratos.push({
        ...contrato,
        ...this.getMontosContrato(contrato)
      })
    });

    this.contratos.data = contratos;
    this.baseContra = contratos;
    this.montosCalculados = true;
  }

  getPorcentaje(value: number, total: number) {
    let porcentaje = Math.round(((value) * 100) / total).toString();
    if(['NaN', '-Infinity', 'Infinity'].includes(porcentaje)) porcentaje = '0';
    return +porcentaje;
  }

  validarParametrosDocGarfantia(requiereBg: number, docGarantia: string, diasVencimiento: number, fechaDevolucion) {
    if (requiereBg === 1) {
      if (docGarantia) {
        if (fechaDevolucion) {
          return 'DEVUELTA';
        }else{
          if (diasVencimiento > 0) {
            return 'VIGENTE';
          }else{
            return 'VENCIDA';
          }
        }
      }else{
        return ' ';
      }
    }else{
      return ' ';
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}




