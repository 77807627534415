<div id="content" class="content">
    <div class="row">
        <div class="col-6 mb-4">
            <a class="btn btn-primary text-white"  (click)="back()">
                <i class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"></i> Volver
            </a>
        </div>
        <div class="col-6 text-right">
            <span *ngIf="solpeVisible === false" class="tab tab-yellow text-bolder"><i class="fa fa-exclamation-triangle text-warning"></i> SOLPE Fuera de proceso</span>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 ui-sortable">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <!-- <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">SOLPE</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div> -->

                <div class="p3-rem bg-white rounded">
                    <div class="row pb-4">
                        <div class="col-12 col-md-8 mb-2">
                            <h3 class="mb-0">Solpe ({{ codigoSolpe }}) {{ solpe?.NOMBRE_SOLPE }}</h3>
                        </div>
                        <div class="col-12 col-md-4 text-md-right text-sm-left text-xs-left">
                            <span class="tab tab-green text-center">{{solpe?.ESTADO_SOLPE}}</span>
                        </div>
                        <div class="col-12 mb-4">
                            <span class="tag2">
                                {{ solpe?.TIPO_SOLPE }}
                            </span>
                            <span class="tag2" *ngIf="solpe?.FORMA_SOLPE">
                                {{ solpe?.FORMA_SOLPE }}
                            </span>
                        </div>
                        <div class="col-12">
                            <!-- <span class="text-muted">Descripción de la necesidad. Informar Proyecto, Zona o Blanco</span> -->
                            <p class="text-muted">
                                {{ solpe?.DESC_SOLPE }}
                            </p>
                        </div>
                    </div>
                    <!-- <div class="row">

                    </div> -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-12">
                                    <span class="d-block d-md-inline align-center mr-5 mt-3"
                                        *ngIf="this.usuarioSolicitante">
                                        <span class="mb-0 text-muted">Solicitante: </span>
                                        <span>
                                            {{ this.usuarioSolicitante }}
                                        </span>
                                    </span>

                                    <span class="d-block d-md-inline align-center mr-5 mt-3"
                                        *ngIf="fechaSolpe">
                                        <span class="mb-0 text-muted">Fecha creación</span>
                                        <span>
                                            {{ fechaSolpe | date: 'dd/MM/yyyy' }}
                                        </span>
                                    </span>

                                    <!-- <span class="d-block d-md-inline align-center mr-5 mt-3"
                                        *ngIf="fechaTerminoSolpe">
                                        <span class="mb-0 text-muted">Duración estimada</span>
                                        <span>
                                            {{ fechaTerminoSolpe | date: 'dd/MM/yyyy' }} - {{dateDiff(this.fechaSolpe, fechaTerminoSolpe)}}
                                        </span>
                                    </span> -->

                                    <span class="d-block d-md-inline align-center mr-5 mt-3"
                                        *ngIf="fechaEstimadaIncioServicio">
                                        <span class="mb-0 text-muted">Fecha de inicio estimada de servicio</span>
                                        <span>
                                            {{ fechaEstimadaIncioServicio | date: 'dd/MM/yyyy' }}
                                        </span>
                                    </span>

                                    <span class="d-block d-md-inline align-center mr-5 mt-3"
                                        *ngIf="duracionEstimadaServicio">
                                        <span class="mb-0 text-muted">Duración estimada de servicio</span>
                                        <span>
                                            {{ duracionEstimadaServicio }} Meses
                                        </span>
                                    </span>

                                    <span class="d-block d-md-inline align-center mr-5 mt-3"
                                        *ngIf="solpe?.MONTO_SOLPE">
                                        <span class="mb-0 text-muted">Monto: </span>
                                        <span>
                                            {{ solpe?.MONTO_SOLPE | number }} {{solpe?.TIMO_DESCRIPCION}}
                                        </span>
                                    </span>

                                    <span class="d-block d-md-inline align-center mr-5 mt-3"
                                        *ngIf="solpe?.ORDEN_COMPRA">
                                        <span class="mb-0 text-muted">Número de documento: </span>
                                        <span>
                                            {{ solpe?.ORDEN_COMPRA }}
                                        </span>
                                    </span>

                                    <span class="d-block d-md-inline align-center mr-5 mt-3">
                                        <span class="mb-0 text-muted">Gestionador: </span>
                                        <span>
                                            {{ getGestionadorName() }}
                                        </span>
                                    </span>

                                </div>
                            </div>

                            <div class="row mt-4">

                                <div class="col-12">
                                    <span class="text-muted">Empresas propuestas</span>
                                    <p *ngIf="solpe?.EMPR_PROPUESTA != null">
                                        <span class="tag2" *ngFor="let empresa of empresasPropuestas">
                                            {{empresa}}
                                        </span>
                                    </p>
                                    <p *ngIf="solpe?.EMPR_PROPUESTA == null">Sin empresas propuestas</p>
                                </div>
                            </div>
                            <div class="row mt-2 mb-4">
                                <div class="col-12">
                                    <!-- Designar Gestionador-->
                                    <div *ngIf="((corrEstadoSolpe == 80 || corrEstadoSolpe == 132) && rolId == 15)">
                                        <form [formGroup]="formGCC" novalidate>
                                            <div class="row">
                                                <label for="banco" class="col-md-12 col-form-label">Desingar gestionador</label>
                                                <div class="col-10 col-md-8">
                                                    <select class="form-control" #gestionador formControlName="gestionador">
                                                        <option value="0">Seleccione ...</option>
                                                        <option *ngFor="let item of gestionadores" value="{{ item.CORRELATIVO }}">{{ item.NOMBRE }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-2 col-md-2">
                                                    <button 
                                                    *ngIf="corrEstadoSolpe == 80 && rolId == 15"
                                                    class="btn tag text-primary" (click)="relacionaGestionador()" [disabled]="isGestionada">
                                                        Designar
                                                    </button>
                                                    
                                                    <button 
                                                    *ngIf="((corrEstadoSolpe == 132) && rolId == 15)"
                                                    class="btn tag text-primary" data-toggle="modal" [disabled]="isGestionada" href="#modal-cambio-gestor">
                                                        Cambiar
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row text-right">
                                <ng-container *ngIf="solpeVisible">
                                    <div class="col-12 mb-2">
                                        <button *ngIf="solpe && distribucion !== null" type="button" class="btn tag mr-2 mb-2"
                                            (click)="onDescargarPDF()">
                                            <!-- <i class="fas fa-lg fa-fw m-r-10 fa-print"></i> -->
                                            <i class="far fa-file-pdf m-r-10"></i>
                                            Descargar
                                        </button>
                                        <a href="#modal-historial" data-toggle="modal" class="btn tag text-primary mr-2 mb-2">
                                            <i class="fa fa-lg fa-fw m-r-10 fa-list-ol"></i>
                                            Ver Historial
                                        </a>
                                        <button type="button" class="btn tag text-primary mr-2 mb-2" data-toggle="modal" data-target="#modalEstrategiaLiberacion" (click)="definirEstrategiaLiberacion()">
                                            Estrategia de liberación
                                        </button>
                                        <ng-container *ngIf="corrEstadoSolpe != 81">
                                            <a *ngIf="existeAccion('13')" href="#modal-distribucion" data-toggle="modal"
                                            (click)="cargarModalDistribucion(solpe['SOLPE_CORRELATIVO'])"
                                            class="btn btn-primary text-white mr-2 mb-2" [ngClass]="{'disabled': (totalPorcentaje >= 100 || corrEstadoSolpe == 86) || submittedDistribucion}" style="color: white;">
                                                Cargar Distribución
                                            </a>
    
                                            <a href="#modal-encuesta" data-toggle="modal" class="btn btn-primary text-white mr-2 mb-2" (click)="openModalEncuesta()" *ngIf="tipoSolpeCorr == 61 && ordenCompraSubida && existeAccion('28')">
                                                <i class="fa fa-lg fa-fw m-r-10 fa-file-alt"></i> Encuesta de Compra
                                            </a>
    
                                            <a href="#modal-proveedor" data-toggle="modal" class="btn tag text-primary mr-2 mb-2" (click)="openModalProveedor()" *ngIf="formaSolpeCorr === 64">
                                                <!-- <i class="fa fa-lg fa-fw m-r-10 fa-address-card"></i>  -->
                                                <i class="far fa-address-card m-r-10"></i>
                                                Proveedor o Contratista
                                            </a>
    
                                            <a href="#modal-asignacion" data-toggle="modal" class="btn tag text-primary mr-2 mb-2" (click)="openModalAsignacion()" *ngIf="formaSolpeCorr == 64">
                                                Justificar Asignación
                                            </a> 
    
                                            <a href="#modal-servicios" data-toggle="modal" class="btn btn-primary text-white mr-2 mb-2" (click)="openModalServicios()" *ngIf="tipoSolpeCorr == 63 && corrEstadoSolpe == 86">
                                                <i class="fa fa-lg fa-fw m-r-10 fa-address-card"></i> Servicios Profesionales
                                            </a>
                                            <!-- Solicitada-->
                                            <ng-container *ngIf="corrEstadoSolpe == 86">
                                                <a *ngIf="rolId == 23" class="btn tag text-primary mr-2 mb-2" 
                                                href="#modal-update" data-toggle="modal"
                                                (click)="empresas_propuestas = empresasPropuestas"
                                                >
                                                    Modificar
                                                </a>
                                                <a *ngIf="corrEstadoSolpe == 86 && existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>
                                                <!-- <a *ngIf="tipoSolpeCorr == 82" href="javascript:void(0)" (click)="aceptarSolpe(89)" class="btn btn-block btn-success" [ngClass]="{'disabled': modificacionesCto.length == 0}">Enviar a Jefe de Abastecimiento</a> -->
                                                <a *ngIf="tipoSolpeCorr == 82 && existeAccion('5')" 
                                                href="javascript:void(0)" class="btn btn-primary text-white mr-2 mb-2" 
                                                [ngClass]="{'disabled': modificacionesCto.length == 0}" data-toggle="modal" data-target="#enviarAJacModal">
                                                    Enviar a Jefe de Abastecimiento
                                                </a>
    
                                                <a *ngIf="tipoSolpeCorr != 82 && existeAccion('5')" 
                                                href="javascript:void(0)" class="btn btn-primary text-white mr-2 mb-2" 
                                                [ngClass]="{'disabled': !documentosCargados}" data-toggle="modal" data-target="#enviarAJacDosModal">
                                                    Enviar a Jefe de Abastecimiento
                                                </a>
                                                <!-- <a *ngIf="tipoSolpeCorr != 82" href="javascript:void(0)" (click)="enviarPyC()" class="btn btn-block btn-success" [ngClass]="{'disabled': !documentosCargados}">Enviar a Lider P&C</a> -->
                                            </ng-container>
                                            <!-- Jefe abastecimiento -->
                                            <ng-container *ngIf="corrEstadoSolpe == 131 && rolId == 15">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="tipoSolpeCorr !== 82" href="javascript:void(0)" class="btn btn-primary text-white mb-2" [ngClass]="{'disabled': !documentosCargados}" data-toggle="modal" data-target="#enviarAPyCModal">Enviar a Lider P&C</a>

                                                <a *ngIf="enviarAPyC === true && tipoSolpeCorr === 82" href="javascript:void(0)" class="btn btn-primary text-white mb-2" [ngClass]="{'disabled': !documentosCargados}" data-toggle="modal" data-target="#enviarAPyCModal">Enviar a Lider P&C</a>

                                                <a *ngIf="enviarAPyC === false && tipoSolpeCorr === 82" href="javascript:void(0)" class="btn btn-primary text-white mb-2" [ngClass]="{'disabled': !documentosCargados}" data-toggle="modal" data-target="#enviarADafModal">Enviar a DAF</a>
                                            </ng-container>
                                            <!-- Lider de P&C-->
                                            <ng-container *ngIf="corrEstadoSolpe == 87 && rolId == 17">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="existeAccion('13')" href="javascript:void(0)" class="btn btn-primary text-white mb-2" [ngClass]="{'disabled': totalPorcentaje != 100}" data-toggle="modal" data-target="#enviarARgModal">Enviar a Responsable Grafos</a>
                                            </ng-container>
                                            <!-- DAF-->
                                            <ng-container *ngIf="corrEstadoSolpe == 97 && rolId == 8">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="existeAccion('5')" href="javascript:void(0)" class="btn btn-primary text-white mb-2" data-toggle="modal" data-target="#avanzarSolpeDafUno">Aceptar Solpe</a>
                                            </ng-container>
                                            <!-- Abastecimiento-->
                                            <ng-container *ngIf="corrEstadoSolpe == 89 && rolId == 15">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="existeAccion('5')" href="javascript:void(0)" class="btn btn-primary text-white mb-2" data-toggle="modal" data-target="#avanzarSolpeAbastecimiento">Aceptar Solpe</a>
                                            </ng-container>
                                            <!-- DAF-->
                                            <ng-container *ngIf="corrEstadoSolpe == 94 && rolId == 8">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="existeAccion('5')" href="javascript:void(0)" class="btn btn-primary text-white mb-2" data-toggle="modal" data-target="#avanzarSolpeDafDos">Aceptar Solpe</a>
                                            </ng-container>
                                            <!-- Director de abastecimiento-->
                                            <ng-container *ngIf="corrEstadoSolpe == 145 && rolId == 27">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="existeAccion('5')" href="javascript:void(0)" class="btn btn-primary text-white mb-2" data-toggle="modal" data-target="#avanzarSolpeDab">Aceptar Solpe</a>
                                            </ng-container>
                                            <!-- Gerente General-->
                                            <ng-container *ngIf="corrEstadoSolpe == 95 && rolId == 11">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="existeAccion('5')" href="javascript:void(0)" class="btn btn-primary text-white mb-2" data-toggle="modal" data-target="#avanzarSolpeGg">Aceptar Solpe</a>
                                            </ng-container>
                                            <!-- Sesion de directorio-->
                                            <ng-container *ngIf="corrEstadoSolpe == 96 && rolId == 25">
                                                <a *ngIf="existeAccion('23')" href="#modal-rechazar" data-toggle="modal" class="btn btn-light mr-2 mb-2">Rechazar Solpe</a>

                                                <a *ngIf="existeAccion('5')" href="javascript:void(0)" class="btn btn-primary text-white mb-2" data-toggle="modal" data-target="#avanzarSolpeDirectorio">Aceptar Solpe</a>
                                            </ng-container>
                                            <!-- Jefe de Abastecimiento y Mantencion 2 designacion -->
                                            <ng-container *ngIf="corrEstadoSolpe == 80 && rolId == 15">
                                                <!--132-->
                                                <!-- <button *ngIf="existeAccion('5')" href="javascript:void(0)" (click)="cerrarSolpe()" class="btn btn-block btn-success" [disabled]="!isGestionada">Cerrar Solpe</button> -->
                                            </ng-container>
                                            <!-- Gestionador de contratos y compras -->
                                            <ng-container *ngIf="corrEstadoSolpe == 132 && rolId == 12">
                                                <button href="javascript:void(0)"
                                                [ngClass]="{'disabled': !documentosCargados}"
                                                href="#modal-orden-compra" data-toggle="modal"
                                                class="btn btn-primary text-white mr-2 mb-2"
                                                [disabled]="!documentosCargados"
                                                >
                                                    Completar gestion
                                                </button>
                                                <!-- <button *ngIf="tipoSolpeCorr !== 61" href="javascript:void(0)" (click)="aceptarSolpe(133)" class="btn btn-primary text-white mr-2">
                                                    Completar gestion
                                                </button> -->
                                            </ng-container>
                                            <!-- Jefe de Abastecimiento y Mantencion cierre de solpe -->
                                            <!-- [disabled]="!isGestionada" -->
                                            <ng-container *ngIf="corrEstadoSolpe == 133 && rolId == 15">
                                                <button *ngIf="existeAccion('5')" href="javascript:void(0)" class="btn btn-primary text-white mr-2" data-toggle="modal" data-target="#avanzarSolpeCierre">Cerrar Solpe</button>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                               
                                <ng-container *ngIf="solpeVisible === false">
                                    <div class="col-12 mb-2">
                                        <button *ngIf="solpe && distribucion !== null" type="button" class="btn tag mr-2 mb-2"
                                            (click)="onDescargarPDF()">
                                            <!-- <i class="fas fa-lg fa-fw m-r-10 fa-print"></i> -->
                                            <i class="far fa-file-pdf m-r-10"></i>
                                            Descargar
                                        </button>
                                        <a href="#modal-historial" data-toggle="modal" class="btn tag text-primary mr-2 mb-2">
                                            <i class="fa fa-lg fa-fw m-r-10 fa-list-ol"></i>
                                            Ver Historial
                                        </a>
                                        <button type="button" class="btn tag text-primary mr-2 mb-2" data-toggle="modal" data-target="#modalEstrategiaLiberacion" (click)="definirEstrategiaLiberacion()">
                                            Estrategia de liberación
                                        </button>
                                    </div>
                                </ng-container>
                                <!-- routerLink="/solpe" -->
                                <!--<a *ngIf="existeAccion('1')" href="#modal-archivo" data-toggle="modal" (click)="cargarModalArchivo(solpe['SOLPE_CORRELATIVO'])" class="btn btn-info btn-block">
                                    <i class="fa fa-lg fa-fw m-r-10 fa-cloud-upload-alt"></i> Archivos
                                </a>
                                <a *ngIf="existeAccion('3')" href="#modal-comentario" data-toggle="modal" (click)="cargarModalComentarios(solpe['SOLPE_CORRELATIVO'])" class="btn btn-info btn-block">
                                    <i class="fa fa-lg fa-fw m-r-10 fa-comment-alt"></i> Comentarios
                                </a>-->



                                <!--
                                se comenta de momento ya que se evaluara la forma y momento en la que se
                                designa el proveedor
                                    <a href="#modal-empresas" data-toggle="modal" class="btn btn-info btn-block" (click)="openModalEmpresas()">
                                    <i class="fa fa-lg fa-fw m-r-10 fa-address-card"></i> Empresa seleccionada
                                </a> -->


                            </div>

                            <!-- <div style="background-color: #e1ecec; border-radius: 5px; border: 1px solid rgb(75, 168, 75); padding: 20px;"
                            *ngIf="tipoSolpeCorr == 82"> -->
                            <div class="border rounded p-20" *ngIf="tipoSolpeCorr == 82">
                                <div class="row">
                                    <div class="col-12">
                                        <h4>Contrato {{ contrato.CODIGO_CONTRATO }} {{ contrato.NOMBRE_CONTRATO }}</h4>
                                    </div>
                                </div>

                                <div class="row mb-4 mt-2">
                                    <div class="col-12 mt-2 mb-2">
                                        <div class="d-flex-inline align-center border rounded p-10 m-r-10 m-b-10">
                                            <div class="round-name">
                                                    {{contrato.ADMIN_PROVEEDOR?.slice(0, 2)}}
                                            </div>
                                            <div class="nombre">
                                                <span class="mb-0">{{contrato.ADMIN_PROVEEDOR}} </span>
                                                <br>
                                                <span class="text-muted">Administrador</span>
                                            </div>
                                            <div class="opciones">
                                                <!-- <a class="btn rounded-icon ml-2" href="#modalAdec" data-toggle="modal" title="Ver Adec" (click)="getAdecContrato()">
                                                    <i class="fas fa-clock"></i>
                                                </a> -->
                                            </div>
                                        </div>

                                        <div class="d-flex-inline align-center border rounded p-10">
                                            <div class="round-name">
                                                 {{contrato.ADMINISTRADOR?.slice(0, 2)}}
                                            </div>
                                            <div class="nombre">
                                                <span class="mb-0">{{contrato.ADMINISTRADOR}} </span>
                                                <br>
                                                <span class="text-muted">Adm EMSA</span>
                                            </div>
                                            <div class="opciones">
                                                <!-- <a class="btn rounded-icon ml-2" href="#modalAdecEmsa" data-toggle="modal" title="Ver Adec Emsa"
                                                (click)="getAdecEmsaContrato(); getAdemsa()"
                                                >
                                                <i class="fas fa-pen"></i>
                                                </a> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">

                                        <span class="d-block d-md-inline align-center mr-5 mt-3">
                                            <span class="mb-0 text-muted">Fecha inicio</span>
                                            <span>
                                                {{ contrato.FECHA_INICIO_CONTRATO | date: 'dd/MM/yyyy' }}
                                            </span>
                                        </span>

                                        <span class="d-block d-md-inline align-center mr-5 mt-3">
                                            <span class="mb-0 text-muted">Fecha Término</span>
                                            <span>
                                                {{ contrato.FECHA_TERMINO_CONTRATO | date: 'dd/MM/yyyy' }}
                                            </span>
                                        </span>

                                        <span class="d-block d-md-inline align-center mr-5 mt-3">
                                            <span class="mb-0 text-muted">Moneda</span>
                                            <span>
                                                {{ contrato.TIPO_MONEDA }}
                                            </span>
                                        </span>

                                        <span class="d-block d-md-inline align-center mr-5 mt-3">
                                            <span class="mb-0 text-muted">Boleta</span>
                                            <span>
                                                {{ (contrato.REQUIERE_BG == 1) ? 'Si' : 'No' }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <hr>
                                <ng-container class="mt-2 mb-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="text-muted mb-0">Datos de proveedor</p>
                                        </div>
                                        <div class="col-12">
                                            <h5 class="mb-0">{{ contrato.PROVEEDOR }}</h5>
                                            <p>{{ contrato.RUT_PROVEEDOR }}</p>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container class="mt-2 mb-4">
                                    <div style="background-color: #ffe9da; border-radius: 5px; border: 1px solid #ff8b3e; padding: 20px;" class="row">
                                        <div class="col-12 mb-0">
                                            <p class="mb-0">Modificaciones: </p>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <span class="col-12 col-md-3 align-center">
                                                    <span class="mb-0 text-muted">Saldo contrato:</span>
                                                    <span>
                                                        {{ (contrato.SALDO_CONTRATO - contrato.MONTO_EP + contrato.MONTO_REAJUSTE) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                    </span>
                                                </span>
                                                <span class="col-12 col-md-3 align-center">
                                                    <span class="mb-0 text-muted">Monto Original:</span>
                                                    <span>
                                                        {{ contrato.MONTO_ORIGINAL | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                    </span>
                                                </span>
                                                <span class="col-12 col-md-3 align-center">
                                                    <span class="mb-0 text-muted">Monto modificado:</span>
                                                    <span>
                                                        {{ contrato.MONTO_ACTUALIZADO | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                    </span>
                                                </span>
                                                <span class="col-12 col-md-3 align-center">
                                                    <span class="mb-0 text-muted">Modificaciones:</span>
                                                    <span>
                                                        {{((contrato.MONTO_ACTUALIZADO - contrato.MONTO_ORIGINAL)/contrato.MONTO_ORIGINAL)*100 | number :'' :'es-CL'}} %
                                                    </span>
                                                </span>
                                            </div>
                                            <hr>
                                            <div class="row">
                                                <span class="col-12 col-md-3 align-center">
                                                    <span class="mb-0 text-muted">Monto modificaciones:</span>
                                                    <span>{{(contrato.MONTO_ACTUALIZADO - contrato.MONTO_ORIGINAL) | number :'' :'es-CL'}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <!-- <div class="row">
                                    <div class="col-md-2"><strong>Nro. Contrato</strong></div>
                                    <div class="col-md-4">{{ contrato.CODIGO_CONTRATO }}</div>
                                    <div class="col-md-2"><strong>Fecha Inicio</strong></div>
                                    <div class="col-md-4">{{ contrato.FECHA_INICIO_CONTRATO | date: 'dd/MM/yyyy' }}</div>
                                </div>
                                <br> -->
                                <!-- <div class="row">
                                    <div class="col-md-2"><strong>Cantidad de Modificaciones</strong></div>
                                    <div class="col-md-4">0</div>
                                    <div class="col-md-2"><strong>Fecha Término</strong></div>
                                    <div class="col-md-4">{{ contrato.FECHA_TERMINO_CONTRATO | date: 'dd/MM/yyyy' }}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Razón Social</strong></div>
                                    <div class="col-md-4">{{ contrato.PROVEEDOR }}</div>
                                    <div class="col-md-2"><strong>Moneda</strong></div>
                                    <div class="col-md-4">{{ contrato.TIPO_MONEDA }}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>RUT</strong></div>
                                    <div class="col-md-4">{{ contrato.RUT_PROVEEDOR }}</div>
                                    <div class="col-md-2"><strong>Monto Original</strong></div>
                                    <div class="col-md-4">{{ contrato.MONTO_ORIGINAL | number}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Nombre Contrato</strong></div>
                                    <div class="col-md-4">{{ contrato.NOMBRE_CONTRATO }}</div>
                                    <div class="col-md-2"><strong>Monto modificado</strong></div>
                                    <div class="col-md-4">{{ contrato.MONTO_ACTUALIZADO | number }}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4"></div>
                                    <div class="col-md-2"><strong>Modificacion</strong></div>
                                    <div class="col-md-4">{{contrato.MONTO_ACTUALIZADO - contrato.MONTO_ORIGINAL | number}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Admin EMSA</strong></div>
                                    <div class="col-md-4">{{ contrato.ADMINISTRADOR }}</div>
                                    <div class="col-md-2"><strong>% Modificaciones</strong></div>
                                    <div class="col-md-4">{{((contrato.MONTO_ACTUALIZADO - contrato.MONTO_ORIGINAL)/contrato.MONTO_ORIGINAL)*100 | number}}</div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Admin Contratista</strong></div>
                                    <div class="col-md-4">{{ contrato.ADMIN_PROVEEDOR }}</div>
                                    <div class="col-md-2"><strong>Saldo contrato</strong></div>
                                    <div class="col-md-4">{{ contrato.SALDO_CONTRATO | number}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Boleta de Garantía</strong></div>
                                    <div class="col-md-4">{{ (contrato.REQUIERE_BG == 1) ? 'Si' : 'No' }}</div>
                                    <div class="col-md-2"><strong>Fecha Vcto - BG</strong></div>
                                    <div class="col-md-4"></div>
                                </div> -->
                            </div>
                            <br>
                            <form [formGroup]="formAgregarModificacion" (ngSubmit)="onCrearModificacion()" novalidate *ngIf="tipoSolpeCorr == 82">
                                <div class="border rounded p-20">
                                    <h5>MODIFICACIÓN DE CONTRATO</h5>
                                    <hr>
                                    <ng-container *ngIf="solpeVisible">
                                        <div class="row form-group">
                                            <label class="col-md-2 col-form-label">Modificación</label>
                                            <div class="col-md-10" style="padding-top: 8px;">
                                                <div class="form-check form-check-inline">
                                                    <input type="checkbox" value="1" id="chk_monto" #chk_monto class="form-check-input" formControlName="chk_monto" (change)="onChangeMontoMod($event)">
                                                    <label class="form-check-label" for="chk_monto">Monto</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input type="checkbox" value="1" id="chk_fecha" #chk_fecha class="form-check-input" formControlName="chk_fecha" (change)="onChangeFechaMod($event)">
                                                    <label class="form-check-label" for="chk_fecha">Fecha</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input type="checkbox" value="1" id="chk_alcance" #chk_alcance class="form-check-input" formControlName="chk_alcance" (change)="onChangeAlcanceMod($event)">
                                                    <label class="form-check-label" for="chk_alcance">Alcance</label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="row form-group">
                                        <label class="col-md-2 col-form-label">Monto Modificación</label>
                                        <div class="col-md-4">
                                            <input type="number" formControlName="monto_modificacion" #monto_modificacion class="form-control" (keyup)="onChangeMontoModificacion(monto_modificacion.value)" />
                                        </div>
                                        <div class="col-md-2">
                                            <ng-container *ngIf="isInfiniteNumber(porcentajeModifacionInfo)">
                                                <div *ngIf="montoModificadoContratotoLbl != 0">
                                                    <span>{{montoModificadoContratotoLbl | number : '':'es-CL'}}</span>
                                                    <span class="tab tab-green ml-2">{{porcentajeModifacionInfo | number : '':'es-CL'}} % <i class="fa fa-arrow-up"></i></span>
                                                </div>
                                                <div *ngIf="montoModificadoContrato != 0">
                                                    <span>{{montoModificadoContrato | number : '':'es-CL' }}</span>
                                                    <span class="tab tab-green ml-2">{{porcentajeModifacionInfo | number : '':'es-CL'}} % <i class="fa fa-arrow-up"></i></span>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="porcentajeModifacionInfo > 10 && isInfiniteNumber(porcentajeModifacionInfo)" class="col-md-4">
                                            <span class="text-red">
                                                <strong>Atención</strong>: El porcentaje acumulado de la modificación de contrato excede el 10%.
                                            </span>
                                        </div>
                                        <div *ngIf="isInfiniteNumber(porcentajeModifacionInfo) == false" class="col-md-12 mt-2">
                                            <span class="text-red">
                                                <strong>Atención</strong>: El porcentaje de la modificación es indeterminado, por favor pulse el botón   
                                                <button class="btn btn-light btn-sm" (click)="onRefresh()">Actualizar</button>
                                                para recargar la información.
                                            </span>
                                            <!-- <div class="btn rounded-icon mr-2">
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-md-2 col-form-label">Nuevo Monto Actualizado</label>
                                        <div class="col-md-4">
                                            <input type="number" formControlName="monto_actualizado" #monto_actualizado class="form-control" />
                                        </div>
                                        <!-- <div class="col-md-4">
                                            <div *ngIf="montoActualizadoContratoLbl != 0">
                                                <span>{{montoActualizadoContratoLbl | number : '':'es-CL'}}</span>
                                                <span class="tab tab-green  ml-2">{{porcentajeSumaModifacionesInfo | number : '':'es-CL'}} % <i class="fa fa-arrow-up"></i></span>
                                            </div>
                                            <div *ngIf="montoActualizadoContrato != 0">
                                                <span>{{montoActualizadoContrato | number : '':'es-CL'}}</span>
                                                <span class="tab tab-green  ml-2">{{porcentajeSumaModifacionesInfo | number : '':'es-CL'}} % <i class="fa fa-arrow-up"></i></span>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row form-group" *ngIf="this.modificacionesCto.length == 0">
                                        <!-- <label for="fecha_inicio" class="col-md-2 col-form-label">Fecha Inicio</label>
                                        <div class="col-md-4 input-group">
                                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Inicio" formControlName="fecha_inicio" #fecha_inicio>
                                        </div> -->
                                        <label for="fecha_termino" class="col-md-2 col-form-label">Fecha Término</label>
                                        <div class="col-md-4">
                                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Término" formControlName="fecha_termino" #fecha_termino>
                                        </div>
                                    </div>
                                    <div class="row form-group" *ngIf="this.modificacionesCto.length > 0">
                                        <!-- <label for="fecha_inicio" class="col-md-2 col-form-label">Fecha Inicio</label>
                                        <div class="col-md-4 input-group">
                                            <input type="text" class="form-control m-b-5" placeholder="Fecha de Inicio" formControlName="fecha_inicio_2">
                                        </div> -->
                                        <label for="fecha_termino" class="col-md-2 col-form-label">Fecha Término</label>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control m-b-5" placeholder="Fecha de Término" formControlName="fecha_termino_2">
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="alcance" class="col-md-2 col-form-label">Alcance</label>
                                        <div class="col-md-6">
                                            <textarea class="form-control" rows="3" formControlName="alcance" #alcance></textarea>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="solpeVisible">
                                        <div class="row" *ngIf="this.modificacionesCto.length == 0">
                                            <div class="col-md-12 text-right">
                                                <button class="btn btn-primary " type="submit">
                                                    Agregar
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="distribucion && distribucion.length > 0">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <h4>Distribución</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-9 text-right">

                                    <h4 *ngIf="rolId == 17" class="text-primary">
                                        Por Distribuir: {{ 100 - totalPorcentaje | number : '1.0-2' : 'es-CL' }}% &nbsp;&nbsp;&nbsp;
                                        Distribuido: {{ totalPorcentaje | number : '1.0-2' : 'es-CL' }}% &nbsp;&nbsp;&nbsp;
                                        Rechazado: {{ grafosRechazados | number : '1.0-2' : 'es-CL'}}% &nbsp;&nbsp;&nbsp;
                                        Aprobado: {{ grafosAprobados | number : '1.0-2': 'es-CL' }}%
                                    </h4>
                                </div>
                            </div>
                            <div *ngIf="grafosAprobados > 100" class="row">
                                <div class="col-12">
                                    <p class="text-danger">Distribucion supera el 100% de los grafos, por favor verifique nuevamente los porcentajes asignados.</p>
                                </div>
                            </div>
                            <table mat-table [dataSource]="dataSource" class="table table-striped border table-td-valign-middle table-th-valign-middle ">
                                <ng-container matColumnDef="Articulo">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Artículo</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.ARTICULO_NOMBRE }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Zona">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Zona</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.ZONA_NOMBRE }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Blanco">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Blanco</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.BLANCO_NOMBRE }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Proyecto">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Proyecto</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.PROYECTO_CODIGO }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Porcentaje">
                                    <th class=" normalText" style="padding: 10px !important;width:7%;" mat-header-cell *matHeaderCellDef>Porcentaje</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                        <!-- {{ element.PORCENTAJE | number :'' :'es-CL' }}% &nbsp;&nbsp;&nbsp;
                                        <a *ngIf="rolId == 17 && corrEstadoSolpe == 88 && element.SODI_VIGENTE != 'P'" href="#modal-porcentajeDistribucion" data-toggle="modal" (click)="cargarModalPorDistribucion(element.DISTRIBUCION_CORRELATIVO, element.PORCENTAJE, element.SODI_VIGENTE)" class="btn btn-primary btn-sm"
                                            title="Modificar Porcentaje Distribución">
                                            <i class="fa fa-edit"></i>
                                        </a> -->
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Grafo">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Grafo</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.GRAFO_CODIGO }}</td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="Monto">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Monto</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ calcularMontoPorcentaje(solpe['MONTO_SOLPE'], element.PORCENTAJE) | number: 'es-CL' }}</td>
                                </ng-container> -->
                                <ng-container matColumnDef="Monto">
                                    <th class="normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Monto</th>
                                    <td style="padding: 10px !important; text-align: right;" mat-cell *matCellDef="let element ">{{ ((element.PORCENTAJE / 100) * solpe?.MONTO_SOLPE) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Responsable">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Responsable</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.RESPONSABLE_GRAFO }}</td>
                                </ng-container>
                                <ng-container matColumnDef="Acciones">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Estado</th>
                                    <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                        <div class="btn-group" *ngIf="corrEstadoSolpe == 88">
                                            <button *ngIf="existeAccion('6') && element.SODI_VIGENTE == 'P'"
                                            (click)="onAceptarDistribucion(element.DISTRIBUCION_CORRELATIVO)"
                                            class="btn tag text-primary btn-sm" title="Aceptar Grafo" [ngClass]="{'btn-info': element.SODI_VIGENTE == 'S'}" data-toggle="modal" data-target="#aceptarDistribucionGrafos">
                                                Aceptar
                                            </button>
                                            <button *ngIf="existeAccion('24') && element.SODI_VIGENTE == 'P'"
                                            (click)="onRechazarDistribucion(element.DISTRIBUCION_CORRELATIVO)"
                                            class="btn tag text-primary btn-sm" title="Rechazar Grafo" [ngClass]="{'btn-danger': element.SODI_VIGENTE == 'N'}" data-toggle="modal" data-target="#rechazarDistribucionGrafos">
                                                Rechazar
                                            </button>
                                        </div>
                                        <div>
                                            <p *ngIf="element.SODI_VIGENTE == 'S'" class="text-primary mb-0">APROBADO</p>
                                            <p *ngIf="element.SODI_VIGENTE == 'P'" class="text-info mb-0">PENDIENTE</p>
                                            <p *ngIf="element.SODI_VIGENTE == 'N'" class="text-danger mb-0">RECHAZADO</p>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Eliminar">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef></th>
                                    <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                        <ng-container *ngIf="rolId == 17">
                                            <button *ngIf="(corrEstadoSolpe == 87 || corrEstadoSolpe == 88) || (grafosAprobados > 100)" (click)="onEliminarDistribucion(element.DISTRIBUCION_CORRELATIVO)" class="btn btn-light btn-sm" title="Eliminar distribución" data-toggle="modal" data-target="#eliminarDistribucionGrafos"><i class="fa fa-trash"></i></button>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="dataSourceHeader"></tr>
                                <tr mat-row *matRowDef="let row; columns: dataSourceHeader;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <!-- <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">ADJUNTOS</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div> -->
                <div class="row">
                    <!-- archivos -->
                    <div class="col-12 col-md-7 mb-4">
                        <div class="p3-rem rounded bg-white">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Archivos</h5>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12">
                                    <p class="text-muted">
                                        Todos los documentos que se muestran de color rojo son requeridos,
                                        recuerde que debe subir estos para poder continuar.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                                        <ng-container *ngIf="solpeVisible && existeAccion('1')">
                                            <div class="row form-group">
                                                <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                                                <div class="col-md-9">
                                                    <select class="form-control" formControlName="grupo" #grupo [ngClass]="{ 'is-invalid': submittedDocumento && fd.grupo.errors}">
                                                        <option value="0">Seleccione ...</option>
                                                        <option *ngFor="let item of archivos" [value]="item.correlativo">{{item.descripcion}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                                                <div class="col-md-9">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                                        <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
    
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-4">
                                                <div class="col-md-12 text-right">
                                                    <button class="btn btn-primary" type="submit">Agregar</button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" />
                                        <!-- listado de archivos de solpe -->
                                        <div *ngIf="documentosSolpe">
                                            <ng-container *ngFor="let item of documentosSolpe">
                                                <div class="file border rounded mb-2 mt-2">
                                                    <div class="row">
                                                        <div class="col-6 content-center">
                                                            <div class="file-detail content-center nowrap">
                                                                <div class="file-img content-center">
                                                                    <i class="far fa-file-alt text-primary mr-2"></i>
                                                                </div>
                                                                <div class="">
                                                                    <p class="mb-0 nombreArchivoCustom">
                                                                        {{item.descripcion_archivo}}
                                                                    </p>
                                                                    <p class="mb-0"
                                                                    [ngClass]="item.OBLIGATORIO == 'S' && item.nombre_archivo == null ? 'text-red': 'text-muted'"
                                                                    >
                                                                        {{item.descripcion}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 content-center" style="justify-content: end;">
                                                            <view-file-button [nombreArchivo]="item.nombre_archivo"
                                                                [tipoArchivo]="'solpe'"></view-file-button>
                                                            <div *ngIf="item.nombre_archivo !== null" class="btn rounded-icon mr-2"
                                                                (click)="onDescargar(item.nombre_archivo)">
                                                                <i class="fas fa-file-download"></i>
                                                            </div>
                                                            <ng-container *ngIf="solpeVisible">
                                                                <!-- (click)="onEliminarArchivoSolpe(item.correlativo_archivo, item.nombre_archivo)" -->
                                                                <ng-container *ngIf="existeAccion('29')">
                                                                    <div *ngIf="item.nombre_archivo !== null" class="btn rounded-icon mr-2" data-toggle="modal" data-target="#modalEliminarArchivoSolpe" (click)="eliminarArchivoSolpe(item.correlativo_archivo, item.nombre_archivo)">
                                                                        <i class="fas fa-trash"></i>
                                                                    </div> 
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- comentarios -->
                    <div class="col-12 col-md-5">
                        <div class="p3-rem rounded bg-white">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Comentarios</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <form [formGroup]="formAgregarComentario" (ngSubmit)="onAgregarComentario()" novalidate>
                                        <div *ngIf="existeAccion('3')" class="row form-group">
                                            <!-- <label for="comentario" class="col-md-3 col-form-label">Comentario</label> -->
                                            <div class="col-10">
                                                <input type="text" class="form-control" name="comentario" placeholder="Agregar Comentario" #comentario formControlName="comentario" [ngClass]="{ 'is-invalid': submittedComentario && fc.comentario.errors}" autocomplete="off">
                                            </div>

                                            <div class="col-2 col-md-2 text-right">
                                                <button class="btn btn-primary btn-block" type="submit" [disabled]="formAgregarComentario.invalid || submittedComentario"><i
                                                        class="fas fa-paper-plane"></i></button>
                                            </div>
                                        </div>

                                        <br><br><br>
                                        <p *ngFor="let item of comentariosSolpe">
                                            <span>
                                                <div>
                                                    <div class="d-flex">
                                                        <div class="round-name">
                                                            {{item.NOMBRE_USUARIO.slice(0, 2)}}
                                                        </div>
                                                        <div class="nombre table">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <p class="mb">{{item.NOMBRE_USUARIO}}</p>
                                                                </div>
                                                                <div class="col-6">
                                                                    <p class="ml-4 text-muted text-right">{{ item.FECHA_COMENTARIO }}</p>
                                                                </div>
                                                            </div>

                                                            <span class="text-muted">{{item.COMENTARIO}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Modal de cargar documento -->
<!--
<div class="modal fade" id="modal-archivo">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Documento</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                    <div class="row form-group">
                        <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="grupo" #grupo [ngClass]="{ 'is-invalid': submittedDocumento && fd.grupo.errors}">
                      <option value="0">Seleccione ...</option>
                      <option *ngFor="let item of archivos" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
                    </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                        <div class="col-md-9">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4 text-right">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                        </div>
                    </div>
                    <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" />

                    <div *ngIf="documentosSolpe">
                        <hr>
                        <p *ngFor="let item of documentosSolpe">
                            <span style="color: #999; font-weight: bold !important;" [ngClass]="{ 'text-red': item.OBLIGATORIO == 'S'}">
                      <i class="fa fa-lg fa-fw m-r-10 fa-file"></i> {{ item.descripcion | uppercase }}: &nbsp;
                    </span>
                            <a [routerLink]="" (click)="onDescargar(item.nombre_archivo)">{{ item.nombre_archivo }}</a>
                        </p>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-10"></div>
                        <div class="col-md-2"><a href="javascript:;" (click)="onCloseArchivo()" #closeModalAgregarArchivo class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
-->
<!-- Modal de comentarios -->
<!--
<div class="modal fade" id="modal-comentario">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Cometario</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarComentario" (ngSubmit)="onAgregarComentario()" novalidate>
                    <div class="row form-group">
                        <label for="comentario" class="col-md-12 col-form-label">Comentario</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" name="comentario" #comentario formControlName="comentario" [ngClass]="{ 'is-invalid': submittedComentario && fc.comentario.errors}" autocomplete="off">
                        </div>
                        <div class="col-md-4 text-right">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                        </div>
                    </div>
                    <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" />
                    <div *ngIf="comentariosSolpe">
                        <hr>
                        <p *ngFor="let item of comentariosSolpe">
                            <span style="color: #999; font-weight: bold !important;">
                              <i class="fa fa-lg fa-fw m-r-10 fa-comment-alt"></i> {{ item.FECHA_COMENTARIO }}<br>
                              {{ item.NOMBRE_USUARIO }}:<br>
                            </span> {{ item.COMENTARIO }}
                        </p>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-10"></div>
                        <div class="col-md-2"><a href="javascript:;" (click)="onCloseComentario()" #closeModalAgregarComentario class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
-->

<!-- Modal de Historial -->
<div class="modal fade" id="modal-historial">
    <div class="modal-dialog modal-lg">
        <div style="width: 125%;" class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Historial Solpe</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div style="padding: 2rem !important;" class="modal-body">
                <div class="mb-1 text-right">
                    <app-view-excel-buttons [props]="excelButtonHistorial">
                    </app-view-excel-buttons>
                </div>
                <table mat-table [dataSource]="dataHistorial" class="table table-striped table-td-valign-middle table-th-valign-middle border">
                    <ng-container matColumnDef="Fecha">
                        <th class=" normalText" style="padding: 10px !important; width: 155px;" mat-header-cell *matHeaderCellDef>Fecha</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_CREACION | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Usuario">
                        <th class=" normalText" style="padding: 10px !important; width: 175px;" mat-header-cell *matHeaderCellDef>Usuario</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUARIO }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Tipo">
                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Tipo</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.TIPO }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Estado">
                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Estado</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.ESTADO }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Vigente">
                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Activo</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.VIGENTE }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Grafo">
                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Grafo</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.GRAFO }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Resp">
                        <th class=" normalText" style="padding: 10px !important; width: 145px;" mat-header-cell *matHeaderCellDef>Resp</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.RESP }}</td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Observacion">
                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Observacion</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.OBSERVACION }}</td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="dataHeaderHistorial"></tr>
                    <tr mat-row *matRowDef="let row; columns: dataHeaderHistorial;" id="row" style="cursor: pointer;" (click)="selectionHistorial.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionHistorial.isSelected(row)}" (mouseover)="row.hovered = true"
                        (mouseout)="row.hovered = false"></tr>

                </table>

                <!-- <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2"><a href="javascript:;" class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- Modal de distribucion -->
<div class="modal fade" id="modal-distribucion">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Distribución</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarDistribucion" (ngSubmit)="onAgregarDistribucion()" novalidate>
                    <div class="row form-group">
                        <label for="articulo" class="col-md-12 col-form-label">Arículo</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="articulos" #articulo formControlName="articulo" name="articulo" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.articulo.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.articulo.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="zona" class="col-md-12 col-form-label">Zona</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="zonas" #zona formControlName="zona" name="zona" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.zona.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.zona.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="blanco" class="col-md-12 col-form-label">Blanco</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="blancos" #blanco formControlName="blanco" name="blanco" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.blanco.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.blanco.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="proyecto" class="col-md-12 col-form-label">Proyecto</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="proyectos" #proyecto formControlName="proyecto" name="proyecto" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.proyecto.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.proyecto.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="grafo" class="col-md-12 col-form-label">Grafo</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="grafos" #grafo formControlName="grafo" name="grafo" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.grafo.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.grafo.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="grafo" class="col-md-12 col-form-label">Porcentaje Asignado</label>
                        <div class="col-md-12">
                            <input type="number" name="porcentaje" formControlName="porcentaje" #porcentaje class="form-control" [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.porcentaje.errors}" />
                            <p class="text-muted">
                                * Porcentaje debe ser menor o igual a {{100 - totalPorcentaje}}
                            </p>
                        </div>
                    </div>
                    <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" />
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary" type="submit">Agregar</button>
                            <a href="javascript:;" (click)="onCloseDistribucion()" #closeModalAgregarDistribucion class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de empresa seleccionada -->
<div class="modal fade" id="modal-empresas">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Empresa seleccionada</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarEmpresaPropuesta" (ngSubmit)="onAgregarEmpresaSeleccionada()" novalidate>
                    <div class="row form-group">
                        <label for="articulo" class="col-md-12 col-form-label">Empresa selecionada</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="empresasPropuestas" #empresa formControlName="empresa" name="empresa" class="select2-selection select2-selection--single">
                            </ng-select2>
                            <div *ngIf="submittedEmpresaPropuesta && fEmp.articulo.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <!-- <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" /> -->
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary btn-block" type="submit">
                                Agregar
                           </button>
                            <a href="javascript:;" (click)="onCloseDistribucion()" #closeModalAgregarDistribucion class="btn btn-light" data-dismiss="modal">Cerrar</a>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de Encuesta -->
<div class="modal fade" id="modal-encuesta">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Encuesta de Compra</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarEncuesta" (ngSubmit)="onAgregarEncuesta()" novalidate>
                    <div class="row form-group">
                        <div class="col-md-12">¿Se recibieron los productos en las fechas solicitadas por usted?</div>
                        <div class="col-md-12">
                            <div class="radio radio-css radio-inline">
                                <input type="radio" id="respuesta_1_1" name="pregunta_1" value="S" formControlName="pregunta_1" [ngClass]="{ 'is-invalid': submittedEncuesta && fe.pregunta_1.errors}" />
                                <label for="respuesta_1_1">SI</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" id="respuesta_2_1" name="pregunta_1" value="N" formControlName="pregunta_1" [ngClass]="{ 'is-invalid': submittedEncuesta && fe.pregunta_1.errors}" />
                                <label for="respuesta_2_1">NO</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-12">¿Recibió el producto solicitado?</div>
                        <div class="col-md-12">
                            <div class="radio radio-css radio-inline">
                                <input type="radio" id="respuesta_1_2" name="pregunta_2" value="S" formControlName="pregunta_2" [ngClass]="{ 'is-invalid': submittedEncuesta && fe.pregunta_2.errors}" />
                                <label for="respuesta_1_2">SI</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" id="respuesta_2_2" name="pregunta_2" value="N" formControlName="pregunta_2" [ngClass]="{ 'is-invalid': submittedEncuesta && fe.pregunta_2.errors}" />
                                <label for="respuesta_2_2">NO</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-12">¿Gestionador de Contratos y Compras mantuvo constante comunicación con el proceso del estado de su SOLPE?</div>
                        <div class="col-md-12">
                            <div class="radio radio-css radio-inline">
                                <input type="radio" id="respuesta_1_3" name="pregunta_3" value="S" formControlName="pregunta_3" [ngClass]="{ 'is-invalid': submittedEncuesta && fe.pregunta_3.errors}" />
                                <label for="respuesta_1_3">SI</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" id="respuesta_2_3" name="pregunta_3" value="N" formControlName="pregunta_3" [ngClass]="{ 'is-invalid': submittedEncuesta && fe.pregunta_3.errors}" />
                                <label for="respuesta_2_3">NO</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-12">Recomendaciones</div>
                        <div class="col-md-12">
                            <textarea class="form-control" rows="3" name="descripcion" formControlName="descripcion" [ngClass]="{ 'is-invalid': submittedEncuesta && fe.descripcion.errors}"></textarea>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="encuentaEnviada === false" class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary" type="submit" [disabled]="rolId != 23">Guardar</button>
                            <a href="javascript:;" (click)="onCloseEncuesta()" #closeModalEncuesta class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-4"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de Proveedor -->
<div class="modal fade" id="modal-proveedor">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Datos del Proveedor / Contratista</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formProveedor" (ngSubmit)="onAgregarProveedor()" novalidate>
                    <div *ngIf="corrEstadoSolpe === 86 && rolId === 23" class="row form-group">
                        <label for="proveedor" class="col-md-12 col-form-label">Razón Social</label>
                        <div class="col-md-12">
                            <!-- <select *ngIf="!nuevoProveedor" name="proveedor" formControlName="proveedor" #proveedor (change)="onChangeProveedor()" class="form-control" [ngClass]="{ 'is-invalid': submittedProveedor && fp.proveedor.errors}">
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of proveedores" value="{{ item.id }}">{{ item.text }}</option>
                            </select> -->
                            <ng-select2 [data]="proveedores" width="100%" #proveedor name="proveedor" formControlName="proveedor" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedProveedor && fp.proveedor.errors}" (valueChanged)='onChangeProveedor($event)'>
                            </ng-select2>
                            <input *ngIf="nuevoProveedor" name="proveedor_input" #proveedor_input formControlName="proveedor_input" class="form-control" [ngClass]="{ 'is-invalid': submittedProveedor && fp.proveedor_input.errors}" />
                        </div>
                    </div>
                    <div *ngIf="corrEstadoSolpe !== 86">
                        <div *ngIf="nombreProveedor === ''"class="row form-group">
                            <label for="proveedor" class="col-md-12 col-form-label">Razón Social</label>
                            <div class="col-md-9">
                                <!-- <select *ngIf="!nuevoProveedor" name="proveedor" formControlName="proveedor" #proveedor (change)="onChangeProveedor()" class="form-control" [ngClass]="{ 'is-invalid': submittedProveedor && fp.proveedor.errors}">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of proveedores" value="{{ item.id }}">{{ item.text }}</option>
                                </select> -->
                                <ng-select2 [data]="proveedores" width="100%" #proveedor name="proveedor" formControlName="proveedor" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedProveedor && fp.proveedor.errors}" (valueChanged)='onChangeProveedor($event)'>
                                </ng-select2>
                                <input *ngIf="nuevoProveedor" name="proveedor_input" #proveedor_input formControlName="proveedor_input" class="form-control" [ngClass]="{ 'is-invalid': submittedProveedor && fp.proveedor_input.errors}" />
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-primary btn-block" (click)="onNuevoProveedor()" [disabled]="rolId != 23 || corrEstadoSolpe != 86">+ Nuevo</button>
                            </div>
                        </div>
                        <div *ngIf="nombreProveedor !== ''" class="col-md-9">
                            <h3>{{nombreProveedor}}</h3>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="rut" class="col-md-12 col-form-label">RUT</label>
                        <div class="col-md-12">
                            <input type="text" name="rut" formControlName="rut" #rut class="form-control" maxlength="10" placeholder="formato: 11222333-4" [ngClass]="{ 'is-invalid': submittedProveedor && fp.rut.errors}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="telefono" class="col-md-12 col-form-label">Teléfono</label>
                        <div class="col-md-12">
                            <input type="text" name="telefono" formControlName="telefono" #telefono class="form-control" [ngClass]="{ 'is-invalid': submittedProveedor && fp.telefono.errors}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="contacto" class="col-md-12 col-form-label">Nombre Contacto</label>
                        <div class="col-md-12">
                            <input type="text" name="contacto" formControlName="contacto" #contacto class="form-control" [ngClass]="{ 'is-invalid': submittedProveedor && fp.contacto.errors}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="email" class="col-md-12 col-form-label">Email</label>
                        <div class="col-md-12">
                            <input type="text" name="email" formControlName="email" #email class="form-control" [ngClass]="{ 'is-invalid': submittedProveedor && fp.email.errors}" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2" type="submit" [disabled]="rolId != 23 || corrEstadoSolpe != 86">Asignar Proveedor</button>
                            <a href="javascript:;" (click)="onCloseProveedor()" #closeModalAgregarProveedor class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de asignacion directa -->
<div class="modal fade" id="modal-asignacion">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Justificación de Asignación Directa</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAsignacion" (ngSubmit)="onAgregarAsignacion()" novalidate>
                <h5>Justificaciones posibles a Asignación Directa</h5>
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th class="text-normal">N°</th>
                        <th class="text-normal">Excepciones válidas para asignaciones directas según P-RC-01</th>
                        <th class="text-normal" *ngIf="tipoSolpeCorr == 61">Compras</th>
                        <th class="text-normal" *ngIf="tipoSolpeCorr == 62">Servicios</th>
                        <th class="text-normal" *ngIf="tipoSolpeCorr == 60">Arriendos</th>
                        <th class="text-normal" *ngIf="tipoSolpeCorr == 63">Servicios Profesionales</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Proveedor único o dueño único de patentes o derechos de la propiedad industrial</td>
                            <td *ngIf="tipoSolpeCorr == 61" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="compras_1"></td>
                            <td *ngIf="tipoSolpeCorr == 62" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="servicios_1"></td>
                            <td *ngIf="tipoSolpeCorr == 60" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="arriendos_1"></td>
                            <td *ngIf="tipoSolpeCorr == 63" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="sprofesionales_1"></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Repuestos de la misma marca de un equipo existente o pérdida de garantías de</td>
                            <td *ngIf="tipoSolpeCorr == 61" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="compras_2"></td>
                            <td *ngIf="tipoSolpeCorr == 62" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="servicios_2"></td>
                            <td *ngIf="tipoSolpeCorr == 60" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="arriendos_2"></td>
                            <td *ngIf="tipoSolpeCorr == 63" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="sprofesionales_2"></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Base instalada relevante de una marca especifica que conllevaría beneficios</td>
                            <td *ngIf="tipoSolpeCorr == 61" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="compras_3"></td>
                            <td *ngIf="tipoSolpeCorr == 62" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="servicios_3"></td>
                            <td *ngIf="tipoSolpeCorr == 60" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="arriendos_3"></td>
                            <td *ngIf="tipoSolpeCorr == 63" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="sprofesionales_3"></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Emergencia de seguridad u operacional</td>
                            <td *ngIf="tipoSolpeCorr == 61" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="compras_4"></td>
                            <td *ngIf="tipoSolpeCorr == 62" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="servicios_4"></td>
                            <td *ngIf="tipoSolpeCorr == 60" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="arriendos_4"></td>
                            <td *ngIf="tipoSolpeCorr == 63" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="sprofesionales_4"></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Proveedor estrátegico: cuando no existe otro proveedor aprobado/calificado por</td>
                            <td *ngIf="tipoSolpeCorr == 61" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="compras_5"></td>
                            <td *ngIf="tipoSolpeCorr == 62" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="servicios_5"></td>
                            <td *ngIf="tipoSolpeCorr == 60" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="arriendos_5"></td>
                            <td *ngIf="tipoSolpeCorr == 63" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="sprofesionales_5"></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Arriendo de inmuebles</td>
                            <td *ngIf="tipoSolpeCorr == 61" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="compras_6"></td>
                            <td *ngIf="tipoSolpeCorr == 62" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="servicios_6"></td>
                            <td *ngIf="tipoSolpeCorr == 60" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="arriendos_6"></td>
                            <td *ngIf="tipoSolpeCorr == 63" class="text-center"><input class="form-check-input" type="checkbox" value="" formControlName="sprofesionales_6"></td>
                        </tr>
                    </tbody>
                </table>
                <br>

                    <div class="row form-group">
                        <label for="justificacion" class="col-md-12 col-form-label">Justificación de la necesidad de contratar el servicio o compra el bien</label>
                        <div class="col-md-12">
                            <textarea class="form-control" formControlName="justificacion" #justificacion rows="3" [ngClass]="{ 'is-invalid': submittedAsignacion && fa.justificacion.errors}"></textarea>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="razones" class="col-md-12 col-form-label">Razones concretas para elegir al contratista  que se propone</label>
                        <div class="col-md-12">
                            <textarea class="form-control" formControlName="razones" #razones rows="3" [ngClass]="{ 'is-invalid': submittedAsignacion && fa.razones.errors}"></textarea>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="explicacion" class="col-md-12 col-form-label">Explicación de la razonabilidad del precio</label>
                        <div class="col-md-12">
                            <textarea class="form-control" formControlName="explicacion" #explicacion rows="3" [ngClass]="{ 'is-invalid': submittedAsignacion && fa.explicacion.errors}"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2" type="submit" [disabled]="rolId != 23 || corrEstadoSolpe != 86">
                                Guardar
                            </button>
                            <a href="javascript:;" (click)="onCloseAsignacion()" #closeModalAsignacion class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de servicios profesionales -->
<div class="modal fade" id="modal-servicios">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Solicitud de Servicios Profesionales</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formServicios" (ngSubmit)="onAgregarServicios()" novalidate>

                    <h5>Datos del árera/proyecto usuario</h5>
                    <hr>
                    <div class="row form-group">
                        <label for="fecha_solpe" class="col-md-2 col-form-label">Fecha Solpe</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="fecha_solpe">
                        </div>
                        <label for="nombre_solicitante" class="col-md-2 col-form-label">Nombre Solicitante</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="nombre_solicitante">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="cargo_solicitante" class="col-md-2 col-form-label">Cargo Solicitante</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="cargo_solicitante" [ngClass]="{ 'is-invalid': submittedServicios && fs.cargo_solicitante.errors}">
                        </div>
                        <label for="proyecto_usuario" class="col-md-2 col-form-label">Área/Proyecto Usuario</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="proyecto_usuario" [ngClass]="{ 'is-invalid': submittedServicios && fs.proyecto_usuario.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="admin_contrato" class="col-md-2 col-form-label">Administrador de Contrato</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="admin_contrato" [ngClass]="{ 'is-invalid': submittedServicios && fs.admin_contrato.errors}">
                        </div>
                        <label for="centro_costo" class="col-md-2 col-form-label">Centro Costo/Grafo</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="centro_costo" [ngClass]="{ 'is-invalid': submittedServicios && fs.centro_costo.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="financiamiento" class="col-md-2 col-form-label">¿Tiene Financiamiento?</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="financiamiento" [ngClass]="{ 'is-invalid': submittedServicios && fs.financiamiento.errors}">
                        </div>
                    </div>

                    <h5>Datos del Consultor</h5>
                    <hr>
                    <div class="row form-group">
                        <label for="nombre_consultor" class="col-md-2 col-form-label">Nombre</label>
                        <div class="col-md-4 input-group">
                            <!-- <select class="form-control m-b-5" formControlName="nombre_consultor" (change)="onChangeProveedorServicios()" [ngClass]="{ 'is-invalid': submittedServicios && fs.nombre_consultor.errors}">
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of proveedores" value="{{item.id}}">{{item.text}}</option>
                            </select> -->
                            <ng-select2 [data]="proveedores" width="100%" #nombre_consultor name="nombre_consultor" formControlName="proveedor" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedServicios && fp.nombre_consultor.errors}" (valueChanged)='onChangeProveedorServicios($event)'>
                            </ng-select2>
                        </div>
                        <label for="rut_consultor" class="col-md-2 col-form-label">Rut</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="rut_consultor" maxlength="10" [ngClass]="{ 'is-invalid': submittedServicios && fs.rut_consultor.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="profesion_consultor" class="col-md-2 col-form-label">Profesión</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="profesion_consultor" [ngClass]="{ 'is-invalid': submittedServicios && fs.profesion_consultor.errors}">
                        </div>
                        <label for="telefono_consultor" class="col-md-2 col-form-label">Teléfono</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="telefono_consultor" [ngClass]="{ 'is-invalid': submittedServicios && fs.telefono_consultor.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="email_consultor" class="col-md-2 col-form-label">Email</label>
                        <div class="col-md-4 input-group">
                            <input type="email" class="form-control m-b-5" formControlName="email_consultor" [ngClass]="{ 'is-invalid': submittedServicios && fs.email_consultor.errors}">
                        </div>
                        <label for="negocio_er_consultor" class="col-md-2 col-form-label">¿Negocio con empresa relacionada?</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="negocio_er_consultor" [ngClass]="{ 'is-invalid': submittedServicios && fs.negocio_er_consultor.errors}">
                        </div>
                    </div>

                    <h5>Período de Contratación</h5>
                    <hr>
                    <div class="row form-group">
                        <label for="fecha_inicio" class="col-md-2 col-form-label">Fecha de Inicio</label>
                        <div class="col-md-4 input-group">
                            <input type="date" class="form-control m-b-5" formControlName="fecha_inicio" (change)="onChangeFecha()" [ngClass]="{ 'is-invalid': submittedServicios && fs.fecha_inicio.errors}">
                        </div>
                        <label for="fecha_termino" class="col-md-2 col-form-label">Fecha de Término</label>
                        <div class="col-md-4 input-group">
                            <input type="date" class="form-control m-b-5" formControlName="fecha_termino" (change)="onChangeFecha()" [ngClass]="{ 'is-invalid': submittedServicios && fs.fecha_termino.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="plazo_total" class="col-md-2 col-form-label">Plazo Total</label>
                        <div class="col-md-4 input-group">
                            <input type="number" class="form-control m-b-5" formControlName="plazo_total">
                        </div>
                        <label class="col-md-2 col-form-label">Meses</label>
                    </div>

                    <h5>Honorarios</h5>
                    <hr>
                    <div class="row form-group">
                        <label for="tarifa" class="col-md-2 col-form-label">Tarifa</label>
                        <div class="col-md-4 input-group">
                            <input type="number" class="form-control m-b-5" formControlName="tarifa" (keyup)="onChangeTarifa()" [ngClass]="{ 'is-invalid': submittedServicios && fs.tarifa.errors}">
                        </div>
                        <label for="moneda" class="col-md-2 col-form-label">Moneda</label>
                        <div class="col-md-4 input-group">
                            <select class="form-control m-b-5" formControlName="moneda" [ngClass]="{ 'is-invalid': submittedServicios && fs.moneda.errors}">
                      <option value="0">Seleccione ...</option>
                      <option *ngFor="let item of monedas" value="{{item.correlativo}}">{{item.descripcion}}</option>
                    </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="unidad" class="col-md-2 col-form-label">Unidad</label>
                        <div class="col-md-4 input-group">
                            <input type="text" class="form-control m-b-5" formControlName="unidad" [ngClass]="{ 'is-invalid': submittedServicios && fs.unidad.errors}">
                        </div>
                        <label for="cantidad" class="col-md-2 col-form-label">Cantidad</label>
                        <div class="col-md-4 input-group">
                            <input type="number" class="form-control m-b-5" formControlName="cantidad" (keyup)="onChangeTarifa()" [ngClass]="{ 'is-invalid': submittedServicios && fs.cantidad.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="otros_gastos" class="col-md-2 col-form-label">Otros Gastos</label>
                        <div class="col-md-4 input-group">
                            <input type="number" class="form-control m-b-5" formControlName="otros_gastos" (keyup)="onChangeTarifa()" [ngClass]="{ 'is-invalid': submittedServicios && fs.otros_gastos.errors}">
                        </div>
                        <label for="costo_total" class="col-md-2 col-form-label">Costo Total Estimado</label>
                        <div class="col-md-4 input-group">
                            <input type="number" class="form-control m-b-5" formControlName="costo_total" [ngClass]="{ 'is-invalid': submittedServicios && fs.costo_total.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="justificacion_otros_gastos" class="col-md-2 col-form-label">Justificación Otros Gastos</label>
                        <div class="col-md-10 input-group">
                            <textarea type="text" class="form-control m-b-5" rows="3" formControlName="justificacion_otros_gastos" [ngClass]="{ 'is-invalid': submittedServicios && fs.justificacion_otros_gastos.errors}"></textarea>
                        </div>
                    </div>

                    <h5>Descripción del Servicio</h5>
                    <hr>
                    <div class="row form-group">
                        <label for="justificacion_necesidad" class="col-md-6 col-form-label">Justificación de la Necesidad</label>
                        <div class="col-md-12 input-group">
                            <textarea type="text" class="form-control m-b-5" rows="3" formControlName="justificacion_necesidad" [ngClass]="{ 'is-invalid': submittedServicios && fs.justificacion_necesidad.errors}"></textarea>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="actividades" class="col-md-6 col-form-label">Actividades a Ejecutar por el Consultor</label>
                        <div class="col-md-12 input-group">
                            <textarea type="text" class="form-control m-b-5" rows="3" formControlName="actividades" [ngClass]="{ 'is-invalid': submittedServicios && fs.actividades.errors}"></textarea>
                        </div>
                    </div>

                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary" type="submit" [disabled]="rolId != 23 || corrEstadoSolpe != 86">Guardar</button>
                            <a href="javascript:;" (click)="onCloseServicios()" #closeModalServicios class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal Modificar Porcentaje -->
<div class="modal fade" id="modal-porcentajeDistribucion">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Modificar Porcentaje Distribución</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formModificarPorcentaje" (ngSubmit)="onModificarPorcentaje()" novalidate>
                    <div class="row form-group">
                        <label for="porcentaje" class="col-md-12 col-form-label">Porcentaje</label>
                        <div class="col-md-8">
                            <input type="number" class="form-control" name="porcentaje" #porcentaje formControlName="porcentaje" [ngClass]="{ 'is-invalid': submittedModificarPorcentaje && fModPor.porcentaje.errors}" autocomplete="off">
                            <p class="text-muted">
                                * Porcentaje debe ser menor o igual a {{ maxPorcentajeDisponible }}
                            </p>
                        </div>
                        <div class="col-md-4 text-right">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-edit"></i> Modificar</button>
                        </div>
                    </div>
                    <input type="hidden" name="corr_solpe" formControlName="corr_distribucion" #corr_distribucion value="" />

                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a href="javascript:;" (click)="onCloseModificarPorcentaje()" #closeModalModificarPorcentaje class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de Rechazar -->

<div class="modal fade" id="modal-rechazar">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Observación de Rechazo</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formRechazar" (ngSubmit)="rechazarSolpe()" novalidate>
                    <div class="row form-group">
                        <label for="observacion" class="col-md-12 col-form-label">Observación</label>
                        <div class="col-md-12">
                            <textarea class="form-control" name="observacion" formControlName="observacion" rows="4"
                            [ngClass]="{ 'is-invalid': submittedRechazar && fr.observacion.errors}" autocomplete="off"></textarea>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary" type="submit">Agregar</button>
                            <a href="javascript:;" #closeModalRechazar class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal Orden de compra -->
<div class="modal fade" id="modal-orden-compra" #modal_orden_compra>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar número de documento</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarOrdenCompra" (ngSubmit)="postOrdenCompra($event)" novalidate>
                    <div class="row form-group">
                        <label for="grafo" class="col-md-12 col-form-label">Número de documento</label>
                        <div class="col-md-12">
                            <input type="text" name="orden_compra" formControlName="orden_compra" #orden_compra class="form-control"
                            />
                            <!-- [ngClass]="{ 'is-invalid': submittedDistribucion && ford.orden_compra.errors}"  -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="fecha_ingreso" class="col-md-12 col-form-label">Fecha entrega estimada</label>
                        <div class="col-md-12 input-group">
                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Emisión" formControlName="fecha_entrega" #fecha_entrega>
                        </div>
                    </div>
                    <!-- <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" /> -->
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2" type="submit" [disabled]="formAgregarOrdenCompra.invalid || submittedOrdenCompra">Agregar</button>
                            <a href="javascript:0;" (click)="onCloseOC()" #closeModalOC class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal estrategis de liberacion -->
<div class="modal fade" id="modalEstrategiaLiberacion" tabindex="-1" role="dialog" aria-labelledby="modalEstrategiaLiberacionTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- <ul class="list-group">
                <li *ngFor="let estrategaia of estrategiaLiberacion; let i = index" class="list-group-item">
                    {{i + 1}} - {{estrategaia}}
                </li>
            </ul> -->
            <div class="row mb-3">
                <div class="col">
                    <h4 id="modalEstrategiaLiberacionTitle">Estados a cumplir en la Estrategia de liberación</h4>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div *ngFor="let estrategaia of estrategiaLiberacion; let i = index" class="">
                        <p class="text-muted">
                            <span class="numb-bg rounded-circle m-r-10">
                                {{i + 1}}
                            </span>
                             {{estrategaia}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal coment -->
<div class="modal fade" id="modal-cambio-gestor">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Cambio de gestionador</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formCambioGestor" (ngSubmit)="cambioGestor()" novalidate>
                    <div class="row form-group">
                        <label for="observacion" class="col-md-12 col-form-label">Motivo de cambio</label>
                        <div class="col-md-12">
                            <textarea class="form-control" name="observacion" formControlName="observacion" rows="4"
                            [ngClass]="{ 'is-invalid': submittedCambioGestor && fg.observacion.errors}" autocomplete="off"></textarea>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary" type="submit">Agregar</button>
                            <a href="javascript:;" #closeModalCambioGestor class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de update solpe -->
<div class="modal fade" id="modal-update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title">Actulizar datos</span>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formUpdateSolpe" (ngSubmit)="onUpdate()" novalidate>
                    <div *ngIf="formaPagosMis && !tipoContrato">
                        <div class="row form-group">
                            <label class="col-12 col-md-3 col-form-label">RUT Empresa</label>
                            <div class="col-12 col-md-9">
                                <input type="text" formControlName="rut_empresa" #rut_empresa class="form-control" maxlength="10" placeholder="formato: 11222333-4"  [ngClass]="{ 'is-invalid': submitted && fu.rut_empresa.errors}" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-12 col-md-3 col-form-label">Razón Social</label>
                            <div class="col-12 col-md-9">
                                <input type="text" formControlName="razon_social" #razon_social class="form-control" [ngClass]="{ 'is-invalid': submitted && fu.razon_social.errors}" />
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                    <br>
                    <div class="row form-group">
                        <label class="col-12 col-md-3 col-form-label">Nombre Solpe</label>
                        <div class="col-12 col-md-9">
                            <input type="text" formControlName="nombre" #nombre class="form-control" [ngClass]="{ 'is-invalid': submitted && fu.nombre.errors}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="descripcion" class="col-12 col-md-3 col-form-label">Descripción de la necesidad. Informar Proyecto, Zona o Blanco</label>
                        <div class="col-12 col-md-9">
                            <textarea class="form-control" rows="3" formControlName="descripcion" #descripcion [ngClass]="{ 'is-invalid': submitted && fu.descripcion.errors}"></textarea>
                        </div>
                    </div>
                    <div *ngIf="!tipoContrato">
                        <div class="row form-group">
                            <label for="moneda" class="col-12 col-md-3 col-form-label">Moneda</label>
                            <div class="col-12 col-md-9">
                                <select class="form-control" formControlName="moneda" #moneda 
                                [ngClass]="{ 'is-invalid': submitted && fu.moneda.errors}"
                                >
                                    <option value="">Seleccione ...</option>
                                    <option *ngFor="let item of monedas" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-12 col-md-3 col-form-label">Monto</label>
                            <div class="col-12 col-md-9">
                                <input type="number" formControlName="monto" #monto class="form-control" [ngClass]="{ 'is-invalid': submitted && fu.monto.errors}" />
                            </div>
                            <div class="col-12 col-md-12 text-right mt-2">
                                <p>{{ monto.value | number: '1.2-2' : 'es-CL' }}</p>
                            </div>
                        </div>
                        <div class="row form-group rounded " style="background-color: #F3F5F7; padding: 1rem">
                            <label class="col-md-12 col-form-label">Empresas Propuestas</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="agregar_empresa" #agregar_empresa>
                                    <div class="input-group-append">
                                        <button type="button" (click)="addEmpresaPropuesta()"
                                        class="btn bg-white tag text-orange dropdown-toggle no-caret">
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                                <br>
                                <!-- F3F5F7 -->
                                <div class="" *ngIf="empresas_propuestas?.length > 0">
                                    <div class="" style="padding-bottom: 5px;">
                                        <ul style="list-style: none; padding-left: 0;">
                                            <li class="alert bg-white fade show" *ngFor="let item of empresas_propuestas; let i = index">
                                                <div class="row">
                                                    <div class="col-6 text-left">
                                                        {{ item | uppercase }}
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        <a class="btn rounded-icon" (click)="deleteEmpresaSugerida(i)" title="Revisar">
                                                            <i class="fa fa-trash-alt"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="text-center" *ngIf="empresas_propuestas?.length == 0">
                                    <span>Sin empresas registradas</span>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row mt-5">
                        <div class="col-12 text-right">
                            <!-- <div class="col-md-4 text-right"> -->
                                <button class="btn btn-primary mb-1 mr-2 " type="submit">Modificar</button>
                            <!-- </div> -->
                            <!-- <div class="col-md-2"> -->
                                <a href="javascript:;" (click)="onClose()" #closeModalUpdateData
                                class="btn tag mb-1 text-orange" data-dismiss="modal">Cerrar</a>
                            <!-- </div> -->
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="modalEliminarArchivoSolpe" tabindex="-1" role="dialog" aria-labelledby="modalEliminarArchivoSolpe" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEliminarArchivoSolpe">Eliminar archivo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModalEliminarArchivo>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Desea eliminar el archivo señalado?</p>
          <p>Una vez eliminado no podrá recuperar el archivo</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onEliminarArchivoSolpe()">Eliminar</button>
        </div>
      </div>
    </div>
</div>
<!-- Solicitacitante -->
<!-- Modal avanzar etapa envia a JAC -->
<div class="modal fade" id="enviarAJacModal" tabindex="-1" role="dialog" aria-labelledby="enviarAJacModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="enviarAJacModalLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(131)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal avanzar etapa envia a JAC segundo caso -->
<div class="modal fade" id="enviarAJacDosModal" tabindex="-1" role="dialog" aria-labelledby="enviarAJacDosModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="enviarAJacDosModalLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(131)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Jefe de abastecimiento -->
<!-- Modal avanzar etapa envia a PYC -->
<div class="modal fade" id="enviarAPyCModal" tabindex="-1" role="dialog" aria-labelledby="enviarAPyCModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="enviarAPyCModalLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="enviarPyC()">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal avanzar etapa envia a DAF -->
<div class="modal fade" id="enviarADafModal" tabindex="-1" role="dialog" aria-labelledby="enviarADafModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="enviarADafModalLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpeEnviarAdaf()">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Lider de P&C-->
<!-- Modal avanzar etapa envia a Responsable de grafos -->
<div class="modal fade" id="enviarARgModal" tabindex="-1" role="dialog" aria-labelledby="enviarARgModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="enviarARgModalLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE y enviarla a los Responsables de Grafos?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="enviarRespGrafos()">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- DAF -->
<!-- Modal avanzar etapa DAF 1 -->
<div class="modal fade" id="avanzarSolpeDafUno" tabindex="-1" role="dialog" aria-labelledby="avanzarSolpeDafUnoLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avanzarSolpeDafUnoLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(89)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal avanzar etapa DAF 2 -->
<div class="modal fade" id="avanzarSolpeDafDos" tabindex="-1" role="dialog" aria-labelledby="avanzarSolpeDafDosLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avanzarSolpeDafDosLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(80)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Abastecimiento-->
<!-- Modal avanzar etapa Abastecimiento -->
<div class="modal fade" id="avanzarSolpeAbastecimiento" tabindex="-1" role="dialog" aria-labelledby="avanzarSolpeAbastecimientoLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avanzarSolpeAbastecimientoLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(80)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Director de abastecimiento-->
<!-- Modal avanzar etapa Director de abastecimiento -->
<div class="modal fade" id="avanzarSolpeDab" tabindex="-1" role="dialog" aria-labelledby="avanzarSolpeDabLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avanzarSolpeDabLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(80)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Gerente General-->
<!-- Modal avanzar etapa Gerente General -->
<div class="modal fade" id="avanzarSolpeGg" tabindex="-1" role="dialog" aria-labelledby="avanzarSolpeGgLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avanzarSolpeGgLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(80)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Sesion de directorio-->
<!-- Modal avanzar etapa Sesion de directorio -->
<div class="modal fade" id="avanzarSolpeDirectorio" tabindex="-1" role="dialog" aria-labelledby="avanzarSolpeDirectorioLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avanzarSolpeDirectorioLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea avanzar la etapa de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarSolpe(80)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Jefe de Abastecimiento y Mantencion cierre de solpe -->
<!-- Modal avanzar etapa Jefe de Abastecimiento y Mantencion cierre de solpe -->
<div class="modal fade" id="avanzarSolpeCierre" tabindex="-1" role="dialog" aria-labelledby="avanzarSolpeCierreLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avanzarSolpeCierreLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea cerrar y finalizar el proceso de la SOLPE?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="cerrarSolpe()">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Etapa responsable de grafos Aceptar -->
<!-- Modal Aceptar distribucion por grafos -->
<div class="modal fade" id="aceptarDistribucionGrafos" tabindex="-1" role="dialog" aria-labelledby="aceptarDistribucionGrafosLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="aceptarDistribucionGrafosLabel">Avanzar etapa de SOLPE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea aceptar esta distribución?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarDistribucion(aceptarDistribucionCorrelativo)">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal Eliminar distribucion por grafos -->
<div class="modal fade" id="eliminarDistribucionGrafos" tabindex="-1" role="dialog" aria-labelledby="eliminarDistribucionGrafosLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="eliminarDistribucionGrafosLabel">Eliminar distribución</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea eliminar esta distribución?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="eliminarDistribucion()">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal Eliminar distribucion por grafos -->
<div class="modal fade" id="rechazarDistribucionGrafos" tabindex="-1" role="dialog" aria-labelledby="rechazarDistribucionGrafosLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="rechazarDistribucionGrafosLabel">Rechazar distribución</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>¿Desea rechazar esta distribución?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="rechazarDistribucion()">Aceptar</button>
        </div>
      </div>
    </div>
</div>