import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'src/assets/plugins/moment/moment';
import { AddonsService } from '../shared/services/addons.service';
import { AuthService } from '../shared/services/auth.service';
import { BoletasService } from '../shared/services/boletas.service';
import { ContratoService } from '../shared/services/contrato.service';
import { ExcelService } from '../shared/services/excel.service';
import { FileService } from '../shared/services/file.service';
import { NotificationService } from '../shared/services/notification.service';
import { formatCurrency } from '../shared/functions/common';
import { ViewExcelButtonsProps } from '../shared/view-excel-button/view-excel-buttons.component';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-boletas',
  templateUrl: './boletas.component.html',
  styleUrls: ['./boletas.component.css'],
  providers: [FileService, BoletasService, ContratoService]
})
export class BoletasComponent implements OnInit {

  /** Acciones permitidas para el usuario segun su rol */
  accionesPermitidas: string[] = [];
  userId: number = 0;
  // variable para ver selccion en tabs superiores
  selectedFilter: number = 1;
  cantidadBoletasMsj: number = 0;
  contratoSelected: any;
  contratoSelectedEditar: any;
  descripcionServicio: string = '';

  /** Grilla Boletas de Garantia */
  boletas: any[] = [];
  boletasExcel: any[] = [];
  totalBoletas: number = 0;
  boletas90Dias: number = 0;
  boletas60Dias: number = 0;
  boletas30Dias: number = 0;
  boletas45Dias: number = 0;
  boletas0Dias: number = 0;
  boletasVigente: number = 0;
  boletasDevueltas: number = 0;
  public selected: string;
  selection = new SelectionModel<any>(false, []);
  msg_boletas : string;

  dataSource:any;
 /*  dataSourceHeader: string[] = ['IDboleta','NroBoleta', 'NombreServicio', 'Contrato', 'Proveedor',
  'Administrador', 'Monto', 'DESCRIPCION_ADJ', 'Banco', 'EstadoBoleta' , 'FechaEmision', 'FechaIngreso',
  'FechaVencimiento', 'FechaProrroga', 'FechaDevolucion', 'DiasVencimiento', 'Observaciones', 'Acciones']; */
  dataSourceHeader: string[] = ['IDboleta','Contrato','NroBoleta', 'NombreServicio', 'Observaciones',
  'Administrador', 'Monto', 'DESCRIPCION_ADJ', 'EstadoBoleta' ,
  'DiasVencimiento', 'FechaDevolucion', 'docDevolucion', 'FechaVctoCto', 'FechaProyectadaVencimientoContrato', 'Acciones'];
  @ViewChild('paginatorFuncionalidadGrl', {static: false}) paginatorBoletasGrl: MatPaginator;
  @ViewChild('closeModalAgregar') closeModalAgregar: ElementRef;
  @ViewChild('closeModalEditar') closeModalEditar: ElementRef;


  @ViewChild('fileAdjunto')
  garantiaFileAdjunto: ElementRef;

  @ViewChild('fileAdjuntoDevolucion')
  fileAdjuntoDevolucion: ElementRef;

  /** Crear Boleta Garantia */
  bancos: any[] = [];
  monedas: any[] = [];
  tipos: any[] = [];
  tipoDoc: any[] = [];
  contratos: any;
  boletaFormAgregar : FormGroup;
  boletaFormEditar : FormGroup;
  submitted = false;
  archivos64 :any;
  fileName :string;
  type :string;
  fileValid: boolean;

  // documento devoluacion garantia
  archivos64Devolucion :any;
  fileNameDevolucion :string;
  typeDevolucion :string;
  fileValidDevolucion: boolean;

  sortedData: any;
  boletasInit: any;
  descripcionContratoEditar: string = '';
  nombreArchivoVerEditar: string = '';
  rolId: number = 0;
  editarBoleta: boolean;

  excelButtonDocGarantia: ViewExcelButtonsProps = {
    title: 'Documento de Garantia',
    filename: 'documentos_garantia',
    headers: [
      {
        title: 'Contrato',
        value: 'Contrato',
      },
      {
        title: 'Año',
        value: 'Anio',
      },
      {
        title: 'Nro Boleta',
        value: 'NroBoleta',
      },
      {
        title: 'Servicio',
        value: 'Servicio',
      },
      {
        title: 'Tipo de documento',
        value: 'TipoDocumento',
      },
      {
        title: 'Proveedor',
        value: 'Proveedor',
      },
      {
        title: 'Admin EMSA',
        value: 'AdminEMSA',
      },
      {
        title: 'Monto',
        value: 'Monto',
      },
      {
        title: 'Moneda',
        value: 'Moneda',
      },
      {
        title: 'Banco',
        value: 'Banco',
      },
      {
        title: 'Estado de documento de garantía',
        value: 'EstadoBoleta',
      },
      {
        title: 'Fecha de emisión',
        value: 'FechaEmision',
      },
      {
        title: 'Fecha de ingreso',
        value: 'FechaIngreso',
      },
      {
        title: 'Fecha de vencimiento',
        value: 'FechaVencimiento',
      },
      {
        title: 'Fecha de prorroga',
        value: 'FechaProrroga',
      },
      {
        title: 'Fecha de devoluciοn',
        value: 'FechaDevolucion',
      },
      {
        title: 'Observaciones',
        value: 'Observaciones',
      },
      {
        title: 'Dias de vencimiento',
        value: 'DiasVencimiento',
      },
      {
        title: 'Fecha de devoluciοn',
        value: 'FechaDevolucion',
      },
      {
        title: 'Estado del contrato',
        value: 'EstadoContrato',
      },
      {
        title: 'Fecha de termino de contrato',
        value: 'FechaTerminoContrato',
      },
      {
        title: 'Fecha proyectada de vencimiento',
        value: 'FechaProyectadaVcto',
      },
      {
        title: 'Alerta dias vencimiento',
        value: 'AlertaDiasVcto',
      },
      {
        title: 'Alerta vencimiento de garantía',
        value: 'AlertaVctoGarantia',
      },
    ],
    data: []
  }

  constructor(
    private boletasService: BoletasService,
    private excelService: ExcelService,
    private notifyService: NotificationService,
    public fb: FormBuilder,
    private addonsService : AddonsService,
    private contratosService: ContratoService,
    private fileService: FileService,
    private authService: AuthService
    ) {
      this.userId = JSON.parse(localStorage.getItem('userData')).userId;
      this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
      let corrMenu = 4;
      this.authService.getAccionesPorUsuario(corrMenu).subscribe(resp => {
        let acciones: any[] = JSON.parse(resp);
        if(acciones.length > 0) {
          this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
        }
      },
      error => {console.log(error)},
      () => {
        this.getAllBoletas();
      });

      this.editarBoleta = false;
  }
  existeAccion(accion: string) {
    return (this.accionesPermitidas.includes(accion));
  }

  onChange(contratoSelecionado: any){

  }

  onChangeEditar(contratoSelecionado: any){

  }

  filterContrato(contratoSelecionado: any){
    this.contratos?.map((contrato) => {
      if(contrato['id'] == contratoSelecionado){
        this.descripcionServicio = contrato['text'];
      }
    });
    //console.log(this.contratos);
    return this.descripcionServicio;
  }

  ngOnInit(): void {
    this.msg_boletas = 'Total de Boletas en Garantía';

    /** Crear Boleta Garantia */
    this.getBancos();
    this.getMonedas();
    this.getTipos();
    this.getContratos();
    this.getTipoDocmentoGarantia();
    this.resetForm();
  }
  private validacion() {
    this.boletaFormAgregar = new FormGroup({
      'contrato': new FormControl(0, [Validators.required]),
      'banco': new FormControl(0, [Validators.required]),
      'nro_boleta': new FormControl(null, [Validators.required]),
      'monto': new FormControl(null, [Validators.required]),
      'moneda': new FormControl(0, [Validators.required]),
      'fecha_emision': new FormControl(null, [Validators.required]),
      'fecha_ingreso': new FormControl(moment(new Date()).format('DD/MM/YYYY HH:mm:ss')),
      'fecha_vencimiento': new FormControl(null, [Validators.required]),
      'fecha_prorroga': new FormControl(null),
      'fecha_devolucion': new FormControl(null),
      'descripcion': new FormControl(null),
      'observacion': new FormControl(null),
      'tipo': new FormControl(0, [Validators.required]),
      'bg_correlativo': new FormControl(null),
      'tipoDocumento': new FormControl(null, [Validators.required]),
    });
  }

  alertaVencimientoBoleta(fechaVencimientoContrato: string, fechaVencimientoDocumento: string, fechaProrrogaDocumento: any, diasVencimientoDocumento: number) {
    const fechaVencimiento = fechaProrrogaDocumento !== null ? moment(fechaProrrogaDocumento) : moment(fechaVencimientoDocumento);
    const fechaVencimientoCalc = moment(fechaVencimientoContrato).add(100, 'days');
    const fechaVencimientoDiff = fechaVencimiento.diff(fechaVencimientoCalc, 'days');

    if (diasVencimientoDocumento <= 0) {
      return '';
    } else {
      return fechaVencimientoDiff >= 0 ? '' : 'Documento NO cumple cobertura 100 dias';
    }
  }
    
  exportAsXLSX():void {
    // this.boletasExcel = this.formatDataExcel(this.boletas);
    // this.excelService.exportAsExcelFile(this.boletasExcel, 'boletas');
    // this.notifyService.showSuccess('Descargando...', 'Descargar archivo');
  }
  private formatDataExcel(datos: any[]) {
    const data_sorted = datos.sort(
      (dataA, dataB) =>
        +dataB['ANIO_CONTRATO'] - +dataA['ANIO_CONTRATO'] ||
        dataA['CODIGO_CONTRATO'].localeCompare(dataB['CODIGO_CONTRATO']) ||
        new Date(dataB['FECHA_VENCIMIENTO']).getTime() - new Date(dataA['FECHA_VENCIMIENTO']).getTime(),
    );
    let new_data: any[] = [];

    for(let i in data_sorted) {
      const data = datos[i];
      const EstadoBoleta = this.getEstadoBoleta(data);
      let { fechaVencimientoCalc, fechaVencimientoDiff } = this.getFechaVencimientoData(data);

      new_data[i] = {
        Contrato: data["CODIGO_CONTRATO"],
        'Anio': data["ANIO_CONTRATO"],
        'NroBoleta': data["NRO_BOLETA"],
        'Servicio': data["DESCRIPCION_BG"],
        'TipoDocumento': data["TIPO_DOCUMENTO_DESCRIPCION"],
        'Proveedor': data['PROVEEDORNAME'],
        'Administrador EMSA': data['ADMINISTRADORNAME'],
        Monto: formatCurrency(data['MONTO'],2,3,''),
        Moneda: data['Simbol'],
        Banco: data['BANCO'],
        'EstadoBoleta': EstadoBoleta,
        'FechaEmision': this.formatDate(data['FECHA_EMISION']),
        'FechaIngreso': this.formatDate(data['FECHA_INGRESO']),
        'FechaVencimiento': this.formatDate(data['FECHA_VENCIMIENTO']),
        'FechaProrroga': this.formatDate(data['FECHA_PRORROGA']),
        'FechaDevolucion': this.formatDate(data['FECHA_DEVOLUCION']),
        Observaciones: data["OBSERVACION_BG"],
        'DiasVencimiento': EstadoBoleta === 'Vigente' ? data['DIAS_POR_VENCER'] : '',
        'EstadoContrato': data['ESTADO_CONTRATO'],
        'FechaTerminoContrato': this.formatDate(data['FECHA_TERMINO_CONTRATO']),
        'FechaProyectadaVcto': fechaVencimientoCalc.format('DD-MM-YYYY'),
        'AlertaDiasVcto': EstadoBoleta !== 'Devuelta' ? fechaVencimientoDiff : '',
        'AlertaVctoGarantia': this.createAlertaVctoDocGarantia(data),
      };
    }

    return new_data;
  }
  applyFilterBoletasGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  private cantidadBoletas() {

    this.totalBoletas = this.boletas.length;
    /* if(this.existeAccion("Visualizar Propios") || this.existeAccion("Visualizar Todos")) {
      if(!this.existeAccion("Visualizar Propios")) this.userId = 0;
      this.boletasService.getCantidadBoletasPorDias(0, this.userId).subscribe(resp => {
        let response = JSON.parse(resp);
        this.totalBoletas = response[0]['CANTIDAD'];
        this.cantidadBoletasMsj = this.totalBoletas;
      });
    } */
  }
  private cantidadBoletas30() {
    if(this.existeAccion("Visualizar Propios") || this.existeAccion("Visualizar Todos")) {
      if(!this.existeAccion("Visualizar Propios")) this.userId = 0;
      this.boletasService.getCantidadBoletasPorDias(30, this.userId).subscribe(resp => {
        let response = JSON.parse(resp);
        this.boletas30Dias = response[0]['CANTIDAD'];
      });
    }
  }
  private cantidadBoletas60() {
    if(this.existeAccion("Visualizar Propios") || this.existeAccion("Visualizar Todos")) {
      if(!this.existeAccion("Visualizar Propios")) this.userId = 0;
      this.boletasService.getCantidadBoletasPorDias(60, this.userId).subscribe(resp => {
        let response = JSON.parse(resp);
        this.boletas60Dias = response[0]['CANTIDAD'];
      });
    }
  }
  private cantidadBoletas90() {
    if(this.existeAccion("Visualizar Propios") || this.existeAccion("Visualizar Todos")) {
      if(!this.existeAccion("Visualizar Propios")) this.userId = 0;
      this.boletasService.getCantidadBoletasPorDias(90, this.userId).subscribe(resp => {
        let response = JSON.parse(resp);
        this.boletas90Dias = response[0]['CANTIDAD'];
      });
    }
  }
  getAllBoletas(){
    // en caso de que sea admin de sistemas
    if (this.rolId === 1) {
      this.boletasService.getBoletas(0).subscribe(resp => {
        this.boletas = JSON.parse(resp);
        this.dataSource = new MatTableDataSource(this.boletas);
        this.createPaginator(this.paginatorBoletasGrl, this.dataSource);

        this.boletasInit = JSON.parse(resp);
        this.msg_boletas = 'Total de Boletas en Garantía';

        this.totalBoletas = this.boletas.length;

        this.boletas30Dias = this.boletas.filter(t=> (t.DIAS_POR_VENCER <= 30) && (t.DIAS_POR_VENCER >= 0)).length;
        this.boletas45Dias = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 30) && (t.DIAS_POR_VENCER <= 45) && (t.DIAS_POR_VENCER >= 0))).length;
        this.boletas60Dias = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 30) && (t.DIAS_POR_VENCER <= 60) && (t.DIAS_POR_VENCER >= 0))).length;
        this.boletas90Dias = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 60) && (t.DIAS_POR_VENCER <= 90) && (t.DIAS_POR_VENCER >= 0))).length;
        this.boletas0Dias = this.boletas.filter(t=> (t.DIAS_POR_VENCER < 0 && t.FECHA_DEVOLUCION === null)).length;
        this.boletasVigente = this.boletas.filter(t=> (t.DIAS_POR_VENCER > 0 && t.FECHA_DEVOLUCION === null)).length;
        this.boletasDevueltas = this.boletas.filter(t=> (t.FECHA_DEVOLUCION !== null)).length;

        // asignar datos a excel
        console.log(this.formatDataExcel(this.boletas));
        this.excelButtonDocGarantia.data = this.formatDataExcel(this.boletas);
      });
    } else {
      if(this.existeAccion("Visualizar Propios") || this.existeAccion("Visualizar Todos")) {
        if(!this.existeAccion("Visualizar Propios")) this.userId = 0;
        this.boletasService.getBoletas(this.userId).subscribe(resp => {
          this.boletas = JSON.parse(resp);
          this.dataSource = new MatTableDataSource(this.boletas);
          this.createPaginator(this.paginatorBoletasGrl, this.dataSource);

          this.boletasInit = JSON.parse(resp);
          this.msg_boletas = 'Total de Boletas en Garantía';

          this.totalBoletas = this.boletas.length;

          this.boletas30Dias = this.boletas.filter(t=> (t.DIAS_POR_VENCER <= 30) && (t.DIAS_POR_VENCER >= 0)).length;
          this.boletas45Dias = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 30) && (t.DIAS_POR_VENCER <= 45) && (t.DIAS_POR_VENCER >= 0))).length;
          this.boletas60Dias = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 30) && (t.DIAS_POR_VENCER <= 60) && (t.DIAS_POR_VENCER >= 0))).length;
          this.boletas90Dias = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 60) && (t.DIAS_POR_VENCER <= 90) && (t.DIAS_POR_VENCER >= 0))).length;
          this.boletas0Dias = this.boletas.filter(t=> (t.DIAS_POR_VENCER < 0 && t.FECHA_DEVOLUCION === null)).length;
          this.boletasVigente = this.boletas.filter(t=> (t.DIAS_POR_VENCER > 0)).length;
          this.boletasDevueltas = this.boletas.filter(t=> (t.FECHA_DEVOLUCION !== null)).length;

          // asignar datos a excel
          console.log(this.formatDataExcel(this.boletas));
          this.excelButtonDocGarantia.data = this.formatDataExcel(this.boletas);
        });
      }
    }
    this.selectedFilter = 1;
  }
  filtrarBoletas(dias: number)
  {
    let bolestasFiltradas = [];
    if(dias == 30)
    {
      bolestasFiltradas = this.boletas.filter(t=> ((t.DIAS_POR_VENCER <= 30) && (t.DIAS_POR_VENCER >= 0)))
      this.selectedFilter = 2;
    }
    else if(dias == 45)
    {
      bolestasFiltradas = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 30) && (t.DIAS_POR_VENCER <= 45) && (t.DIAS_POR_VENCER >= 0)));
      this.selectedFilter = 3;
    }
    else if(dias == 60)
    {
      bolestasFiltradas = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 30) && (t.DIAS_POR_VENCER <= 60) && (t.DIAS_POR_VENCER >= 0)));
      this.selectedFilter = 4;
    }
    else if(dias == 90)
    {
      bolestasFiltradas = this.boletas.filter(t=> ((t.DIAS_POR_VENCER > 60) && (t.DIAS_POR_VENCER <= 90) && (t.DIAS_POR_VENCER >= 0)));
      this.selectedFilter = 5;
    }
    else if(dias == 0)
    {
      bolestasFiltradas = this.boletas.filter(t=> ((t.DIAS_POR_VENCER < 0 && t.FECHA_DEVOLUCION === null)));
      this.selectedFilter = 6;
    }
    else if(dias == 1)
    {
      //this.boletasVigente = this.boletas.filter(t=> (t.DIAS_POR_VENCER > 0)).length;
      bolestasFiltradas = this.boletas.filter(t=> (t.DIAS_POR_VENCER > 0));
      this.selectedFilter = 7;
    }
    else if(dias == 2)
    {
      //this.boletasVigente = this.boletas.filter(t=> (t.DIAS_POR_VENCER > 0)).length;
      bolestasFiltradas = this.boletas.filter(t=> (t.FECHA_DEVOLUCION !== null));
      this.selectedFilter = 8;
    }
    this.dataSource = new MatTableDataSource(bolestasFiltradas);
    this.createPaginator(this.paginatorBoletasGrl, this.dataSource);
  }
  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  sortData(sort: Sort) {
    const data = this.boletasInit.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorBoletasGrl, this.dataSource);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'NroBoleta': return compare(a.NRO_BOLETA, b.NRO_BOLETA, isAsc);
        case 'Contrato': return compare(a.CODIGO_CONTRATO, b.CODIGO_CONTRATO, isAsc);
        case 'Proveedor': return compare(a.PROVEEDOR, b.PROVEEDOR, isAsc);
        case 'Administrador': return compare(a.ADMINISTRADOR, b.ADMINISTRADOR, isAsc);
        case 'Monto': return compare(a.MONTO, b.MONTO, isAsc);
        case 'Moneda': return compare(a.TIPO_MONEDA, b.TIPO_MONEDA, isAsc);
        case 'Banco': return compare(a.BANCO, b.BANCO, isAsc);
        case 'EstadoBoleta': return compare(a.ESTADO_CONTRATO, b.ESTADO_CONTRATO, isAsc);
        case 'FechaEmision': return compare(a.FECHA_EMISION, b.FECHA_EMISION, isAsc);
        case 'FechaIngreso': return compare(a.FECHA_INGRESO, b.FECHA_INGRESO, isAsc);
        case 'FechaVencimiento': return compare(a.FECHA_VENCIMIENTO, b.FECHA_VENCIMIENTO, isAsc);
        case 'FechaProrroga': return compare(a.FECHA_PRORROGA, b.FECHA_PRORROGA, isAsc);
        case 'FechaDevolucion': return compare(a.FECHA_DEVOLUCION, b.FECHA_DEVOLUCION, isAsc);
        case 'DiasVencimiento': return compare(a.DIAS_POR_VENCER, b.DIAS_POR_VENCER, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.dataSource =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorBoletasGrl, this.dataSource);
    }
  }
  getBoletasPorDias(dias: number, selectedTab: number){
    if(this.existeAccion("Visualizar Propios") || this.existeAccion("Visualizar Todos")) {
      if(!this.existeAccion("Visualizar Propios")) this.userId = 0;
      this.boletasService.getBoletasDiasVenc(dias, this.userId).subscribe(resp => {
          this.boletas = JSON.parse(resp);
          this.dataSource = new MatTableDataSource(this.boletas);
          this.createPaginator(this.paginatorBoletasGrl, this.dataSource);

          this.msg_boletas = 'Boletas en Garantía que vencen en ' + dias + ' días';
      });
    }

    switch (selectedTab) {
      case 1:
        this.selectedFilter = selectedTab;
        this.cantidadBoletasMsj = this.totalBoletas;
      break;
      case 2:
        this.selectedFilter = selectedTab;
        this.cantidadBoletasMsj = this.boletas30Dias;
      break;
      case 3:
        this.selectedFilter = selectedTab;
        this.cantidadBoletasMsj = this.boletas60Dias;
      break;
      case 4:
        this.selectedFilter = selectedTab;
        this.cantidadBoletasMsj = this.boletas90Dias;
      break;

      default:
        this.selectedFilter = 1;
        this.cantidadBoletasMsj = this.totalBoletas;
        break;
    }
  }
  onDescargar(fileName: string){
    /* if(fileName) {
      this.fileService.downloadFile(fileName).subscribe(resp => {
        FileSaver.saveAs(resp, fileName);
        this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
      }, err =>{
        this.notifyService.showError(err.error.message, 'Descargar archivos');
      });
    } else {
      this.notifyService.showInfo('Boleta de garantia no tiene archivos adjuntos', 'Descargar archivos');
    } */

    if(fileName) {
      this.fileService.getFile({fileName: fileName, fileType: 'boletas'}).subscribe(resp => {
        FileSaver.saveAs(resp, fileName);
        this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
      }, err =>{
        this.notifyService.showError(err.error.message, 'Descargar archivos');
      });
    } else {
      this.notifyService.showInfo('Boleta de garantia no tiene archivos adjuntos', 'Descargar archivos');
    }
  }
  /** Crear Boleta Garantia */
  resetForm(){
    this.fileName = null;
    this.type = null;
    this.archivos64 = null;
    this.fileValid = true;
    
    // documento devolucion garantia
    this.fileNameDevolucion = null;
    this.typeDevolucion = null;
    this.archivos64Devolucion = null;
    this.fileValidDevolucion = true;

    this.submitted = false;
    /* this.boletaFormAgregar = new FormGroup({
      'contrato' : new FormControl(0),
      'banco' : new FormControl(0),
      'nro_boleta' : new FormControl(null),
      'monto' : new FormControl(null),
      'moneda' : new FormControl(0),
      'fecha_emision' : new FormControl(null),
      'fecha_ingreso' : new FormControl(null),
      'fecha_vencimiento' : new FormControl(null),
      'fecha_prorroga' : new FormControl(null),
      'fecha_devolucion' : new FormControl(null),
      'descripcion' : new FormControl(null),
      'observacion' : new FormControl(null),
      'tipo' : new FormControl(0),
      'bg_correlativo': new FormControl(0),
      'tipoDocumento': new FormControl(null)
    }); */
    // this.boletaFormAgregar = this.fb.group({
    //   contrato: ['', [Validators.required]],
    //   banco: ['', [Validators.required]],
    //   nro_boleta: ['', [Validators.required]],
    //   monto: ['', [Validators.required]],
    //   moneda: ['', [Validators.required]],
    //   fecha_emision: ['', [Validators.required]],
    //   fecha_ingreso: ['', [Validators.required]],
    //   fecha_vencimiento: ['', [Validators.required]],
    //   fecha_prorroga: [''],
    //   fecha_devolucion: [''],
    //   descripcion: [''],
    //   observacion: [''],
    //   tipo: [''],
    //   bg_correlativo: [''],
    //   tipoDocumento: ['', [Validators.required]]
    // });
    this.boletaFormAgregar = new FormGroup({
      'contrato': new FormControl(null, [Validators.required]),
      'banco': new FormControl(null, [Validators.required]),
      'nro_boleta': new FormControl(null, [Validators.required]),
      'monto': new FormControl(null, [Validators.required]),
      'moneda': new FormControl(null, [Validators.required]),
      'fecha_emision': new FormControl(null, [Validators.required]),
      'fecha_ingreso': new FormControl(moment(new Date()).format('DD/MM/YYYY HH:mm:ss')),
      'fecha_vencimiento': new FormControl(null, [Validators.required]),
      'fecha_prorroga': new FormControl(null),
      'fecha_devolucion': new FormControl(null),
      'descripcion': new FormControl(null),
      'observacion': new FormControl(null),
      'tipo': new FormControl(null, [Validators.required]),
      'bg_correlativo': new FormControl(null),
      'tipoDocumento': new FormControl(null, [Validators.required]),
    });

    this.boletaFormEditar = new FormGroup({
      'contrato': new FormControl(null, [Validators.required]),
      'banco': new FormControl(null, [Validators.required]),
      'nro_boleta': new FormControl(null, [Validators.required]),
      'monto': new FormControl(null, [Validators.required]),
      'moneda': new FormControl(null, [Validators.required]),
      'fecha_emision': new FormControl(null, [Validators.required]),
      'fecha_ingreso': new FormControl(null, [Validators.required]),
      'fecha_vencimiento': new FormControl(null, [Validators.required]),
      'fecha_prorroga': new FormControl(null),
      'fecha_devolucion': new FormControl(null),
      'descripcion': new FormControl(null),
      'observacion': new FormControl(null),
      'tipo': new FormControl(null, [Validators.required]),
      'bg_correlativo': new FormControl(null),
      'tipoDocumento': new FormControl(null, [Validators.required]),
    });
  }
  private getBancos() {
    this.boletasService.getTipoDominios('TIBA').subscribe(resp => {
      this.bancos = JSON.parse(resp);
    });
  }
  private getMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.monedas = JSON.parse(resp);
    });
  }
  private getTipoDocmentoGarantia() {
    this.boletasService.getTipoDominios('TIPO_DOC_GARANTIA').subscribe(resp => {
      this.tipoDoc = JSON.parse(resp);
    });
  }
  private getTipos() {
    this.boletasService.getTipoDominios('TIADJ').subscribe(resp => {
      this.tipos = JSON.parse(resp);
    });
  }
  private getContratos() {
    this.contratosService.getContratosBusqueda().subscribe(resp=>{
      this.contratos= JSON.parse(resp);
    });
  }

  detectFiles(event: any){
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      let [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64 = bs64
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;

              if(!this.addonsService.validateType(this.type))
              {

                this.boletaFormAgregar.patchValue(
                  {
                    adjunto: ""
                  }
                )
                this.fileValid = false
                this.garantiaFileAdjunto.nativeElement.value = "";
                this.notifyService.showWarning('Solo se permite subir documentos: pdf, docx, csv, xls, xlsx', 'Crear boleta');
              }
              else
              {
                this.fileValid = true
              }
        }
      };
    }
  }

  detectFilesDevolucion(event: any){
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      let [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64Devolucion = bs64
              this.fileNameDevolucion = event.target.files[0].name;
              this.typeDevolucion = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;

              if(!this.addonsService.validateType(this.typeDevolucion))
              {

                this.boletaFormAgregar.patchValue(
                  {
                    adjuntoDevolucion: ""
                  }
                );

                this.boletaFormEditar.patchValue(
                  {
                    adjuntoDevolucion: ""
                  }
                )
                this.fileValidDevolucion = false
                this.fileAdjuntoDevolucion.nativeElement.value = "";
                this.notifyService.showWarning('Solo se permite subir documentos: pdf, docx, csv, xls, xlsx', 'Crear boleta');
              }
              else
              {
                this.fileValidDevolucion = true
              }
        }
      };
    }
  }

  onClose() {
    this.resetForm();
    //this.validacion();
    this.getAllBoletas();
  }

  onCrear() {
    this.submitted = true;
    // && !this.fileValid
    if (this.boletaFormAgregar.invalid) {
      this.submitted = false;
      this.notifyService.showWarning('Todos los campos son obligatorios', 'Crear boleta');
      return;
    }

    const fecha_devolucion = (this.boletaFormAgregar.value.fecha_devolucion) ? this.boletaFormAgregar.value.fecha_devolucion : null;

    if (fecha_devolucion) {
      if(this.fileName && this.fileNameDevolucion) {
        const postDataFileDevolucion: {fileName: string, type: string, files: string } = { fileName: this.fileNameDevolucion , type : this.typeDevolucion , files: this.archivos64Devolucion};

        const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64};

        this.addonsService.postFileComprobanteDevolucionBoleta(postDataFileDevolucion).subscribe(resp =>{
          if (JSON.parse(resp)){
            const nombreArchivoDevolucion = JSON.parse(resp);
            
            this.addonsService.postFileBoleta(postDataFile).subscribe(resp =>{
              if (JSON.parse(resp)){
                const nombreArchivo = JSON.parse(resp);
                this.postBoleta(nombreArchivo, nombreArchivoDevolucion);
              }
            });
          }
        });
      } else {
        // this.postBoleta(null);
        this.notifyService.showWarning(
          'Debe adjuntar el archivo del documento de garantia y de la devolucion de garantia',
          'Documentos de garantia'
        );
        this.submitted =false;
      }
    } else {
      if(this.fileName) {
        const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64}
  
        this.addonsService.postFileBoleta(postDataFile).subscribe(resp =>{
          if (JSON.parse(resp)){
            const nombreArchivo = JSON.parse(resp);
            this.postBoleta(nombreArchivo, '');
          }
        });
      } else {
        // this.postBoleta(null);
        this.notifyService.showWarning(
          'Debe adjuntar el archivo del documento de garantia',
          'Documentos de garantia'
        );
        this.submitted =false;
      }

    }
  }
  private postBoleta(nombreArchivo: string, nombreArchivoDevolucion: string)   {
        let fecha_prorroga = (this.boletaFormAgregar.controls.fecha_prorroga.value) ? this.boletaFormAgregar.controls.fecha_prorroga.value : null;
        let fecha_devolucion = (this.boletaFormAgregar.controls.fecha_devolucion.value) ? this.boletaFormAgregar.controls.fecha_devolucion.value : null;
        let tipo = (this.boletaFormAgregar.controls.tipo.value) ? this.boletaFormAgregar.controls.tipo.value : 0;
        const postData : {
            iv_contrato : number,
            iv_banco : number,
            iv_nro_boleta : string,
            iv_moneda : number,
            iv_monto : number,
            iv_fecha_emision : string,
            iv_fecha_ingreso : string,
            iv_fecha_vencimiento : string,
            iv_fecha_prorroga? : string,
            iv_fecha_devolucion? : string,
            iv_descripcion? : string,
            iv_observacion? : string,
            iv_adjunto? : string,
            iv_tipo? :number,
            in_usua_correlativo_creacion: number,
            in_tipo_documento: number,
            iv_adjunto_devolucion: string
          } = {
          iv_contrato: this.boletaFormAgregar.controls.contrato.value,
          iv_banco: this.boletaFormAgregar.controls.banco.value,
          iv_nro_boleta: this.boletaFormAgregar.controls.nro_boleta.value,
          iv_moneda: this.boletaFormAgregar.controls.moneda.value,
          iv_monto: this.boletaFormAgregar.controls.monto.value,
          iv_fecha_emision: this.boletaFormAgregar.controls.fecha_emision.value,
          iv_fecha_ingreso: moment(new Date()).format('YYYY-MM-DD'), //this.boletaFormAgregar.value.fecha_ingreso,
          iv_fecha_vencimiento: this.boletaFormAgregar.controls.fecha_vencimiento.value,
          iv_fecha_prorroga: fecha_prorroga,
          iv_fecha_devolucion: fecha_devolucion,
          iv_descripcion: this.filterContrato(this.contratoSelected),
          iv_observacion: this.boletaFormAgregar.controls.observacion.value,
          iv_adjunto: nombreArchivo,
          iv_tipo: tipo,
          in_usua_correlativo_creacion: 0,
          in_tipo_documento: this.boletaFormAgregar.controls.tipoDocumento.value,
          iv_adjunto_devolucion: nombreArchivoDevolucion
        };

        this.boletasService.postBoleta(postData).subscribe(resp=>{
          this.notifyService.showSuccess('Boleta creada con exito.', 'Crear Boleta de Garantía');
          this.submitted = false;
          this.closeModalAgregar.nativeElement.click();
        }, err =>{
          this.notifyService.showError('Error al crear boleta', 'Crear Boleta de Garantía');
          this.submitted = false;
          this.closeModalAgregar.nativeElement.click();
          console.log(err);
        });
  }
  /** Editar Boleta de garantia */
  editar(id: number) {
    this.boletasService.getBoleta(id).subscribe(resp => {
      // this.boletaFormEditar = new FormGroup({
      //   'contrato' : new FormControl(JSON.parse(resp)[0].CONTRATO_CORRELATIVO),
      //   'banco' : new FormControl(JSON.parse(resp)[0].DOMI_CORRELATIVO_TIMA),
      //   'nro_boleta' : new FormControl(JSON.parse(resp)[0].NRO_BOLETA),
      //   'monto' : new FormControl(JSON.parse(resp)[0].MONTO),
      //   'moneda' : new FormControl(JSON.parse(resp)[0].DOMI_CORRELATIVO_TIMO),
      //   'fecha_emision' : new FormControl(JSON.parse(resp)[0].FECHA_EMISION),
      //   'fecha_ingreso' : new FormControl(JSON.parse(resp)[0].FECHA_INGRESO),
      //   'fecha_vencimiento' : new FormControl(JSON.parse(resp)[0].FECHA_VENCIMIENTO),
      //   'fecha_prorroga' : new FormControl(JSON.parse(resp)[0].FECHA_PRORROGA),
      //   'fecha_devolucion' : new FormControl(JSON.parse(resp)[0].FECHA_DEVOLUCION),
      //   'descripcion' : new FormControl(JSON.parse(resp)[0].DESCRIPCION),
      //   'observacion' : new FormControl(JSON.parse(resp)[0].OBSERVACION),
      //   'tipo' : new FormControl(JSON.parse(resp)[0].DOMI_CORRELATIVO_TIADJ),
      //   'bg_correlativo' : new FormControl(id),
      //   'tipoDocumento': new FormControl(JSON.parse(resp)[0].TIPO_DOCUMENTO)
      // });

      this.boletaFormEditar.controls.contrato.setValue(JSON.parse(resp)[0].CONTRATO_CORRELATIVO);
      this.boletaFormEditar.controls.banco.setValue(JSON.parse(resp)[0].DOMI_CORRELATIVO_TIMA);
      this.boletaFormEditar.controls.nro_boleta.setValue(JSON.parse(resp)[0].NRO_BOLETA);
      this.boletaFormEditar.controls.monto.setValue(JSON.parse(resp)[0].MONTO);
      this.boletaFormEditar.controls.moneda.setValue(JSON.parse(resp)[0].DOMI_CORRELATIVO_TIMO);
      this.boletaFormEditar.controls.fecha_emision.setValue(JSON.parse(resp)[0].FECHA_EMISION);
      this.boletaFormEditar.controls.fecha_ingreso.setValue(moment(JSON.parse(resp)[0].FECHA_CREACION).format('DD/MM/YYYY HH:mm:ss'));
      this.boletaFormEditar.controls.fecha_vencimiento.setValue(JSON.parse(resp)[0].FECHA_VENCIMIENTO);
      this.boletaFormEditar.controls.fecha_prorroga.setValue(JSON.parse(resp)[0].FECHA_PRORROGA);
      this.boletaFormEditar.controls.fecha_devolucion.setValue(JSON.parse(resp)[0].FECHA_DEVOLUCION);
      this.boletaFormEditar.controls.descripcion.setValue(JSON.parse(resp)[0].DESCRIPCION);
      this.boletaFormEditar.controls.observacion.setValue(JSON.parse(resp)[0].OBSERVACION);
      this.boletaFormEditar.controls.tipo.setValue(JSON.parse(resp)[0].DOMI_CORRELATIVO_TIADJ);
      this.boletaFormEditar.controls.bg_correlativo.setValue(id);
      this.boletaFormEditar.controls.tipoDocumento.setValue(JSON.parse(resp)[0].TIPO_DOCUMENTO);

      this.boletaFormEditar.updateValueAndValidity();

      this.descripcionContratoEditar = this.boletaFormEditar.value.descripcion;
      this.nombreArchivoVerEditar = JSON.parse(resp)[0].ARCHIVO_ADJUNTO;

      if (JSON.parse(resp)[0].FECHA_DEVOLUCION) {
        this.boletaFormEditar.controls.banco.disable();
        this.boletaFormEditar.controls.nro_boleta.disable();
        this.boletaFormEditar.controls.monto.disable();
        this.boletaFormEditar.controls.moneda.disable();
        this.boletaFormEditar.controls.fecha_emision.disable();
        this.boletaFormEditar.controls.fecha_ingreso.disable();
        this.boletaFormEditar.controls.fecha_vencimiento.disable();
        this.boletaFormEditar.controls.fecha_prorroga.disable();
        this.boletaFormEditar.controls.fecha_devolucion.disable();
        this.boletaFormEditar.controls.descripcion.disable();
        this.boletaFormEditar.controls.observacion.disable();
        this.boletaFormEditar.controls.tipo.disable();
        this.boletaFormEditar.controls.tipoDocumento.disable();
        this.editarBoleta = true;
      }else{
        this.boletaFormEditar.controls.banco.enable();
        this.boletaFormEditar.controls.nro_boleta.enable();
        this.boletaFormEditar.controls.monto.enable();
        this.boletaFormEditar.controls.moneda.enable();
        this.boletaFormEditar.controls.fecha_emision.enable();
        this.boletaFormEditar.controls.fecha_ingreso.enable();
        this.boletaFormEditar.controls.fecha_vencimiento.enable();
        this.boletaFormEditar.controls.fecha_prorroga.enable();
        this.boletaFormEditar.controls.fecha_devolucion.enable();
        this.boletaFormEditar.controls.descripcion.enable();
        this.boletaFormEditar.controls.observacion.enable();
        this.boletaFormEditar.controls.tipo.enable();
        this.boletaFormEditar.controls.tipoDocumento.enable();
        this.editarBoleta = false;
      }
    });
  }
  onEdit() {
    this.submitted = true;
    // && !this.fileValid
    if (this.boletaFormEditar.invalid) {
      this.submitted = false;
      this.notifyService.showError('Verifique los campos obligatorios', 'Editar Documento');
      return;
    }

    const bg_correlativo = this.boletaFormEditar.controls.bg_correlativo.value;
    const contrato = this.boletaFormEditar.controls.contrato.value;
    const banco = this.boletaFormEditar.controls.banco.value;
    const nro_boleta = this.boletaFormEditar.controls.nro_boleta.value;
    const monto = this.boletaFormEditar.controls.monto.value;
    const moneda = this.boletaFormEditar.controls.moneda.value;
    const fecha_emision = (this.boletaFormEditar.controls.fecha_emision.value) ? this.boletaFormEditar.controls.fecha_emision.value : null;
    const fecha_ingreso = (this.boletaFormEditar.controls.fecha_ingreso.value) ? this.boletaFormEditar.controls.fecha_ingreso.value : null;
    const fecha_vencimiento = (this.boletaFormEditar.controls.fecha_vencimiento.value) ? this.boletaFormEditar.controls.fecha_vencimiento.value : null;
    const fecha_prorroga = (this.boletaFormEditar.controls.fecha_prorroga.value) ? this.boletaFormEditar.controls.fecha_prorroga.value : null;
    const fecha_devolucion = (this.boletaFormEditar.controls.fecha_devolucion.value) ? this.boletaFormEditar.controls.fecha_devolucion.value : null;
    const observacion = (this.boletaFormEditar.controls.observacion.value) ? this.boletaFormEditar.controls.observacion.value : null;
    const tipo = (this.boletaFormEditar.controls.tipo.value) ? this.boletaFormEditar.controls.tipo.value : 0;
    

    if (fecha_devolucion) {
      if (this.fileNameDevolucion) {
        const postDataFileDevolucion: {fileName: string, type: string, files: string } = { fileName: this.fileNameDevolucion , type : this.typeDevolucion , files: this.archivos64Devolucion};

        this.addonsService.postFileComprobanteDevolucionBoleta(postDataFileDevolucion).subscribe(resp =>{
          if (JSON.parse(resp)){
            const nombreArchivoDevolucion = JSON.parse(resp);

            const postData : {
              iv_bg_correlativo: number,
              iv_contrato : number,
              iv_banco : number,
              iv_nro_boleta : string,
              iv_moneda : number,
              iv_monto : number,
              iv_fecha_emision : string,
              iv_fecha_ingreso : string,
              iv_fecha_vencimiento : string,
              iv_fecha_prorroga? : string,
              iv_fecha_devolucion? : string,
              iv_descripcion : string,
              iv_observacion : string,
              iv_adjunto: string,
              iv_tipo :number,
              in_usua_correlativo_creacion: number,
              in_tipo_documento: number,
              iv_adjunto_devolucion: string
            } = {
                iv_bg_correlativo: bg_correlativo,
                iv_contrato: contrato,
                iv_banco: banco,
                iv_nro_boleta: nro_boleta,
                iv_moneda: moneda,
                iv_monto: monto,
                iv_fecha_emision: fecha_emision,
                iv_fecha_ingreso: fecha_ingreso,
                iv_fecha_vencimiento: fecha_vencimiento,
                iv_fecha_prorroga: fecha_prorroga,
                iv_fecha_devolucion: fecha_devolucion,
                iv_descripcion: this.filterContrato(contrato),
                iv_observacion: observacion,
                iv_adjunto: '',
                iv_tipo: tipo,
                in_usua_correlativo_creacion: 0,
                in_tipo_documento: tipo,
                iv_adjunto_devolucion: nombreArchivoDevolucion
            };
            
            this.boletasService.postEditarBoleta(postData).subscribe(resp=>{
              this.notifyService.showSuccess('Boleta editada con exito.', 'Editar Boleta de Garantía');
        
              if (fecha_devolucion !== null) {
                this.boletasService.postEnviarCorreoModificacionBoleta({in_bg_correlativo: bg_correlativo}).subscribe(resp=>{
                  this.submitted = false;
                  this.getAllBoletas();
                  this.notifyService.showSuccess('Envio de notificacion exitoso', 'Editar Boleta de Garantía');
                }, err =>{
                  this.submitted = false;
                  this.notifyService.showError('Error al editar boleta', 'Editar Boleta de Garantía');
                  console.log(err);
                });
              }else{
                this.boletasService.postEnviarCorreoModificacionDg({in_bg_correlativo: bg_correlativo}).subscribe(resp=>{
                  this.submitted = false;
                  this.getAllBoletas();
                  this.notifyService.showSuccess('Envio de notificacion exitoso', 'Editar Boleta de Garantía');
                }, err =>{
                  this.submitted = false;
                  this.notifyService.showError('Error al editar boleta', 'Editar Boleta de Garantía');
                  console.log(err);
                });
              }
            }, err =>{
              this.submitted = false;
              this.notifyService.showError('Error al editar boleta', 'Editar Boleta de Garantía');
              console.log(err);
            });
          }
        });

      } else {
        this.submitted = false;
        this.notifyService.showWarning('Debe adjuntar el documento de devolucion', 'Editar Documento');
        return;
      }
    } else {
      const postData : {
        iv_bg_correlativo: number,
        iv_contrato : number,
        iv_banco : number,
        iv_nro_boleta : string,
        iv_moneda : number,
        iv_monto : number,
        iv_fecha_emision : string,
        iv_fecha_ingreso : string,
        iv_fecha_vencimiento : string,
        iv_fecha_prorroga? : string,
        iv_fecha_devolucion? : string,
        iv_descripcion : string,
        iv_observacion : string,
        iv_adjunto: string,
        iv_tipo :number,
        in_usua_correlativo_creacion: number,
        in_tipo_documento: number,
        iv_adjunto_devolucion: string
      } = {
        iv_bg_correlativo: bg_correlativo,
        iv_contrato: contrato,
        iv_banco: banco,
        iv_nro_boleta: nro_boleta,
        iv_moneda: moneda,
        iv_monto: monto,
        iv_fecha_emision: fecha_emision,
        iv_fecha_ingreso: fecha_ingreso,
        iv_fecha_vencimiento: fecha_vencimiento,
        iv_fecha_prorroga: fecha_prorroga,
        iv_fecha_devolucion: fecha_devolucion,
        iv_descripcion: this.filterContrato(contrato),
        iv_observacion: observacion,
        iv_adjunto: '',
        iv_tipo: tipo,
        in_usua_correlativo_creacion: 0,
        in_tipo_documento: tipo,
        iv_adjunto_devolucion: ''
      };

      this.boletasService.postEditarBoleta(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Boleta editada con exito.', 'Editar Boleta de Garantía');
  
        if (fecha_devolucion !== null) {
          this.boletasService.postEnviarCorreoModificacionBoleta({in_bg_correlativo: bg_correlativo}).subscribe(resp=>{
            this.submitted = false;
            this.getAllBoletas();
            this.notifyService.showSuccess('Envio de notificacion exitoso', 'Editar Boleta de Garantía');
          }, err =>{
            this.submitted = false;
            this.notifyService.showError('Error al editar boleta', 'Editar Boleta de Garantía');
            console.log(err);
          });
        }else{
          this.boletasService.postEnviarCorreoModificacionDg({in_bg_correlativo: bg_correlativo}).subscribe(resp=>{
            this.submitted = false;
            this.getAllBoletas();
            this.notifyService.showSuccess('Envio de notificacion exitoso', 'Editar Boleta de Garantía');
          }, err =>{
            this.submitted = false;
            this.notifyService.showError('Error al editar boleta', 'Editar Boleta de Garantía');
            console.log(err);
          });
        }
      }, err =>{
        this.submitted = false;
        this.notifyService.showError('Error al editar boleta', 'Editar Boleta de Garantía');
        console.log(err);
      });
    }

    this.closeModalEditar.nativeElement.click();
  }

  formatDate(date: string) {
    return date !== null ? moment(date).format('DD-MM-YYYY') : ''
  }

  getEstadoBoleta(boleta: any) {
    if(boleta.FECHA_DEVOLUCION !== null) return 'Devuelta';
    if(boleta.DIAS_POR_VENCER <= 0) return 'Vencida';
    return 'Vigente';
  }

  getFechaVencimientoData(boleta: any) {
    const EstadoBoleta = this.getEstadoBoleta(boleta);
    const fechaVencimiento = moment(boleta['FECHA_VENCIMIENTO']);
    const fechaVencimientoCalc = moment(boleta['FECHA_TERMINO_CONTRATO']).add(100, 'days');
    let fechaVencimientoDiff = 0;
    if(EstadoBoleta === 'Vigente'){
      fechaVencimientoDiff = fechaVencimiento.diff(fechaVencimientoCalc, 'days');
    } else {
      fechaVencimientoDiff = 0;
    }

    return { fechaVencimientoCalc, fechaVencimientoDiff };
  }

  createAlertaVctoDocGarantia(boleta: any) {
    const EstadoBoleta = this.getEstadoBoleta(boleta);
    if(EstadoBoleta === 'Devuelta') return '';
    const { fechaVencimientoDiff } = this.getFechaVencimientoData(boleta);
    if(EstadoBoleta === 'Vigente' && fechaVencimientoDiff > 0) return '';

    let alertaVctoDocGarantia = '** ';
    if(EstadoBoleta === 'Vigente'){
      alertaVctoDocGarantia += `Contrato ${boleta['ESTADO_CONTRATO'].toLowerCase()} - boleta vigente`;
    } else {
      alertaVctoDocGarantia += 'Vencida, no tiene valor - se debe devolver';
    }

    if(fechaVencimientoDiff < 0) alertaVctoDocGarantia += ' - contrato sin cobertura 100 días'

    alertaVctoDocGarantia += ' **';
    return alertaVctoDocGarantia;
  }

  get f() { return this.boletaFormAgregar.controls; }
  get ef() { return this.boletaFormEditar.controls; }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
