<div id="content" class="content">
    <div class="row">
        <div class="col-6">
            <a (click)="back()" style="margin-right: 10px;" class="btn btn-primary text-white"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"></i> Volver</a>
        </div>
        <div class="col-6 text-right">
            <div class="mb-4">
                <!-- accion de finiquito -->
                <button *ngIf="existeAccion('18') && (dataContrato.ESTADO_CORRELATIVO == 30 || dataContrato.ESTADO_CORRELATIVO == 31)" 
                type="button" class="btn btn-primary"
                href="#modalFiniquito" data-toggle="modal">
                    <i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Finiquitar Contrato
                </button>
            </div>
            <div class="form-check mt-1" *ngIf="rolId == 1">
                <!-- <input class="form-check-input" type="checkbox" #check id="inlineCss" (click)="onPasarRevision()"
                    [checked] = "dataContrato.ESTADO_CORRELATIVO == 138"
                >
                <label class="form-check-label mt-1" for="inlineCss">
                    En Revisión
                </label> -->
                <mat-slide-toggle
                    style="padding-right: 10px;"
                    [color]="'warn'"
                    [checked]="dataContrato.REQUIERE_BG == '1'"
                    (change)="onCambiarRequiereBg($event)"
                >
                    Requiere Doc. Garantía
                </mat-slide-toggle>
                <mat-slide-toggle
                    [color]="'warn'"
                    [checked]="dataContrato.ESTADO_CORRELATIVO == 138"
                    (change)="onPasarRevision()"
                >
                    En Revisión
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="row">
        <div class="col-12 col-md-8" *ngIf="dataContrato">
            <div class="bg-white p3-rem rounded">
                <div class="row mb-2">
                    <div class="col-12">
                        <h3>Contrato {{dataContrato?.CODIGO_CONTRATO}} {{dataContrato?.NOMBRE_CONTRATO}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="row mb-5">
                        <div class="col-12">
                            <div *ngIf="dataContrato.ESTADO_CONTRATO == 'A Vigente'" class="tab tab-green">{{dataContrato.ESTADO_CONTRATO}}</div>
                            <div *ngIf="dataContrato.ESTADO_CONTRATO == 'B Expirado'" class="tab tab-yellow">{{dataContrato.ESTADO_CONTRATO}}</div>
                            <div *ngIf="dataContrato.ESTADO_CONTRATO == 'C Finiquitado'" class="tab tab-red">{{dataContrato.ESTADO_CONTRATO}}</div>
                            <div *ngIf="dataContrato.ESTADO_CONTRATO == 'X Cancelado'" class="tab tab-red">{{dataContrato.ESTADO_CONTRATO}}</div>
                            <div *ngIf="dataContrato.ESTADO_CORRELATIVO == 138" class="tab tab-red">{{dataContrato.ESTADO_CONTRATO}}</div>
                            <span *ngIf="dataContrato.REQUIERE_BG === '1'" class="tab tab-yellow ml-2">
                                Requiere Documento de Garantía
                            </span>
                            <span *ngIf="dataContrato.es_critico === 1" class="tab tab-yellow ml-2">
                                Contrato Critico
                            </span>
                            <span class="tag2">
                                {{dataContrato.ORIGEN}}
                            </span>
                            <span class="tag2">
                                {{dataContrato.AREA}}
                            </span>
                            <span class="tag2">
                                {{dataContrato.TIPO_CONTRATO}}
                            </span>
                            
                            <!-- <span *ngIf="dataContrato.REQUIERE_BG === '0'" class="tag2">
                                No Requiere Documento de Garantía
                            </span> -->
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 font-14">
                        <p class="text-muted">Datos del provedor</p>
                        <h4 class="mb-0">{{dataContrato?.RUT_PROVEEDOR}}</h4>
                        <h4>{{dataContrato?.PROVEEDOR}}</h4>

                        <div class="row mt-4">
                            <div class="col-12">
                                <div class="d-flex-inline align-center border rounded p-10 m-r-10 m-b-10">
                                    <div class="round-name">
                                            {{dataContrato.ADMIN_PROVEEDOR?.slice(0, 2)}}
                                    </div>
                                    <div class="nombre">
                                        <span class="mb-0">{{dataContrato.ADMIN_PROVEEDOR}} </span>
                                        <br>
                                        <span class="text-muted">Administrador</span>
                                    </div>
                                    <div class="opciones">
                                        <a class="btn rounded-icon ml-2" href="#modalAdec" data-toggle="modal" title="Ver Adec" (click)="getAdecContrato()">
                                            <!-- <i class="fas fa-pen"></i> -->
                                            <i class="fas fa-clock"></i>
                                            <!-- <i class="fas fa-history"></i> -->
                                        </a>
                                    </div>
                                </div>

                                <div class="d-flex-inline align-center border rounded p-10">
                                    <div class="round-name">
                                         {{dataContrato.ADMINISTRADOR?.slice(0, 2)}}
                                    </div>
                                    <div class="nombre">
                                        <span class="mb-0">{{dataContrato.ADMINISTRADOR}} </span>
                                        <br>
                                        <span class="text-muted">Adm EMSA</span>
                                    </div>
                                    <div class="opciones" *ngIf="rolId == 1">
                                        <a class="btn rounded-icon ml-2" href="#modalAdecEmsa" data-toggle="modal" title="Ver Admin EMSA"
                                        (click)="getAdecEmsaContrato(); getAdemsa()"
                                        >
                                        <i class="fas fa-pen"></i>
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <div class="d-inline-flex flex-column mr-5 mb-4">
                                    <span class="text-muted">Moneda</span>
                                    <span class="color-b font-14">{{dataContrato.TIPO_MONEDA}}</span>
                                </div>

                                <div class="d-inline-flex flex-column mr-5 mb-4">
                                    <span class="text-muted">Ultimo EP</span>
                                    <span class="color-b font-14">
                                        {{dataContrato.ULTIMO_EP}}
                                    </span>
                                </div>

                                <div class="d-inline-flex flex-column mr-5 mb-4">
                                    <span class="text-muted">Vencimiento BG</span>
                                    <span class="color-b font-14">
                                        {{dataContrato.VENCIMIENTO_BG}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="dataContrato.OBSERVACION_CIERRE">
                            <div class="col-12">
                                <p class="text-muted mb-0">Observacion de cierre</p>
                                <span>{{dataContrato.OBSERVACION_CIERRE}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-5">
                    <mat-tab-group mat-align-tabs="end">
                        <mat-tab label="Estados de pago ({{epCount}})">
                            <div class="tab-content mt-4">
                                <ng-container >
                                    <div class="tab-pane fade active show" id="default-tab-41">
                                        <div class="row">
                                            <div class="col-8">
                                                <app-view-excel-buttons [props]="excelButtonEP">
                                                </app-view-excel-buttons>
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input>
                                            </div>
                                        </div>
                                        <table mat-table [dataSource]="dsEPNormal" class="table table-striped table-td-valign-middle table-th-valign-middle border">
                                            <ng-container matColumnDef="EP_CORRELATIVO">
                                                <th mat-header-cell *matHeaderCellDef> No. </th>
                                                <td mat-cell *matCellDef="let element " >
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <span>{{element.EP_NUMERO}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="DatosEp">
                                                <th mat-header-cell *matHeaderCellDef> Descripcion </th>
                                                <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                    <div class="row">
                                                        <div class="col col-md-12" >
                                                            {{element.EP_DESCRIPCION }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="EP_ESTADO">
                                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                                <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                    <!-- <div class="row">
                                                        <div class="col col-md-12" >
                                                            {{element.EP_ESTADO}}
                                                        </div>
                                                    </div> -->
                                                    <span>{{element.EP_ESTADO}}</span>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="hes_codigo">
                                                <th mat-header-cell *matHeaderCellDef> Hes </th>
                                                <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                    <div class="row">
                                                        <div *ngIf="element.hes_codigo!==''" class="col col-md-12">
                                                            <span>{{element.hes_codigo}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="nro_factura">
                                                <th mat-header-cell *matHeaderCellDef> Factura </th>
                                                <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                    <div class="row">
                                                        <div *ngIf="element.nro_factura!==''" class="col col-md-12">
                                                            <span>
                                                                <!-- {{element.nro_factura}} -->
                                                                <view-file-link
                                                                    [nombreArchivo]="element.factura_documento"
                                                                    [tipoArchivo]="'facturas_ep'"
                                                                    [descripcionArchivo]="element.nro_factura"
                                                                >
                                                                </view-file-link>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="FechaEp">
                                                <th mat-header-cell *matHeaderCellDef> inicio </th>
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="row">
                                                        <div class="col col-md-6" style="padding-right: 0px;">
                                                            {{element.FECHA_ESTADO_EP | date:'dd/MM/yyyy'}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container> -->
                                            <ng-container matColumnDef="EP_PERIODO_DESDE">
                                                <th mat-header-cell *matHeaderCellDef> Desde </th>
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="row">
                                                        <div class="col col-md-12" style="padding-right: 0px;">
                                                            {{element.EP_PERIODO_DESDE | date:'dd/MM/yyyy'}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="EP_PERIODO_HASTA">
                                                <th mat-header-cell *matHeaderCellDef> Hasta </th>
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="row">
                                                        <div class="col col-md-12" style="padding-right: 0px;">
                                                            {{element.EP_PERIODO_HASTA | date:'dd/MM/yyyy'}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="fecha_pago">
                                                <th mat-header-cell *matHeaderCellDef>Fecha de pago</th>
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="row">
                                                        <div *ngIf="element.EP_ESTADO=='Facturado'" class="col col-md-12" style="padding-right: 0px;">
                                                            {{element.fecha_pago | date:'dd/MM/yyyy'}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Monto">
                                                <th mat-header-cell *matHeaderCellDef> Monto </th>
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="row">
                                                        <div class="col" >
                                                            {{element.monto | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Reajuste">
                                                <th mat-header-cell *matHeaderCellDef> Reajuste </th>
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="row">
                                                        <div class="col" >
                                                            {{element.reajuste_ep | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Opciones">
                                                <th mat-header-cell *matHeaderCellDef>  </th>
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="btn rounded-icon" routerLink="/estado-pago-checklist/{{element.EP_CORRELATIVO}}">
                                                        <i class="fas fa-eye"></i>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="dsHeaderEPNormal"></tr>
                                            <tr style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPNormal; "></tr>
                                            <tr class="mat-row " *matNoDataRow>
                                                <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                            </tr>
                                        </table>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <p>Total Facturado: {{totalFacturadoEp | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</p>
                                            </div>
                                        </div>
                                        <hr>
                                        <!-- <mat-paginator #paginatorEPNormal [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                                    </div>
                                </ng-container>
                            </div>
                        </mat-tab>
                        <mat-tab label="Itemizado" isActive="true">
                            <div class="tab-content mt-4">
                                <ng-container *ngIf="dataContrato ">
                                    <div class="panel-body" >
                                        <ng-container *ngIf="itemizado.length > 0">
                                            <div class="row">
                                                <div class="col-12 m-b-10">
                                                    <app-view-excel-buttons [props]="excelButtonItemizado">
                                                    </app-view-excel-buttons>
                                                </div>
                                                <div class="col-md-12 table_container">
                                                    <table class="table border">
                                                        <thead class="">
                                                            <th class="text-normal">R</th>
                                                            <th class="text-normal">Q</th>
                                                            <th class="text-normal">SubPos</th>
                                                            <th class="text-normal">Desc</th>
                                                            <th class="text-normal">Articulo</th>
                                                            <th class="text-normal text-right">Cantidad</th>
                                                            <th class="text-normal">Unidad</th>
                                                            <th class="text-normal text-right">P. Unitario</th>
                                                            <th class="text-normal text-right">Total</th>
                                                            <th class="text-normal text-right">Avance Q</th>
                                                            <th class="text-normal text-right">Saldo Q</th>
                                                            <th class="text-normal text-right">Avance</th>
                                                            <th class="text-normal text-right">Saldo</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of itemizado">
                                                                <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>
                                                                <ng-container *ngIf="!item.Cabecera">
                                                                    <td class="pt-2"><i *ngIf="item.R" class="fa fa-check text-muted"></i></td>
                                                                    <td class="pt-2"><i *ngIf="item.Scpq" class="fa fa-check text-muted"></i></td>
                                                                    <td class="pt-2 text-muted text-right">{{ item.Subpos }}</td>
                                                                    <td class="pt-2">{{ item.Descripcion }}</td>
                                                                    <td class="pt-2">{{ item.Articulo }}</td>
                                                                    <td class="pt-2 text-right">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                                                    <td class="pt-2 text-muted">{{ item.Unidad }}</td>
                                                                    <td class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                    <td class="pt-2 text-right">{{ item.Total | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                    <td class="pt-2 text-right">{{ item.AvanceQ | number:'1.2': 'es-CL' }}</td>
                                                                    <td class="pt-2 text-right">
                                                                        <span>
                                                                            {{ item.SaldoQ | number:'1.2': 'es-CL' }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="pt-2 text-right">
                                                                        {{ item.Avance | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}
                                                                    </td>
                                                                    <td class="pt-2 text-right">
                                                                        <span>
                                                                            {{ item.Saldo | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="row table_totals">
                                                        <div class="col-3"></div>
                                                        <div class="col-3"></div>
                                                        <div class="col-2 text-center">
                                                            <p style="padding-right: 2px;">{{totalItemizado  | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</p>
                                                        </div>
                                                        <div class="col-4 text-right">
                                                            <p style="padding-right: 2px;">{{saldoItemizado | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>

                            </div>
                        </mat-tab>
                        <mat-tab label="Modificaciones">
                            <div class="tab-content mt-4">
                                <div class="col-12">
                                    <table class="table border">
                                        <thead class="">
                                            <th class="text-normal">N Solpe</th>
                                            <th class="text-normal">Descripcion</th>
                                            <th class="text-normal">Modificaciones</th>
                                            <th class="text-normal">Estado</th>
                                            <th class="text-normal"></th>
                                            <th class="text-normal"></th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let modificacion of modificacionesContrato">
                                                <td>
                                                    <p class="mb-0 text-muted">
                                                        {{ modificacion.CODIGO_SOLPE }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p class="mb-0">
                                                        {{ modificacion.SOLPE_NOMBRE }}
                                                    </p>

                                                </td>
                                                <td>
                                                    <p class="mb-0 text-muted" *ngIf="modificacion.monto_actualizado != null && modificacion.monto_actualizado > 0"> Monto actualizado: {{ modificacion.monto_actualizado | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</p>
                                                    <p class="mb-0 text-muted" *ngIf="modificacion.fecha_inicio != null && modificacion.fecha_inicio != 'Invalid date'"> Fecha inicio: {{ modificacion.fecha_inicio | date: 'dd/MM/yyyy' }}</p>
                                                    <p class="mb-0 text-muted" *ngIf="modificacion.fecha_termino != null && modificacion.fecha_termino != 'Invalid date'"> Fecha termin: {{ modificacion.fecha_termino | date: 'dd/MM/yyyy' }}</p>
                                                    <p class="mb-0 text-muted" *ngIf="modificacion.alcance != null"> Nuevo alcance: {{ modificacion.alcance }}</p>
                                                </td>
                                                <td>
                                                    <div *ngIf="modificacion.ESTADO_SOLPE == 'Aplicado'" class="tab tab-green">{{modificacion.ESTADO_SOLPE}}</div>
                                                    <div *ngIf="modificacion.ESTADO_SOLPE == 'Cerrada'" class="tab tab-yellow">Sin aplicar</div>
                                                </td>
                                                <td>
                                                    <button *ngIf="modificacion.domi_correlativo_estadosolpe == 110"
                                                    (click)="onAplicarModificacionContrato(modificacion.CORRELATIVO)"
                                                    class="btn btn-warning" style="margin-left: 30px;" data-toggle="modal" data-target="#aplicarModificacionContratoModal">
                                                        Aplicar modificacion
                                                    </button>
                                                </td>
                                                <td>
                                                    <div class="btn rounded-icon" routerLink="/detalle-solpe/{{modificacion.CORRELATIVO}}">
                                                        <i class="fas fa-eye"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="dataContrato.REQUIERE_BG === '1'" label="Doc Garantia">
                            <div class="tab-content mt-4">
                                <div class="row">
                                    <div class="col-md-8"></div>
                                    <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterBoletas($event) " placeholder="Buscar ... " #input2>
                                </div>
                                <table mat-table [dataSource]="boletas" class="table table-striped table-td-valign-middle table-th-valign-middle border">
                                    <ng-container matColumnDef="NUM_BOLETA">
                                        <th mat-header-cell *matHeaderCellDef> Numero </th>
                                        <td mat-cell *matCellDef="let element ">
                                            <div class="row pb-2 pt-2">
                                                <div class="col col-md-12" >
                                                    {{element.NUM_BOLETA }}
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="TIPO">
                                        <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                        <td mat-cell *matCellDef="let element ">
                                            <div class="row pb-2 pt-2">
                                                <div class="col col-md-12" >
                                                    {{element.TIPO }}
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="FECHA_EMISION">
                                        <th mat-header-cell *matHeaderCellDef> Ingreso </th>
                                        <td mat-cell *matCellDef="let element ">
                                            <div class="row pb-2 pt-2">
                                                <div class="col col-md-12" >
                                                    {{element.FECHA_INGRESO | date: 'dd/MM/yyyy'}}
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="BANCO">
                                        <th mat-header-cell *matHeaderCellDef> Banco </th>
                                        <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                            <div class="row pb-2 pt-2">
                                                <div class="col-12 col-md-12">
                                                    {{element.BANCO}}
                                                </div>

                                                <div class="col-12 col-md-12" >
                                                    {{element.SIMBOLO_MONEDA}} {{element.MONTO | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="TIPO_MONEDA">
                                        <th mat-header-cell *matHeaderCellDef> Moneda </th>
                                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                            <div class="row pb-2 pt-2">
                                                <div class="col-12 col-md-12">
                                                    {{element.TIPO_MONEDA}}
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="FECHA_VENCIMIENTO">
                                        <th mat-header-cell *matHeaderCellDef> Vencimiento </th>
                                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                            <div class="row pb-2 pt-2">
                                                <div class="col-12 col-md-12">
                                                    {{element.FECHA_VENCIMIENTO | date: 'dd/MM/yyyy'}}
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="FECHA_INGRESO">
                                        <th mat-header-cell *matHeaderCellDef> Estado </th>
                                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                            <div *ngIf="element.FECHA_DEVOLUCION !== null" class="tab bg-success text-white">Devuelta</div>
                                            <div *ngIf="element.DIAS_RESTANTES > 0 && element.FECHA_DEVOLUCION === null" class="tab tab-green">Vigente</div>
                                            <div *ngIf="element.DIAS_RESTANTES <= 0 && element.FECHA_DEVOLUCION === null" class="tab tab-yellow">Vencida</div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="DIAS_RESTANTES">
                                        <th mat-header-cell *matHeaderCellDef> Dias restantes </th>
                                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                            <div class="row pb-2 pt-2">
                                                <div *ngIf="element.DIAS_RESTANTES > 0" class="col-12 col-md-12">
                                                    Dias restantes: {{element.DIAS_RESTANTES}}
                                                </div>
                                                <div *ngIf="element.DIAS_RESTANTES <= 0" class="col-12 col-md-12">
                                                    Dias restantes: 0
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ADJUNTO">
                                        <th mat-header-cell *matHeaderCellDef>  </th>
                                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                            <div class="pb-2 pt-2">
                                                <div class="btn rounded-icon" (click)="onDescargarBoleta(element.ADJUNTO)">
                                                    <i class="fas fa-file-download"></i>
                                                </div>
                                                <view-file-button
                                                    [nombreArchivo]="element.ADJUNTO"
                                                    [tipoArchivo]="'boletas'"
                                                ></view-file-button>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="dcBoletas"></tr>
                                    <tr style="cursor: pointer; " mat-row *matRowDef="let row; columns: dcBoletas; "></tr>
                                    <tr class="mat-row " *matNoDataRow>
                                        <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input2.value}} "</td>
                                    </tr>
                                </table>
                                <!-- <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="rolId !== 3" label="SOLPE">
                            <div class="tab-content mt-4">
                                <div class="col-12">
                                    <table class="table border">
                                        <thead class="">
                                            <th class="text-normal">Codigo</th>
                                            <th class="text-normal">Nombre</th>
                                            <th class="text-normal">Forma</th>
                                            <th class="text-normal">Tipo</th>
                                            <th class="text-normal">Fecha de Aprobacion</th>
                                            <th class="text-normal">Moneda</th>
                                            <th class="text-normal">Monto</th>
                                            <th class="text-normal"></th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of solpeContrato">
                                                <td>
                                                    {{ item.CODIGO_SOLPE }}
                                                </td>
                                                <td>
                                                    {{ item.NOMBRE_SOLPE }}
                                                </td>
                                                <td>
                                                    {{ item.FORMA_SOLPE }}
                                                </td>
                                                <td>
                                                    {{ item.TIPO_SOLPE }}
                                                </td>
                                                <td>
                                                    {{ item.FECHA_CIERRE | date:'dd/MM/yyyy' }}
                                                </td>
                                                <td>
                                                    {{ item.MONEDA }}
                                                </td>
                                                <td>
                                                    {{ item.MONTO | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}
                                                </td>
                                                <td>
                                                    <a routerLink="/detalle-solpe/{{item.SOLPE_CORRELATIVO}}" class="btn  rounded-icon" title="Revisar">
                                                        <i class="fa fa-eye"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Documentos">
                            <div class="tab-content mt-4 mb-4">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="tex-muted font-14">Documentos</p>
                                    </div>
                                </div>
                                <div class="col-12 text-right mb-4">
                                    <button *ngIf="existeAccion('1')" class="btn btn-primary" (click)="cargarModalArchivo(idContrato)">
                                        <span *ngIf="agregarArchivoVisible === false">Agregar documento</span>
                                        <span *ngIf="agregarArchivoVisible === true">Contraer</span>
                                    </button>
                                </div>
                                <br>
                                <div *ngIf="agregarArchivoVisible" class="container">
                                    <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                                        <div class="row form-group">
                                            <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                                            <div class="col-md-9">
                                                <select class="form-control" formControlName="grupo" #grupo [ngClass]="{ 'is-invalid': submittedDocumento && fd.grupo.errors}">
                                          <option value="0">Seleccione ...</option>
                                          <option *ngFor="let item of archivos" [value]="item.CORRELATIVO">{{item.GRUPO}}</option>
                                        </select>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                                            <div class="col-md-9">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                                    <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                                            <div class="col-md-9">
                                                <input type="text" formControlName="descripcion" #descripcion class="form-control" [ngClass]="{ 'is-invalid': submittedDocumento && fd.descripcion.errors}"/>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <label class="col-md-3 col-form-label" for="bg">Documento publico</label>
                                            <div class="col-md-9" style="padding-top: 10px;">
                                                <input type="checkbox" formControlName="isPublic" name="isPublic" #isPublic value="0" [ngClass]="{ 'is-invalid': submittedDocumento && fd.isPublic.errors}"/>
                                            </div>
                                        </div>
                                        <input type="hidden" name="corr_cont" formControlName="corr_cont" #corr_cont value="" />
                                        <br>
                                        <div class="row">
                                            <div class="col-md-12 text-right">
                                                <button class="btn btn-primary mr-4" type="submit">Agregar</button>
                                                <!-- <a href="javascript:;" (click)="onCloseArchivo()" #closeModalAgregarArchivo class="btn btn-light" data-dismiss="modal">Cerrar</a> -->
                                            </div>
                                        </div>
                                    </form>
                                    <br>
                                </div>
                                <ng-container *ngIf="archivosRegistrados">
                                    <div class="col-12">
                                        <table class="table border">
                                            <thead class="">
                                                <th></th>
                                                <th class="text-normal">Documento</th>
                                                <th class="text-normal"></th>
                                                <th class="text-normal">Fecha subida</th>
                                                <th class="text-normal">Usuario</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr class="" *ngFor="let item of archivosContrato">
                                                    <td *ngIf="rolId !== 3 || item.esPublico == 'S'">
                                                        <span class="pb-2 mt-2 ml-2 d-flex content-center">
                                                            <i class="far fa-file-alt mr-2 text-primary icons-primary" ></i>
                                                        </span>
                                                       </td>
                                                    <td *ngIf="rolId !== 3 || item.esPublico == 'S'">
                                                        <h5 class="pb-0 pt-2 mb-0" style="font-weight:normal;">
                                                            {{ item.descripcion }}
                                                        </h5>
                                                        <span class="text-muted pb-2">{{ item.GRUPO }}</span>
                                                    </td>
                                                    <td *ngIf="rolId !== 3 || item.esPublico == 'S'">
                                                        <span *ngIf="item.esPublico == 'S'" class="tab tab-green mb-2 mt-2">Publico</span>
                                                    </td>
                                                    <td *ngIf="rolId !== 3 || item.esPublico == 'S'">
                                                        <p class="text-muted pb-2 pt-2 mb-0">
                                                            {{ item.fecha_creacion | date }}
                                                        </p>
                                                    </td>
                                                    <td *ngIf="rolId !== 3 || item.esPublico == 'S'">
                                                        <p class="text-muted pb-2 pt-2 mb-0 c-case">
                                                            {{ item.usuario }}
                                                        </p>
                                                    </td>
                                                    <td *ngIf="rolId !== 3 || item.esPublico == 'S'" class="text-right">
                                                        <div style="display: flex; justify-content: right;">
                                                            <div class="btn rounded-icon mr-1" (click)="onDescargar(item.NOMBRE_ARCHIVO)">
                                                                <i class="fas fa-file-download"></i>
                                                            </div>
                                                            &nbsp;
                                                            <view-file-button
                                                                [nombreArchivo]="item.NOMBRE_ARCHIVO"
                                                                [tipoArchivo]="'contratos'"
                                                            ></view-file-button>
                                                            <div *ngIf="existeAccion('29')" class="btn rounded-icon" (click)="onDelete(item.NOMBRE_ARCHIVO, item.correlativo)">
                                                                <i class="fas fa-trash"></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="rolId !== 5 && rolId !== 4" label="Contratos del Proveedor">
                            <div class="tab-content mt-4">
                                <div class="col-12">
                                    <table class="table border">
                                        <thead class="">
                                            <th class="text-normal">Codigo</th>
                                            <th class="text-normal">Nombre</th>
                                            <th class="text-normal">Administrador EMSA</th>
                                            <th class="text-normal">Administrador EC</th>
                                            <th class="text-normal">Monto</th>
                                            <th class="text-normal"></th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of contratosProveedor">
                                                <td>
                                                    <p class="mb-0">
                                                        {{ item.CODIGO_CONTRATO }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p class="mb-0">
                                                        {{ item.NOMBRE_CONTRATO }}
                                                    </p>
                                                    <div *ngIf="item.ESTADO_CORRELATIVO == 30" class="tab tab-green">{{item.ESTADO_CONTRATO}}</div>
                                                    <div *ngIf="item.ESTADO_CORRELATIVO == 31" class="tab tab-yellow">{{item.ESTADO_CONTRATO}}</div>
                                                    <div *ngIf="item.ESTADO_CORRELATIVO == 32" class="tab tab-red">{{item.ESTADO_CONTRATO}}</div>
                                                    <div *ngIf="item.ESTADO_CORRELATIVO == 33" class="tab tab-red">{{item.ESTADO_CONTRATO}}</div>
                                                    <div *ngIf="item.ESTADO_CORRELATIVO == 138" class="tab tab-red">{{item.ESTADO_CONTRATO}}</div>
                                                </td>
                                                <td>
                                                    {{ item.NOMBRE_ADMIN_EMSA }}
                                                </td>
                                                <td>
                                                    {{ item.NOMBRE_ADMIN_EC }}
                                                </td>
                                                <td>
                                                    {{item.MONTO_CONTRATO | currency:item?.ISOCode:'':item?.decimalPipe: 'es-CL'}} {{item.TIPO_MONEDA}}
                                                </td>
                                                <td>
                                                    <a *ngIf="existeAccion('27') || item.CORRELATIVO_ADMIN_EC == userId" class="btn rounded-icon" routerLink="/detalle-contrato/{{item.CONT_CORRELATIVO}}/{{item.SOLPE_CORRELATIVO}}" title="Ver Detalle">
                                                        <i class="fas fa-eye"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="dataContrato">
            <!-- checklist contrato -->
            <div *ngIf="rolId == 1">
                <div *ngIf="contratoPublicado === 0" class="bg-white p3-rem rounded mb-4">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="d-flex justify-space-between">
                                <h5>
                                    Checklist Nuevo Contrato
                                </h5>
                                <div class="btn rounded-icon" (click)="onSyncContratoChecklist()">
                                    <!-- btn border rounded-circle -->
                                    <i class="fas fa-sync"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <p class="text-muted">Debe verificar todos los puntos antes de enviar las notificaciones a los administradores de contrato</p>
                        </div>
                    </div>
                    <!-- <div class="row mb-3">
                        <div class="col-12">
                            <div *ngIf="checkCompletado == true" class="tab tab-red">
                                Pendiente
                            </div>
                            <div *ngIf="checkCompletado == false" class="tab tab-green">
                                Completado
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12 mt-3 mb-4">
                            <!-- checklist  -->
                            <p>
                                <i *ngIf="checkAdminEmsa === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkAdminEmsa === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Adminitrador de contratos EMSA</span>
                            </p>
                            <p>
                                <i *ngIf="checkAdminEECC === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkAdminEECC === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Adminitrador de contratos EECC</span>
                            </p>
                            <hr>
                            <p>
                                <i *ngIf="checkItemizado === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkItemizado === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Itemizado</span>
                            </p>
                            <p>
                                <i *ngIf="checkArticulos === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkArticulos === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Articulos</span>
                            </p>
                            <p>
                                <i *ngIf="checkItemizadoAprobado === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkItemizadoAprobado === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Itemizado Aprobado</span>
                            </p>
                            <hr>
                            <p>
                                <i *ngIf="checkCartaAdjudicacion === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkCartaAdjudicacion === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Carta de adjudicacion</span>
                            </p>
                            <p>
                                <i *ngIf="checkContratoFirmado === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkContratoFirmado === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Contrato firmado</span>
                            </p>
                            <!-- <p>
                                <i *ngIf="checkActaInicio === true" class="fas fa-check-circle icon-18 text-success"></i>
                                <i *ngIf="checkActaInicio === false" class="fas fa-circle text-muted icon-18"></i>
                                <span class="pl-4">Acta de Inicio</span>
                            </p> -->
                        </div>
                        <div class="col-12 text-right">
                            <button class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg" [disabled]="checkCompletado">
                                Enviar notificación
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Datos del contrato  -->
            <div class="bg-white p3-rem rounded">
                <!-- tabla -->
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <p class="text-muted text-right">
                            {{dataContrato.TIPO_MONEDA}}
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="text-muted text-right">
                            USD
                        </p>
                    </div>
                    <div class="col-2">
                        <p class="text-muted text-right">
                            % Part
                        </p>
                    </div>
                </div>
                <div class="border p-10 rounded">
                    <div class="row">
                        <div class="col-2">
                            <span class="text-muted">
                                Original
                            </span>
                        </div>
                        <div class="col-4">
                            <p class="text-right">
                                {{dataContrato?.MONTO_ORIGINAL | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right">
                                {{ dataContrato?.MONTO_USD | currency: this.GloblaDataService.USD?.ISOCode: '': this.GloblaDataService.USD?.decimalPipe : 'es-CL' }}
                            </p>
                        </div>
                        <div class="col-2">
                            <p class="text-right">-</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <span class="text-muted">
                                Modif
                            </span>
                        </div>
                        <div class="col-4">
                            <p class="text-right mb-0">
                                {{ dataContrato?.MONTO_MODIF | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right mb-0">
                                {{ dataContrato?.MONTO_MODIF_USD | currency: this.GloblaDataService.USD?.ISOCode: '': this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-2">
                            <p class="text-right">
                                {{ dataContrato?.PORCENTAJE_MODIF | number: '1.0-0' : 'es-CL'}} %
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <span class="text-muted">
                                Act
                            </span>
                        </div>
                        <div class="col-4">
                            <p class="text-right">
                                {{dataContrato.MONTO_ACTUALIZADO | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right">
                                {{ dataContrato?.MONTO_ACTUALIZADO_USD | currency: this.GloblaDataService.USD?.ISOCode: '': this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-2">
                            <p class="text-right">
                                -
                            </p>
                        </div>
                    </div>
                </div>
                <div class="border p-10 rounded mt-2">
                    <div class="row">
                        <div class="col-2">
                            <span class="text-muted">
                                Reajuste
                            </span>
                        </div>
                        <div class="col-4">
                            <p class="text-right">
                                {{dataContrato?.MONTO_REAJUSTE | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right">
                                {{ dataContrato?.REAJUSTE_USD | currency: this.GloblaDataService.USD?.ISOCode: '': this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-2">
                            <p class="text-right">
                                {{ dataContrato?.PORCENTAJE_REAJUSTE | number: '1.0-0' : 'es-CL' }} %
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <span class="text-muted">
                                EP
                            </span>
                        </div>
                        <div class="col-4">
                            <p class="text-right text-danger">
                                {{dataContrato.MONTO_EP | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right text-danger">
                                {{ dataContrato?.MONTO_EP_USD | currency: this.GloblaDataService.USD?.ISOCode: '': this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-2">
                            <p class="text-right text-danger">
                                {{ dataContrato?.PORCENTAJE_EP | number: '1.0-0' : 'es-CL'}} %
                            </p>
                        </div>
                    </div>

                    <!-- <div class="row border-top">
                        <div class="col-2">
                            <p class="text-muted mb-0 mt-2">
                                Saldo Rea
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right mb-0 text-green mt-2">
                                {{ dataContrato?.MONTO_SALDO_REAJUSTE | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right mb-0 text-green mt-2">
                                {{ dataContrato?.MONTO_SALDO_USD_REAJUSTE | currency: this.GloblaDataService.USD?.ISOCode: '': this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-2">
                            <p class="text-right text-green mt-2 mb-0">
                                {{ dataContrato?.PORCENTAJE_SALDO_REAJUSTE | number: '1.0-0' : 'es-CL'}} %
                            </p>
                        </div>
                    </div> -->

                    <div class="row border-top">
                        <div class="col-2">
                            <p class="text-muted mb-0 mt-2">
                                Saldo
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right mb-0 text-green mt-2">
                                {{ dataContrato?.MONTO_SALDO | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-right mb-0 text-green mt-2">
                                {{ dataContrato?.MONTO_SALDO_USD | currency: this.GloblaDataService.USD?.ISOCode: '': this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}
                            </p>
                        </div>
                        <div class="col-2">
                            <p class="text-right text-green mt-2 mb-0">
                                {{ dataContrato?.PORCENTAJE_SALDO | number: '1.0-0' : 'es-CL'}} %
                            </p>
                        </div>
                        <div class="col-12">
                            <p class="text-muted mb-0 mt-2">
                               Nota: Saldo = Monto disponible del contrato sin Reajuste
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Avance del contrato -->
            <div class="bg-white p3-rem rounded mt-4">
                <div class="row mb-4">
                    <div class="col-12">
                        <div class="d-flex justify-space-between">
                            <h5>
                                Avance del contrato
                            </h5>
                            <div *ngIf="tipoVencimiento == 1" class="tab tab-red">
                                Venc Monto
                            </div>
                            <div *ngIf="tipoVencimiento == 2" class="tab tab-green">
                                Venc Plazo
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!-- avances -->
                        <div class="d-inline-flex flex-column mr-5 mb-4">
                            <span class="text-muted">Inicio</span>
                            <span>{{dataContrato.FECHA_INICIO_CONTRATO}}</span>
                        </div>

                        <div class="d-inline-flex flex-column mr-5 mb-4">
                            <span class="text-muted">Termino Actual</span>
                            <span>{{dataContrato.FECHA_TERMINO_CONTRATO}}</span>
                        </div>

                        <div class="d-inline-flex flex-column mr-5 mb-4">
                            <span class="text-muted">Termino Original</span>
                            <span>{{dataContrato.FECHA_TERMINO_ORIGINAL}}</span>
                        </div>

                        <div class="d-inline-flex flex-column mr-5 mb-4">
                            <span class="text-muted">Termino Proyectada</span>
                            <span>{{dataContrato.FECHA_TERMINO_PROYECTADA}}</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 font-14 mt-2">
                        <div class="row">
                            <div class="col-12 col-md-12 mb-4">
                                <div class="row">
                                    <div class="col-4">
                                        <span class="text-muted">Avance de tiempo</span>
                                    </div>
                                    <div class="col-8 text-right">
                                        <span class="">
                                            <span>{{avanceTiempo?.PORCENTAJE}}%</span>
                                            <span>
                                                ({{avanceTiempo?.DIAS_RESTANTES}} dias restantes)
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="progress rounded-corner progress-striped active ">
                                            <div *ngIf="avanceTiempo?.PORCENTAJE <= 0" class="progress-bar bg-orange" style="width: 0%">
                                                <!-- 0% -->
                                            </div>
                                            <div *ngIf="avanceTiempo?.PORCENTAJE > 0" class="progress-bar bg-orange" [ngStyle]="{'width': avanceTiempo?.PORCENTAJE+'%' }">
                                                <!-- {{avanceTiempo?.PORCENTAJE}}% -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-12 mb-4">
                                <div class="row">
                                    <div class="col-4">
                                        <span class="text-muted">Avance financiero</span>
                                    </div>
                                    <div class="col-8 text-right">
                                        <span class="" style="text-align: right;">
                                            {{avanceFinanciero?.PORCENTAJE}}% ({{(avanceFinanciero?.MONTO) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}} Saldo)
                                        </span>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="progress rounded-corner progress-striped active">
                                            <div *ngIf="avanceFinanciero?.PORCENTAJE == 0" class="progress-bar bg-0" style="width: 100%">
                                                <!-- 0% -->
                                            </div>
                                            <div class="progress-bar bg-orange" [ngStyle]="{'width': avanceFinanciero?.PORCENTAJE+'%' }">
                                                <!-- {{avanceFinanciero?.PORCENTAJE}}% -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="row border p-5">
                                    <div class="col-4 pt-2 pb-2">
                                        <span>
                                            <p class="text-muted mb-0">Total</p>
                                            {{avanceTiempo?.MESES_TOTALES}} Meses Ctt
                                        </span>
                                    </div>
                                    <div class="col-4 pt-2 pb-2">
                                        <span>
                                            <p class="text-muted mb-0">Avance</p>
                                            {{avanceTiempo?.MESES_TRANSCURRIDOS}} Meses Ctt
                                        </span>
                                    </div>
                                    <div class="col-4 pt-2 pb-2">
                                        <span>
                                            <p class="text-muted mb-0">Restan</p>
                                            {{avanceTiempo?.MESES_RESTANTES}} Meses Ctt
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>



    <div class="modal modal-message" id="modalAdec" >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="width: 80%;">
                    <h4 class="modal-title">Administrador Contratista</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body" style="width: 80%;">
                    <h4 class="modal-title">Detalle Contrato {{dataContrato?.CODIGO_CONTRATO}}</h4>
                    <div class="row">
                        <div class="col-md-12">

                            <table mat-table [dataSource]="dsAdecContrato" matSort class="table table-striped border table-td-valign-middle table-th-valign-middle">
                                <ng-container matColumnDef="AdminContratista">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="AdminContratista">Admin. Contratista</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_ADEC }}</td>
                                </ng-container>
                                <ng-container matColumnDef="FechaInicio">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaInicio">Fecha Inicio</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_INICIO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaTermino">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaTermino">Fecha Termino</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_TERMINO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Vigente">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Vigente">Vigente</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.VIGENTE ==='S' ? 'Si' : 'No' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="UsuarioActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="UsuarioActualizacion">Usuario Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_CREACION}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaActualizacion">Fecha Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_CREACION | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="dsHeaderAdecContrato"></tr>
                                <tr mat-row *matRowDef="let row; columns: dsHeaderAdecContrato;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                            </table>

                            <!-- <mat-paginator #paginatorAdec [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-12 text-right">
                            <a href="javascript:;" class="btn tag" data-dismiss="modal">
                                Cerrar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-message" id="modalAdecEmsa" >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="width: 80%;">
                    <h4 class="modal-title">Administrador EMSA</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body" style="width: 80%;">
                    <div class="row" style="margin-bottom: 10px;">
                        <div class="col-md-6">
                            <h4 class="modal-title">Detalle Contrato {{dataContrato?.CODIGO_CONTRATO}} </h4>
                        </div>
                        <div class="col-md-6" style="text-align: end;">
                            <button class="btn btn-warning" style="margin-left: 30px;" (click)="showUpdateEmsa = !showUpdateEmsa">
                                <i class="fas fa-user-secret"></i>
                                Admin. EMSA </button>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 20px;" *ngIf="showUpdateEmsa">
                        <div class="col-md-3" style="text-align: left;padding-top: 5px;">
                            <label>Administrador Emsa</label>
                        </div>
                        <div class="col-md-7">
                            <ng-select2 [data]="ademsa" [value]="0" width="100%" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submittedEmsa && f.ademsa.errors}" (valueChanged)='onGetAdemsaUpdateId($event)'>
                            </ng-select2>
                            <div *ngIf="submittedEmsa && f.ademsa.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>

                        </div>
                        <div class="col-md-2" style="text-align: end;">
                            <button class="btn btn-success" (click)="onGuardarAdminEmsa()">
                                <i class="fas fa-save"></i> Guardar</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table mat-table [dataSource]="dsAdecEmsaContrato" matSort class="table table-striped border table-td-valign-middle table-th-valign-middle">
                                <ng-container matColumnDef="AdminEmsa">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="AdminEmsa">Admin. Emsa</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_EMSA }}</td>
                                </ng-container>
                                <ng-container matColumnDef="FechaInicio">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaInicio">Fecha Inicio</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_INICIO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaTermino">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaTermino">Fecha Termino</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_TERMINO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Vigente">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Vigente">Vigente</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.VIGENTE ==='S' ? 'Si' : 'No' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="UsuarioActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="UsuarioActualizacion">Usuario Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_CREACION}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaActualizacion">Fecha Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_CREACION | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="dsHeaderAdecEmsaContrato"></tr>
                                <tr mat-row *matRowDef="let row; columns: dsHeaderAdecEmsaContrato;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                            </table>
                            <!-- <mat-paginator #paginatorAdecEmsa [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10"></div>
                        <div class="col-md-2">
                            <a href="javascript:;" class="btn btn-light btn-block" data-dismiss="modal">
                                <i class="fas fa-window-close"></i> Cerrar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="modalFiniquito" >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Finiquito de contrato</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeModalAgregar>×</button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="formFiniquito" (ngSubmit)="onAgregarFiniquito()" novalidate>
                        <div class="row form-group">
                            <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                            <div class="col-md-9">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" 
                                    aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && this.formFiniquito.controls.adjunto.errors}">
                                    <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="descripcion" class="col-md-3 col-form-label">Observacion de cierre</label>
                            <div class="col-md-9">
                                <input type="text" formControlName="observacion" #observacion class="form-control" 
                                [ngClass]="{ 'is-invalid': submittedDocumento && this.formFiniquito.controls.observacion.errors}"/>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="estado" class="col-md-3 col-form-label">Estado de cierre</label>
                            <div class="col-md-9">
                                <select class="form-control" formControlName="estado" #estado
                                    [ngClass]="{ 'is-invalid': submittedDocumento && this.formFiniquito.controls.estado.errors}">
                                    <option value="32">Finiquito</option>
                                    <option value="33">Cancelado</option>
                                </select>
                            </div>
                        </div>
                        <input type="hidden" name="corr_cont" formControlName="corr_cont" #corr_cont value="" />
                        <input type="hidden" name="grupo" formControlName="grupo" #grupo value="7" />
                        <input type="hidden" name="isPublic" formControlName="isPublic" #isPublic value="0" />
                        <br>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-primary mr-4" type="submit">Agregar</button>
                                <!-- <a href="javascript:;" (click)="onCloseArchivo()" #closeModalAgregarArchivo class="btn btn-light" data-dismiss="modal">Cerrar</a> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- modal envio de correo de creacion de contratos -->
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Notificación de creación de Contrato</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>La notificación de creación de contrato será emitida a los siguientes destinatarios:</p>
                <div class="tab-content mt-4">
                    <div class="col-12">
                        <table class="table border">
                            <thead class="">
                                <th class="text-normal">Usuario</th>
                                <th class="text-normal">Correo</th>
                                <th class="text-normal">Descripcion</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let usuario of usuariosEnvioCorreoCreacionList">
                                    <td>
                                        <p class="mb-0 text-muted">
                                            {{ usuario.nombre_usuario }}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="mb-0 text-muted">
                                            {{ usuario.email_usuario }}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="mb-0 text-muted">
                                            {{ usuario.cargo_usuario }}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="onPublicarContrato()" data-dismiss="modal">Enviar correos</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal aplicar modificacion de contrato-->
<div class="modal fade" id="aplicarModificacionContratoModal" tabindex="-1" role="dialog" aria-labelledby="aplicarModificacionContratoModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="aplicarModificacionContratoModalLabel">Aplicar modificación de contrato</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Desea aplicar esta modificación al contrato?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aplicarModificacion(modificacionContratoCorrelativo)">Aplicar</button>
        </div>
      </div>
    </div>
</div>
