import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface RegistraEstadoPagoResponse {
  id: number;
  isNew: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EstadoPagoService {

  constructor(private http: HttpClient) { }
  private urlService: string = environment.apiUrl;

  getEstadosPago(in_cont_correlativo: number, in_corr_usuario: number, in_corr_cargo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getEstadosPagos/' + in_cont_correlativo + '/' + in_corr_usuario + '/' + in_corr_cargo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }
  getEstadosPagoIncurrido(in_cont_correlativo: number, in_corr_usuario: number, in_corr_cargo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getEstadosPagosIncurrido/' + in_cont_correlativo + '/' + in_corr_usuario + '/' + in_corr_cargo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let eps = [];
          for (const item in resp['eps']) {
            eps.push({ ...resp['eps'][item] });
          }
          return JSON.stringify(eps);
        }));
  }
  getItemizadoDetalle(in_cont_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getItemizadoDetalle/' + in_cont_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let itemizado = [];
          for (const item in resp['itemizado']) {
            itemizado.push({ ...resp['itemizado'][item] });
          }
          return JSON.stringify(itemizado);
        }));
  }
  getItemizadoDetalleMod(in_cont_correlativo: number, in_solpe_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getItemizadoDetalleMod/' + in_cont_correlativo + '/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let itemizado = [];
          for (const item in resp['itemizado']) {
            itemizado.push({ ...resp['itemizado'][item] });
          }
          return JSON.stringify(itemizado);
        }));
  }
  registraEstadoPagoIncurrido(postData: {
    in_cont_correlativo: number,
    iv_descripcion: string,
    iv_periodo_desde: string,
    iv_periodo_hasta: string,
    in_reajuste: number,
    in_usuario_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usuario_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postEstadoPagoIncurrido', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let ep = [];
          for (const item in resp['ep']) {
            ep.push({ ...resp['ep'][item] });
          }
          return JSON.stringify(ep);
        }));

  }
  registraDetalleEstadoPagoIncurrido(postData: {
    in_ep_correlativo: number,
    in_id_correlativo: number,
    in_solicita_cantidad: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/postDetalleEstadoPagoIncurrido', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let ep = [];
          for (const item in resp['ep']) {
            ep.push({ ...resp['ep'][item] });
          }
          return JSON.stringify(ep);
        }));
  }
  async registraEstadoPago(postData: {
    idContrato: number,
    descripcion: string,
    desde: string,
    hasta: string,
    reAjuste: number,
    detalle: Array<any>
  }) {
    const result = await this.http.post<RegistraEstadoPagoResponse>(
      this.urlService + 'estadoPago/postEstadoPago',
      postData
    ).toPromise();

    return result
  }
  registraDetalleEstadoPago(postData: {
    in_ep_correlativo: number,
    in_id_correlativo: number,
    in_solicita_cantidad: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/postDetalleEstadoPago', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let ep = [];
          for (const item in resp['ep']) {
            ep.push({ ...resp['ep'][item] });
          }
          return JSON.stringify(ep);
        }));
  }
  registraRetencionEstadoPago(postData: {
    in_ep_correlativo: number,
    in_retencion_laboral: number,
    in_retencion_contractual: number,
    in_domi_correlativo_tirecon: number,
    in_domi_timo_correlativo: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRetencionEstadoPago', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let ep = [];
          for (const item in resp['ep']) {
            ep.push({ ...resp['ep'][item] });
          }
          return JSON.stringify(ep);
        }));
  }
  getGruposChk(in_cont_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getGruposChk/' + in_cont_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let checklist = [];
          for (const item in resp['checklist']) {
            checklist.push({ ...resp['checklist'][item] });
          }
          return JSON.stringify(checklist);
        }));
  }
  asociaItemContrato(postData: {
    in_cont_correlativo: number,
    in_itemchk_correlativo: number,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRelacionaContratoItem', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grupo = [];
          for (const item in resp['grupo']) {
            grupo.push({ ...resp['grupo'][item] });
          }
          return JSON.stringify(grupo);
        }));
  }
  getItemRelacionadosContrato(in_cont_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getGruposChkContrato/' + in_cont_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grupo = [];
          for (const item in resp['grupo']) {
            grupo.push({ ...resp['grupo'][item] });
          }
          return JSON.stringify(grupo);
        }));
  }
  eliminaRelacionContratoItem(postData: {
    in_cont_correlativo: number,
    in_itemchk_correlativo: number,
    in_usua_correlativo_creacion: number,
    iv_estado: string
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/eliminaRelacionContratoItem', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grupo = [];
          for (const item in resp['grupo']) {
            grupo.push({ ...resp['grupo'][item] });
          }
          return JSON.stringify(grupo);
        }));
  }
  getGruposMantenedor() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getGruposMantenedor', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grupos = [];
          for (const item in resp['grupos']) {
            grupos.push({ ...resp['grupos'][item] });
          }
          return JSON.stringify(grupos);
        }));
  }
  getItemsMantenedor(in_grupochk_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getItemsMantenedor/' + in_grupochk_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let items = [];
          for (const item in resp['items']) {
            items.push({ ...resp['items'][item] });
          }
          return JSON.stringify(items);
        }));
  }
  creaGrupoChk(postData: {
    iv_nombre: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postCreaGrupoChk', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grupo = [];
          for (const item in resp['grupo']) {
            grupo.push({ ...resp['grupo'][item] });
          }
          return JSON.stringify(grupo);
        }));
  }
  creaItemChk(postData: {
    in_grupo_correlativo: number,
    iv_nombre: string,
    iv_obligatorio: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postCreaItemChk', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let items = [];
          for (const item in resp['items']) {
            items.push({ ...resp['items'][item] });
          }
          return JSON.stringify(items);
        }));
  }
  eliminarGrupoMantenedor(in_grupochk_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    let in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'estadoPago/deleteGrupoMantenedor/' + in_grupochk_correlativo + '/' + in_usua_correlativo_creacion, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let items = [];
          for (const item in resp['items']) {
            items.push({ ...resp['items'][item] });
          }
          return JSON.stringify(items);
        }));
  }
  eliminarItemMantenedor(in_item_grupochk_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    let in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'estadoPago/deleteItemMantenedor/' + in_item_grupochk_correlativo + '/' + in_usua_correlativo_creacion, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let items = [];
          for (const item in resp['items']) {
            items.push({ ...resp['items'][item] });
          }
          return JSON.stringify(items);
        }));
  }
  modificaGrupoChk(postData: {
    in_grupochk_correlativo: number,
    iv_nombre: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/putGrupoChk', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grupo = [];
          for (const item in resp['grupo']) {
            grupo.push({ ...resp['grupo'][item] });
          }
          return JSON.stringify(grupo);
        }));
  }
  modificaItemChk(postData: {
    in_item_grupochk_correlativo: number,
    iv_valor_nombre: string,
    iv_valor_obligatorio: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/putItemChk', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grupo = [];
          for (const item in resp['grupo']) {
            grupo.push({ ...resp['grupo'][item] });
          }
          return JSON.stringify(grupo);
        }));
  }
  getCabeceraEP(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getCabeceraEP/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let ep = [];
          for (const item in resp['ep']) {
            ep.push({ ...resp['ep'][item] });
          }
          return JSON.stringify(ep);
        }));
  }
  getDetalleEP(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getDetalleEP/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let detalleEP = [];
          for (const item in resp['detalleEP']) {
            detalleEP.push({ ...resp['detalleEP'][item] });
          }
          return JSON.stringify(detalleEP);
        }));
  }
  getComprobanteEP(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getComprobanteEP/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let detalleEP = [];
          for (const item in resp['detalleEP']) {
            detalleEP.push({ ...resp['detalleEP'][item] });
          }
          return JSON.stringify(detalleEP);
        }));
  }
  getRetencionEP(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getRetencionEP/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let retencionEP = [];
          for (const item in resp['retencionEP']) {
            retencionEP.push({ ...resp['retencionEP'][item] });
          }
          return JSON.stringify(retencionEP);
        }));
  }
  getChecklistEP(in_cont_correlativo: number, in_corr_ep: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getChecklistEP/' + in_cont_correlativo + '/' + in_corr_ep, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let check = [];
          for (const item in resp['check']) {
            check.push({ ...resp['check'][item] });
          }
          return JSON.stringify(check);
        }));
  }
  registraDocItemChk(postData: {
    in_item_chk_correlativo: number,
    iv_nombre_archivo: string,
    in_usua_correlativo_creacion: number,
    in_ep_correlativo: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRegistraDocCheck', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let items = [];
          for (const item in resp['items']) {
            items.push({ ...resp['items'][item] });
          }
          return JSON.stringify(items);
        }));
  }
  registraDocEpIncurrido(postData: {
    in_ep_correlativo: number,
    iv_nombre_archivo: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRegistraDocEpIncurrido', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let items = [];
          for (const item in resp['items']) {
            items.push({ ...resp['items'][item] });
          }
          return JSON.stringify(items);
        }));
  }
  /** @in_domi_correlativo_estadoep: 100 creacion, 101 pre-aprobacion */
  registraEstadoEP(postData: {
    in_ep_correlativo: number,
    in_domi_correlativo_estadoep: number,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postEstadoEP', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let estado = [];
          for (const item in resp['estado']) {
            estado.push({ ...resp['estado'][item] });
          }
          return JSON.stringify(estado);
        }));
  }
  getComentarios(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getComentarios/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let comentarios = [];
          for (const item in resp['comentarios']) {
            comentarios.push({ ...resp['comentarios'][item] });
          }
          return JSON.stringify(comentarios);
        }));
  }
  registraComentarios(postData: {
    in_ep_correlativo: number,
    iv_comentario: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postComentario', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let comentario = [];
          for (const item in resp['comentario']) {
            comentario.push({ ...resp['comentario'][item] });
          }
          return JSON.stringify(comentario);
        }));
  }
  getEstadosEP(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getEstadosEP/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let estados = [];
          for (const item in resp['estados']) {
            estados.push({ ...resp['estados'][item] });
          }
          return JSON.stringify(estados);
        }));
  }
  registraDistribucionEP(postData: {
    in_ep_correlativo: number,
    in_itemchk_correlativo: number,
    in_articulo_correlativo: number,
    in_zona_correlativo: number,
    in_blanco_correlativo: number,
    in_grafos_correlativo: number,
    in_cantidad_asignado: number,
    in_monto_asignado: number,
    iv_vigente: string
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/postDistribucionEP', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let distribucion = [];
          for (const item in resp['distribucion']) {
            distribucion.push({ ...resp['distribucion'][item] });
          }
          return JSON.stringify(distribucion);
        }));
  }
  getDistribucionEP(in_ep_correlativo: number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    let in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'estadoPago/getDistribucionEP/' + in_ep_correlativo + '/' + in_cargo_correlativo + '/' + in_usua_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let distribucion = [];
          for (const item in resp['distribucion']) {
            distribucion.push({ ...resp['distribucion'][item] });
          }
          return JSON.stringify(distribucion);
        }));
  }
  actualizaEstadoDistribucionEP(postData: {
    in_dist_ep_correlativo: number,
    iv_vigente: string;
    in_ep_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/putEstadoDistribucionEP', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let distribucion = [];
          for (const item in resp['distribucion']) {
            distribucion.push({ ...resp['distribucion'][item] });
          }
          return JSON.stringify(distribucion);
        }));
  }
  getComprobanteContable(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    let in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;

    console.log(in_ep_correlativo);

    return this.http
      .get(this.urlService + 'addons/getFileComprobanteContable/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          // let distribucion = [];
          // for (const item in resp['distribucion']) {
          //   distribucion.push({...resp['distribucion'][item] });
          // }
          // return JSON.stringify(distribucion);

          return resp;
        }));
  }
  relacionaHES_EP(postData: {
    in_ep_correlativo: number,
    iv_hes_codigo: string,
    in_usuario_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usuario_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRelacionaHES', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let hes = [];
          for (const item in resp['hes']) {
            hes.push({ ...resp['hes'][item] });
          }
          return JSON.stringify(hes);
        }));
  }
  relacionaEPFacturacion(postData: {
    in_ep_correlativo: number,
    in_nro_factura: number,
    iv_fecha_pago: string,
    iv_nombre_archivo: string,
    in_usua_correlativo: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRegistraEPFacturacion', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let facturacion = [];
          for (const item in resp['facturacion']) {
            facturacion.push({ ...resp['facturacion'][item] });
          }
          return JSON.stringify(facturacion);
        }));
  }
  editaDistribucion(postData: {
    in_ep_distribucion_correlativo: number,
    in_cantidad_asignado: number,
    in_monto_asignado: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/putEditaDistribucion', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let distribucion = [];
          for (const item in resp['distribucion']) {
            distribucion.push({ ...resp['distribucion'][item] });
          }
          return JSON.stringify(distribucion);
        }));
  }
  getContratosItemizado(in_usua_correlativo: number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getContratosItemizado/' + in_usua_correlativo + '/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let itemizado = [];
          for (const item in resp['itemizado']) {
            itemizado.push({ ...resp['itemizado'][item] });
          }
          return JSON.stringify(itemizado);
        }));
  }
  postRegItemizadoArticulo(postData: {
    in_detalle_itemizado: number,
    in_articulo_correlativo: number,
    in_usua_correlativo_creacion: number,
    iv_origen: string
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRegItemizadoArticulo', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let registra = [];
          for (const item in resp['registra']) {
            registra.push({ ...resp['registra'][item] });
          }
          return JSON.stringify(registra);
        }));
  }
  postRegistraEstadoItemizado(postData: {
    in_item_correlativo: number,
    corr_contrato: number,
    in_domi_correlativo_esdo_itemizado: number,
    in_usua_correlativo_creacion: number,
    corr_solpe: number;
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postRegistraEstadoItemizado', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let registra = [];
          for (const item in resp['registra']) {
            registra.push({ ...resp['registra'][item] });
          }
          return JSON.stringify(registra);
        }));
  }
  postEnviaCorreoEP(postData: {
    pin_ep_correlativo: number,
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postEnviaCorreoEP', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          /* let registra = [];
          for (const item in resp['registra']) {
            registra.push({...resp['registra'][item] });
          }
          return JSON.stringify(registra); */
          return JSON.stringify(resp['message']);
        }));
  }
  postReenviaGrafosEP(postData: {
    pin_ep_correlativo: number,
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'estadoPago/postReenviaGrafosEP', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          /* let registra = [];
          for (const item in resp['registra']) {
            registra.push({...resp['registra'][item] });
          }
          return JSON.stringify(registra); */
          return JSON.stringify(resp['message']);
        }));
  }
  getItemizadosAprobados(iv_tipo_retorno: any) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getItemizadosAprobados/' + iv_tipo_retorno, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let itemizado = [];
          for (const item in resp['itemizado']) {
            itemizado.push({ ...resp['itemizado'][item] });
          }
          return JSON.stringify(itemizado);
        }));
  }
  getSolpeModItemizado(in_usua_correlativo: number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getSolpeModItemizado/' + in_usua_correlativo + '/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let itemizado = [];
          for (const item in resp['itemizado']) {
            itemizado.push({ ...resp['itemizado'][item] });
          }
          return JSON.stringify(itemizado);
        }));
  }

  getTipoEp(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getTipoEp/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let tipo_ep = [];
          for (const item in resp['tipo_ep']) {
            tipo_ep.push({ ...resp['tipo_ep'][item] });
          }
          return JSON.stringify(tipo_ep);
        }));
  }
  getArchivosEPIncurrido(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getArchivosEPIncurrido/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let archivos = [];
          for (const item in resp['archivos']) {
            archivos.push({ ...resp['archivos'][item] });
          }
          return JSON.stringify(archivos);
        }));
  }
  getDistribucionEPComentarios(in_ep_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'estadoPago/getDistribucionEPComentarios/' + in_ep_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let comentarios = [];
          for (const item in resp['comentarios']) {
            comentarios.push({ ...resp['comentarios'][item] });
          }
          return JSON.stringify(comentarios);
        }));
  }
  postDistribucionEPComentario(postData: {
    in_ep_distibucion_correlativo: number,
    in_comment: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    postData.in_usua_correlativo_creacion = 0;
    return this.http
      .post(
        this.urlService + 'estadoPago/postDistribucionEPComentario', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let registra = [];
          for (const item in resp['registra']) {
            registra.push({ ...resp['registra'][item] });
          }
          return JSON.stringify(registra);
        }));
  }
  postExchangeRateEstadoPago(postData: {
    pin_ep_correlativo: number,
    pin_ExchangeRate: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    //postData.in_usua_correlativo_creacion = 0;
    return this.http
      .post(
        this.urlService + 'estadoPago/postExchangeRateEstadoPago', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let registra = [];
          /* for (const item in resp['registra']) {
            registra.push({...resp['registra'][item] });
          } */
          return JSON.stringify(resp);
        }));
  }


  eliminaLineaDistribucionEP(
    postData: {
      correlativo_distribucion: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/putEliminarLineaDistribucion', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp['message']);
        }));
  }
  getEpList(showAll: boolean) {
    return this.http
      .get(this.urlService + 'estadoPago/getEpList/' + showAll, {
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }

  postUpdateCantidad(postData: {
    pin_ep_det_correlativo: number,
    pin_cantidad: number
  })
  {
    return this.http
      .post(
        this.urlService + 'estadoPago/postUpdateCantidad', postData)
      .pipe(
        map(resp => {
          let registra = [];
          /* for (const item in resp['registra']) {
            registra.push({...resp['registra'][item] });
          } */
          return JSON.stringify(resp);
        }));
  }
  updateCabeceraEP(postData: {
    cont_correlativo: number,
    descripcion: string,
    periodo_desde: string,
    periodo_hasta: string,
    reajuste: number,
    ExchageRate: number,
    correlativo: number
  })
  {
    return this.http
      .post(
        this.urlService + 'estadoPago/updateCabeceraEP', postData)
      .pipe(
        map(resp => {
          let registra = [];
          /* for (const item in resp['registra']) {
            registra.push({...resp['registra'][item] });
          } */
          return JSON.stringify(resp);
        }));
  }


  postEstadoPagoDetalle(postData: {
    correlativo: number,
    ep_correlativo: number,
    cantidad: number,
    idCorrelativo: number,
    vigente: string
  })
  {
    return this.http
      .post(
        this.urlService + 'estadoPago/postEstadoPagoDetalle', postData)
      .pipe(
        map(resp => {
          let registra = [];
          /* for (const item in resp['registra']) {
            registra.push({...resp['registra'][item] });
          } */
          return JSON.stringify(resp);
        }));
  }

  putUpdateEstadoFileEp(
    postData: {
      checkedFile: number,
      correlativoFile: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'estadoPago/putUpdateEstadoFileEp', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }


  getExchageRateTaxes(correlativo: number) {
    return this.http
      .get(this.urlService + 'estadoPago/getExchageRateTaxes/' + correlativo, {
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }

  postRetornaDocumentosGaratiaContrato(
    postData: {
      in_pin_contrato: number
    }
  ) {
    return this.http
      .post(this.urlService + 'estadoPago/postRetornaDocumentosGaratiaContrato', postData)
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }

  postValidateHesExiste(
    codigoHes: string
  ) {
    return this.http
      .get(
         `${this.urlService}estadoPago/getValidateHesExiste/${codigoHes}`
      )
      .pipe(
        map(resp => {
          return JSON.stringify(resp);
        }));
  }
}
