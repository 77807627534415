<div id="content" class="content">
    <!-- <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Cantidad de contratos</h4>
        </div>
        <div class="panel-body ">
            <div class="row">
                <div class="col-md-4">
                    <div class="widget widget-stats bg-info">
                        <div class="stats-icon"><i class="fas fa-sync"></i></div>
                        <div class="stats-info" *ngIf="dashboard">
                            <h4>Total de contratos</h4>
                            <p>{{dashboard.TOTAL_CONT}}</p>
                        </div>
                        <div class="stats-link">
                            <a (click)="onFiltroDashboard(1)" style="cursor: pointer;">Ver contratos <i class="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="widget widget-stats bg-orange">
                        <div class="stats-icon"><i class="fa fa-clock"></i></div>
                        <div class="stats-info" *ngIf="dashboard">
                            <h4>Vencimiento por fecha en los proximos 6 meses</h4>
                            <p>{{dashboard.VENCEN_FECHA}}</p>
                        </div>
                        <div class="stats-link">
                            <a (click)="onFiltroDashboard(4)" style="cursor: pointer;">Ver contratos <i class="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="widget widget-stats bg-red">
                        <div class="stats-icon"><i class="fa fa-money-bill-alt"></i></div>
                        <div class="stats-info" *ngIf="dashboard">
                            <h4>Vencimiento por monto en los proximos 6 meses</h4>
                            <p>{{dashboard.VENCEN_MONTO}}</p>
                        </div>
                        <div class="stats-link">
                            <a (click)="onFiltroDashboard(5)" style="cursor: pointer;">Ver contratos <i class="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="col"></div>
        <!-- <div class="col col-md-2">

        </div> -->

    </div>
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <!-- <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Contratos</h4>
            <div class="panel-heading-btn ">
                <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning " data-click="panel-collapse">
                    <i class="fa fa-minus"></i>
                </a>
            </div>
        </div> -->
        <div class="bg-white p3-rem rounded">
            <div class="row mb-4">
                <div class="col-12 col-md-6">
                    <h4 class="normalText">
                        Contratos
                    </h4>
                </div>
                <div class="col-12 col-md-6 text-right mb-4">
                    <button *ngIf="existeAccion('Exportar') && (idRol === 15 || idRol === 4)" class="btn tag text-primary mr-4" [disabled]="isExporting" data-toggle="modal" data-target="#modalExportarRangoFechas"><i class="fas fa-file-download mr-2"></i>Exportar AUD</button>
                    <button *ngIf="existeAccion('Exportar')" class="btn tag text-primary mr-4" [disabled]="isExporting" (click)="onExport()"><i class="fas fa-file-download mr-2"></i>Exportar</button>
                    <a *ngIf="existeAccion('Agregar')" class="btn btn-primary" href="#modal-solpe" data-toggle="modal">
                        Agregar
                    </a>&nbsp;&nbsp;
                </div>
            </div>
            <div class="row">
                <!-- <div class="col col-md-5" style="padding-top: 10px;">
                        <input type="checkbox" style="margin-right: 5px;" name="c_interno" #c_interno [(ngModel)]="isCheckedCInterno" (change)="onChangeCInterno(c_interno.checked)" [ngClass]="{ 'is-invalid': submitted && f.c_interno.errors }" />
                        <label class="form-label" for="contrato_interno">Ver contratos internos</label>


                    <div class="col-md-10" style="padding-top: 10px;">
                        <button type="button" class="btn btn-info" (click)="onFiltroDashboard(30)" style="margin-right: 5px;">Vigentes</button>
                        <button type="button" class="btn btn-light" (click)="onFiltroDashboard(31)" style="margin-right: 5px;">Expirados</button>
                        <button type="button" class="btn btn-warning" (click)="onFiltroDashboard(32)" style="margin-right: 5px;">Finiquitados</button>
                        <button type="button" class="btn btn-inverse" (click)="onFiltroDashboard(33)" style="margin-right: 5px;">Cancelados</button>
                    </div>


                    <a class="btn btn-default" href="#modal" data-toggle="modal" style="margin-right: 5px;">Ver Grafico</a>
                </div> -->
                <div class="col-6">
                    <ng-container *ngIf="idRol !== 4 && idRol !== 5">
                        <button [ngClass]="{'text-primary text-bolder': selectedFilter === 1}" class="btn tag mb-1" style="margin-right: 5px;" (click)="onFiltroDashboard(1)">Todos <span [ngClass]="{'bg-light-orange': selectedFilter === 1}" class="counter">{{nTotal}}</span> </button>
                        <button [ngClass]="{'text-primary text-bolder': selectedFilter === 2}" class="btn tag mb-1" type="button"  (click)="onFiltroDashboard(30)" style="margin-right: 5px;">Vigentes <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 2}">{{nVigentes}}</span></button>
                        <button [ngClass]="{'text-primary text-bolder': selectedFilter === 3}" class="btn tag mb-1" type="button"  (click)="onFiltroDashboard(31)" style="margin-right: 5px;">Expirados <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 3}">{{nExpirados}}</span></button>
                        <button *ngIf="idRol != 3" [ngClass]="{'text-primary text-bolder': selectedFilter === 4}" class="btn tag mb-1" type="button"  (click)="onFiltroDashboard(32)" style="margin-right: 5px;">Finiquitados <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 4}">{{nFiniquitos}}</span></button>
                        <button *ngIf="idRol != 3" [ngClass]="{'text-primary text-bolder': selectedFilter === 5}" class="btn tag mb-1" type="button"  (click)="onFiltroDashboard(33)" style="margin-right: 5px;">Cancelados <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 5}">{{nCancelados}}</span></button>
                    </ng-container>
                </div>
                <!-- <div class="col-3">
                    <button [ngClass]="{'text-primary text-bolder': isCheckedCInterno}" class="btn tag" (click)="isCheckedCInterno = !isCheckedCInterno">Contratos Interno</button>
                </div> -->
                <div class="col-3">
                    <ng-container *ngIf="idRol !== 4 && idRol !== 5">
                        <button [ngClass]="{'text-primary text-bolder': selectedFilter === 6}" class="btn tag" (click)="onFiltroDashboard(34)" >Contratos Interno</button>
                    </ng-container>
                </div>
                <!-- <div class="col"> -->
                    <input type="checkbox" style="margin-right: 5px; display:none" name="c_interno" #c_interno [(ngModel)]="isCheckedCInterno" (change)="onChangeCInterno(c_interno.checked)" />
                        <!-- <label class="form-label" for="contrato_interno">Ver contratos internos</label> -->
                <!-- </div> -->
                <div class="col-3">
                    <input style="padding-right: 2rem !important;" class="form-control" matInput (keyup)="applyFilterContrato($event)"
                    placeholder="Buscar ... " #input>
                    <i matSuffix class="fas fa-search" style="position: absolute;top: 0.7rem;right: 1.5rem;"></i>
                    <!-- <mat-icon >mode_edit</mat-icon> -->
                </div>


            </div>
            <br>

            <div class="row">
                <div class="col-md-12">
                    <table mat-table [dataSource]="contratos" matSort (matSortChange)="sortData($event)"
                    class="table border table-striped table-td-valign-middle table-th-valign-middle ">
                        <ng-container matColumnDef="Codigo">
                            <th mat-header-cell *matHeaderCellDef> No. </th>
                            <td mat-cell *matCellDef="let element ">
                                <div class="new-tab" *ngIf="CalcularDiferencia(element.FECHA_INICIO_CONTRATO) < 30">Nuevo</div>
                                <div class="row">
                                    <div class="col col-md-12">
                                        <!-- Cod. Interno:  -->{{element.CONTRATO_CORRELATIVO}}
                                    </div>
                                    <div class="col col-md-12">
                                        <span class="text-muted">
                                            Cod. Int:
                                        </span>
                                        {{element.CODIGO_SAP}}
                                    </div>
                                    <!-- <div class="col col-md-6">
                                        <strong>Nombre:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.NOMBRE_CONTRATO}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Tipo Contrato:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.TIPO_CONTRATO}}
                                    </div> -->
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Contrato">
                            <th mat-header-cell *matHeaderCellDef> Datos de contrato </th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                <div class="col col-md-12">
                                    {{element.NOMBRE_CONTRATO}}
                                </div>
                                <div class="col col-md-12">
                                    <span class="text-muted">
                                        {{element.TIPO_CONTRATO}}
                                    </span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Proveedor">
                            <th mat-header-cell *matHeaderCellDef> Proveedor </th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                <div class="row">
                                    <div class="col col-md-12">
                                        {{element.PROVEEDOR}}
                                    </div>
                                    <div class="col col-md-12">
                                        <span class="text-muted">
                                            <!-- {{element.MONTO_CONTRATO | currency:element?.ISOCode:element?.Simbol:element?.decimalPipe}} {{element.TIPO_MONEDA}} -->
                                            {{element.MONTO_CONTRATO | currency:element?.ISOCode:'':element?.decimalPipe : 'es-CL'}} {{element.TIPO_MONEDA}}
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Adm">
                            <th mat-header-cell *matHeaderCellDef> Administradores </th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                <div class="col col-md-12">
                                    <span class="text-muted">
                                        EMSA:
                                    </span>
                                    {{element.ADMINISTRADOR}}
                                </div>
                                <div class="col col-md-12">
                                    <span class="text-muted">
                                        EECC:
                                    </span>
                                    {{element.ADMINISTRADOR_PROVEEDOR}}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaIni">
                            <th mat-header-cell *matHeaderCellDef> Estado </th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                <div class="row">
                                    <!-- <div class="col col-md-6">
                                        <strong>Fecha inicio:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.FECHA_INICIO_CONTRATO}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Fecha termino:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.FECHA_TERMINO_CONTRATO}}
                                    </div> -->
                                    <div class="col col-md-12">
                                        <span  *ngIf="element.DIAS_RESTANTES < 0"></span>
                                        <span  *ngIf="element.DIAS_RESTANTES >= 0">{{element.DIAS_RESTANTES}} Días restantes</span>
                                    </div>
                                    <!-- <div class="col col-md-6">
                                        <strong>Estado:</strong>
                                    </div> -->
                                    <div class="col col-md-12">
                                        <div *ngIf="element.DIAS_RESTANTES >= 0" class="tab tab-green">{{element.ESTADO_CONTRATO}}</div>
                                        <div *ngIf="element.DIAS_RESTANTES < 0" class="tab tab-yellow">{{element.ESTADO_CONTRATO}}</div>
                                        <!-- <div *ngIf="element.ESTADO_CONTRATO == 'Finiquitado'" class="tab tab-red">{{element.ESTADO_CONTRATO}}</div>
                                        <div *ngIf="element.ESTADO_CONTRATO == 'Cancelado'" class="tab tab-red">{{element.ESTADO_CONTRATO}}</div>  -->
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Ver">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td style="padding: 10px !important; width: 10%; text-align: center !important;" mat-cell *matCellDef="let element ">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <!-- <a class="btn rounded-icon" href="#modal" data-toggle="modal" title="Ver Detalle">
                                        <i class="fas fa-eye"></i>
                                    </a> -->
                                    <a class="btn rounded-icon" routerLink="/detalle-contrato/{{element.CONTRATO_CORRELATIVO}}/{{element.SOLPE_CORRELATIVO}}" title="Ver Detalle">
                                        <i class="fas fa-eye"></i>
                                    </a>

                                   <!--  <a class="btn btn-primary" routerLink="/modificaciones-contrato" (click)="modifContrato(element.CONTRATO_CORRELATIVO)" title="Agregar Modificaciones" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                                        <i class="fas fa-edit"></i>
                                    </a>
                                    <a class="btn btn-light" style="color: white;" href="#modalAdec" data-toggle="modal" title="Ver Adec" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                                        <i class="fas fa-users"></i>
                                    </a>
                                    <a class="btn btn-warning" style="color: white;" href="#modalAdecEmsa" data-toggle="modal" title="Ver Adec Emsa" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                                        <i class="fas fa-user-secret"></i>
                                    </a> -->
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="dcContratos"></tr>
                        <tr class="pt-4 pb-4 p-relative" [ngClass]="{'highlight': selectedRowIndex === row.id}"
                        (click)="onVer(row.CONTRATO_CORRELATIVO , row.CODIGO_CONTRATO, row, row.SOLPE_CORRELATIVO) " style="cursor: pointer;" mat-row
                        *matRowDef="let row; columns: dcContratos;">
                        </tr>
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row " *matNoDataRow>
                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>



        <div class="modal fade" id="modal_graph">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cantidad de Contratos por Estado</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div *ngIf="chartDonutData" style="margin-left: 60px;">
                                    <div mk-morris-js [options]="chartDonutOptions" style="width: 100%;" [data]="chartDonutData" type="Donut"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Contratos</h4>
            <div class="panel-heading-btn ">
                <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning " data-click="panel-collapse"><i class="fa fa-minus"></i></a>
            </div>
        </div>
        <div class="panel-body ">
            <div class="row">
                <div class="col col-md-3">
                    <input class="form-control" matInput (keyup)="applyFilterContrato($event)" placeholder="Buscar ... " #input>
                </div>
                <div class="col col-md-5" style="padding-top: 10px;">
                        <input type="checkbox" style="margin-right: 5px;" name="c_interno" #c_interno [(ngModel)]="isCheckedCInterno" (change)="onChangeCInterno(c_interno.checked)" [ngClass]="{ 'is-invalid': submitted && f.c_interno.errors }" />
                        <label class="form-label" for="contrato_interno">Ver contratos internos</label>


                    <div class="col-md-10" style="padding-top: 10px;">
                        <button type="button" class="btn btn-info" (click)="onFiltroDashboard(30)" style="margin-right: 5px;">Vigentes</button>
                        <button type="button" class="btn btn-light" (click)="onFiltroDashboard(31)" style="margin-right: 5px;">Expirados</button>
                        <button type="button" class="btn btn-warning" (click)="onFiltroDashboard(32)" style="margin-right: 5px;">Finiquitados</button>
                        <button type="button" class="btn btn-inverse" (click)="onFiltroDashboard(33)" style="margin-right: 5px;">Cancelados</button>

                    </div>



                </div>

                <div class="col-md-2">
                    <a class="btn btn-primary btn-block" href="#modal-solpe" data-toggle="modal"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i>
                  Agregar Contrato
              </a>&nbsp;&nbsp;
                </div>
                <div class="col col-md-2">
                    <button class="btn btn-green btn-block" (click)="onExport()"><i class="fas fa-lg fa-fw m-r-10 fa-file-excel"></i>Exportar</button>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="col-md-12">
                    <table mat-table [dataSource]="contratos" matSort (matSortChange)="sortData($event)" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                        <ng-container matColumnDef="Codigo">
                            <td mat-cell *matCellDef="let element ">
                                <div class="row">
                                    <div class="col col-md-6">
                                        <strong>Cod. Interno:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.CONTRATO_CORRELATIVO}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Cod. SAP:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.CODIGO_SAP}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Nombre:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.NOMBRE_CONTRATO}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Tipo Contrato:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.TIPO_CONTRATO}}
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Proveedor">
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                <div class="row">
                                    <div class="col col-md-6">
                                        <strong>Proveedor:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.PROVEEDOR}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Monto:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.MONTO_CONTRATO | currency:element.ISOCode:element.Simbol:element.decimalPipe}} {{element.TIPO_MONEDA}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Admin.:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.ADMINISTRADOR}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Admin. Proveedor:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.ADMINISTRADOR_PROVEEDOR}}
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaIni">
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                <div class="row">
                                    <div class="col col-md-6">
                                        <strong>Fecha inicio:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.FECHA_INICIO_CONTRATO}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Fecha termino:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.FECHA_TERMINO_CONTRATO}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Dias restantes:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.DIAS_RESTANTES}}
                                    </div>
                                    <div class="col col-md-6">
                                        <strong>Estado:</strong>
                                    </div>
                                    <div class="col col-md-6">
                                        {{element.ESTADO_CONTRATO}}
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Ver">
                            <td style="padding: 10px !important; width: 10%; text-align: center !important;" mat-cell *matCellDef="let element ">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <a class="btn btn-info" href="#modal" data-toggle="modal" title="Ver Detalle">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a class="btn btn-primary" routerLink="/modificaciones-contrato" (click)="modifContrato(element.CONTRATO_CORRELATIVO)" title="Agregar Modificaciones" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                                        <i class="fas fa-edit"></i>
                                    </a>
                                    <a class="btn btn-light" style="color: white;" href="#modalAdec" data-toggle="modal" title="Ver Adec" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                                        <i class="fas fa-users"></i>
                                    </a>
                                    <a class="btn btn-warning" style="color: white;" href="#modalAdecEmsa" data-toggle="modal" title="Ver Adec Emsa" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                                        <i class="fas fa-user-secret"></i>
                                    </a>
                                </div>
                            </td>
                        </ng-container>
                        <tr [ngClass]="{'highlight': selectedRowIndex === row.id}" (click)="onVer(row.CONTRATO_CORRELATIVO , row.CODIGO_CONTRATO, row) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dcContratos;"></tr>

                        <tr class="mat-row " *matNoDataRow>
                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>



        <div class="modal fade" id="modal_graph">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cantidad de Contratos por Estado</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div *ngIf="chartDonutData" style="margin-left: 60px;">
                                    <div mk-morris-js [options]="chartDonutOptions" style="width: 100%;" [data]="chartDonutData" type="Donut"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <div class="modal modal-message" id="modal" style="margin-left: 15px !important;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><!-- Detalle Contrato {{nameCode}} --></h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeModalContratos>×</button>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="dataContrato ">
                        <div class="row mb-4">
                            <div class="col-9">
                                <h3>Contrato {{dataContrato.CODIGO_CONTRATO}} {{dataContrato.NOMBRE_CONTRATO}}</h3>
                            </div>
                            <div class="col-3">
                                <div *ngIf="dataContrato.ESTADO_CONTRATO == 'Vigente'" class="tab tab-green">{{dataContrato.ESTADO_CONTRATO}}</div>
                                <div *ngIf="dataContrato.ESTADO_CONTRATO == 'Expirado'" class="tab tab-yellow">{{dataContrato.ESTADO_CONTRATO}}</div>
                                <div *ngIf="dataContrato.ESTADO_CONTRATO == 'Finiquitado'" class="tab tab-red">{{dataContrato.ESTADO_CONTRATO}}</div>
                                <div *ngIf="dataContrato.ESTADO_CONTRATO == 'Cancelado'" class="tab tab-red">{{dataContrato.ESTADO_CONTRATO}}</div>
                            </div>
                        </div>
                        <!-- <a class="btn rounded-icon" href="#modal" data-toggle="modal" title="Ver Detalle">
                            <i class="fas fa-eye"></i>
                        </a> -->
                       <!--  <a class="btn btn-primary" routerLink="/modificaciones-contrato" (click)="modifContrato(element.CONTRATO_CORRELATIVO)" title="Agregar Modificaciones" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                            <i class="fas fa-edit"></i>
                        </a>
                        <a class="btn btn-light" style="color: white;" href="#modalAdec" data-toggle="modal" title="Ver Adec" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                            <i class="fas fa-users"></i>
                        </a>
                        <a class="btn btn-warning" style="color: white;" href="#modalAdecEmsa" data-toggle="modal" title="Ver Adec Emsa" *ngIf="element.ESTADO_CONTRATO == 'Vigente'">
                            <i class="fas fa-user-secret"></i>
                        </a> -->
                        <div class="row d-flex ">
                            <div class="col-12 col-md-7 font-14">
                                <p class="text-muted">Datos del provedor</p>
                                <h4>{{dataContrato.RUT_PROVEEDOR}}</h4>
                                <h4>{{dataContrato.PROVEEDOR}}</h4>
                                <span class="text-muted">Administrador</span>
                                <p class="color-b font-14">{{dataContrato.ADMIN_PROVEEDOR}}
                                    <a class="btn rounded-icon ml-2" href="#modalAdec" data-toggle="modal" title="Ver Admin EECC">
                                        <i class="fas fa-pen"></i>
                                    </a>
                                </p>
                                <span class="text-muted">Adm EMSA</span>
                                <p class="color-b font-14">{{dataContrato.ADMINISTRADOR}}
                                    <a class="btn rounded-icon ml-2" href="#modalAdecEmsa" data-toggle="modal" title="Ver Admin EMSA">
                                        <i class="fas fa-pen"></i>
                                    </a>
                                </p>
                                <span class="text-muted">Moneda</span>
                                <p class="color-b font-14">{{dataContrato.TIPO_MONEDA}}</p>

                                <span class="text-muted">Ultimo EP</span>
                                <p class="color-b font-14">{{dataContrato.ULTIMO_EP | date:'dd/MM/yyyy'}} <span *ngIf="dataContrato.ULTIMO_EP == null">-</span></p>

                                <span class="text-muted">Fecha de vencimiento de la ultima BG</span>
                                <p *ngIf="ultimaBoleta?.length > 0" class="color-b font-14">{{ultimaBoleta[0]?.FECHA_VENCIMIENTO | date: 'dd/MM/yyyy'}}</p>
                                <p *ngIf="ultimaBoleta?.length <= 0" class="color-b font-14">-</p>

                            </div>
                            <div class="col-12 col-md-5 font-14">
                                <div class="bg-light pt-4 pb-4 pl-4 pr-4 rounded">
                                    <div class="row">
                                        <div class="col"></div>
                                        <div class="col text-center text-muted">{{dataContrato.TIPO_MONEDA}}</div>
                                        <div class="col text-center text-muted">USD</div>
                                    </div>
                                    <div class="row pt-2 pb-2">
                                        <div class="col">
                                            <span class="text-muted">
                                                Original
                                            </span>
                                        </div>
                                        <!-- <div class="col color-b font-14" style="text-align: right;">{{dataContrato?.MONTO_ORIGINAL | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</div> -->
                                        <div class="col color-b font-14" style="text-align: right;">{{dataContrato?.MONTO_ORIGINAL | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_ORIGINAL / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_ORIGINAL * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}</div>
                                    </div>
                                    <div class="row pt-2 pb-2">
                                        <div class="col">
                                            <span class="text-muted">
                                                Reajuste
                                            </span>
                                        </div>
                                        <div class="col color-b font-14" style="text-align: right;">{{dataContrato.MONTO_REAJUSTE | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_REAJUSTE / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_REAJUSTE * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}</div>
                                    </div>
                                    <div class="row pt-2 pb-4">
                                        <div class="col">
                                            <span class="text-muted">
                                                Modificaciones
                                            </span>
                                        </div>
                                        <div class="col color-b font-14" style="text-align: right;">{{(dataContrato.MONTO_ACTUALIZADO - dataContrato.MONTO_ORIGINAL) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ ((dataContrato.MONTO_ACTUALIZADO - dataContrato.MONTO_ORIGINAL) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe : 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ (((dataContrato.MONTO_ACTUALIZADO - dataContrato.MONTO_ORIGINAL) * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}</div>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div class="row pt-4 pb-2">
                                        <div class="col">
                                            <span class="text-muted">
                                                Monto Act
                                            </span>
                                        </div>
                                        <div class="col color-b font-14" style="text-align: right;">{{dataContrato.MONTO_ACTUALIZADO | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_ACTUALIZADO / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_ACTUALIZADO * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}</div>
                                    </div>
                                    <div class="row pt-2 pb-2">
                                        <div class="col">
                                            <span class="text-muted">
                                                EP
                                            </span>
                                        </div>
                                        <div class="col color-b font-14" style="text-align: right;">{{dataContrato.MONTO_EP | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_EP / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}</div>
                                        <div class="col color-b font-14" *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_EP * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}</div>
                                    </div>
                                    <div class="row pt-2 pb-2">
                                        <div class="col">
                                            <span class="text-muted">
                                                Saldo
                                            </span>
                                        </div>
                                        <div class="col color-b font-14" style="text-align: right;">
                                            <strong>
                                                {{(dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP) | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                            </strong>
                                        </div>
                                        <div class="col color-b font-14" *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">
                                            <strong>
                                                {{ ((dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}
                                            </strong>
                                        </div>
                                        <div class="col color-b font-14" *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">
                                            <strong>
                                                {{ (((dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP) * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe: 'es-CL'}}
                                            </strong>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <!-- <mat-divider></mat-divider> -->
                        <div class="row mb-4 mt-4">
                            <div class="col-12 col-md-7 font-14 mt-4">
                                <div class="row">
                                    <div class="col-4">
                                        <span class="text-muted">Fecha Inicio</span>
                                        <p>{{dataContrato.FECHA_INICIO_CONTRATO | date:'dd/MM/yyyy'}}</p>
                                    </div>
                                    <div class="col-4">
                                        <span class="text-muted">Fecha Termino</span>
                                        <p>{{dataContrato.FECHA_TERMINO_CONTRATO | date:'dd/MM/yyyy'}}</p>
                                    </div>
                                    <!-- <div class="col-4">
                                        <span class="text-muted">Fecha vencimiento (no la tengo)</span>
                                        <p>{{dataContrato.FECHA_TERMINO_CONTRATO}}</p>
                                    </div> -->
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <span class="text-muted">Avance tiempo</span>
                                    </div>
                                    <div class="col-6 text-right">
                                        <span class="">{{avanceTiempo?.DIAS_RESTANTE}} dias restantes</span>
                                    </div>
                                    <div class="col-11">
                                        <div class="progress rounded-corner progress-striped active ">
                                            <div *ngIf="avanceTiempo?.PORCENTAJE===0 " class="progress-bar bg-0 " style="width: 100% ">
                                                0%
                                            </div>
                                            <div *ngIf="avanceTiempo?.PORCENTAJE> 0" class="progress-bar bg-codelco-azul-20" [ngStyle]="{'width': avanceTiempo?.PORCENTAJE+'%' }">
                                            <!-- <div class="progress-bar bg-info" [ngStyle]="{'width': (((avanceTiempo.DIAS_CONTRATO - avanceTiempo.DIAS_RESTANTE) * 100) / avanceTiempo.DIAS_CONTRATO ) | number: '1.0-0' : 'es-CL' +'%' }"> -->
                                                {{avanceTiempo?.PORCENTAJE}}%
                                                <!-- {{(((avanceTiempo.DIAS_CONTRATO - avanceTiempo.DIAS_RESTANTE) * 100) / avanceTiempo.DIAS_CONTRATO ) | number:'1.0-0' }}% -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">
                                        <span class="text-muted">Avance financiero</span>
                                    </div>
                                    <div class="col-5 text-right">
                                        <span class="" style="text-align: right;">Saldo: {{(dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}</span>
                                    </div>
                                    <div class="col-11">
                                        <div class="progress rounded-corner progress-striped active">
                                            <div *ngIf="avanceFinanciero?.PORCENTAJE ===0" class="progress-bar bg-0" style="width: 100%">
                                                0%
                                            </div>
                                            <div class="progress-bar bg-codelco-yellow" [ngStyle]="{'width': avanceFinanciero?.PORCENTAJE+'%' }">
                                                {{avanceFinanciero?.PORCENTAJE}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-md-5 mt-4">
                               <!--  <div class="row">
                                    <div class="col">
                                        <span class="text-muted">Ultimo ep</span>
                                        <p>Tengo que buscarlo</p>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-12">
                                        <p class="tex-muted font-14">Documentos</p>
                                    </div>
                                </div>
                                <ng-container *ngIf="archivos">
                                    <div class="row">
                                        <div class="row">
                                            <div class="col-6" *ngFor="let item of archivos">
                                                    <!-- <div class="col-6" *ngIf="item.NOMBRE_ARCHIVO">
                                                        <a (click)="onDescargar(item.NOMBRE_ARCHIVO)">
                                                            <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                            <label style="cursor: pointer;" for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                        </a>
                                                    </div>
                                                    <div class="col-6" *ngIf="!item.NOMBRE_ARCHIVO">
                                                        <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                        <label for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                    </div> -->
                                                <div class="checkbox checkbox-css">
                                                    <ng-container *ngIf="item.NOMBRE_ARCHIVO">
                                                        <a (click)="onDescargar(item.NOMBRE_ARCHIVO)">
                                                            <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                            <label style="cursor: pointer;" for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.NOMBRE_ARCHIVO">
                                                        <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                        <label for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <div class=" mt-5">
                        <mat-tab-group mat-align-tabs="end">
                            <mat-tab label="SOLPE">
                                <div class="tab-content mt-4">
                                    <div class="col-12">
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <th>Codigo</th>
                                                <th>Nombre</th>
                                                <th>Forma</th>
                                                <th>Tipo</th>
                                                <th>Fecha de Aprobacion</th>
                                                <th>Monto</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of solpeContrato">
                                                    <td>
                                                        {{ item.CODIGO_SOLPE }}
                                                    </td>
                                                    <td>
                                                        {{ item.NOMBRE_SOLPE }}
                                                    </td>
                                                    <td>
                                                        {{ item.FORMA_SOLPE }}
                                                    </td>
                                                    <td>
                                                        {{ item.TIPO_SOLPE }}
                                                    </td>
                                                    <td>
                                                        {{ item.FECHA_CIERRE | date:'dd/MM/yyyy' }}
                                                    </td>
                                                    <td>
                                                        {{ item.MONTO | number: '':'es-CL' }}
                                                    </td>
                                                    <td>
                                                        <!-- (click)="revisarSolpe(item.SOLPE_CORRELATIVO, item.SOLPE_ESTADO)" -->
                                                        <a routerLink="/detalle-solpe/{{item.SOLPE_CORRELATIVO}}" (click)="revisarSolpe(item.SOLPE_CORRELATIVO, item.SOLPE_ESTADO)" class="btn  rounded-icon" title="Revisar">
                                                            <i class="fa fa-eye"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Itemizado" isActive="true">
                                <div class="tab-content mt-4">
                                    <ng-container *ngIf="dataContrato ">
                                        <div class="panel-body" >
                                            <ng-container *ngIf="itemizado.length > 0">
                                                <div class="row">
                                                    <div class="col-md-12 table_container">
                                                        <table class="table">
                                                            <thead class="thead-dark">
                                                                <th>R</th>
                                                                <th>Q</th>
                                                                <th>SubPos</th>
                                                                <th>Desc</th>
                                                                <th>Unidad</th>
                                                                <th class="text-right">Cantidad</th>
                                                                <th class="text-right">P.Unitario</th>
                                                                <th class="text-right">Total</th>
                                                                <th class="text-right">Consumo</th>
                                                                <th class="text-right">Saldo</th>
                                                                <th class="text-right">Consumo Q</th>
                                                                <th class="text-right">Saldo Q</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of itemizado">
                                                                    <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>

                                                                    <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                                                    <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                                                    <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                                                    <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Unidad }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                                                    <!-- <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td> -->
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (item.PrecioUnitario * item.Cantidad) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Consumo | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ ((item.PrecioUnitario * item.Cantidad) - item.Consumo) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.ConsumoQ | number:'1.2': 'es-CL' }}</td>
                                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (item.Cantidad - item.ConsumoQ) | number:'1.2': 'es-CL' }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                               <!--  <div class="row">
                                                    <legend>Total</legend>
                                                    <table class="table table-striped table-bordered table-td-valign-middle ">
                                                        <thead>
                                                            <th colspan="5" style="width: 48%;">&nbsp;</th>
                                                            <th style="width: 11%;">Total</th>
                                                            <th colspan="2" style="width:83px;">&nbsp;</th>
                                                            <th style="width: 9%;">Avance</th>
                                                            <th style="width: 9%;">Saldo</th>
                                                            <th colpsan="2" style="width:6%;">&nbsp;</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="5">&nbsp;</td>
                                                                <td>{{ this.getSumTotal() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>
                                                                <td colspan="2">&nbsp;</td>
                                                                <td>{{ this.getSumAvance() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                <td>{{ this.getSaldo() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                                <td colspan="2">&nbsp;</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> -->
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-tab>
                            <mat-tab label="Modificaciones">
                                <div class="tab-content mt-4">
                                    <div class="col-12">
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <th>N Solpe</th>
                                                <th>Descripcion</th>
                                                <th>Modificaciones</th>
                                                <th>Estado</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let modificacion of modificacionesContrato">
                                                    <td>
                                                        <p>
                                                            {{ modificacion.CORRELATIVO }}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <h5 class="mb-0" style="font-weight:normal;">
                                                            {{ modificacion.SOLPE_NOMBRE }}
                                                        </h5>

                                                    </td>
                                                    <td>
                                                        <p class="mb-0 text-muted" *ngIf="modificacion.monto_actualizado != null"> Monto actualizado: {{ modificacion.monto_actualizado | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</p>
                                                        <p class="mb-0 text-muted" *ngIf="modificacion.fecha_inicio != null && modificacion.fecha_inicio != 'Invalid date'"> Fecha inicio: {{ modificacion.fecha_inicio }}</p>
                                                        <p class="mb-0 text-muted" *ngIf="modificacion.fecha_termino != null && modificacion.fecha_termino != 'Invalid date'"> Fecha termin: {{ modificacion.fecha_termino }}</p>
                                                        <p class="mb-0 text-muted" *ngIf="modificacion.alcance != null"> Nuevo alcance: {{ modificacion.alcance }}</p>
                                                    </td>
                                                    <td>
                                                        <!-- <div class="tag">
                                                            {{ modificacion.ESTADO_SOLPE }}
                                                        </div> -->
                                                        <div *ngIf="modificacion.ESTADO_SOLPE == 'Aplicado'" class="tab tab-green">{{modificacion.ESTADO_SOLPE}}</div>
                                                        <div *ngIf="modificacion.ESTADO_SOLPE == 'Cerrada'" class="tab tab-yellow">Sin aplicar</div>
                                                        <!-- <div *ngIf="modificacion.ESTADO_SOLPE == 'Finiquitado'" class="tab tab-red">{{modificacion.ESTADO_SOLPE}}</div>
                                                        <div *ngIf="modificacion.ESTADO_SOLPE == 'Cancelado'" class="tab tab-red">{{modificacion.ESTADO_SOLPE}}</div> -->
                                                    </td>
                                                    <td><!-- {{ modificacion.CORRELATIVO_ESDO_ITEMIZADO }} {{ modificacion.domi_correlativo_estadosolpe }} -->
                                                        <button *ngIf="modificacion.domi_correlativo_estadosolpe == 110"
                                                        (click)="aplicarModificacion(modificacion.CORRELATIVO)"
                                                        class="btn btn-warning" style="margin-left: 30px;" >
                                                            Aplicar modificacion
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Estados de pago">
                                <div class="tab-content mt-4">
                                    <ng-container>
                                        <div class="col-xl-12">
                                            <!-- <ul class="nav nav-tabs">
                                                <li class="nav-item">
                                                    <a href="#default-tab-41" data-toggle="tab" class="nav-link active">
                                                        <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-money-bill-alt"></i>Estados de pago</span>
                                                        <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-money-bill-alt"></i>Estados de pago</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item" *ngIf="idRol !== 3 ">
                                                    <a href="#default-tab-51" data-toggle="tab" class="nav-link" >
                                                        <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-circle"></i>Estados de pago incurrido</span>
                                                        <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-circle"></i>Estados de pago incurrido</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item" *ngIf="idRol !== 3 ">
                                                    <a href="#default-tab-61" data-toggle="tab" class="nav-link">
                                                        <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle"></i>Estados de pago provisional</span>
                                                        <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle"></i>Estados de pago provisional</span>
                                                    </a>
                                                </li>
                                            </ul> -->
                                            <div class="tab-pane fade active show" id="default-tab-41">
                                                <div class="col-md-12">
                                                    <!-- <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input> -->
                                                    <table mat-table [dataSource]="dsEPNormal" class="table table-striped table-td-valign-middle table-th-valign-middle ">
                                                        <ng-container matColumnDef="EP_CORRELATIVO">
                                                            <td mat-cell *matCellDef="let element " >
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <p>{{element.EP_CORRELATIVO }}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DatosEp">
                                                            <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                                <div class="row">
                                                                    <div class="col col-md-12" >
                                                                        {{element.EP_DESCRIPCION }}
                                                                    </div>
                                                                    <div class="col col-md-12" >
                                                                        {{element.EP_ESTADO}} <span *ngIf="element.EP_ESTADO=='Facturado'">({{element.nro_factura}})</span>
                                                                    </div>
                                                                    <div *ngIf="element.hes_codigo!==''" class="col col-md-12">
                                                                        <span class="text-muted">Hes: </span><span>{{element.hes_codigo}}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="EP_PERIODO_DESDE">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col col-md-12" style="padding-right: 0px;">
                                                                        <span class="text-muted">Desde: </span>{{element.EP_PERIODO_DESDE | date:'dd/MM/yyyy'}}
                                                                    </div>
                                                                    <div class="col col-md-12" style="padding-right: 0px;">
                                                                        <span class="text-muted">Hasta: </span>{{element.EP_PERIODO_HASTA | date:'dd/MM/yyyy'}}
                                                                    </div>
                                                                    <div *ngIf="element.EP_ESTADO=='Facturado'" class="col col-md-12" style="padding-right: 0px;">
                                                                        <span class="text-muted">Pago: </span>{{element.fecha_pago | date:'dd/MM/yyyy'}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="FechaEp">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col col-md-6" style="padding-right: 0px;">
                                                                        <span class="text-muted">Ultima modificacion: </span>{{element.FECHA_ESTADO_EP | date:'dd/MM/yyyy'}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Monto">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col" >
                                                                        {{element.monto | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Opciones">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <!-- <i class="fas fa-lg fa-fw m-r-10 fa-eye"></i> -->
                                                                <div class="btn rounded-icon">
                                                                    <i class="fas fa-eye"></i>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr (click)="onIrEp(row.EP_CORRELATIVO) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPNormal; "></tr>
                                                        <!-- Row shown when there is no matching data. -->
                                                        <tr class="mat-row " *matNoDataRow>
                                                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                                        </tr>
                                                    </table>
                                                    <mat-paginator #paginatorEPNormal [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                                </div>
                                            </div>
                                            <!-- <div class="tab-content">
                                                <div class="tab-pane fade active show" id="default-tab-41">
                                                    <div class="col-md-12">
                                                        <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input>
                                                        <table mat-table [dataSource]="dsEPNormal" class="table table-striped table-td-valign-middle table-th-valign-middle ">
                                                            <ng-container matColumnDef="EP_CORRELATIVO">
                                                                <td mat-cell *matCellDef="let element " >
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <p>{{element.EP_CORRELATIVO }}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="DatosEp">
                                                                <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                                    <div class="row">
                                                                        <div class="col col-md-12" >
                                                                            {{element.EP_DESCRIPCION }}
                                                                        </div>
                                                                        <div class="col col-md-12" >
                                                                            {{element.EP_ESTADO}}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="FechaEp">
                                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                    <div class="row">
                                                                        <div class="col col-md-6" style="padding-right: 0px;">
                                                                            <span class="text-muted">Ultima modificacion: </span>{{element.FECHA_ESTADO_EP}}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="Monto">
                                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                    <div class="row">
                                                                        <div class="col" >
                                                                            {{element.monto | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="Opciones">
                                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">

                                                                    <div class="btn rounded-icon">
                                                                        <i class="fas fa-eye"></i>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <tr (click)="onIrEp(row.EP_CORRELATIVO) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPNormal; "></tr>

                                                            <tr class="mat-row " *matNoDataRow>
                                                                <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                                            </tr>
                                                        </table>

                                                            <mat-paginator #paginatorEPNormal [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="default-tab-51">
                                                    <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input>
                                                    <table mat-table [dataSource]="dsEPIncurrido" class="table table-striped table-td-valign-middle table-th-valign-middle ">
                                                        <ng-container matColumnDef="EP_CORRELATIVO">
                                                            <td mat-cell *matCellDef="let element " >
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <p>{{element.EP_CORRELATIVO }}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DatosEp">
                                                            <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                                <div class="row">
                                                                    <div class="col col-md-12" >
                                                                        {{element.EP_DESCRIPCION }}
                                                                    </div>
                                                                    <div class="col col-md-12" >
                                                                        {{element.EP_ESTADO}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="FechaEp">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col col-md-6" style="padding-right: 0px;">
                                                                        <span class="text-muted">Ultima modificacion: </span>{{element.FECHA_ESTADO_EP}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Monto">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col" >
                                                                        0
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Opciones">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">

                                                                <div class="btn rounded-icon">
                                                                    <i class="fas fa-eye"></i>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr (click)="onIrEp(row.EP_CORRELATIVO) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPIncurrido; "></tr>

                                                        <tr class="mat-row " *matNoDataRow>
                                                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                                        </tr>
                                                    </table>
                                                    <mat-paginator  #paginatorEPIncurrido [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                                </div>
                                                <div class="tab-pane fade" id="default-tab-61">
                                                    <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input>
                                                    <table mat-table [dataSource]="dsEPProvisional" class="table table-striped table-td-valign-middle table-th-valign-middle ">
                                                        <ng-container matColumnDef="EP_CORRELATIVO">
                                                            <td mat-cell *matCellDef="let element " >
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <p>{{element.EP_CORRELATIVO }}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DatosEp">
                                                            <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                                <div class="row">
                                                                    <div class="col col-md-12" >
                                                                        {{element.EP_DESCRIPCION }}
                                                                    </div>
                                                                    <div class="col col-md-12" >
                                                                        {{element.EP_ESTADO}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="FechaEp">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col col-md-6" style="padding-right: 0px;">
                                                                        <span class="text-muted">Ultima modificacion: </span>{{element.FECHA_ESTADO_EP}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Monto">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col" >
                                                                        0
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Opciones">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">

                                                                <div class="btn rounded-icon">
                                                                    <i class="fas fa-eye"></i>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr (click)="onIrEp(row.EP_CORRELATIVO) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPProvisional; "></tr>

                                                        <tr class="mat-row " *matNoDataRow>
                                                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                                        </tr>
                                                    </table>
                                                    <mat-paginator #paginatorEPProvisional [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                                </div>
                                            </div> -->
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-tab>
                            <mat-tab label="Boletas">
                                <div class="tab-content mt-4">
                                    <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterBoletas($event) " placeholder="Buscar ... " #input>
                                    <table mat-table [dataSource]="boletas" class="table table-striped table-td-valign-middle table-th-valign-middle ">
                                        <ng-container matColumnDef="NroBoleta">
                                            <td mat-cell *matCellDef="let element ">
                                                <div class="row pb-2 pt-2">
                                                    <div class="col col-md-12" >
                                                        {{element.NUM_BOLETA }}
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DescripcionBoleta">
                                            <td mat-cell *matCellDef="let element ">
                                                <div class="row pb-2 pt-2">
                                                    <div class="col col-md-12" >
                                                        {{element.DESCRIPCION }}
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Banco">
                                            <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                <div class="row pb-2 pt-2">
                                                    <div class="col-12 col-md-12">
                                                        {{element.BANCO}}
                                                    </div>

                                                    <div class="col-12 col-md-12" >
                                                        {{element.SIMBOLO_MONEDA}} {{element.MONTO }}
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TipoMoneda">
                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                <div class="row pb-2 pt-2">
                                                    <div class="col-12 col-md-12">
                                                        {{element.TIPO_MONEDA}}
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FechaVencimiento">
                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                <div class="row pb-2 pt-2">
                                                    <div class="col-12 col-md-12">
                                                        Vencimiento: {{element.FECHA_VENCIMIENTO | date: 'dd/MM/yyyy'}}
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="EstadoBoleta">
                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                <div *ngIf="element.DIAS_RESTANTES > 0" class="tab tab-green">Vigente</div>
                                                <div *ngIf="element.DIAS_RESTANTES <= 0" class="tab tab-yellow">Vencida</div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DiasVencimiento">
                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                <div class="row pb-2 pt-2">
                                                    <div *ngIf="element.DIAS_RESTANTES > 0" class="col-12 col-md-12">
                                                        Dias restantes: {{element.DIAS_RESTANTES}}
                                                    </div>
                                                    <div *ngIf="element.DIAS_RESTANTES <= 0" class="col-12 col-md-12">
                                                        Dias restantes: 0
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Opciones">
                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                <!-- <i class="fas fa-lg fa-fw m-r-10 fa-cloud-download-alt"></i> -->
                                                <div class="pb-2 pt-2">

                                                    <div class="btn rounded-icon" (click)="onDescargarBoleta(element.ADJUNTO)">
                                                        <i class="fas fa-file-download"></i>
                                                    </div>
                                                    <view-file-button
                                                        [nombreArchivo]="element.ADJUNTO"
                                                        [tipoArchivo]="tipoArchivoBoleta"
                                                    ></view-file-button>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <!--   <ng-container matColumnDef="TipoMoneda">
                                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Moneda</th>
                                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.TIPO_MONEDA}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Banco">
                                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Banco</th>
                                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.BANCO}} </td>
                                    </ng-container>-->
                                        <tr (click)="onVerBoleta(row.NUM_BOLETA) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dcBoletas; "></tr>
                                        <!-- Row shown when there is no matching data. -->
                                        <tr class="mat-row " *matNoDataRow>
                                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </mat-tab>
                            <mat-tab label="Documentos">
                                <div class="tab-content mt-4 mb-4">
                                    <div class="col-12 text-right mb-4">
                                        <button class="btn btn-primary" (click)="cargarModalArchivo(idContrato)">
                                            <span *ngIf="agregarArchivoVisible === false">Agregar documento</span>
                                            <span *ngIf="agregarArchivoVisible === true">Contraer</span>
                                        </button>
                                    </div>
                                    <br>
                                    <div *ngIf="agregarArchivoVisible" class="container">
                                        <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                                            <div class="row form-group">
                                                <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                                                <div class="col-md-9">
                                                    <select class="form-control" formControlName="grupo" #grupo [ngClass]="{ 'is-invalid': submittedDocumento && fd.grupo.errors}">
                                              <option value="0">Seleccione ...</option>
                                              <option *ngFor="let item of archivos" [value]="item.CORRELATIVO">{{item.GRUPO}}</option>
                                            </select>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                                                <div class="col-md-9">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                                        <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                                                <div class="col-md-9">
                                                    <input type="text" formControlName="descripcion" #descripcion class="form-control" [ngClass]="{ 'is-invalid': submittedDocumento && fd.descripcion.errors}"/>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <label class="col-md-3 col-form-label" for="bg">Documento publico</label>
                                                <div class="col-md-9" style="padding-top: 10px;">
                                                    <input type="checkbox" formControlName="isPublic" name="isPublic" #isPublic value="0" [ngClass]="{ 'is-invalid': submittedDocumento && fd.isPublic.errors}"/>
                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="col-md-8"></div>
                                                <div class="col-md-4 text-right">
                                                    <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                                                </div>
                                            </div> -->
                                            <input type="hidden" name="corr_cont" formControlName="corr_cont" #corr_cont value="" />

                                            <!-- <div *ngIf="documentosSolpe">
                                                <hr>
                                                <p *ngFor="let item of documentosSolpe">
                                                    <span style="color: #999; font-weight: bold !important;" [ngClass]="{ 'text-red': item.OBLIGATORIO == 'S'}">
                                                    <i class="fa fa-lg fa-fw m-r-10 fa-file"></i> {{ item.descripcion | uppercase }}: &nbsp;
                                                    </span>
                                                    <a [routerLink]="" (click)="onDescargar(item.nombre_archivo)">{{ item.nombre_archivo }}</a>
                                                </p>
                                            </div> -->
                                            <br>
                                            <div class="row">
                                                <div class="col-md-12 text-right">
                                                    <button class="btn btn-primary mr-4" type="submit">Agregar</button>
                                                    <a href="javascript:;" (click)="onCloseArchivo()" #closeModalAgregarArchivo class="btn btn-light" data-dismiss="modal">Cerrar</a>
                                                </div>
                                            </div>
                                        </form>
                                        <br>
                                    </div>
                                    <ng-container *ngIf="archivosRegistrados">
                                        <div class="col-12">
                                            <table class="table">
                                                <thead class="thead-dark">
                                                    <th></th>
                                                    <th>Documento</th>
                                                    <th></th>
                                                    <th>Fecha subida</th>
                                                    <th>Usuario</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    <tr class="" *ngFor="let item of archivosContrato">
                                                        <td *ngIf="idRol !== 3 || item.esPublico == 'S'">
                                                            <span class="pb-2 mt-2 d-flex">
                                                                <i class="fas fa-file icons-primary"></i>
                                                            </span>
                                                            <!-- <p>
                                                                {{ item.CORRELATIVO }}
                                                            </p> -->
                                                        </td>
                                                        <td *ngIf="idRol !== 3 || item.esPublico == 'S'">
                                                            <h5 class="pb-0 pt-2 mb-0" style="font-weight:normal;">
                                                                {{ item.descripcion }}
                                                            </h5>
                                                            <span class="text-muted pb-2">{{ item.GRUPO }}</span>
                                                        </td>
                                                        <td *ngIf="idRol !== 3 || item.esPublico == 'S'">
                                                            <span *ngIf="item.esPublico == 'S'" class="tab tab-green mb-2 mt-2">Publico</span>
                                                        </td>
                                                        <td *ngIf="idRol !== 3 || item.esPublico == 'S'">
                                                            <p class="text-muted pb-2 pt-2 mb-0">
                                                                {{ item.fecha_creacion | date }}
                                                            </p>
                                                            <!-- <p class="mb-0 text-muted" *ngIf="modificacion.monto_actualizado != null"> Monto actualizado: {{ modificacion.monto_actualizado | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</p>
                                                            <p class="mb-0 text-muted" *ngIf="modificacion.fecha_inicio != null"> Fecha inicio: {{ modificacion.fecha_inicio }}</p>
                                                            <p class="mb-0 text-muted" *ngIf="modificacion.fecha_termino != null"> Fecha termin: {{ modificacion.fecha_termino }}</p>
                                                            <p class="mb-0 text-muted" *ngIf="modificacion.alcance != null"> Nuevo alcance: {{ modificacion.alcance }}</p> -->
                                                        </td>
                                                        <td *ngIf="idRol !== 3 || item.esPublico == 'S'">
                                                            <p class="text-muted pb-2 pt-2 mb-0 c-case">
                                                                {{ item.usuario }}
                                                            </p>
                                                            <!-- <div class="tag">
                                                                {{ modificacion.ESTADO_SOLPE }}
                                                            </div> -->
                                                            <!-- <div *ngIf="modificacion.ESTADO_SOLPE == 'Aplicada'" class="tab tab-green">{{modificacion.ESTADO_SOLPE}}</div>
                                                            <div *ngIf="modificacion.ESTADO_SOLPE == 'Cerrada'" class="tab tab-yellow">Sin aplicar</div> -->
                                                            <!-- <div *ngIf="modificacion.ESTADO_SOLPE == 'Finiquitado'" class="tab tab-red">{{modificacion.ESTADO_SOLPE}}</div>
                                                            <div *ngIf="modificacion.ESTADO_SOLPE == 'Cancelado'" class="tab tab-red">{{modificacion.ESTADO_SOLPE}}</div> -->
                                                        </td>
                                                        <td *ngIf="idRol !== 3 || item.esPublico == 'S'" class="text-right"><!-- {{ modificacion.CORRELATIVO_ESDO_ITEMIZADO }} {{ modificacion.domi_correlativo_estadosolpe }} -->
                                                            <!-- <button *ngIf="modificacion.domi_correlativo_estadosolpe == 110"
                                                            (click)="aplicarModificacion(modificacion.CORRELATIVO)"
                                                            class="btn btn-warning" style="margin-left: 30px;" >
                                                                Aplicar modificacion
                                                            </button> -->
                                                            <div style="display: flex; justify-content: center;">
                                                                <div class="btn rounded-icon" (click)="onDescargar(item.NOMBRE_ARCHIVO)">
                                                                    <i class="fas fa-file-download"></i>
                                                                </div>
                                                                &nbsp;
                                                                <view-file-button
                                                                    [nombreArchivo]="item.NOMBRE_ARCHIVO"
                                                                    [tipoArchivo]="tipoArchivoContrato"
                                                                ></view-file-button>
                                                                <div class="btn rounded-icon" (click)="onDelete(item.NOMBRE_ARCHIVO, item.correlativo)">
                                                                    <i class="fas fa-trash"></i>
                                                                </div>
                                                            </div>


                                                            <!-- <div class="btn rounded-icon ml-4" (click)="showModalPreview(item.NOMBRE_ARCHIVO)">
                                                                <i class="fas fa-eye"></i>
                                                            </div>   -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- <div class="tab-content">
                                            <div class="col-md-12">
                                                <div class="checkbox checkbox-css" *ngFor="let item of archivosRegistrados">
                                                    <ng-container *ngIf="item.NOMBRE_ARCHIVO">
                                                        <a (click)="onDescargar(item.NOMBRE_ARCHIVO)">
                                                            <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                            <label style="cursor: pointer;" for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.NOMBRE_ARCHIVO">
                                                        <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                        <label for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div> -->
                                    </ng-container>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <!-- <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                        <div class="panel-heading   ui-sortable-handle">
                            <h4 class="panel-title normalText">Detalles Contrato {{ nameCode }}</h4>
                            <div class="panel-heading-btn ">
                                <a href="javascript:; " class="btn btn-xs btn-icon btn-circle btn-warning " data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                            </div>
                        </div>
                        <div class="panel-body" style="border: 1px solid #333 !important;">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a href="#default-tab-1" data-toggle="tab" class="nav-link active">
                                        <span class="d-sm-block d-none">Detalle Contrato</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#default-tab-3" data-toggle="tab" class="nav-link">
                                        <span class="d-sm-block d-none">Modificaciones</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#default-tab-4" data-toggle="tab" class="nav-link">
                                        <span class="d-sm-block d-none">Estado de Pagos del contrato</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#default-tab-5" data-toggle="tab" class="nav-link">
                                        <span class="d-sm-block d-none">Boletas del contrato</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#default-tab-2" data-toggle="tab" class="nav-link">
                                        <span class="d-sm-block d-none">Documentos</span>
                                    </a>
                                </li>
                            </ul>

                            <div class="tab-content">
                                <div class="tab-pane fade active show" id="default-tab-1">
                                    <ng-container *ngIf="dataContrato ">
                                        <table class="table table-bordered table-striped">
                                            <tr>
                                                <td>
                                                    <div class="row">
                                                        <div class="col col-md-6" style="margin-bottom: 5px;"><strong>Rut Proveedor:</strong></div>
                                                        <div class="col col-md-6">{{ dataContrato.RUT_PROVEEDOR}}</div>
                                                        <div class="col col-md-6" style=" margin-bottom: 5px;"><strong>Proveedor:</strong></div>
                                                        <div class="col col-md-6">{{ dataContrato.PROVEEDOR}}</div>
                                                        <div class="col col-md-6" style="margin-bottom: 5px;"><strong>Administrador:</strong></div>
                                                        <div class="col col-md-6">{{ dataContrato.ADMINISTRADOR}}</div>
                                                        <div class="col col-md-6" style="margin-bottom: 5px;"><strong>Admin. Proveedor:</strong></div>
                                                        <div class="col col-md-6">{{ dataContrato.ADMIN_PROVEEDOR}}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row">
                                                        <div class="col col-md-6" style="margin-bottom: 5px;"><strong>Fecha inicio:</strong></div>
                                                        <div class="col col-md-6" style="margin-bottom: 5px;">{{ dataContrato.FECHA_INICIO_CONTRATO}}</div>
                                                        <div class="col col-md-6" style="margin-bottom: 5px;"><strong>Fecha término:</strong></div>
                                                        <div class="col col-md-6" style="margin-bottom: 5px;">{{ dataContrato.FECHA_TERMINO_CONTRATO}}</div>
                                                        <div class="col col-md-6" style="margin-bottom: 5px;"><strong>Fecha vencimiento:</strong></div>
                                                        <div class="col col-md-6" style="margin-bottom: 5px;">{{ dataContrato.FECHA_TERMINO_CONTRATO}}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="row">
                                                        <div class="col col-md-6"><strong>Monto original:</strong></div>
                                                        <div class="col col-md-6">{{ dataContrato.MONTO_ORIGINAL | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row">
                                                        <div class="col col-md-6"><strong>Moneda:</strong></div>
                                                        <div class="col col-md-6">{{ dataContrato.TIPO_MONEDA }}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <br>
                                        <hr>

                                        <div class="form-group">
                                            <h4 class="col-form-label">Montos</h4>
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <th class="text-break" style="width: 25%;">Tipo</th>
                                                    <th class="text-break" style="width: 25%; text-align: right;">{{dataContrato.TIPO_MONEDA}}</th>
                                                    <th class="text-break" style="width: 25%; text-align: right;">USD</th>

                                                </thead>
                                                <tbody>
                                                    <tr>

                                                        <td>Monto</td>
                                                        <td style="text-align: right;">{{dataContrato.MONTO_ORIGINAL | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>
                                                        <td *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_ORIGINAL / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>
                                                        <td *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_ORIGINAL * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>
                                                    </tr>
                                                    <tr>

                                                        <td>Actualizado</td>
                                                        <td style="text-align: right;">{{dataContrato.MONTO_ACTUALIZADO | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>

                                                        <td *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_ACTUALIZADO / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>
                                                        <td *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_ACTUALIZADO * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>

                                                    </tr>
                                                    <tr>

                                                        <td>Reajuste</td>
                                                        <td style="text-align: right;">{{dataContrato.MONTO_REAJUSTE | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>

                                                        <td *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_REAJUSTE / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>
                                                        <td *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_REAJUSTE * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>

                                                    </tr>
                                                    <tr>

                                                        <td>EP</td>
                                                        <td style="text-align: right;">{{dataContrato.MONTO_EP | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>

                                                        <td *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ (dataContrato.MONTO_EP / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>
                                                        <td *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ ((dataContrato.MONTO_EP * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>

                                                    </tr>
                                                    <tr>

                                                        <td>Saldo</td>
                                                        <td style="text-align: right;">{{(dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP) | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}} </td>

                                                        <td *ngIf="dataContrato.TIMO_CORRELATIVO == 9" style="text-align: right;">{{ ((dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>
                                                        <td *ngIf="!(dataContrato.TIMO_CORRELATIVO == 9)" style="text-align: right;">{{ (((dataContrato.SALDO_CONTRATO - dataContrato.MONTO_EP) * dataContrato.VALOR_MONEDA_CONTRATO) / dataContrato.VALOR_USD) | currency: this.GloblaDataService.USD?.ISOCode: this.GloblaDataService.USD?.Simbol: this.GloblaDataService.USD?.decimalPipe}}</td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ng-container>

                                </div>

                                <div class="tab-pane fade" id="default-tab-5">
                                    <div class="tab-content">
                                        <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterBoletas($event) " placeholder="Buscar ... " #input>
                                        <table mat-table [dataSource]="boletas" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                                            <ng-container matColumnDef="NroBoleta">
                                                <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                    <div class="row">
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            Nro. Boleta:
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            {{element.NUM_BOLETA }}
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            Monto:
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            {{element.MONTO | currency:'CLP':'$' }}
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            Banco:
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            {{element.BANCO}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="FechaEmision">
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <div class="row">
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            Vencimiento:
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                            {{element.FECHA_EMISION}}
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                            Moneda:
                                                        </div>
                                                        <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                            {{element.TIPO_MONEDA}}
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Opciones">
                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                    <i class="fas fa-lg fa-fw m-r-10 fa-cloud-download-alt"></i>
                                                </td>
                                            </ng-container>

                                            <tr (click)="onVerBoleta(row.NUM_BOLETA) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dcBoletas; "></tr>
                                            <tr class="mat-row " *matNoDataRow>
                                                <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="default-tab-3">
                                    <div class="col-12">
                                        <table>
                                            <thead>
                                                <th>N Solpe</th>
                                                <th>Descripcion</th>
                                                <th>Estado</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let modificacion of modificacionesContrato">
                                                    <td>{{ modificacion.CORRELATIVO }}</td>
                                                    <td>
                                                        <p class="mb-0">
                                                            {{ modificacion.SOLPE_NOMBRE }}
                                                        </p>
                                                        <p class="mb-0">
                                                            <span *ngIf="modificacion.monto_actualizado != null"> Monto actualizado: {{ modificacion.monto_actualizado | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</span>
                                                            <span *ngIf="modificacion.fecha_inicio != null"> Fecha inicio: {{ modificacion.fecha_inicio }}</span>
                                                            <span *ngIf="modificacion.fecha_termino != null"> Fecha termin: {{ modificacion.fecha_termino }}</span>
                                                            <span *ngIf="modificacion.alcance != null"> Nuevo alcance: {{ modificacion.alcance }}</span>
                                                        </p>
                                                    </td>
                                                    <td>{{ modificacion.ESTADO_SOLPE }}</td>
                                                    <td>
                                                        <button *ngIf="modificacion.domi_correlativo_estadosolpe == 110"
                                                        (click)="aplicarModificacion(modificacion.CORRELATIVO)"
                                                        class="btn btn-warning" style="margin-left: 30px;" >
                                                            Aplicar modificacion
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="default-tab-4">
                                    <ng-container >
                                        <div class="col-xl-12">
                                            <ul class="nav nav-tabs">
                                                <li class="nav-item">
                                                    <a href="#default-tab-41" data-toggle="tab" class="nav-link active">
                                                        <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-money-bill-alt"></i>Estados de pago</span>
                                                        <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-money-bill-alt"></i>Estados de pago</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item" *ngIf="idRol !== 3 ">
                                                    <a href="#default-tab-51" data-toggle="tab" class="nav-link" >
                                                        <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-circle"></i>Estados de pago incurrido</span>
                                                        <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-circle"></i>Estados de pago incurrido</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item" *ngIf="idRol !== 3 ">
                                                    <a href="#default-tab-61" data-toggle="tab" class="nav-link">
                                                        <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle"></i>Estados de pago provisional</span>
                                                        <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle"></i>Estados de pago provisional</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="tab-content">
                                                <div class="tab-pane fade active show" id="default-tab-41">
                                                    <div class="col-md-12">
                                                        <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input>
                                                        <table mat-table [dataSource]="dsEPNormal" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                                                            <ng-container matColumnDef="DatosEp">
                                                                <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                                    <div class="row">
                                                                        <div class="col col-md-4" style="font-size: smaller;">
                                                                            Contrato:
                                                                        </div>
                                                                        <div class="col col-md-8" style="font-size: smaller;">
                                                                            {{element.CONTRATO }}
                                                                        </div>
                                                                        <div class="col col-md-4" style="font-size: smaller;">
                                                                            Descripción EP:
                                                                        </div>
                                                                        <div class="col col-md-8" style="font-size: smaller;">
                                                                            {{element.EP_DESCRIPCION }}
                                                                        </div>
                                                                        <div class="col col-md-4" style="font-size: smaller;">
                                                                            Estado Ep.:
                                                                        </div>
                                                                        <div class="col col-md-8" style="font-size: smaller;">
                                                                            {{element.EP_ESTADO}}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="FechaEp">
                                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                    <div class="row">
                                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                                            Desde:
                                                                        </div>
                                                                        <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                            {{element.EP_PERIODO_DESDE}}
                                                                        </div>
                                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                                            Hasta:
                                                                        </div>
                                                                        <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                            {{element.EP_PERIODO_HASTA}}
                                                                        </div>
                                                                        <div class="col col-md-6" style="font-size: smaller;">
                                                                            Fecha de estado:
                                                                        </div>
                                                                        <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                            {{element.FECHA_ESTADO_EP}}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="Opciones">
                                                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                    <i class="fas fa-lg fa-fw m-r-10 fa-eye"></i>
                                                                </td>
                                                            </ng-container>
                                                            <tr (click)="onIrEp(row.EP_CORRELATIVO) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPNormal; "></tr>

                                                            <tr class="mat-row " *matNoDataRow>
                                                                <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                                            </tr>
                                                        </table>

                                                            <mat-paginator #paginatorEPNormal [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="default-tab-51">
                                                    <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input>
                                                    <table mat-table [dataSource]="dsEPIncurrido" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                                                        <ng-container matColumnDef="DatosEp">
                                                            <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                                <div class="row">
                                                                    <div class="col col-md-4" style="font-size: smaller;">
                                                                        Contrato:
                                                                    </div>
                                                                    <div class="col col-md-8" style="font-size: smaller;">
                                                                        {{element.CONTRATO }}
                                                                    </div>
                                                                    <div class="col col-md-4" style="font-size: smaller;">
                                                                        Descripción EP:
                                                                    </div>
                                                                    <div class="col col-md-8" style="font-size: smaller;">
                                                                        {{element.EP_DESCRIPCION }}
                                                                    </div>
                                                                    <div class="col col-md-4" style="font-size: smaller;">
                                                                        Estado Ep.:
                                                                    </div>
                                                                    <div class="col col-md-8" style="font-size: smaller;">
                                                                        {{element.EP_ESTADO}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="FechaEp">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col col-md-6" style="font-size: smaller;">
                                                                        Desde:
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                        {{element.EP_PERIODO_DESDE}}
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller;">
                                                                        Hasta:
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                        {{element.EP_PERIODO_HASTA}}
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller;">
                                                                        Fecha de estado:
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                        {{element.FECHA_ESTADO_EP}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Opciones">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <i class="fas fa-lg fa-fw m-r-10 fa-eye"></i>
                                                            </td>
                                                        </ng-container>
                                                        <tr (click)="onIrEp(row.EP_CORRELATIVO) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPIncurrido; "></tr>

                                                        <tr class="mat-row " *matNoDataRow>
                                                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                                        </tr>
                                                    </table>
                                                    <mat-paginator  #paginatorEPIncurrido [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                                </div>
                                                <div class="tab-pane fade" id="default-tab-61">
                                                    <input class="form-control col-md-4" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEPIncurrido($event) " placeholder="Buscar ... " #input>
                                                    <table mat-table [dataSource]="dsEPProvisional" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                                                        <ng-container matColumnDef="DatosEp">
                                                            <td mat-cell *matCellDef="let element " style="padding: 0px !important;">
                                                                <div class="row">
                                                                    <div class="col col-md-4" style="font-size: smaller;">
                                                                        Contrato:
                                                                    </div>
                                                                    <div class="col col-md-8" style="font-size: smaller;">
                                                                        {{element.CONTRATO }}
                                                                    </div>
                                                                    <div class="col col-md-4" style="font-size: smaller;">
                                                                        Descripción EP:
                                                                    </div>
                                                                    <div class="col col-md-8" style="font-size: smaller;">
                                                                        {{element.EP_DESCRIPCION }}
                                                                    </div>
                                                                    <div class="col col-md-4" style="font-size: smaller;">
                                                                        Estado Ep.:
                                                                    </div>
                                                                    <div class="col col-md-8" style="font-size: smaller;">
                                                                        {{element.EP_ESTADO}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="FechaEp">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <div class="row">
                                                                    <div class="col col-md-6" style="font-size: smaller;">
                                                                        Desde:
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                        {{element.EP_PERIODO_DESDE}}
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller;">
                                                                        Hasta:
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                        {{element.EP_PERIODO_HASTA}}
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller;">
                                                                        Fecha de estado:
                                                                    </div>
                                                                    <div class="col col-md-6" style="font-size: smaller; padding-right: 0px;">
                                                                        {{element.FECHA_ESTADO_EP}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Opciones">
                                                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                                                                <i class="fas fa-lg fa-fw m-r-10 fa-eye"></i>
                                                            </td>
                                                        </ng-container>
                                                        <tr (click)="onIrEp(row.EP_CORRELATIVO) " style="cursor: pointer; " mat-row *matRowDef="let row; columns: dsHeaderEPProvisional; "></tr>

                                                        <tr class="mat-row " *matNoDataRow>
                                                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                                        </tr>
                                                    </table>
                                                    <mat-paginator #paginatorEPProvisional [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="tab-pag fade" id="default-tab-2">

                                    <ng-container *ngIf="archivos">
                                        <div class="tab-content">
                                            <div class="col-md-12">
                                                <div class="checkbox checkbox-css" *ngFor="let item of archivos">
                                                    <ng-container *ngIf="item.NOMBRE_ARCHIVO">
                                                        <a (click)="onDescargar(item.NOMBRE_ARCHIVO)">
                                                            <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                            <label style="cursor: pointer;" for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="!item.NOMBRE_ARCHIVO">
                                                        <input disabled type="checkbox" id="cssCheckbox{{item.CORRELATIVO}}" value="" [checked]="item.EXISTE === 'S' ? true : false">
                                                        <label for="cssCheckbox{{item.CORRELATIVO}}">{{ item.GRUPO}}</label>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- avance -->
                    <!-- <div class="panel panel-inverse" data-sortable-id="form-stuff-3">
                        <div class="panel-heading   ui-sortable-handle">
                            <h4 class="panel-title normalText">Avance Contrato {{nameCode}}</h4>
                            <div class="panel-heading-btn ">
                                <a href="javascript:; " class="btn btn-xs btn-icon btn-circle btn-warning " data-click="panel-collapse"><i class="fa fa-minus "></i></a>
                            </div>
                        </div>
                        <div class="panel-body" style="border: 1px solid #333 !important;">
                            <ng-container *ngIf="avanceTiempo ">
                                <div class="form-group row m-b-15 ">
                                    <table>
                                        <tr>
                                            <td>
                                                <div class="row">
                                                    <div class="col col-md-6">
                                                        <strong>Meses contrato:</strong>
                                                    </div>
                                                    <div class="col col-md-6">
                                                        {{avanceTiempo.MESES_CONTRATO}}
                                                    </div>
                                                    <div class="col col-md-6">
                                                        <strong>Meses restantes:</strong>
                                                    </div>
                                                    <div class="col col-md-6">
                                                        {{avanceTiempo.MESES_RESTANTES}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="row">
                                                    <div class="col col-md-6">
                                                        <strong>Meses avance:</strong>
                                                    </div>
                                                    <div class="col col-md-6">
                                                        {{avanceTiempo.MESES_AVANCE}}
                                                    </div>
                                                    <div class="col col-md-6">
                                                        <strong>Dias restantes:</strong>
                                                    </div>
                                                    <div class="col col-md-6">
                                                        {{avanceTiempo.DIAS_RESTANTE}}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="row ">
                                    <label class="col-form-label col-md-3 ">Av Tiempo</label>
                                    <div class="col-md-9 " style="margin-top: 10px; ">
                                        <div class="progress rounded-corner progress-striped active ">
                                            <div *ngIf="avanceTiempo.PORCENTAJE===0 " class="progress-bar bg-gray " style="width: 100% ">
                                                0%
                                            </div>
                                            <div *ngIf="avanceTiempo.PORCENTAJE> 0" class="progress-bar bg-codelco-azul-20" [ngStyle]="{'width': avanceTiempo.PORCENTAJE+'%' }">
                                                {{avanceTiempo.PORCENTAJE}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="avanceFinanciero">
                                <div class="row">
                                    <label class="col-form-label col-md-3">Av Financiero</label>
                                    <div class="col-md-9" style="margin-top: 10px;">
                                        <div class="progress rounded-corner progress-striped active">
                                            <div *ngIf="avanceFinanciero.PORCENTAJE ===0" class="progress-bar bg-gray" style="width: 100%">
                                                0%
                                            </div>
                                            <div class="progress-bar bg-codelco-yellow" [ngStyle]="{'width': avanceFinanciero.PORCENTAJE+'%' }">
                                                {{avanceFinanciero.PORCENTAJE}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="row">
                                    <div *ngIf="chartDonutDataTiempo" class="col-md-6" style="margin-top: 10px;">
                                        <div mk-morris-js [options]="chartDonutOptions" [data]="chartDonutDataTiempo" type="Donut"></div>
                                    </div>
                                    <div *ngIf="chartDonutDataFinc" class="col-md-6" style="margin-top: 10px;">
                                        <div mk-morris-js [options]="chartDonutOptions" [data]="chartDonutDataFinc" type="Donut"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div> -->


                    <!-- <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                        <div class="panel-heading   ui-sortable-handle">
                            <h4 class="panel-title normalText">Itemizado del contrato {{nameCode}} </h4>
                            <div class="panel-heading-btn ">
                                <a href="javascript:; " class="btn btn-xs btn-icon btn-circle btn-warning " data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                            </div>
                        </div>
                        <div class="panel-body" style="border: 1px solid #333 !important;">
                            <ng-container *ngIf="itemizado.length > 0">
                                <div class="row">
                                    <div class="col-md-12 table_container">
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <th>R</th>
                                                <th>Q</th>
                                                <th>SubPos</th>
                                                <th>Desc</th>
                                                <th>Unidad</th>
                                                <th>Cantidad</th>
                                                <th>V.Unitario</th>
                                                <th>Total</th>
                                                <th>Consumo</th>
                                                <th>Saldo</th>
                                                <th>Consumo Q</th>
                                                <th>Saldo Q</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of itemizado">
                                                    <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>

                                                    <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                                    <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                                    <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                                    <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Unidad }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (item.PrecioUnitario * item.Cantidad) | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Consumo | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ ((item.PrecioUnitario * item.Cantidad) - item.Consumo) | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.ConsumoQ | number:'1.2': 'es-CL' }}</td>
                                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (item.Cantidad - item.ConsumoQ) | number:'1.2': 'es-CL' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="row">
                                    <legend>Total</legend>
                                    <table class="table table-striped table-bordered table-td-valign-middle ">
                                        <thead>
                                            <th colspan="5" style="width: 48%;">&nbsp;</th>
                                            <th style="width: 11%;">Total</th>
                                            <th colspan="2" style="width:83px;">&nbsp;</th>
                                            <th style="width: 9%;">Avance</th>
                                            <th style="width: 9%;">Saldo</th>
                                            <th colpsan="2" style="width:6%;">&nbsp;</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colspan="5">&nbsp;</td>
                                                <td>{{ this.getSumTotal() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>
                                                <td colspan="2">&nbsp;</td>
                                                <td>{{ this.getSumAvance() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <td>{{ this.getSaldo() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <td colspan="2">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                        </div>
                    </div> -->
                    <p class="text-right"><a href="javascript:;" #closeModalVer class="btn btn-light" data-dismiss="modal">Cerrar</a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-message" id="modalAdec" >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="width: 80%;">
                    <h4 class="modal-title">Administrador Contratista</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body" style="width: 80%;">
                    <h4 class="modal-title">Detalle Contrato {{nameCode}}</h4>
                    <div class="row">
                        <div class="col-md-12">

                            <table mat-table [dataSource]="dsAdecContrato" matSort class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle">
                                <ng-container matColumnDef="AdminContratista">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="AdminContratista">Admin. Contratista</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_ADEC }}</td>
                                </ng-container>
                                <ng-container matColumnDef="FechaInicio">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaInicio">Fecha Inicio</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_INICIO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaTermino">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaTermino">Fecha Termino</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_TERMINO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Vigente">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Vigente">Vigente</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.VIGENTE ==='S' ? 'Si' : 'No' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="UsuarioActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="UsuarioActualizacion">Usuario Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_CREACION}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaActualizacion">Fecha Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_CREACION | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="dsHeaderAdecContrato"></tr>
                                <tr mat-row *matRowDef="let row; columns: dsHeaderAdecContrato;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                            </table>

                            <mat-paginator #paginatorAdec [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10"></div>
                        <div class="col-md-2">
                            <a href="javascript:;" class="btn btn-light btn-block" data-dismiss="modal">
                                <i class="fas fa-window-close"></i> Cerrar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-message" id="modalAdecEmsa" >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="width: 80%;">
                    <h4 class="modal-title">Administrador EMSA</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body" style="width: 80%;">
                    <div class="row" style="margin-bottom: 10px;">
                        <div class="col-md-6">
                            <h4 class="modal-title">Detalle Contrato {{nameCode}} </h4>
                        </div>
                        <div class="col-md-6" style="text-align: end;">
                            <button class="btn btn-warning" style="margin-left: 30px;" (click)="showUpdateEmsa = !showUpdateEmsa">
                                <i class="fas fa-user-secret"></i>
                                Admin. EMSA </button>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 20px;" *ngIf="showUpdateEmsa">
                        <div class="col-md-3" style="text-align: left;padding-top: 5px;">
                            <label>Administrador Emsa</label>
                        </div>
                        <div class="col-md-7">
                            <ng-select2 [data]="ademsa" [value]="0" width="100%" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submitted && f.ademsa.errors}" (valueChanged)='onGetAdemsaUpdateId($event)'>
                            </ng-select2>
                            <div *ngIf="submitted && f.ademsa.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>

                        </div>
                        <div class="col-md-2" style="text-align: end;">
                            <button class="btn btn-success" (click)="onGuardarAdminEmsa()">
                                <i class="fas fa-save"></i> Guardar</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table mat-table [dataSource]="dsAdecEmsaContrato" matSort class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle">
                                <ng-container matColumnDef="AdminEmsa">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="AdminEmsa">Admin. Emsa</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_EMSA }}</td>
                                </ng-container>
                                <ng-container matColumnDef="FechaInicio">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaInicio">Fecha Inicio</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_INICIO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaTermino">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaTermino">Fecha Termino</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_TERMINO | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Vigente">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Vigente">Vigente</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.VIGENTE ==='S' ? 'Si' : 'No' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="UsuarioActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="UsuarioActualizacion">Usuario Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_USUA_CREACION}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="FechaActualizacion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaActualizacion">Fecha Actualización</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_CREACION | date: 'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="dsHeaderAdecEmsaContrato"></tr>
                                <tr mat-row *matRowDef="let row; columns: dsHeaderAdecEmsaContrato;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                            </table>
                            <mat-paginator #paginatorAdecEmsa [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10"></div>
                        <div class="col-md-2">
                            <a href="javascript:;" class="btn btn-light btn-block" data-dismiss="modal">
                                <i class="fas fa-window-close"></i> Cerrar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>



<!-- Modal de seleccionar Solpe -->
<div class="modal modal-message" id="modal-solpe">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Seleccione Solpe</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #modalSeleccionarSolpe>×</button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col col-md-6">
                        <input class="form-control" matInput (keyup)="applyFilterSolpes($event)" placeholder="Buscar ... " #input>
                    </div>
                    <div class="col col-md-6 text-right">
                        <a href="#modal-agregar" data-toggle="modal"  type="button" class="btn btn-primary" (click)="seleccionarContratoInterno()">
                            Crear contrato interno
                        </a>
                    </div>
                </div>
                <br>
                <table mat-table [dataSource]="dataSourceSolpe" matSort class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle">
                    <ng-container matColumnDef="Correlativo">
                        <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Correlativo">Correlativo</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.CODIGO_SOLPE }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Nombre">
                        <th class=" normalText" style="padding:
                          10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Nombre">Nombre</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_SOLPE}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Forma">
                        <th class=" normalText" style="padding:
                          10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Forma">Forma</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FORMA_SOLPE}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Tipo">
                        <th class=" normalText" style="padding:
                          10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Tipo">Tipo</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.TIPO_SOLPE}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="FechaAprobacion">
                        <th class=" normalText" style="padding:
                          10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaAprobacion">Fecha Aprobación</th>
                        <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_APROBACION | date: 'dd/MM/yyyy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Acciones" class="text-right" style="text-align: right !important;">
                        <th class=" normalText text-right" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
                        <td class="text-right" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                            <a href="#modal-agregar" data-toggle="modal" class="btn btn-primary btn-sm" (click)="seleccionarSolpe(element.SOLPE_CORRELATIVO, element.MONTO, element.MONEDA, element.NOMBRE_SOLPE, element.GESTIONADOR)" style="color:white">
                                Seleccionar
                            </a>
                        </td>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="dataSourceHeaderSolpe"></tr>
                    <tr mat-row *matRowDef="let row; columns: dataSourceHeaderSolpe;" id="row" style="cursor: pointer;" (click)="selectionSolpe.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionSolpe.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                </table>

                <!-- <mat-paginator #paginatorSolpe [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->

            </div>
        </div>
    </div>
</div>

<!-- Modal de crear -->
<div class="modal modal-message" id="modal-agregar" style="margin-left: 15px !important;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Contrato</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarContrato" (ngSubmit)="onCrearContrato()" novalidate>
                    <br>
                    <div class="row form-group">
                        <label for="solpe" class="col-md-2 col-form-label">Solpe</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="solpe" #solpe>
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of solpesCerradas" [value]="item.SOLPE_CORRELATIVO">{{item.NOMBRE_SOLPE}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-md-2 col-form-label">Año Contrato</label>
                        <div class="col-md-4">
                            <input type="number" formControlName="ano_contrato" #ano_contrato class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ano_contrato.errors }" />
                        </div>
                        <label class="col-md-2 col-form-label">Código de Contrato</label>
                        <div class="col-md-4 input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">CO - </div>
                            </div>
                            <input type="text" formControlName="codigo_contrato" #codigo_contrato class="form-control" [ngClass]="{ 'is-invalid': submitted && f.codigo_contrato.errors }" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-md-2 col-form-label">Nombre del Contrato</label>
                        <div class="col-md-10">
                            <input type="text" formControlName="nombre_contrato" #nombre_contrato class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre_contrato.errors }" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="tipo_contrato" class="col-md-2 col-form-label">Tipo de Contrato</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="tipo_contrato" #tipo_contrato [ngClass]="{ 'is-invalid': submitted && f.tipo_contrato.errors }">
                          <option value="0">Seleccione ...</option>
                          <option *ngFor="let item of tipoContrato" [value]="item.correlativo">{{item.descripcion}}</option>
                      </select>
                        </div>
                        <label class="col-md-2 col-form-label">Proveedor</label>
                        <div class="col-md-4">
                            <ng-select2 [data]="proveedores" width="100%" #proveedor name="proveedor" formControlName="proveedor" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submitted && f.proveedor.errors}" (valueChanged)='onGetAdminContrato($event)'>
                            </ng-select2>
                            <div *ngIf="submitted && f.proveedor.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-md-2 col-form-label"></label>
                        <div class="col-md-4">
                        </div>
                        <label class="col-md-2 col-form-label">Admin. Contratista</label>
                        <div class="col-md-4">
                            <ng-select2 [data]="admcPorProveedor" width="100%" #admin_contrato name="admin_contrato" formControlName="admin_contrato" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submitted && f.admin_contrato.errors}">
                            </ng-select2>
                            <div *ngIf="submitted && f.admin_contrato.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-md-2 col-form-label">Monto Contrato</label>
                        <div class="col-md-4">
                            <input type="number" formControlName="monto" (ngModelChange)="changeMontoContratovista($event)" #monto class="form-control" [ngClass]="{ 'is-invalid': submitted && f.monto.errors }" />
                            <br>
                        </div>
                        <label for="tipo_moneda" class="col-md-2 col-form-label">Moneda</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="tipo_moneda" #tipo_moneda [ngClass]="{ 'is-invalid': submitted && f.tipo_moneda.errors }">
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of tipoMoneda" [value]="item.correlativo">{{item.descripcion}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6">
                                    <p style="text-align: left; font-size: 18px;">Monto del Contrato</p>
                                </div>
                                <div class="col-6">
                                    <p style="text-align: right; font-size: 18px;">{{montoContratoInput | number: '1.2' : 'es-CL'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6">
                                    <p style="text-align: left; font-size: 18px;">Monto de la Solpe</p>
                                </div>
                                <div class="col-6">
                                    <p style="text-align: right; font-size: 18px;">{{montoSolpeParaContrato | number: '1.2': 'es-CL'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="background-color: #f5f5f5; border-radius: 5px; padding: 10px">
                        <div class="row form-group">
                            <label for="fecha_contrato" class="col-md-2 col-form-label">Fecha del Contrato</label>
                            <div class="col-md-4 input-group">
                                <input type="date" class="form-control m-b-5" placeholder="Fecha de Contrato" formControlName="fecha_contrato" #fecha_contrato [ngClass]="{ 'is-invalid': submitted && f.fecha_contrato.errors }">
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="fecha_inicio" class="col-md-2 col-form-label">Fecha Inicio Contrato</label>
                            <div class="col-md-4 input-group">
                                <input type="date" class="form-control m-b-5" placeholder="Fecha Inicio Contrato" formControlName="fecha_inicio" #fecha_inicio [ngClass]="{ 'is-invalid': submitted && f.fecha_inicio.errors }">
                            </div>
                            <label for="fecha_termino" class="col-md-2 col-form-label">Fecha Término Contrato</label>
                            <div class="col-md-4">
                                <input type="date" class="form-control m-b-5" placeholder="Fecha Término Contrato" formControlName="fecha_termino" #fecha_termino [ngClass]="{ 'is-invalid': submitted && f.fecha_termino.errors }">
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row form-group">
                        <label for="banco" class="col-md-2 col-form-label">Gerencia</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="gerencia" #gerencia [ngClass]="{ 'is-invalid': submitted && f.gerencia.errors }">
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of tipoGerencia" [value]="item.correlativo">{{item.descripcion}}</option>
                            </select>
                        </div>
                        <label for="banco" class="col-md-2 col-form-label">Área</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="area" #area [ngClass]="{ 'is-invalid': submitted && f.area.errors }">
                          <option value="0">Seleccione ...</option>
                          <option *ngFor="let item of tipoArea" [value]="item.correlativo">{{item.descripcion}}</option>
                      </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="banco" class="col-md-2 col-form-label">Admin. Emsa</label>
                        <div class="col-md-4">
                            <ng-select2 [data]="ademsa" width="100%" #administrador name="administrador" formControlName="administrador" class="select2-selection select2-selection--single" [ngClass]="{ 'is-invalid': submitted && f.administrador.errors}">
                            </ng-select2>
                            <div *ngIf="submitted && f.administrador.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                        <label for="banco" class="col-md-2 col-form-label">Gestionador</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="gestionador" #gestionador [ngClass]="{ 'is-invalid': submitted && f.gestionador.errors }">
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of gestionadores" value="{{ item.CORRELATIVO }}">{{ item.NOMBRE }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="origen" class="col-md-2 col-form-label">Origen</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="origen" #origen [ngClass]="{ 'is-invalid': submitted && f.origen.errors }">
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of tipoOrigen" [value]="item.correlativo">{{item.descripcion}}</option>
                            </select>
                        </div>
                        <label for="cct_critico" class="col-md-2 col-form-label">Contrato Critico</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="cct_critico" #cct_critico [ngClass]="{ 'is-invalid': submitted && f.origen.errors }">
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-md-2 col-form-label" for="bg">Requiere BG?</label>
                        <div class="col-md-10" style="padding-top: 10px;">
                            <input type="checkbox" formControlName="bg" name="bg" #bg value="1" [ngClass]="{ 'is-invalid': submitted && f.bg.errors }" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="estado_proceso" class="col-md-2 col-form-label">Estado del Proceso</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="estado_proceso" #estado_proceso [ngClass]="{ 'is-invalid': submitted && f.estado_proceso.errors }">
                          <option value="0">Seleccione ...</option>
                          <option *ngFor="let item of tipoEstadoProceso" [value]="item.correlativo">{{item.descripcion}}</option>
                      </select>
                        </div>
                        <label for="estado_contrato" class="col-md-2 col-form-label">Estado del Contrato</label>
                        <div class="col-md-4">
                            <select class="form-control" formControlName="estado_contrato" #estado_contrato [ngClass]="{ 'is-invalid': submitted && f.estado_contrato.errors }">
                          <option value="0">Seleccione ...</option>
                          <option *ngFor="let item of tipoEstadoContrato" [value]="item.correlativo">{{item.descripcion}}</option>
                      </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-md-2 col-form-label" for="contrato_interno">Contrato interno?</label>
                        <div class="col-md-10" style="padding-top: 10px;">
                            <input type="checkbox" formControlName="contrato_interno" name="contrato_interno" #contrato_interno value="1" [ngClass]="{ 'is-invalid': submitted && f.contrato_interno.errors }" />
                        </div>
                    </div>
                    <br><br>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-2"><a href="javascript:;" (click)="onClose()" #closeModalAgregar class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                        <div class="col-md-4 text-right">
                            <button class="btn btn-primary btn-block" type="submit" [disabled]="formAgregarContrato.invalid"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar Contrato</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de filtro por fecha de los conratos -->
<!-- Modal -->
<div class="modal fade" id="modalExportarRangoFechas" tabindex="-1" role="dialog" aria-labelledby="modalExportarRangoFechasLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="modalExportarRangoFechasLabel">Exportar</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div>
                    <p>Se debe seleccionar un rango de fechas de los contratos Vigentes a exportar</p>
                    <form [formGroup]="formExportarContratosPorFecha" (ngSubmit)="onExportarContratosPorFecha()" novalidate>
                        <div class="row form-group">
                            <label for="fechaInicio" class="col-md-3 col-form-label">Fecha Desde</label>
                            <div class="col-md-9">
                                <input type="date" formControlName="fechaIni" #fechaIni class="form-control" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="fechaInicio" class="col-md-3 col-form-label">Fecha Hasta</label>
                            <div class="col-md-9">
                                <input type="date" formControlName="fechaFin" #fechaFin class="form-control" />
                            </div>
                        </div>
                        <br>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="submit" class="btn btn-primary ml-2" [disabled]="formExportarContratosPorFecha.invalid">Exportar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>


<!-- Modal de cargar documento -->
<div class="modal fade" id="modal-archivo">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Documento</h4>
                <button type="button" class="close" data-dismiss="modal" #closebutton aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                    <div class="row form-group">
                        <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="grupo" #grupo [ngClass]="{ 'is-invalid': submittedDocumento && fd.grupo.errors}">
                      <option value="0">Seleccione ...</option>
                      <option *ngFor="let item of archivos" [value]="item.CORRELATIVO">{{item.GRUPO}}</option>
                    </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                        <div class="col-md-9">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                        <div class="col-md-9">
                            <input type="text" formControlName="descripcion" #descripcion class="form-control" [ngClass]="{ 'is-invalid': submittedDocumento && fd.descripcion.errors}"/>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-md-3 col-form-label" for="bg">Documento publico</label>
                        <div class="col-md-9" style="padding-top: 10px;">
                            <input type="checkbox" formControlName="isPublic" name="isPublic" #isPublic value="0" [ngClass]="{ 'is-invalid': submittedDocumento && fd.isPublic.errors}"/>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4 text-right">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                        </div>
                    </div> -->
                    <input type="hidden" name="corr_cont" formControlName="corr_cont" #corr_cont value="" />

                    <!-- <div *ngIf="documentosSolpe">
                        <hr>
                        <p *ngFor="let item of documentosSolpe">
                            <span style="color: #999; font-weight: bold !important;" [ngClass]="{ 'text-red': item.OBLIGATORIO == 'S'}">
                            <i class="fa fa-lg fa-fw m-r-10 fa-file"></i> {{ item.descripcion | uppercase }}: &nbsp;
                            </span>
                            <a [routerLink]="" (click)="onDescargar(item.nombre_archivo)">{{ item.nombre_archivo }}</a>
                        </p>
                    </div> -->
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-4" type="submit">Agregar</button>
                            <a href="javascript:;" (click)="onCloseArchivo()" #closeModalAgregarArchivo class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>