<div id="content" class="content">
    <a (click)="back()" style="margin-right: 10px;" class="btn btn-primary text-white"><i _ngcontent-yco-c146=""
            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"></i> Volver</a>
    <br>
    <br>
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <!-- <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Estado de Pago</h4>
        </div> -->
        <div class="p3-rem bg-white rounded" id="printComponent">
            <div class="row">
                <div class="col-8">
                    <h4 class="mb-0">
                        Contrato {{contrato.CODIGO_CONTRATO}} {{ contrato.NOMBRE_CONTRATO }} <br>
                    </h4>
                    <p class="">{{ contrato.PROVEEDOR }}</p>
                </div>
                <div class="col-4 text-right">
                    <span class="tab tab-green text-center">{{ cabecera?.ESDO_EP_DESC }}</span>
                </div>
            </div>
            <h5 class="mb-0">
                {{ cabecera?.EP_DESCRIPCION }}
            </h5>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-12">
                            <span class="d-block d-md-inline align-center mr-5 mt-3">
                                <span class="text-muted mb-0">Periodo:</span>
                                <span>
                                    {{ cabecera?.EP_PERIODO_DESDE | date: 'dd/MM/yyyy'}} - {{ cabecera.EP_PERIODO_HASTA |
                                    date: 'dd/MM/yyyy'}}
                                </span>
                                <!-- <a *ngIf="cabecera.ESDO_EP == 100 && rolId == 3" class="btn rounded-icon ml-2" href="#modalPeriodo" data-toggle="modal" title="Modificar periodo"> -->
                                <!-- <a class="btn rounded-icon ml-2" href="#modalPeriodo" data-toggle="modal" title="Modificar periodo">
                                    <i class="fas fa-pen"></i>
                                </a> -->
                            </span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3">
                                <span class="mb-0 text-muted">Monto EP:</span>
                                <ng-container *ngIf="distribucionV1.length > 0">
                                    <span>
                                        {{ totalMontoDistribucion | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="distribucionV1.length <= 0">
                                    <span>
                                        <!-- {{ TotalEPActual | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }} -->
                                        {{ getMontoEp() | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}
                                    </span>
                                </ng-container>
                            </span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3">
                                <span class="mb-0 text-muted">Moneda:</span>
                                <span>
                                    {{ contrato.TIPO_MONEDA }}
                                </span>
                            </span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"
                                *ngIf="contrato.TIMO_CORRELATIVO !== 9">
                                <span class="text-muted mb-0">Tasa de cambio:</span>
                                <span>
                                    <span>
                                        {{ cabecera.EXCHAGE_RATE | number : '' :'es-CL'}}
                                    </span>
                                    <a *ngIf="(cabecera.ESDO_EP == 101 || cabecera.ESDO_EP == 107) && rolId == 2" class="btn rounded-icon ml-2 mr-2"
                                        href="#modalTasaCambio" data-toggle="modal" title="Modificar tasa" (click)="getTasas()">
                                        <i class="fas fa-pen"></i>
                                    </a>
                                    <span *ngIf="cabecera.ESDO_EP == 101 && rolId == 2">
                                        <!-- <span *ngIf="tasaActualizada === true" class="tab tab-green text-center">Tasa actualizada</span> -->
                                        <span *ngIf="tasaActualizada === false" class="tab tab-yellow text-center">Verificar tasa de cambio</span>
                                    </span>
                                </span>
                            </span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"
                                *ngIf="retencion?.RETENCION_LABORAL !== null">
                                <span class="mb-0 text-muted">Retención Laboral:</span>
                                <span>
                                    {{ retencion.RETENCION_LABORAL }}
                                </span>
                            </span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"
                                *ngIf="retencion?.RETENCION_CONTRACTUAL !== null">
                                <span class="mb-0 text-muted">Retención Contractual:</span>
                                <span>
                                    {{ retencion.RETENCION_CONTRACTUAL }}
                                </span>
                            </span>
                            <!-- *ngIf="cabecera?.REAJUSTE !== '' && cabecera?.REAJUSTE > 0" -->
                            <span class="d-block d-md-inline align-center mr-5 mt-3" *ngIf="TotalReajuste > 0">
                                <span class="mb-0 text-muted">Reajuste:</span>
                                <span *ngIf="cabecera?.REAJUSTE !== '' && cabecera?.REAJUSTE > 0">
                                    {{ cabecera.REAJUSTE | number: '' : 'es-CL' }} %
                                </span>
                                <span>
                                    ({{ this.TotalReajuste | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }})
                                </span>
                            </span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"
                                *ngIf="cabecera.HES != null">
                                <span class="mb-0 text-muted">HES:</span>
                                <span>
                                    {{ cabecera.HES }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <hr>
                    <br>
                </div>
                <div class="col-md-12 text-right no-print">
                    <button class="btn tag text-primary mr-2"
                        *ngIf="(cabecera.ESDO_EP == 101 || cabecera.ESDO_EP == 107) && rolId == 2" data-toggle="modal"
                        data-target="#modal-rechazar-ep">Rechazar Estado de Pago</button>
                    <button class="btn tag text-primary mr-2"
                        *ngIf="(cabecera.ESDO_EP == 105 || cabecera.ESDO_EP == 137) && rolId == 20" data-toggle="modal"
                        data-target="#modal-rechazar-ep">Rechazar Estado de Pago</button>
                    <!-- <button *ngIf="rolId !== 10 && rolId !== 13 && cabecera && contrato && detalleEP !== null"
                        type="button" class="btn tag mr-2" (click)="onDescargarPDF()">
                        <i class="fas fa-lg fa-fw m-r-10 fa-print"></i>
                        Descargar PDF
                    </button> -->
                    <button *ngIf="rolId !== 10 && rolId !== 13 && cabecera && contrato && detalleEP !== null" type="button" class="btn tag mr-2"
                        (click)="onDescargarPDF()">
                        <i class="fas fa-lg fa-fw m-r-10 fa-print"></i>
                        Descargar PDF
                    </button>
                    <a style="margin-right: 5px; margin-left: 5px;" href="#modal-historial" data-toggle="modal"
                        class="btn tag text-primary">
                        <i class="fa fa-lg fa-fw m-r-10 fa-list-ol"></i> Ver Historial
                    </a>
                    <button *ngIf="(cabecera.ESDO_EP == 104 || cabecera.ESDO_EP == 108 || cabecera.ESDO_EP == 109)" style="margin-right: 5px;" type="button" class="btn tag text-primary" data-toggle="modal"
                        data-target="#modal-Grafos">Grafos</button>
                    <ng-container *ngIf="cabecera.ESDO_EP == 137 && rolId == 20">
                        <button style="margin-right: 5px;" type="button" class="btn tag text-primary"
                            (click)="reeviarGrafos()" [disabled]="!distribucionesCreadas">Reenviar grafos</button>
                    </ng-container>
                    <!-- <button style="margin-right: 5px;" type="button" class="btn btn-primary btn-sm" (click)="probarComprobanteXls()">Probar comprobante xls</button> -->
                    <button *ngIf="(cabecera.ESDO_EP == 104 || cabecera.ESDO_EP == 108 || cabecera.ESDO_EP == 109)" style="margin-right: 5px;" type="button" class="btn tag text-primary" data-toggle="modal"
                        data-target="#modal-comprobante">Comprobante</button>

                    <button style="margin-right: 5px;" type="button" class="btn btn-primary"
                    href="#modalEditar" data-toggle="modal" title="Modificar datos generales"
                    *ngIf="(cabecera.ESDO_EP == 100 || cabecera.ESDO_EP == 106) && rolId == 3"
                    >Modificar</button>
                </div>
            </div>

            <br>
            <!-- <ng-container *ngIf="cabecera.ESDO_EP != 104 && cabecera.ESDO_EP != 108 && cabecera.ESDO_EP != 109"> -->
            <ng-container>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-8 text-right">
                        <h5 *ngIf="rolId == 20">
                            <!-- Cantidad Pendiente por Distribuir: {{ totalDetalleEP - totalDistribucion | number:'1.2': 'es-CL'}} &nbsp;&nbsp; -->
                            Monto Pendiente por Distribuir: {{ pendientePorDistribuir() | currency:this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}
                        </h5>
                    </div>
                </div>
                <ng-container *ngIf="(cabecera.ESDO_EP == 100 || cabecera.ESDO_EP == 106) && rolId == 3;
                then editTemplate else showTemplate">
                </ng-container>
                <!-- edit template  -->
                <ng-template #editTemplate>
                    <div class="row">
                        <div class="col-12">
                            <div class="table_container">
                                <table class="table border">
                                    <thead class="thead-darkk">
                                        <tr>
                                            <th>R</th>
                                            <th>Q</th>
                                            <th>SubPos</th>
                                            <th>Descripción</th>
                                            <th>Unidad</th>
                                            <th class="text-right">Cant.</th>
                                            <th class="text-right">P. Unit.</th>
                                            <th class="text-right">Total</th>
                                            <th class="text-right border border-right-0 border-bottom-0"
                                                style="border: none">Anterior</th>
                                            <th class="text-right border border-right-0 border-bottom-0 border-left-0"
                                                style="border: none">Actual</th>
                                            <th class="text-right border border-left-0 border-bottom-0"
                                                style="border: none">Ep</th>
                                            <th class="text-right">Actual + Rea</th>
                                            <th class="text-right">Reajuste</th>
                                            <!-- <th>{{ contrato.TIPO_MONEDA }}</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of itemizado">
                                            <tr>
                                                <td *ngIf="item.Cabecera" colspan="16"><strong>{{ item.Cabecera }}</strong>
                                                </td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R"
                                                    class="pt-2"><i class="fa fa-check"></i></td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.R"
                                                    class="pt-2">&nbsp;</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.Scpq"
                                                    class="pt-2"><i class="fa fa-check"></i></td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.Scpq"
                                                    class="pt-2">&nbsp;</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera" class="pt-2">
                                                    {{ item.Subpos }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera" class="pt-2">
                                                    {{ item.Descripcion }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.Unidad }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">
                                                        {{ item.Cantidad | number:'1.2': 'es-CL' }}
                                                </td>
                                                <!-- <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td> -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.PrecioUnitario | currency:
                                                    this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'
                                                    }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ (item.Cantidad * item.PrecioUnitario) |
                                                    currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.AvanceQ | number:'1.2': 'es-CL' }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.AvanceQ + item.CantidadSolicitada | number:'1.2': 'es-CL' }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">
                                                    <ng-container *ngIf="(cabecera.ESDO_EP == 100 || cabecera.ESDO_EP == 106) && rolId == 3;then editNumber else showNumber">here is ignored</ng-container>
                                                    <ng-template #editNumber>
                                                        <input *ngIf="item.Scpq" type="number" appDecimalPlaces [(ngModel)]="item.CantidadSolicitada" step="0.01" min="0" class="form-control form-control-sm" (change)="actulizarCantidades2(item.idDetalleEstadoPago, item.CantidadSolicitada, item.Correlativo)"/>
                                                        <input *ngIf="!item.Scpq" type="number" appDecimalPlaces [(ngModel)]="item.CantidadSolicitada" step="0.01" min="0" max="{{item.Cantidad - item.AvanceQ}}" class="form-control form-control-sm" (change)="actulizarCantidades2(item.idDetalleEstadoPago, item.CantidadSolicitada, item.Correlativo)"/>
                                                    </ng-template>
                                                    <ng-template #showNumber>{{ item.CantidadSolicitada | number:'1.2': 'es-CL' }}</ng-template>
                                                </td>
                                                <!-- <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R" class="pt-2 text-right">{{ item.TimoAcumAnt }} + {{item.PrecioUnitario}} * {{item.CantidadSolicitada}} * {{this.cabecera.REAJUSTE}}</td> -->
                                                <!-- monto actual -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R"
                                                    class="pt-2 text-right">{{ +((+item.PrecioUnitario *
                                                    +item.CantidadSolicitada) * (((+this.cabecera.REAJUSTE) / 100) + 1)) |
                                                    currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.R"
                                                    class="pt-2 text-right">{{ (item.PrecioUnitario *
                                                    item.CantidadSolicitada) | currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <!-- monto ep -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R"
                                                    class="pt-2 text-right"> {{ ((item.PrecioUnitario *
                                                    item.CantidadSolicitada) * ((+this.cabecera.REAJUSTE) / 100)) |
                                                    currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.R"
                                                    class="pt-2 text-right"> {{0 | number:'1.2': 'es-CL'}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                    <tr>
                                        <td colspan="7"></td>
                                        <td class="text-right">
                                            <p class="mb-0">
                                                <!-- {{this.TotalEP | currency: this.contractCurrenci?.ISOCode: '':
                                                this.contractCurrenci?.decimalPipe : 'es-CL'}} -->
                                            </p>
                                        </td>
                                        <td colspan="3"></td>
                                        <td class="text-right">
                                            <p class="mb-0">
                                                <!-- {{this.TotalEPActual | currency: this.contractCurrenci?.ISOCode: '':
                                                this.contractCurrenci?.decimalPipe : 'es-CL'}} -->
                                                {{getMontoEp() | currency: this.contractCurrenci?.ISOCode: '':
                                                this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                            </p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0">
                                                {{this.TotalReajuste | currency: this.contractCurrenci?.ISOCode: '':
                                                this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- end edit template -->

                <!-- show template -->
                <ng-template #showTemplate>
                    <div *ngIf="rolId !== 10 && rolId !== 13" class="row mt-4">
                        <div class="col-md-12">
                            <div class="table_container">
                                <table class="table border" id="table_detalle">
                                    <thead class="thead-darkk">
                                        <tr>
                                            <th colspan="5"></th>
                                            <th colspan="3">Original</th>
                                            <th colspan="3" class="text-center border border-top-0">Avance Cantidad</th>
                                            <th colspan="3" class="text-center">Avance en {{ contrato.TIPO_MONEDA }}</th>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                            <th *ngIf="!downloadingPdf">&nbsp;</th>
                                        </tr>
                                        <tr>
                                            <th>R</th>
                                            <th>Q</th>
                                            <th>SubPos</th>
                                            <th>Descripción</th>
                                            <th>Unidad</th>
                                            <th class="text-right">Cant.</th>
                                            <th class="text-right">P. Unit.</th>
                                            <th class="text-right">Total</th>
                                            <th class="text-right border border-right-0 border-bottom-0"
                                                style="border: none">Anterior</th>
                                            <th class="text-right border border-right-0 border-bottom-0 border-left-0"
                                                style="border: none">Actual</th>
                                            <th class="text-right border border-left-0 border-bottom-0"
                                                style="border: none">Ep</th>
                                            <th class="text-right">Anterior</th>
                                            <th class="text-right">Acumulado</th>
                                            <th class="text-right">Actual + Rea</th>
                                            <th class="text-right">Reajuste</th>
                                            <!-- <th>{{ contrato.TIPO_MONEDA }}</th> -->
                                             <!-- Saldo -->
                                            <th class="text-right"></th>
                                            <th *ngIf="!downloadingPdf" class="text-right">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of detalleEP">
                                            <tr >
                                                <td *ngIf="item.Cabecera" colspan="16"><strong>{{ item.Cabecera }}</strong>
                                                </td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R"
                                                    class="pt-2"><i class="fa fa-check"></i></td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.R"
                                                    class="pt-2">&nbsp;</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.Scpq"
                                                    class="pt-2"><i class="fa fa-check"></i></td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.Scpq"
                                                    class="pt-2">&nbsp;</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera" class="pt-2">
                                                    {{ item.Subpos }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera" class="pt-2">
                                                    {{ item.Descripcion }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.Unidad }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">
                                                        {{ item.Cantidad | number:'1.2': 'es-CL' }}
                                                </td>
                                                <!-- <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td> -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.PrecioUnitario | currency:
                                                    this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'
                                                    }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ (item.Cantidad * item.PrecioUnitario) |
                                                    currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.CantidadAcumAnt | number:'1.2': 'es-CL' }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ item.CantidadAcumAnt + item.CantidadSolicitada | number:'1.2': 'es-CL' }}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">
                                                    <ng-container *ngIf="(cabecera.ESDO_EP == 100 || cabecera.ESDO_EP == 106) && rolId == 3;then editNumber else showNumber">here is ignored</ng-container>
                                                    <ng-template #editNumber>
                                                        <input *ngIf="item.Scpq" type="number" appDecimalPlaces [(ngModel)]="item.CantidadSolicitada" step="0.01" min="0" class="form-control form-control-sm" (change)="calcularTotales();actulizarCantidades(item.DetCorrelativo, item.CantidadSolicitada)"/>
                                                        <input *ngIf="!item.Scpq" type="number" appDecimalPlaces [(ngModel)]="item.CantidadSolicitada" step="0.01" min="0" max="{{item.Cantidad - item.AvanceQ}}" class="form-control form-control-sm" (change)="calcularTotales();actulizarCantidades(item.DetCorrelativo, item.CantidadSolicitada)"/>
                                                    </ng-template>
                                                    <ng-template #showNumber>{{ item.CantidadSolicitada | number:'1.2': 'es-CL' }}</ng-template>
                                                </td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right">{{ (item.TimoAcumAnt) | currency:
                                                    this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'
                                                    }}</td>
                                                <!-- monto acumulada -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R"
                                                    class="pt-2 text-right">{{ +(item.TimoAcumAnt + ((item.PrecioUnitario *
                                                    item.CantidadSolicitada) * (((+this.cabecera.REAJUSTE) / 100) + 1))) |
                                                    currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <!-- <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R" class="pt-2 text-right">{{ item.TimoAcumAnt }} + {{item.PrecioUnitario}} * {{item.CantidadSolicitada}} * {{this.cabecera.REAJUSTE}}</td> -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.R"
                                                    class="pt-2 text-right">{{ (item.TimoAcumAnt + (item.PrecioUnitario *
                                                    item.CantidadSolicitada)) | currency: this.contractCurrenci?.ISOCode:
                                                    '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <!-- monto actual -->
                                                <td [ngClass]="{'text-red font-weight-bold': (sumatoriaLineas(item.PrecioUnitario, item.CantidadSolicitada, (+this.cabecera.REAJUSTE), item.R, distribuciones, item.Correlativo) !== 0) && rolId == 20}" style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R"
                                                    class="pt-2 text-right" title="{{sumatoriaLineas(item.PrecioUnitario, item.CantidadSolicitada, (+this.cabecera.REAJUSTE), item.R, distribuciones, item.Correlativo) |
                                                        currency: this.contractCurrenci?.ISOCode: '':
                                                        this.contractCurrenci?.decimalPipe : 'es-CL'}}">{{ +((+item.PrecioUnitario *
                                                    +item.CantidadSolicitada) * (((+this.cabecera.REAJUSTE) / 100) + 1)) |
                                                    currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <td [ngClass]="{'text-red font-weight-bold': (sumatoriaLineas(item.PrecioUnitario, item.CantidadSolicitada, (+this.cabecera.REAJUSTE), item.R, distribuciones, item.Correlativo) !== 0) && rolId == 20}" style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.R"
                                                    class="pt-2 text-right" title="{{sumatoriaLineas(item.PrecioUnitario, item.CantidadSolicitada, (+this.cabecera.REAJUSTE), item.R, distribuciones, item.Correlativo) |
                                                        currency: this.contractCurrenci?.ISOCode: '':
                                                        this.contractCurrenci?.decimalPipe : 'es-CL'}}">{{ (item.PrecioUnitario *
                                                    item.CantidadSolicitada) | currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                <!-- monto ep -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && item.R"
                                                    class="pt-2 text-right"> {{ ((item.PrecioUnitario *
                                                    item.CantidadSolicitada) * ((+this.cabecera.REAJUSTE) / 100)) |
                                                    currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !item.R"
                                                    class="pt-2 text-right"> {{0 | number:'1.2': 'es-CL'}}</td>
                                                    <!-- {{ ((item.Saldo)-(item.CantidadAcumAnt *
                                                    item.PrecioUnitario)) | currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL' }} -->
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera"
                                                    class="pt-2 text-right"></td>
                                                <td style="background-color: #f5f5f5;" *ngIf="!item.Cabecera && !downloadingPdf"
                                                    class="pt-2 text-right">
                                                    <a *ngIf="(cabecera.ESDO_EP == 102 || cabecera.ESDO_EP == 137) && (totalDetalleEP != totaDistribucionAprobada) && rolId == 20"
                                                        href="#modal-distribucion" data-toggle="modal"
                                                        (click)="cargarModalDistribucion(item.Correlativo, item.CantidadSolicitada, item.PrecioUnitario, item.CorrArticulo, item.R)"
                                                        class="btn tag text-primary">
                                                        Distribución
                                                    </a>
                                                </td>
                                            </tr>
                                            <ng-container
                                                *ngIf="!item.Cabecera && existeDistribucion(item.Correlativo, item.Cabecera)">
                                                <tr style="color: #627884; text-transform: uppercase;">
                                                    <td class="border border-top-0 border-bottom-0 border-left-0"
                                                        style="background-color: f5f5f5"></td>
                                                    <td colspan="4">Articulo</td>
                                                    <td colspan="2">Zona</td>
                                                    <td colspan="3">Blanco</td>
                                                    <td>Grafo</td>
                                                    <td class="text-right">Cantidad</td>
                                                    <td class="text-right">Monto</td>
                                                    <td>Responsable</td>
                                                    <td>Estado</td>
                                                    <td class="text-right"><span *ngIf="!downloadingPdf">Acciones</span></td>
                                                    <td *ngIf="!downloadingPdf" class="text-right"></td>
                                                    <!-- <td class="text-right">Enviar grafo</td> -->
                                                </tr>
                                                <tr *ngFor="let i of distribuciones">
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO"
                                                        class="border border-top-0 border-bottom-0 border-left-0"
                                                        style="background-color: f5f5f5"></td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO" colspan="4">{{
                                                        i.ARTICULO_NOMBRE }}</td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO" colspan="2">{{
                                                        i.ZONA_NOMBRE }}</td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO" colspan="3">{{
                                                        i.BLANCO_NOMBRE }}</td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO">{{ i.GRAFO_CODIGO
                                                        }}</td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO"
                                                        class="text-right">{{ i.CANTIDAD_ASIGNADA | number:'1.2': 'es-CL' }}</td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO"
                                                        class="text-right">{{ i.MONTO_ASIGNADO | currency:
                                                        this.contractCurrenci?.ISOCode: '':
                                                        this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO">{{
                                                        i.RESPONSABLE_GRAFO }}</td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO">
                                                        <p *ngIf="i.ESTADO == 'ACEPTADA'" class="text-primary">APROBADO</p>
                                                        <p *ngIf="i.ESTADO == 'PENDIENTE'" class="text-info">PENDIENTE</p>
                                                        <p *ngIf="i.ESTADO == 'RECHAZADA'" class="text-danger">RECHAZADO</p>
                                                        <p *ngIf="i.ESTADO == 'EVALUACION DE GRAFO'" class="text-info">
                                                            EVALUACION DE GRAFO</p>
                                                    </td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO"
                                                        class="text-right">
                                                        <!-- <a *ngIf="(rolId == 20 && cabecera.ESDO_EP == 103 && i.ESTADO == 'PENDIENTE') || (rolId == 20 && cabecera.ESDO_EP == 102) || (rolId == 20 && cabecera.ESDO_EP == 103 && i.ESTADO == 'RECHAZADA')" href="#modal-ModDistribucion" data-toggle="modal" (click)="cargarModalPorDistribucion(i.DISTRIBUCION_CORRELATIVO, item.Correlativo, item.CantidadSolicitada, item.PrecioUnitario)" class="btn btn-primary btn-sm"
                                                            title="Modificar Cantidad Distribución">
                                                            <i class="fa fa-edit"></i>
                                                        </a> -->
                                                        <!-- <a *ngIf="(rolId == 20 && (cabecera.ESDO_EP == 103 || cabecera.ESDO_EP == 137) && i.ESTADO == 'PENDIENTE') ||
                                                        (rolId == 20 && cabecera.ESDO_EP == 102) ||
                                                        (rolId == 20 && (cabecera.ESDO_EP == 103 ||  cabecera.ESDO_EP == 137) && i.ESTADO == 'RECHAZADA')"
                                                            href="#modal-ModDistribucion" data-toggle="modal"
                                                            (click)="cargarModalPorDistribucion(i.DISTRIBUCION_CORRELATIVO, item.Correlativo, item.CantidadSolicitada, item.PrecioUnitario, item.R)"
                                                            class="btn rounded-icon ml-2">
                                                            <i class="fas fa-pen"></i>
                                                        </a> -->
                                                        <!-- <a
                                                            *ngIf="(rolId == 20 && (cabecera.ESDO_EP == 103 || cabecera.ESDO_EP == 137) && i.ESTADO == 'PENDIENTE') ||
                                                            (rolId == 20 && cabecera.ESDO_EP == 102) ||
                                                            (rolId == 20 && (cabecera.ESDO_EP == 103 || cabecera.ESDO_EP == 137) && i.ESTADO == 'RECHAZADA')"
                                                            (click)="asignarCorrelativoEliminarLineaDistribucion(i.DISTRIBUCION_CORRELATIVO)"
                                                            href="#modal-eliminar-linea-distribucion"
                                                            data-toggle="modal"
                                                            class="btn rounded-icon ml-2"
                                                        >
                                                            <i class="fas fa-trash"></i>
                                                        </a> -->
                                                        <a
                                                            *ngIf="(rolId == 20 && (cabecera.ESDO_EP == 102 || cabecera.ESDO_EP == 137))"
                                                            (click)="asignarCorrelativoEliminarLineaDistribucion(i.DISTRIBUCION_CORRELATIVO)"
                                                            href="#modal-eliminar-linea-distribucion"
                                                            data-toggle="modal"
                                                            class="btn rounded-icon ml-2"
                                                        >
                                                            <i class="fas fa-trash"></i>
                                                        </a>
                                                        <a 
                                                            class="btn tag text-primary"
                                                            *ngIf="(cabecera.ESDO_EP == 103) && rolId == 22 && i.ESTADO == 'EVALUACION DE GRAFO'"
                                                            href="javascript:void"
                                                            (click)="onAceptarDistribucion(i.DISTRIBUCION_CORRELATIVO)"
                                                            style="cursor: pointer; display: flex;justify-content: center;align-items: center;"
                                                            data-toggle="modal" 
                                                            data-target="#aceptarDistribucionGrafosModal"
                                                        >
                                                                <i class="fa fa-check mr-2"></i> 
                                                                ACEPTAR
                                                            </a>
                                                        <br>
                                                        <a class="btn tag text-primary"
                                                            *ngIf="(cabecera.ESDO_EP == 103) && rolId == 22 && i.ESTADO == 'EVALUACION DE GRAFO'"
                                                            href="javascript:void"
                                                            (click)="asignarCorrelativoDistribucion(i.DISTRIBUCION_CORRELATIVO)"
                                                            style="cursor: pointer; display: flex;justify-content: center;align-items: center;"
                                                            data-toggle="modal"
                                                            data-target="#modal-rechazar-distribucion"><i
                                                                class="fa fa-times mr-2"></i> RECHAZAR</a>

                                                    </td>
                                                    <td *ngIf="item.Correlativo == i.ITEMCHK_CORRELATIVO && !downloadingPdf">
                                                        <a (click)="getComentariosDistribucion(i.DISTRIBUCION_CORRELATIVO)"
                                                            class="btn btn-primary btn-sm" data-toggle="modal"
                                                            data-target="#modal-comentarios-distribucion"
                                                            class="btn rounded-icon ml-2">
                                                            <i class="fas fa-comment-alt"></i>
                                                        </a>
                                                        <!-- <button type="button" (click)="getComentariosDistribucion(i.DISTRIBUCION_CORRELATIVO)" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modal-comentarios-distribucion" >Comentarios</button> -->
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                        <tr>
                                            <td colspan="7"></td>
                                            <td class="text-right">
                                                <!-- <p class="mb-0">
                                                    {{this.TotalEP | currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                </p> -->
                                            </td>
                                            <td colspan="3"></td>
                                            <td class="text-right">
                                                <!-- <p class="mb-0">
                                                    {{this.TotalAcumuladoAnterior | currency:
                                                    this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                </p> -->
                                            </td>
                                            <td class="text-right">
                                                <!-- <p class="mb-0">
                                                    {{this.TotalAcumuladoActual | currency: this.contractCurrenci?.ISOCode:
                                                    '': this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                </p> -->
                                            </td>
                                            <td class="text-right">
                                                <ng-container *ngIf="distribucionV1.length > 0">
                                                    <p class="mb-0">
                                                        {{totalMontoDistribucion | currency: this.contractCurrenci?.ISOCode: '':
                                                        this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                    </p>
                                                </ng-container>
                                                <ng-container *ngIf="distribucionV1.length <= 0">
                                                    <p class="mb-0">
                                                        <!-- {{this.TotalEPActual | currency: this.contractCurrenci?.ISOCode: '':
                                                        this.contractCurrenci?.decimalPipe : 'es-CL'}} -->
                                                        {{getMontoEp() | currency: this.contractCurrenci?.ISOCode: '':
                                                        this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                    </p>
                                                </ng-container>
                                                <!-- <p class="mb-0">
                                                    {{this.TotalEPActual | currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                </p> -->
                                            </td>
                                            <td class="text-right">
                                                <p class="mb-0">
                                                    {{this.TotalReajuste | currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                </p>
                                            </td>
                                            <td class="text-right">
                                                <!-- <p class="mb-0">
                                                    {{this.TotalEPSaldo | currency: this.contractCurrenci?.ISOCode: '':
                                                    this.contractCurrenci?.decimalPipe : 'es-CL'}}
                                                </p> -->
                                            </td>
                                            <td *ngIf="!downloadingPdf">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- end show template -->
                <div class="row">
                    <div *ngIf="rolId !== 10 && rolId !== 13" class="col-12 col-md-4">
                        <!-- <p class="text-muted mb-0">Saldo del contrato: </p>
                        <span class="">{{contrato.MONTO_ACTUALIZADO - contrato.MONTO_EP + contrato.MONTO_REAJUSTE | currency: this.contractCurrenci?.ISOCode: '':
                            this.contractCurrenci?.decimalPipe : 'es-CL'}}
                        </span> -->
                    </div>
                    <div class="col-12 col-md-8 mt-4 mb-0">
                        <div class="text-right">
                            <!-- EP-->
                            <ng-container *ngIf="esIncurrido ==='N'">
                                <button class="btn btn-primary" [disabled]="montoExedido() || !archivosSubidos"
                                    (click)="avanzarAdminEMSA()"
                                    *ngIf="(cabecera.ESDO_EP == 100 || cabecera.ESDO_EP == 106) && rolId == 3">Enviar a Administrador EMSA</button>
                                <button style="margin-right: 5px;" class="btn btn-primary"
                                    *ngIf="(cabecera.ESDO_EP == 101 || cabecera.ESDO_EP == 107) && rolId == 2"
                                    (click)="avanzarAPYC()">Pre-Aprobar Estado de Pago</button>
                                <!-- <button class="btn tag text-primary"
                                    *ngIf="(cabecera.ESDO_EP == 101 || cabecera.ESDO_EP == 107) && rolId == 2"
                                    data-toggle="modal"
                                    data-target="#modal-rechazar-ep"
                                    >Rechazar Estado de Pago</button> -->

                                <button style="margin-right: 5px;" class="btn btn-primary"
                                    *ngIf="cabecera.ESDO_EP == 105 && rolId == 20"
                                    (click)="avanzarADistribucion()">Pre-Aprobar Estado de Pago</button>
                                    
                                <!-- <button class="btn tag text-primary" *ngIf="(cabecera.ESDO_EP == 105 || cabecera.ESDO_EP == 137) && rolId == 20"
                                    data-toggle="modal"
                                    data-target="#modal-rechazar-ep"
                                    >Rechazar Estado de Pago</button> -->

                                <button style="margin-right: 5px;" class="btn btn-primary"
                                    *ngIf="cabecera.ESDO_EP == 102 && rolId == 20" [disabled]="!distribucionesCreadas"
                                    (click)="avanzarARespGrafos()">Enviar a Responsables de Grafo</button>

                                <p *ngIf="montoExedido() && cabecera.ESDO_EP !== 104 && cabecera.ESDO_EP !== 108 && cabecera.ESDO_EP !== 109 && cabecera.ESDO_EP !== 102 && cabecera.ESDO_EP !== 103"
                                    class="text-danger mt-2">
                                    El monto del estado de pago es mayor al saldo del contrato
                                </p>
                                <p *ngIf="!archivosSubidos"
                                    class="text-danger mt-2">
                                    Debe subir todos los archivos solicitados para poder continuar con el estado de pago
                                </p>

                                <!--<button class="btn btn-primary" *ngIf="cabecera.ESDO_EP == 103 && totalDetalleEP == totaDistribucionAprobada && rolId == 20" (click)="avanzarAGestSAP()">Avanzar Etapa</button>-->

                            </ng-container>
                            <!--EP INCURRIDO -->
                            <ng-container *ngIf="esIncurrido ==='S'">
                                <button class="btn btn-primary" (click)="avanzarAPYC()"
                                    *ngIf="(cabecera.ESDO_EP == 100 ) && rolId == 2">Enviar a PyC</button>
                                <button class="btn tag text-primary"
                                    *ngIf="cabecera.ESDO_EP == 105 && (rolId == 17 || rolId == 20)"
                                    (click)="rechazaPYCIncurrido()">Rechazar Estado de Pago</button> &nbsp;&nbsp;
                                <button class="btn btn-primary"
                                    *ngIf="cabecera.ESDO_EP == 105 && (rolId == 17 || rolId == 20)"
                                    (click)="aprobarEstadoPagoIncurrido()">Aprobar Estado de Pago</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="cabecera.ESDO_EP == 104 && rolId == 13">
                <br>
                <hr>
                <br>
                <ng-container *ngIf="hesExistentesEncontradas.length > 0">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title"><i class="fa fa-exclamation-triangle text-danger"></i> La HES ingresada ya fue utilizada</h4>
                                    <p class="card-text">La HES ingresada ya fue utilizada en otro estado de pago, por favor verifique la HES e intente nuevamente.</p>
                                    <a href="javascript:void(0)" style="font-size: 16px;" class="card-link text-primary" data-toggle="modal" data-target="#modalHesExistentes">Ver estados de pago asociados a la HES ingresada</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </ng-container>
                <form [formGroup]="formHES" (ngSubmit)="onAgregarHES()" novalidate>
                    <div class="row">
                        <div class="col-md-5">
                            <h5>HES (Hoja de Entrada de Servicio)</h5>
                            <input name="hes" class="form-control" formControlName="hes" type="number"
                                [ngClass]="{ 'is-invalid': submittedHES && fhes.hes.errors}" />
                        </div>
                        <div class="col-md-2">
                            <h5>&nbsp;</h5>
                            <button type="submit" class="btn btn-primary btn-block" [disabled]="formHES.invalid && !submittedHES">
                                Guardar
                            </button>
                        </div>
                    </div>
                </form>

                <br><br><br>
            </ng-container>
            <ng-container *ngIf="cabecera.ESDO_EP == 108 && rolId == 10">
                <div class="row">
                    <div class="col-md-7">
                        <h4>Finanzas</h4>
                        <hr>

                        <form [formGroup]="formFinanzas" (ngSubmit)="onAgregarFacturacion()" novalidate>
                            <div class="row form-group">
                                <label class="col-md-2 col-form-label">Número de Factura</label>
                                <div class="col-md-10">
                                    <input type="number" formControlName="nro_factura" #nro_factura class="form-control"
                                        [ngClass]="{ 'is-invalid': submittedFacturacion && ff.nro_factura.errors }" />
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="adjunto" class="col-md-2 col-form-label">Adjunto</label>
                                <div class="col-md-10">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" name="adjunto"
                                            formControlName="adjunto" id="adjunto"
                                            aria-describedby="inputGroupFileAddon01"
                                            (change)="detectFileFacturacion($event)"
                                            [ngClass]="{ 'is-invalid': submittedFacturacion && ff.adjunto.errors }">
                                        <label class="custom-file-label" for="adjunto">{{fileNameFactura}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="fecha_pago" class="col-md-2 col-form-label">Fecha de Pago</label>
                                <div class="col-md-10 input-group">
                                    <input type="date" class="form-control m-b-5" formControlName="fecha_pago"
                                        #fecha_pago
                                        [ngClass]="{ 'is-invalid': submittedFacturacion && ff.fecha_pago.errors }">
                                </div>
                            </div>
                            <p class="text-right">
                                <button class="btn btn-primary" type="submit">Guardar Datos</button>
                            </p>
                        </form>
                    </div>
                    <!-- <div class="col-md-5">
                        <h4>Comentarios</h4>
                        <hr>
                        <form [formGroup]="formAgregarComentario" (ngSubmit)="onAgregarComentario()" novalidate>
                            <div class="row form-group">
                                <label for="comentario" class="col-md-3 col-form-label">Comentario</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control" name="comentario" placeholder="Agregar Comentario" #comentario formControlName="comentario" [ngClass]="{ 'is-invalid': submittedComentario && fc.comentario.errors}" autocomplete="off">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4 text-right">
                                    <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                                </div>
                            </div>
                            <br><br><br>
                            <p *ngFor="let item of comentariosEP">
                                <span style="color: #999; font-weight: bold !important;">
                                            <i class="fa fa-lg fa-fw m-r-10 fa-comment-alt"></i> {{ item.FECHA_COMENTARIO }}
                                            {{ item.NOMBRE_USUARIO }}:
                                        </span> {{ item.COMENTARIO }}
                            </p>
                        </form>
                    </div> -->
                </div>
            </ng-container>
            <div *ngIf="cabecera.NRO_FACTURA !== null" class="mt-4">
                <h4>Datos de la factura</h4>
                <hr>
                <div class="tab-content mt-4">
                    <div class="col-md-12">
                        <div class="table_container">
                            <table class="table border">
                                <thead class="">
                                    <th class="text-normal">Nro. Factura</th>
                                    <th class="text-normal">Fecha de pago</th>
                                    <th class="text-normal">Comprobante</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{cabecera.NRO_FACTURA}}
                                        </td>
                                        <td>
                                            {{fechaPagoEp}}
                                        </td>
                                        <td>
                                            {{cabecera.ARCHIVO_FACTURA}}
                                        </td>
                                        <td>
                                            <!-- (click)="revisarSolpe(item.SOLPE_CORRELATIVO, item.SOLPE_ESTADO)" -->
                                            <div style="display: flex;">
                                                <view-file-button [nombreArchivo]="cabecera.ARCHIVO_FACTURA"
                                                    [tipoArchivo]="'facturas_ep'">
                                                </view-file-button>
                                                <div *ngIf="cabecera.ARCHIVO_FACTURA !== null" class="btn rounded-icon mr-2"
                                                    (click)="onDescargarFactura(cabecera.ARCHIVO_FACTURA)">
                                                    <i class="fas fa-file-download"></i>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-7">
            <div class="p3-rem rounded bg-white">
                <div class="row">
                    <div class="col-12">
                        <h5>
                            Documentos Requeridos
                        </h5>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <p class="text-muted">
                            Todos los documentos que se muestran de color rojo son requeridos para gestionar el estado
                            de pago, recuerde que debe subir todos estos antes de enviar su solicitud.
                        </p>
                    </div>
                </div>
                <ng-container *ngIf="esIncurrido ==='N'">
                    <div class="bg-white">
                        <div class="container-docs">
                            <div class="row" *ngFor="let item of checklist">
                                    <ng-container *ngIf="item.es_emsa == 'S';
                                    then emsaTemplate else NoEmsaTemplate">
                                    </ng-container>

                                    <ng-template #emsaTemplate>
                                        <ng-container *ngIf="esEmsa">
                                            <div class="col-12 mt-4" *ngIf="item.Cabecera">
                                                <span>{{ item.Cabecera }}</span>
                                            </div>

                                            <div class="col-12" *ngIf="!item.Cabecera">
                                                <!-- documento -->
                                                <div class="file border rounded mb-2 mt-2">
                                                    <div class="row">
                                                        <div class="col-9 content-center">
                                                            <div class="file-detail content-center nowrap">
                                                                <div class="content-center">
                                                                    <div *ngIf="rolId == 2 && (cabecera.ESDO_EP === 101 || cabecera.ESDO_EP === 107)"
                                                                    class="form-check form-check-inline">
                                                                        <label class="container-check">
                                                                            <input *ngIf="item.NombreArchivo !== null" type="checkbox" [checked]="item.checkedFile === 1 ? true : false" (change)="onChangeCheckFile($event, item.correlativoFile)">
                                                                            <span *ngIf="item.NombreArchivo !== null" class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="file-img content-center">
                                                                    <i class="far fa-file-alt text-primary mr-2"></i>
                                                                </div>
                                                                <div class="">
                                                                    <p class="mb-0 nombreArchivoCustom"
                                                                        [ngClass]="{'text-danger' : item.Obligatorio == 'S'}">
                                                                        {{item.ItemChk}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3 content-center" style="justify-content: end;">
                                                            <view-file-button [nombreArchivo]="item.NombreArchivo"
                                                                [tipoArchivo]="'checklist'"></view-file-button>
                                                            <div *ngIf="item.NombreArchivo !== null" class="btn rounded-icon mr-2"
                                                                (click)="onDescargar(item.NombreArchivo)">
                                                                <i class="fas fa-file-download"></i>
                                                            </div>
                                                            <button *ngIf="existeAccion('1') && rolId == 2" type="button"
                                                                class="btn-sm btn tag text-primary" data-toggle="modal"
                                                                data-target="#exampleModal"
                                                                (click)="openModal(item.ItemChkCorrelativo, item.ItemChk)">
                                                                Subir
                                                            </button>
                                                            <!-- <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- documento end -->
                                            </div>
                                        </ng-container>

                                    </ng-template>

                                    <ng-template #NoEmsaTemplate>
                                        <div class="col-12 mt-4" *ngIf="item.Cabecera">
                                            <span>{{ item.Cabecera }}</span>
                                        </div>
                                        <div class="col-12" *ngIf="!item.Cabecera">
                                            <!-- documento -->
                                            <div class="file border rounded mb-2 mt-2">
                                                <div class="row">
                                                    <div class="col-9 content-center">
                                                        <div class="file-detail content-center nowrap">
                                                            <div class="content-center">
                                                                <div *ngIf="rolId == 2 && (cabecera.ESDO_EP === 101 || cabecera.ESDO_EP === 107)"
                                                                class="form-check form-check-inline">
                                                                    <label class="container-check">
                                                                        <input *ngIf="item.NombreArchivo !== null" type="checkbox" [checked]="item.checkedFile === 1 ? true : false" (change)="onChangeCheckFile($event, item.correlativoFile)">
                                                                        <span *ngIf="item.NombreArchivo !== null" class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="file-img content-center">
                                                                <i class="far fa-file-alt text-primary mr-2"></i>
                                                            </div>
                                                            <div class="">
                                                                <p class="mb-0 nombreArchivoCustom"
                                                                    [ngClass]="{'text-danger' : item.Obligatorio == 'S'}">
                                                                    {{item.ItemChk}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 content-center" style="justify-content: end;">
                                                        <view-file-button [nombreArchivo]="item.NombreArchivo"
                                                            [tipoArchivo]="'checklist'"></view-file-button>
                                                        <div *ngIf="item.NombreArchivo !== null" class="btn rounded-icon mr-2"
                                                            (click)="onDescargar(item.NombreArchivo)">
                                                            <i class="fas fa-file-download"></i>
                                                        </div>
                                                        <button *ngIf="cabecera.ESDO_EP !== 109 && existeAccion('1') && rolId == 3" type="button"
                                                            class="btn-sm btn tag text-primary" data-toggle="modal"
                                                            data-target="#exampleModal"
                                                            (click)="openModal(item.ItemChkCorrelativo, item.ItemChk)">
                                                            Subir
                                                        </button>
                                                        <!-- <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- documento end -->
                                        </div>
                                    </ng-template>

                                        <!-- <div class=" row">
                                            <div class="d-flex col-8 p-11 mb-2 mt-2 border rounded">
                                                <i class="far fa-file-alt d-flex align-center"></i>
                                                <span class="ml-3" [ngClass]="{'text-danger' : item.Obligatorio == 'S'}">
                                                    {{ item.ItemChk }}
                                                </span>
                                                <a  (click)="onDescargar(item.NombreArchivo)" style="cursor: pointer; color: #348fe2;">{{ item.NombreArchivo }}</a>
                                            </div>
                                            <div class="col mb-2 mt-2">
                                                <button type="button" class="btn-sm btn tag text-primary"
                                                data-toggle="modal" data-target="#exampleModal"
                                                (click)="openModal(item.ItemChkCorrelativo, item.ItemChk)">
                                                    Subir
                                                </button>
                                            </div>
                                        </div> -->


                            </div>
                        </div>
                        <!-- <table class="table">
                            <thead class="shadow-none bg-whitesmoke">
                                <th colspan="3">Documentos</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of checklist" >
                                    <td *ngIf="item.Cabecera" colspan="3" class="border-0">
                                        <span><strong>{{ item.Cabecera }}</strong></span>
                                    </td>
                                    <td *ngIf="!item.Cabecera"
                                    class="border border-right-0"
                                    [ngClass]="{'text-danger' : item.Obligatorio == 'S'}">
                                        <div class="d-flex">
                                            <i class="far fa-file-alt d-flex align-center"></i>
                                            <span class="ml-3">
                                                {{ item.ItemChk }}
                                            </span>
                                        </div>
                                    </td>
                                    <td *ngIf="!item.Cabecera" class="text-right border border-right-0 border-left-0">
                                        <a  (click)="onDescargar(item.NombreArchivo)" style="cursor: pointer; color: #348fe2;">{{ item.NombreArchivo }}</a>
                                    </td>
                                    <td *ngIf="!item.Cabecera" class="text-right border border-left-0">
                                        <button type="button" class="btn-sm btn tag text-primary"
                                        data-toggle="modal" data-target="#exampleModal"
                                        (click)="openModal(item.ItemChkCorrelativo, item.ItemChk)">
                                            Subir
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> -->
                    </div>
                </ng-container>
                <ng-container *ngIf="esIncurrido ==='S'">
                    <div class="bg-white">
                        <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivoEPIncurrido()" novalidate>
                            <div class="row form-group">
                                <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                                <div class="col-md-9">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" name="adjunto" id="adjunto"
                                            (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01"
                                            [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                        <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4 text-right">
                                    <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146=""
                                            class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                                </div>
                            </div>
                        </form>
                        <br>
                        <table class="table">
                            <thead class="thead-dark">
                                <th>Documentos</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of archivosEPIncurrido">
                                    <td class="text-center"><a (click)="onDescargarEPIncurrido(item.NOMBRE_ARCHIVO)"
                                            style="cursor: pointer; color: #348fe2;">{{ item.NOMBRE_ARCHIVO }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-12 col-md-5">
            <form class="p3-rem rounded bg-white" [formGroup]="formAgregarComentario" (ngSubmit)="onAgregarComentario()"
                novalidate>
                <div class="row mb-4">
                    <div class="col-12">
                        <h5>Comentarios</h5>
                    </div>
                </div>
                <div *ngIf="cabecera.ESDO_EP !== 109 && existeAccion('3')" class="row form-group">
                    <div class="col-10 col-md-10">
                        <input type="text" class="form-control" name="comentario" placeholder="Agregar Comentario"
                            #comentario formControlName="comentario"
                            [ngClass]="{ 'is-invalid': submittedComentario && fc.comentario.errors}" autocomplete="off">
                    </div>
                    <div class="col-2 col-md-2 text-right">
                        <button class="btn btn-primary btn-block" type="submit" [disabled]="formAgregarComentario.invalid || submittedComentario"><i
                                class="fas fa-paper-plane"></i></button>
                    </div>
                </div>
                <br>
                <br>
                <p [ngClass]="{'mb-4': !last}" *ngFor="let item of comentariosEP; let last = last">
                    <span>
                        <div>
                            <div class="d-flex">
                                <div class="round-name">
                                    {{item.NOMBRE_USUARIO.slice(0, 2)}}
                                </div>
                                <div class="nombre table">
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="mb">{{item.NOMBRE_USUARIO}}</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="ml-4 text-muted text-right">{{ item.FECHA_COMENTARIO }}</p>
                                        </div>
                                    </div>

                                    <span class="text-muted">{{item.COMENTARIO}}</span>
                                </div>
                            </div>
                        </div>
                    </span>
                </p>
            </form>
        </div>
    </div>
</div>


<div class="modal fade" id="modal-historial">
    <div class="modal-dialog modal-lg">
        <div style="width: 120%;" class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Historial EP</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div style="padding: 2rem !important;" class="modal-body">
                <div class="mb-1 text-right">
                    <app-view-excel-buttons [props]="excelButtonHistorial">
                    </app-view-excel-buttons>
                </div>
                <table class="table border">
                    <thead>
                        <th class="text-normal">Fecha</th>
                        <th class="text-normal">Usuario</th>
                        <th class="text-normal">Etapa</th>
                        <th class="text-normal">Estado</th>
                        <th class="text-normal">Grafo</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of historial">
                            <td style="width: 140px;">{{ item.FECHA_CREACION | date: 'dd/MM/yyy HH:mm:ss'}}</td>
                            <td style="width: 180px;">
                                <span class="text-muted">
                                    {{ item.NOMBRE_USUARIO }}
                                </span>
                            </td>
                            <td>
                                <p class="mb-0">
                                    {{ item.ESTADO }}
                                </p>
                            </td>
                            <td>
                                <div *ngIf="['Actual','Gestionado'].includes(item.VIGENTE)" class="tab tab-green">
                                    {{ item.VIGENTE }}
                                </div>
                                <div *ngIf="item.VIGENTE == 'Pendiente'" class="tab tab-yellow">
                                    {{ item.VIGENTE }}
                                </div>
                                <div *ngIf="item.VIGENTE == 'Rechazado'" class="tab tab-yellow">
                                    {{ item.VIGENTE }}
                                </div>
                            </td>
                            <td>
                                <p class="mb-0">
                                    {{ item.GRAFO }}
                                </p>
                                <span class="text-muted">
                                    {{ item.RESP }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row">
                    <!-- <div class="col-md-10"></div> -->
                    <div class="col-md-12 text-right">
                        <a href="javascript:;" class="btn tag text-primary " data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal de distribucion -->
<div class="modal fade" id="modal-distribucion">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Distribución</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <p style="font-weight: bold;">IMPORTANTE: La distribucion es por monto.</p>
                <form [formGroup]="formAgregarDistribucion" (ngSubmit)="onAgregarDistribucion()" novalidate>
                    <div class="row form-group">
                        <label for="articulo" class="col-md-12 col-form-label">Arículo</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="articulos" disabled #articulo formControlName="articulo" name="articulo"
                                class="select2-selection select2-selection--single"
                                [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.articulo.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.articulo.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="zona" class="col-md-12 col-form-label">Zona</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="zonas" #zona formControlName="zona" name="zona"
                                class="select2-selection select2-selection--single"
                                [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.zona.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.zona.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="blanco" class="col-md-12 col-form-label">Blanco</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="blancos" #blanco formControlName="blanco" name="blanco"
                                class="select2-selection select2-selection--single"
                                [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.blanco.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.blanco.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="grafo" class="col-md-12 col-form-label">Grafo</label>
                        <div class="col-md-12">
                            <ng-select2 [data]="grafos" #grafo formControlName="grafo" name="grafo"
                                class="select2-selection select2-selection--single"
                                [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.grafo.errors}">
                            </ng-select2>
                            <div *ngIf="submittedDistribucion && fdist.grafo.errors" class="invalid-feedback">
                                <div>Selecciona un elemento de la lista</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group mt-5">
                        <!-- <label for="cantidad" class="col-md-2 col-form-label">Cantidad Asignada</label>
                        <div class="col-md-4">
                            <input type="number" name="cantidad" formControlName="cantidad" #cantidad
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.cantidad.errors}"
                                (keyup)="changeCantidad()" appDecimalPlaces [readonly]="true"/>
                        </div> -->
                        <label for="monto" class="col-md-2 col-form-label">Monto Asignado</label>
                        <div class="col-md-4">
                            <input type="number" name="monto" formControlName="monto" #monto class="form-control"
                                [ngClass]="{ 'is-invalid': submittedDistribucion && fdist.monto.errors}"
                                (keyup)="changeMonto()"/>
                        </div>
                    </div>
                    <br>
                    <p class="text-muted">
                        <!-- El máximo asignable de catidad para esta distribución es: {{ cantidadDisponible | number:'1.2': 'es-CL'
                        }} <br> -->
                        El monto máximo por distribuir es: {{ montoDisponible | currency:
                        this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }} <br>
                    </p>
                    <p class="text-muted" *ngIf="this.precioUnitarioConReajuste > 0">
                        El precio unitario con el reajuste es: {{ this.precioUnitarioConReajuste | currency:
                        this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}
                    </p>
                    <input type="hidden" name="corr_item" formControlName="corr_item" #corr_item value="" />
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2" type="submit">Agregar</button>
                            <a href="javascript:;" #closeModalAgregarDistribucion
                                class="btn tag" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Subir Archivo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                <div class="modal-body">
                    <h5>{{ nombreChk }}</h5>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" formControlName="adjunto" id="adjunto"
                                    (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01"
                                    [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                            </div>
                        </div>
                        <input type="hidden" name="corr_chk" formControlName="corr_chk">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onCloseArchivo()"
                        #closeModalAgregarArchivo>Cancelar</button>
                    <button type="button" class="btn btn-primary" type="submit">Subir</button>
                </div>
            </form>
        </div>
    </div>
</div>


<!-- Modal Modificar Cantidad Distribucion -->
<div class="modal fade" id="modal-ModDistribucion">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Modificar Monto Distribución</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formModificarDistribucion" (ngSubmit)="onModificarDistribucion()" novalidate>
                    <div class="row form-group">
                        <label for="cantidad" class="col-md-2 col-form-label">Cantidad Asignada</label>
                        <div class="col-md-4">
                            <input type="number" name="cantidad" formControlName="cantidad" #cantidad
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submittedModDistribucion && fModDist.cantidad.errors}"
                                (keyup)="changeModCantidad()" [readonly]="true"/>
                        </div>
                        <label for="monto" class="col-md-2 col-form-label">Monto Asignado</label>
                        <div class="col-md-4">
                            <input type="number" name="monto" formControlName="monto" #monto class="form-control"
                                [ngClass]="{ 'is-invalid': submittedModDistribucion && fModDist.monto.errors}"
                                (keyup)="changeModMonto()" />
                        </div>
                    </div>
                    <br>
                    <p class="text-muted">
                        <!-- El máximo asignable de catidad para esta distribución es: {{ cantidadDisponible | number:'1.2': 'es-CL'
                        }} 
                        <br>  -->
                        El monto máximo por distribuir es: {{ montoDisponible |
                        number:'1.2': 'es-CL' }}
                    </p>

                    <input type="hidden" name="corr_distribucion" formControlName="corr_distribucion" #corr_distribucion
                        value="" />
                    <input type="hidden" name="item_correlativo" formControlName="item_correlativo" #item_correlativo
                        value="" />

                    <br>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-2"><a href="javascript:;" #closeModalModificarDistribucion
                                class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                        <div class="col-md-4">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146=""
                                    class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- summary grafos-->
<div class="modal fade" id="modal-Grafos">
    <div class="modal-dialog modal-lgx">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Total por grafo</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="mb-1 text-right">
                    <app-view-excel-buttons [props]="excelButtonGrafo">
                    </app-view-excel-buttons>
                </div>
                <table class="table border">
                    <thead class="">
                        <th colspan="1" class="text-normal">Codigo</th>
                        <th colspan="3" class="text-normal">Grafo</th>
                        <th colspan="1" class="text-normal">Responsable</th>
                        <th colspan="1" class="text-normal">Monto</th>
                    </thead>
                    <tr *ngFor="let grafo of this.distribucionesGrafos" class="">
                        <td colspan="1">
                            <span>
                                {{ grafo.codigo }}
                            </span>
                        </td>
                        <td colspan="3">
                            <span>
                                {{ grafo.nombre }}
                            </span>
                        </td>
                        <td colspan="1">
                            <span class="text-muted">
                                {{ grafo.responsable }}
                            </span>
                        </td>
                        <td colspan="1" class="text-right">
                            <span>
                                {{ grafo.monto }}
                            </span>
                        </td>
                    </tr>
                    <tfoot>
                        <td colspan="5">Total</td>
                        <td class="text-right" colspan="1">
                            <span>
                                {{ this.sumComprobante() / cabecera.EXCHAGE_RATE | currency: this.contractCurrenci?.ISOCode: '':
                                this.contractCurrenci?.decimalPipe : 'es-CL' }}
                            </span>
                        </td>
                    </tfoot>

                    <tr *ngFor="let grafo of this.distribucionesGrafosV1" class="">
                        <td colspan="1">
                            <span>
                                {{grafo[0] }}
                            </span>
                        </td>
                        <td colspan="3">
                            <span>
                                {{this.getGrafoName(grafo[0])[0]?.grafo }}
                            </span>
                        </td>
                        <td colspan="1">
                            <span class="text-muted">
                                {{ grafo[1][0].responsable }}
                            </span><!--  -->
                        </td>
                        <td colspan="1" class="text-right">
                            <span>
                                {{this.sumTotalGrafoV1(grafo[1]) | currency: this.contractCurrenci?.ISOCode: '':
                                this.contractCurrenci?.decimalPipe : 'es-CL'}}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- modal comentarios distribucion -->
<div class="modal fade" id="modal-comentarios-distribucion">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Comentarios</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="distribucionesComentarios.length > 0; else noComentarios">
                    <div class="row mt-4 mb-4" *ngFor="let comentario of this.distribucionesComentarios">
                        <div class="col-12">
    
                            <div class="d-flex ">
                                <div class="round-name">
                                    {{comentario.nombres.slice(0, 2)}}
                                </div>
                                <div class="nombre table">
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="mb">{{comentario.nombres}} {{comentario.apellido_paterno}}
                                                {{comentario.apellido_materno}}</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="ml-4 text-muted text-right">{{ comentario.Fecha | date: 'dd/MM/yyyyHH:mm'}}</p>
                                        </div>
                                    </div>
    
                                    <span class="text-muted">{{comentario.Comentario}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noComentarios>
                    <p class="text-center">No hay comentarios registrados</p>
                </ng-template>
                <ng-container *ngIf="cabecera.ESDO_EP !== 109">
                    <form [formGroup]="formAgregarComentarioDistribucion" action="">
                        <div class="row">
                            <div class="col-md-10">
                                <input type="text" class="form-control" name="comentariodist"
                                    placeholder="Agregar Comentario" #comentariodist formControlName="comentariodist"
                                    autocomplete="off">
                            </div>
                            <div class="col-md-2 text-right">
                                <button class="btn btn-primary btn-block" (click)="agregarComentarioDistribucion()" [disabled]="formAgregarComentarioDistribucion.invalid">
                                    <i class="fas fa-paper-plane"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- modal Rechazar distribucion -->
<div class="modal fade" id="modal-rechazar-distribucion">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Rechazo de distribución</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="row mt-4 mb-4" *ngFor="let comentario of this.distribucionesComentarios">
                    <div class="col-12">
                        <div class="d-flex ">
                            <div class="round-name">
                                {{comentario.nombres.slice(0, 2)}}
                            </div>
                            <div class="nombre table">
                                <div class="row">
                                    <div class="col-6">
                                        <p class="mb">{{comentario.nombres}} {{comentario.apellido_paterno}}
                                            {{comentario.apellido_materno}}</p>
                                    </div>
                                    <div class="col-6">
                                        <p class="ml-4 text-muted text-right">{{ comentario.Fecha | date: 'dd/MM/yyyyHH:mm'}}</p>
                                    </div>
                                </div>
                                <span class="text-muted">{{comentario.Comentario}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <form [formGroup]="formRechazarDistribucion" action="">
                    <div class="row">
                        <div class="col-12">
                            <p>Por favor ingrese el motivo por el cual esta Rechazando.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <input type="text" class="form-control" name="comentariodistrechazo"
                                placeholder="Ingrese un comentario" #comentariodistrechazo
                                formControlName="comentariodistrechazo" autocomplete="off">
                        </div>
                        <div class="col-md-2 text-right">
                            <button class="btn btn-primary btn-block" data-dismiss="modal" aria-hidden="true"
                                (click)="agregarComentarioDistribucionRechazo()">
                                Rechazar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- modal Rechazar distribucion FIN -->
<div class="modal fade" id="modal-comprobante">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Comprobante contable "{{ cabecera.EP_DESCRIPCION }}"</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="mb-1 text-right">
                    <app-view-excel-buttons [props]="excelButtonComprobante">
                    </app-view-excel-buttons>
                </div>
                <table class="table border">
                    <thead class="">
                        <th class="text-normal">Articulo</th>
                        <th class="text-normal">Zona</th>
                        <th class="text-normal">Blanco</th>
                        <th class="text-normal">Grafo</th>
                        <th class="text-normal text-right">CLP</th>
                    </thead>
                    <tr *ngFor="let comprobante of this.aComprobante" class="">
                        <td>
                            <span>
                                {{comprobante.articulo}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{comprobante.zona}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{comprobante.blanco}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{comprobante.grafo}}
                            </span>
                        </td>
                        <td class="text-right">
                            <ng-container *ngIf="cabecera.EXCHAGE_RATE == 1">
                                <span>
                                    {{ (comprobante.monto * cabecera.EXCHAGE_RATE) | currency:
                                    GlobalDataService.CLP?.ISOCode: "": GlobalDataService.CLP?.decimalPipe : 'es-CL'}}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="cabecera.EXCHAGE_RATE != 1">
                                <span>
                                    {{ comprobante.totalPorLinea | currency:
                                    GlobalDataService.CLP?.ISOCode: "": GlobalDataService.CLP?.decimalPipe : 'es-CL'}}
                                </span>
                            </ng-container>
                        </td>
                    </tr>
                    <tfoot>
                        <td colspan="4">Total</td>
                        <td class="text-right" colspan="1">
                            <span>
                                {{ this.sumComprobante() | currency: GlobalDataService.CLP?.ISOCode: "":
                                GlobalDataService.CLP?.decimalPipe: 'es-CL' }}
                            </span>
                        </td>
                    </tfoot>
                </table>

                <table class="table border mt-3" *ngIf="distribucionV1.length > 0">
                    <thead class="">
                        <th class="text-normal">Articulo</th>
                        <th class="text-normal">Zona</th>
                        <th class="text-normal">Blanco</th>
                        <th class="text-normal">Grafo</th>
                        <th class="text-normal">CLP</th>
                    </thead>
                    <tr *ngFor="let comprobante of this.distribucionV1" class="">
                        <td>
                            <span>
                                {{comprobante.cod_articulo}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{comprobante.cod_zona}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{comprobante.cod_blanco}}
                            </span>
                        </td>
                        <td>
                            <span>
                                {{comprobante.grafo}}
                            </span>
                        </td>
                        <td class="text-right">
                            <span>
                                {{ (comprobante.valor) | currency: GlobalDataService.CLP?.ISOCode: "":
                                GlobalDataService.CLP?.decimalPipe: 'es-CL' }}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- modal tasa -->
<div class="modal fade" id="modalTasaCambio">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Tasa de cambio</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-3">Tasa:</div>
                    <div class="col-md-9">
                        <ng-container *ngIf="tasas?.length > 0 ;then showSelect else showInput">

                        </ng-container>
                        <ng-template #showInput>
                            <input class="form-control" [formControl]="ExchangeRate" min="0" type="number"
                                [(value)]="cabecera.EXCHAGE_RATE">
                        </ng-template>
                        <ng-template #showSelect>
                            <select class="form-control" [(value)]="+cabecera.EXCHAGE_RATE" [formControl]="ExchangeRate">
                                <option  value="0">Seleccione ...</option>
                                <option *ngFor="let item of tasas" value="{{ +item.value}}">{{ item.date | date:'dd/MM/yyyy'}}: {{ item.value | number : '': 'es-CL'}}</option>
                            </select>
                        </ng-template>

                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-primary mr-2" (click)="onGuardarTasa()">Guardar</button>
                        <a href="javascript:;" class="btn" data-dismiss="modal" #closeModalGeneral>
                            Cerrar
                        </a>
                    </div>
                    <!-- <div class="col-md-2">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<!-- modal editar -->
<div class="modal fade" id="modalEditar">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Datos del ep</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body" *ngIf="cabecera">
                <!-- <div class="row">
                    <div class="col-md-3">Tasa:</div>
                    <div class="col-md-9">
                        <input class="form-control" [formControl]="ExchangeRate" min="0" type="number"
                            [(value)]="cabecera.EXCHAGE_RATE">
                    </div>
                </div> -->

                <div class="row form-group">
                    <div class="col-12">
                        <label style="font-size: 14px;" class="col-form-label mb-0 pb-0">Descripcion</label>
                        <input type="text" class="form-control" name="DescricionEP"
                                placeholder="Descripcion" #DescripcionEdit
                                [(value)]="cabecera.EP_DESCRIPCION"
                                [formControl]="DescricionEP" autocomplete="off">
                    </div>
                    <div class="col-12 mt-2">
                        <label style="font-size: 14px;" class="col-form-label mb-0 pb-0">Fecha Desde</label>
                        <div class="input-group">
                            <input type="date" [formControl]="PeriodoDesde" class="form-control m-b-5" #fecha_desde
                            [(value)]="cabecera.EP_PERIODO_DESDE">
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <label style="font-size: 14px;" class="col-form-label mb-0 pb-0">Fecha Hasta</label>
                        <div class="">
                            <input type="date" [formControl]="PeriodoHasta" class="form-control m-b-5" #fecha_hasta
                            [(value)]="cabecera.EP_PERIODO_HASTA">
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <label style="font-size: 14px;" class="col-form-label mb-0 pb-0">Reajuste (%)</label>
                        <div class="">
                            <input type="number" [formControl]="ReajusteEp" appDecimalPlaces class="form-control m-b-5" #reajuste_ep
                            [(value)]="cabecera.REAJUSTE">
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-primary mr-2" (click)="onGuardarModificacionGeneral()">Guardar</button>
                        <a href="javascript:;" class="btn btn-light" data-dismiss="modal"
                        (click)="onCloseModGeneral()" #closeModalGeneral
                        >
                            Cerrar
                        </a>
                    </div>
                    <!-- <div class="col-md-2">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal eliminar distribucion -->
<div class="modal fade" id="modal-eliminar-linea-distribucion" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar linea de distribución</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="font-size: 16px;">¿Esta seguro que desea eliminar esta línea de la distribución?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="eliminarLineaDistribucion()">Eliminar</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
</div>

<!-- modal Rechazar ep -->
<div class="modal fade" id="modal-rechazar-ep">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Rechazo de estado de pago</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formRechazarDistribucion" action="">
                    <div class="row">
                        <div class="col-12">
                            <p>Por favor ingrese el motivo por el cual esta Rechazando.</p>
                            <span class="text-muted">Este comentario sera publico</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <input type="text" class="form-control" name="comentariodistrechazo"
                                placeholder="Ingrese un comentario" #comentariodistrechazo
                                formControlName="comentariodistrechazo" autocomplete="off">
                        </div>
                        <div class="col-md-2 text-right">
                            <button class="btn btn-primary btn-block" data-dismiss="modal" aria-hidden="true"
                                (click)="agregarComentarioEPRechazo()">
                                Rechazar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- modal Rechazar ep FIN -->
<!-- Modal Aceptar distribucion grafos -->
<div class="modal fade" id="aceptarDistribucionGrafosModal" tabindex="-1" role="dialog" aria-labelledby="aceptarDistribucionGrafosModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="aceptarDistribucionGrafosModalLabel">Aceptar distribución</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Esta seguro que desea aceptar esta distribución?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptarDistribucion()">Aceptar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal HES existentes -->
<div class="modal fade" id="modalHesExistentes" tabindex="-1" role="dialog" aria-labelledby="modalHesExistentesLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalHesExistentesLabel">Estados de pago asociados a la HES consultada</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="hesExistentesEncontradas.length > 0">
            <h4>Estados de pago y contratos correspondientes asociados:</h4>
            <br>
            <ng-container *ngFor="let item of hesExistentesEncontradas">
                <div class="card">
                    <div class="card-body">
                        <p><span style="font-weight: bold;">Estado de pago:</span> {{item.ep_numero_descripcion}}</p>
                        <p><span style="font-weight: bold;">Contrato:</span> {{item.contrato_completo}}</p>
                        <p><span style="font-weight: bold;">Administrador EMSA:</span> {{item.nombre_dm_emsaa}}</p>
                    </div>
                </div>
            </ng-container>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
      </div>
    </div>
  </div>
</div>