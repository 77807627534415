import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BoletasService {

  constructor(private http: HttpClient) { }
  private urlService: string = environment.apiUrl;

  getBoletas(in_usua_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;        
    return this.http
        .get(this.urlService + 'boletas/getBoletas/' + in_usua_correlativo, { 
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let boletas = [];
                for (const item in resp['boletas']) {
                    boletas.push({...resp['boletas'][item] });
                }
                return JSON.stringify(boletas);
            }));
  } 
  getBoletasDiasVenc(in_dias : Number, in_usua_correlativo: number  ){    
    const token = JSON.parse(localStorage.getItem('userData'))._token;   
    return this.http
    .get(this.urlService + 'boletas/getBoletasDiasVenc/' + in_dias + '/' + in_usua_correlativo , { 
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let boletas = [];
          for (const item in resp['boletas']) {
            boletas.push({...resp['boletas'][item] });
          }
          return JSON.stringify(boletas);
      }));
      
  } 

  getCantidadBoletasPorDias(in_dias : Number, in_usua_correlativo: number){    
    const token = JSON.parse(localStorage.getItem('userData'))._token;   
    return this.http
    .get(this.urlService + 'boletas/getCantidadBoletasPorDias/' + in_dias + '/' + in_usua_correlativo , { 
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let boletas = [];
          for (const item in resp['boletas']) {
            boletas.push({...resp['boletas'][item] });
          }
          return JSON.stringify(boletas);
      }));
      
  } 

  getTipoDominios(str_tipo : any ){    
    const token = JSON.parse(localStorage.getItem('userData'))._token;   
    return this.http
    .get(this.urlService + 'boletas/getTipoDominios/' + str_tipo , { 
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let boletas = [];
          for (const item in resp['boletas']) {
            boletas.push({...resp['boletas'][item] });
          }
          return JSON.stringify(boletas);
      }));
      
  } 

  getBoleta(in_bg_corr : Number ){    
    const token = JSON.parse(localStorage.getItem('userData'))._token;   
    return this.http
    .get(this.urlService + 'boletas/getBoleta/' + in_bg_corr , { 
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let boleta = [];
          for (const item in resp['boletas']) {
            boleta.push({...resp['boletas'][item] });
          }
          return JSON.stringify(boleta);
      }));      
  } 

  postBoleta(postData : {
      iv_contrato : number,
      iv_banco : number,
      iv_nro_boleta : string,
      iv_moneda : number,
      iv_monto : number,      
      iv_fecha_emision : string,
      iv_fecha_ingreso : string,
      iv_fecha_vencimiento : string,
      iv_fecha_prorroga? : string,
      iv_fecha_devolucion? : string,
      iv_descripcion? : string,
      iv_observacion? : string,
      iv_adjunto? : string,
      iv_tipo? :number,
      in_usua_correlativo_creacion: number,
      in_tipo_documento: number
    } ){  
    const token = JSON.parse(localStorage.getItem('userData'))._token;    
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'boletas/postBoleta', postData, { 
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let boleta = [];
                  for (const item in resp['boleta']) {
                    boleta.push({...resp['boleta'][item] });
                  }
                  return JSON.stringify(boleta);
              }));
            
  } 

  postEditarBoleta(postData : {
    iv_bg_correlativo: number,
    iv_contrato : number,
    iv_banco : number,
    iv_nro_boleta : string,
    iv_moneda : number,
    iv_monto : number,      
    iv_fecha_emision : string,
    iv_fecha_ingreso : string,
    iv_fecha_vencimiento : string,
    iv_fecha_prorroga? : string,
    iv_fecha_devolucion? : string,
    iv_descripcion : string,
    iv_observacion : string,
    iv_tipo :number,
    in_usua_correlativo_creacion: number,
    in_tipo_documento: number,
    iv_adjunto_devolucion: string
  } ){  
  const token = JSON.parse(localStorage.getItem('userData'))._token;    
  postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
        .post(
          this.urlService + 'boletas/postEditarBoleta', postData, { 
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
          .pipe(
            map(resp => {
                let boleta = [];
                for (const item in resp['boleta']) {
                  boleta.push({...resp['boleta'][item] });
                }
                return JSON.stringify(boleta);
            }));
          
  }

  postEnviarCorreoModificacionBoleta(
    postData: {
      in_bg_correlativo: number,
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'boletas/postEnviarCorreoModificacionBoleta', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp['message']);
        }));
  }

  postEnviarCorreoModificacionDg(
    postData: {
      in_bg_correlativo: number,
    }
  ) {
    return this.http
      .post(
        this.urlService + 'boletas/postEnviarCorreoModificacionDg', postData, {
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp['message']);
        }));
  }

}
